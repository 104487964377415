import React, { useState, useCallback } from 'react';
import Modal from 'react-modal';

import { gql, useMutation } from "@apollo/client";

import ReactLoading from 'react-loading';
import { Formik, Field, Form, getIn, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import PageHeader from '../../components/PageHeader';

import { strToCurrencyFormatter } from '../../utils/currency'

const fiftyBasisPoints = 0.005

const GENERATE_AGENCY_PAY_INVOICE_PDF = gql`
  mutation GenerateAgencyPayInvoicePdf(
    $pdfTemplateValues: PdfTemplateValuesInput!
  ){
    generateAgencyPayInvoicePdf(
      pdfTemplateValues: $pdfTemplateValues
    ){
      ok
      message
      pdfUrl
      agencyPayInvoiceId
    }
}`

const SEND_AGENCY_REMIT_INVOICE_EMAIL = gql`
  mutation SendAgencyRemitEmail($agencyPayInvoiceId: ID!) {
    sendAgencyRemitEmail(agencyPayInvoiceId: $agencyPayInvoiceId) {
      ok
      message
    }
  }
`;

let contentStyle = {
  width                 : '40%',
  top                   : '50%',
  left                  : '50%',
  right                 : 'auto',
  bottom                : 'auto',
  marginRight           : '-50%',
  transform             : 'translate(-50%, -50%)',
  boxShadow             : '2px 2px 8px 0px rgba( 0, 0, 0, 0.2 )'
}

let modalStyles = {
  content : contentStyle,
  overlay : {
    backgroundColor : 'rgba(0, 0, 0, 0.5)'
  } 
};

const downloadIcon = (
  <img
    style={{ height: 20, verticalAlign: "bottom" }}
    src="/download.svg"
    alt="download icon"
  />
);

const validationSchema = Yup.object().shape({
  attention: Yup.string().required('Attention is required'),
  organizationName: Yup.string().required('Organization name is required'),
  addressLine1: Yup.string().required('Address line 1 is required'),
  locality: Yup.string().required('City is required'),
  region: Yup.string().required('State is required'),
  postalcode: Yup.string().required('Postal code is required'),
});

function InvoicePdfForm(props){
  const {
    setEmailError,
    setSuccessMessage,
    agencyPayInvoiceId,
    agencyPayPdfUrl,
    pdfErrorMessage,
    selectedContractors,
    errors,
    errorMessage,
    isSubmitting,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    purchaseOrderAmount,
    refetchOutstandingTasks = () => {},
    closeModal,
  } = props;
  
  const [sendAgencyRemitEmail, {loading: isSendingEmail, error: emailSendError, reset}] = useMutation(SEND_AGENCY_REMIT_INVOICE_EMAIL, {
    onCompleted: (data) => {
      if (data.sendAgencyRemitEmail.ok) {
        setSuccessMessage(data.sendAgencyRemitEmail.message);
        refetchOutstandingTasks();
        closeModal();
      } else {
        setEmailError(data.sendAgencyRemitEmail.message);
      }
    },
    onError: (error) => {
      setEmailError(`Failed to send email: ${error.message}`);
    }
  });

  

  const handleSendEmail = () => {
    if (agencyPayInvoiceId) {
      sendAgencyRemitEmail({
        variables: { agencyPayInvoiceId: agencyPayInvoiceId }
      });
    } else {
      setEmailError("No agency pay invoice ID available");
    }
  };

  const getPurchaseOrderId = (selectedContractors) => {
    const firstContractor = Object.values(selectedContractors)[0];
    return firstContractor ? firstContractor.purchaseOrderId : null;
  };

  const getCampaignName = (selectedContractors) => {
    const firstJob = Object.values(selectedContractors)[0];
    return firstJob ? firstJob.campaignName : null;
  };

  function sumSaasFeeAmounts(obj) {
    let array = Object.values(obj);
    let res = array.reduce((sum, item) => {
        let amount = Number(item.saasFeeAmount.replace(/[$,]/g, ''));
        return sum + amount;
    }, 0);
    return strToCurrencyFormatter.format(res)
  }

  function sumSaasFeeAmountsPaid(obj) {
    let array = Object.values(obj);
    let res = array.reduce((sum, item) => {
        if (item.saasFeePaidInFull === true) {
            let amount = Number(item.saasFeeAmount.replace('$', ''));
            return sum + amount;
        }
        return sum;
    }, 0);
    return strToCurrencyFormatter.format(res)
  }

  function sumPremiumsPaid(obj) {
    let array = Object.values(obj);
    let res = array.reduce((sum, item) => {
        if (item.paidInFull === true) {
            // Check for invoicePremium first
            let premium = item.invoicePremium 
                ? Number(item.invoicePremium.replace('$', '').replace(',', '')) 
                : (Number(item.wcPremium?.replace('$', '').replace(',', '')) || 0) + 
                  (Number(item.glPremiumTotalCost?.replace('$', '').replace(',', '')) || 0);
            return sum + premium;
        }
        return sum;
    }, 0);
    return res;
  }
  
  const renderError = () => (
    <div className="br2 f6 flex items-center justify-center pa2 bg-washed-red red mb3">
      <span className="lh-title ml3">{pdfErrorMessage || 'There was an error marking one or more paid in full. Please try again.'}</span>
    </div>
  )

  const renderContractorList = () => {
    function getFullName(row){
      return `${row.firstName} ${row.lastName}`
    }
    const contractorList = Object.keys(selectedContractors).map(function(k){return getFullName(selectedContractors[k])}).join(", ")
    return contractorList
  }

  function getStyles(errors, fieldName) {
    if (getIn(errors, fieldName)) {
      return {
        border: '1px solid red',
        backgroundColor: '#ffdfdf'
      }
    }
  }

  // Parse currency string to float
  const parseCurrency = (currencyStr) => {
    return parseFloat(currencyStr.replace(/[^0-9.-]+/g, "")) || 0;
  };
  
  const totalAmountOwed = parseCurrency(purchaseOrderAmount) - 
    sumPremiumsPaid(selectedContractors) + 
    Object.values(selectedContractors).reduce((total, contractor) => {
      return total + parseCurrency(contractor.saasFeeAmount);
    }, 0);

  const formattedTotalAmountOwed = strToCurrencyFormatter.format(totalAmountOwed);

  const loadingSpinner = () => (
    <ReactLoading type={'spin'} color={'#cccccc'} className="center" width={12} height={12} />
  )

  const renderInvoicePreview = () => {
    const purchaseOrderId = getPurchaseOrderId(selectedContractors);
    const campaignName = values.campaignName || getCampaignName(selectedContractors);

    const emailBody = `Attached is the invoice for the corresponding PO#${purchaseOrderId} ${campaignName} campaign. The total amount due is ${formattedTotalAmountOwed}.

Your prompt attention to this matter is greatly appreciated. Please reach out to stephanie.villa@1099policy.com if you have any questions.`;

    return (
      <div className="flex flex-column" style={{ height: 'calc(100vh - 200px)' }}>
        <h2 className="f5 fw6 lh-copy ph0 mb3">Email Preview</h2>
        <div className="flex-auto overflow-auto">
          <div className="mb3">
            <div className="ba b--black-10 br2 pa3 mb3 f7 lh-copy">
              <p><strong>Subject:</strong> Invoice for PO#{purchaseOrderId} {values.campaignName} – {formattedTotalAmountOwed}</p>
              <p><strong>Body:</strong></p>
              <div className="white-space-pre-line">{emailBody}</div>
            </div>
          </div>
          <div className="mb3">
            <iframe
              src={agencyPayPdfUrl}
              title="Invoice Preview"
              width="100%"
              height="500px"
              className="ba b--black-10 br2"
            />
          </div>
        </div>
        <div className="bt b--black-10 pt3 tr">
          <button
            onClick={handleSendEmail}
            disabled={isSendingEmail}
            className="f6 link dim br2 ph3 pv2 mb2 dib white bg-blue bn pointer"
          >
            {isSendingEmail ? loadingSpinner() : "Send Invoice Email"}
          </button>
        </div>
      </div>
    );
  };

  const renderFormBody = () => (
    <Form onSubmit={handleSubmit}>
      <div className="pb2">
        {(pdfErrorMessage || errorMessage) ? renderError() : null}
        <h2 className="f5 fw5 lh-copy ph0">
          Confirm the following: 
        </h2>
        <div className="dt pv2">
          <div className="dtc">
            Total premium: <b>{purchaseOrderAmount}</b> ({strToCurrencyFormatter.format(sumPremiumsPaid(selectedContractors))}  paid)
          </div>
        </div>
        <div className="dt pv2">
          <div className="dtc">
            Total platform access fees: <b>{sumSaasFeeAmounts(selectedContractors)}</b> ({sumSaasFeeAmountsPaid(selectedContractors)} paid)
          </div>
        </div>
        <div className="lh-copy">
          Including the following contractors in the 1099Policy PDF invoice:&nbsp; <b>{renderContractorList()}</b>
        </div>
      </div>
      <span className="w-100">
        <label htmlFor="organizationName" className="f6 fw5 db mb2 mt3">Organization name</label>
          <Field name="organizationName"
            style={getStyles(errors, 'organizationName')}
            className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
            type="text"
            value={values.organizationName}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Organization name"
          />  
      </span>
      <span className="w-100">
        <label htmlFor="addressLine1" className="f6 fw5 db mb2 mt3">Organization address</label>
          <Field name="addressLine1"
            style={getStyles(errors, 'addressLine1')}
            className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
            type="text"
            value={values.addressLine1}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Address line 1"
          />  
      </span>
      <span className="w-100">
        <label htmlFor="addressLine2" className="f6 fw5 db mb2 mt2"></label>
          <Field name="addressLine2"
            style={getStyles(errors, 'addressLine2')}
            className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
            type="text"
            value={values.addressLine2}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Address line 2"
          />  
      </span>
      <span className="w-100">
        <label htmlFor="addressLine3" className="f6 fw5 db mb2 mt2"></label>
          <Field name="addressLine3"
            style={getStyles(errors, 'addressLine3')}
            className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
            type="text"
            value={values.addressLine3}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Address line 3"
          />  
      </span>
      <div className="db">
        <div className="pb2 dib w-50 mr2">
          <span className="w-100">
            <label htmlFor="locality" className="f6 fw5 db mb2 mt3">City</label>
              <Field name="locality"
                style={getStyles(errors, 'locality')}
                className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
                type="text"
                value={values.locality}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="City"
              />  
          </span>
        </div>
        <div className="pb2 dib w-20 ml2">
          <span className="w-100">
            <label htmlFor="region" className="f6 fw5 db mb2 mt3">State</label>
              <Field name="region"
                style={getStyles(errors, 'region')}
                className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
                type="text"
                value={values.region}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="State"
              />  
          </span>
        </div>
        <div className="pb2 dib w-25 ml2">
          <span className="w-100">
            <label htmlFor="postalcode" className="f6 fw5 db mb2 mt3">Postal code</label>
              <Field name="postalcode"
                style={getStyles(errors, 'postalCode')}
                className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
                type="text"
                value={values.postalcode}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Postal code"
              />  
          </span>
        </div>
      </div>
      <span className="w-100">
        <label htmlFor="attention" className="f6 fw5 db mb2 mt3">Attention</label>
          <Field name="attention"
            style={getStyles(errors, 'attention')}
            className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
            type="text"
            value={values.attention}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="e.g., Jack Smith"
          />  
      </span>
      <span className="w-100">
        <label htmlFor="platformAccessFee" className="f6 fw5 db mb2 mt3">Platform Access Fee</label>
          <Field name="platformAccessFee"
            style={getStyles(errors, 'platformAccessFee')}
            className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
            type="text"
            value={values.platformAccessFee}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder='e.g., $22.00'
          />  
      </span>
      <div className="mt4 fr">
        <button className="f6 link dim br2 ba ph3 pv2 mb2 dib black pointer" onClick={props.closeModal}>Cancel</button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="f6 link dim br2 ph3 pv2 mb2 dib white bg-blue bn pointer ml2">
          Confirm
        </button>
      </div>
    </Form>
  )
  return (
    <div>
      {agencyPayPdfUrl && renderInvoicePreview()}
      {!agencyPayPdfUrl && renderFormBody()}
    </div>
  );
}

function InvoicePdfModal(props){
  const { 
    jobs,
    selectedContractors,
    setSuccessMessage,
    contentLabel,
    modalIsOpen,
    closeModal,
    refetchOutstandingTasks
  } = props

  const [emailError, setEmailError] = useState(null);
  const [agencyPayPdfUrl, setAgencyPayPdfUrl] = useState(false)
  const [pdfErrorMessage, setPdfErrorMessage] = useState(false)
  const [agencyPayInvoiceId, setAgencyPayInvoiceId] = useState(false)

  const [generateAgencyPayInvoicePdf, {loading, error, reset }] = useMutation(GENERATE_AGENCY_PAY_INVOICE_PDF, {
    onCompleted: (data)=>{
      if (!data.generateAgencyPayInvoicePdf?.ok){
        setPdfErrorMessage(data.generateAgencyPayInvoicePdf?.message)
        reset()
      } else {
        setAgencyPayInvoiceId(data.generateAgencyPayInvoicePdf?.agencyPayInvoiceId)
        setAgencyPayPdfUrl(data.generateAgencyPayInvoicePdf?.pdfUrl)
        setSuccessMessage(data.generateAgencyPayInvoicePdf?.message)
        reset()
      }
    },
    refetchQueries: ['FinancialReporting']
  })

  const prepSubmitData = (values) => {
    return {
      jobIds: jobs,
      organizationName: values.organizationName,
      organizationAddress: {
        line1: values.addressLine1,
        line2: values.addressLine2,
        line3: values.addressLine3,
        locality: values.locality,
        region: values.region,
        postalcode: values.postalcode,
      },
      attention: values.attention,
      platformAccessFee: values.platformAccessFee
    }
  }

  const platformFeeEstimate = () => {
    function currencyToNumber(currency){
      return Number(currency.replace(/[^0-9.-]+/g,""));
    }
    if (Object.values(selectedContractors).length <= 0){
      return 0
    }
    
    const selectedContractorClone = structuredClone(selectedContractors);
    const feeEstimate = Object.values(selectedContractorClone).map(item => currencyToNumber(item?.remuneration)).reduce((prev, next) => prev + next) * fiftyBasisPoints
    
    return strToCurrencyFormatter.format(feeEstimate)
  }
  
  const oneRow = Object.values(selectedContractors)[0]
  
  const formInitialValues = {
    organizationName: oneRow?.organizationName,
    addressLine1: oneRow?.organizationAddressLine1,
    addressLine2: oneRow?.organizationAddressLine2,
    addressLine3: oneRow?.organizationAddressLine3,
    locality: oneRow?.organizationLocality,
    region: oneRow?.organizationRegion,
    postalcode: oneRow?.organizationPostalcode,
    attention: oneRow?.purchaseOrderContactName,
    platformAccessFee: platformFeeEstimate(),
    poNumber: oneRow?.poNumber,
    campaignName: oneRow?.campaign
  }

  const renderEmailError = (error) => (
    <div className="br2 f6 flex items-center justify-center pa2 bg-washed-red red mb3">
      <span className="lh-title ml3">{error}</span>
    </div>
  )

  const renderLoading = () => (
    <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
  )

  const handleCloseModal = useCallback(() => {
    setPdfErrorMessage(false);
    setAgencyPayPdfUrl(false);
    setEmailError(null); // Reset email error
    closeModal();
  }, [closeModal]);

  const renderModalBody = () => (
    <div className="roboto">
      <PageHeader title={'Verify Invoice Details'} />
      
      {emailError && renderEmailError(emailError)}

      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const templateValues = prepSubmitData(values)
          setSubmitting(true)
          generateAgencyPayInvoicePdf({
            variables: {
              pdfTemplateValues: {
                ...templateValues
              }
            }
          })
        }}
      >
        {(formikProps ) => (
          <InvoicePdfForm 
            {...props} 
            {...formikProps}
            setSuccessMessage={setSuccessMessage}
            setEmailError={setEmailError}
            agencyPayInvoiceId={agencyPayInvoiceId}
            agencyPayPdfUrl={agencyPayPdfUrl}
            pdfErrorMessage={pdfErrorMessage} 
            selectedContractors={selectedContractors}
            refetchOutstandingTasks={refetchOutstandingTasks}
            closeModal={handleCloseModal}
          />
        )}
      </Formik>
    </div>
  )

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleCloseModal}
      style={modalStyles}
      contentLabel={contentLabel}
    >
      {loading ? renderLoading() : renderModalBody()}
    </Modal>
  )
}

export default InvoicePdfModal;