import { gql } from '@apollo/client';

export const CORE_CONTRACTOR_FIELDS = gql`
  fragment CoreContractorFields on Contractor {
      publicId
      email
      created
      firstName
      lastName
      address {
        line1
        line2
        line3
        locality
        region
        postalcode
      }
      assignments {
        edges {
          node {
            workState
            residenceState
            policy {
              publicId
            }
            invoice {
              publicId
              grossPay
            }
            hasUpcomingPayment
            creditCardChargeDatetime
            totalPremiumOwed
            voided
            effectiveDate
            endDate
            eligibilityStatus
            cancelled
            certificate {
              edges {
                node {
                  wcCoiPdfUrl
                  glCoiPdfUrl
                  copcUrl
                }
              } 
            }
            job {
              publicId
              name
              description
              wage
              jobCategory {
                className
                code
              }
              entity {
                publicId
                name
              }
              poContactName
              poContactEmail
            }
          }
        }
      }
      unredactedDict
      eligibility {
        edges {
          node {
            isEligible
          }
        }
      }
      withholdPremium
      withholdPremiumOrgOverride
      poContactName
      poContactEmail
      companyName
      lastActiveCoveragePeriod
      quotes {
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            insuranceApplication
            publicId
            totalPremiumOwed
            policy {
              edges {
                node {
                  publicId
                  isActive
                  effectiveDate
                  cancelledDate
                  expirationDate
                  pdfUrl
                  wcCoiPdfUrl
                  glCoiPdfUrl
                  esignDisclosures {
                    edges {
                      node {
                        esignPdfUrl
                      }
                    }
                  }
                  copcUrl
                }
              }
            }
            coverageType {
              edges {
                node {
                  name
                }
              }
            }
            invoice {
              publicId
              grossPay
            }
            voided
            effectiveDate
            endDate
            workState
            residenceState
            quoteJsonWorkState
            quoteJsonResidenceState
            job {
              publicId
              name
              description
              wage
              jobCategory {
                className
                code
              }
              entity {
                publicId
                name
              }
              poContactName
              poContactEmail
            }
          }
        }
      }
      externalCertificates {
        edges {
          node {
            certificateAudits {
              edges {
                node {
                  publicId
                  created
                  status
                  certificate {
                    filename
                    pdfUrl
                  }
                  parsedCertificateJson
                  auditResults {
                    edges {
                      node {
                        ruleJson
                        resultJson
                      }
                    }
                  }
                }
              }
            }
            
          }
        }
      }
    }
`;