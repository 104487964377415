import React, {useState, useContext} from 'react';

import { useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';

import { gql, useMutation } from "@apollo/client";

import { AuthContext } from "../../contexts/authContext";
import OnboardingHeader from "../../components/OnboardingHeader";
import AutocompleteOrganizationInput from "../../components/AutocompleteOrganizationInput";

import { SET_ORGANIZATION, LOGOUT_FROM_BACKEND } from "./mutations";

function AdminSelectOrganization(props){
  const { client } = props;

  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  
  const selectedOrg = !!localStorage.getItem("Ten99PolicyOrganization");

  const [setOrganization, {loading, error, reset }] = useMutation(SET_ORGANIZATION, {
    onCompleted: () => {
      reset();
      
      client.refetchQueries({
        include: "all",
      });

      navigate("/overview");
    },
    onError: () => {
      reset()
    },
  })

  function logoutFromDashboard() {
    localStorage.removeItem("Ten99PolicyEnvironment");
    localStorage.removeItem("Ten99PolicyOrganization");
    props.client.clearStore();
  
    auth.signOut();
    navigate("/signin");
  }

  const [logoutFromBackend] = useMutation(LOGOUT_FROM_BACKEND, {
    onCompleted: (data) => {
      logoutFromDashboard()
    },
    onError: () => {
      logoutFromDashboard()
    },
  })

  const onLogout = (event) => {
    logoutFromBackend()
  };
 
  const renderLoading = () => (
    <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
  )

  const renderSelectOrg = () => (
    <OnboardingHeader onLogout={onLogout}>
      <div className="vh-75 dt w-100">
        <div className="dtc v-mid">
          <div className="mw5 center">
            <div className="measure relative">
              <label className="f5 fw6 db mb2 mt3 black-70 dib" htmlFor="name">
                Select Organization </label>
              <AutocompleteOrganizationInput setOrganization={setOrganization} />
            </div>
          </div>
        </div>
      </div>  
    </OnboardingHeader>
  )

  return (
    <>
      {loading && !selectedOrg ? renderLoading() : renderSelectOrg()}
    </>
  )
}

export default AdminSelectOrganization