import { gql } from "@apollo/client";

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization(
    $name: String!,
    $withholdPremium: Boolean,
    $creatorIqApikey: String!,
    $certificateHolder: CertificateHolderInput,
    $companyAddress: CompanyAddressInput,
    $bankAccount: BankAccountInput,
    $billToEmailPrimary: [String],
    $billToEmailCc: [String]
  )
  {
    updateOrganization(
      name: $name,
      withholdPremium: $withholdPremium,
      creatorIqApikey: $creatorIqApikey,
      certificateHolder: $certificateHolder,
      companyAddress: $companyAddress,
      bankAccount: $bankAccount,
      billToEmailPrimary: $billToEmailPrimary,
      billToEmailCc: $billToEmailCc
    ){
      ok
    }
  }
`;

export const UPDATE_INSURANCE_REQUIREMENT = gql`
  mutation UpdateInsuranceRequirement(
    $publicId: String!,
    $name: String!,
    $jobCategories: [String],
    $coverageRules: [CoverageRuleInput]
  ){
    updateInsuranceRequirement(
      publicId: $publicId,
      name: $name,
      jobCategories: $jobCategories,
      coverageRules: $coverageRules,
    ){
      ok
      insuranceRequirement {
        publicId
      }
    }
  }
`;

export const DELETE_INSURANCE_REQUIREMENT = gql`
  mutation DeleteInsuranceRequirement(
    $publicId: String!
  ){
    deleteInsuranceRequirement(
      publicId: $publicId
    ){
      ok
    }
  }
`;