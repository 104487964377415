import React from "react";
import { Helmet } from "react-helmet";

import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import Dashboard from "./dashboard/Dashboard";

import "tachyons/css/tachyons.min.css";
import "./styles";

function App() {
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="theme-color" content="#000000" />
        <title>Dashboard – 1099Policy</title>
        <link
          rel="canonical"
          href="https://dashboard.1099policy.com"
          target="_blank"
        />
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Dashboard />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
