import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const EmailPill = ({ email, onRemove, disabled }) => (
  <div className="mt2">
    <button
      type="button"
      className={`br4 ba f7 pa2 ${
        disabled
          ? "b--black-10 bg-black-5"
          : "bg-washed-blue b--blue blue pointer"
      }`}
      onClick={() => {
        if (!disabled) {
          onRemove(email);
        }
      }}
    >
      <div className="flex items-center space-between">
        <span className="tl">{email}</span>
        {!disabled && <img className="dib pl1 w1" src="/x.svg" alt="Close" />}
      </div>
    </button>
  </div>
);

const EmailInput = ({ value, onChange, isActiveEdit, placeholder }) => {
  const [inputValue, setInputValue] = useState('');
  const wrapperRef = useRef(null);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if ((e.key === ' ' || e.key === ',') && inputValue.trim()) {
      if (validateEmail(inputValue.trim())) {
        onChange([...value, inputValue.trim()]);
        setInputValue('');
      } else {
        alert('Invalid email address');
      }
      e.preventDefault();
    }
  };

  const handleRemoveEmail = (emailToRemove) => {
    onChange(value.filter(email => email !== emailToRemove));
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setInputValue('');
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div className="dib w-30 relative" ref={wrapperRef}>
      <div className={classNames("flex items-center flex-wrap pa2 ba br2", {
        "b--black-10": isActiveEdit,
        "b--transparent": !isActiveEdit,
      })}>
        {value.map((email, index) => (
          <EmailPill key={index} email={email} onRemove={handleRemoveEmail} disabled={!isActiveEdit} />
        ))}
        {isActiveEdit && (
          <input
            type="text"
            className="outline-0 pa2 f6 fw3 border-box flex-grow-1 bn" // Added 'bn' for no border
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder || "Enter email and press space or comma"}
          />
        )}
      </div>
    </div>
  );
};

EmailInput.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  isActiveEdit: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
};

export default EmailInput;
