import React from 'react'

export const Email: React.FunctionComponent<{ email: string; emailLabel?: string; emailIsValid: boolean; setEmail: (_: string) => void; hideEmail?: boolean; itemProps?: object }> = ({
  email,
  emailLabel,
  emailIsValid,
  setEmail,
  hideEmail,
  itemProps,
}) => {
  return (
    <span className={hideEmail ? 'dn' : 'db pv1'}>
      <label className="f6 fw5" htmlFor="email">{emailLabel || 'Email'}</label>
      <input
        id="email"
        type="text"
        value={email}
        className="outline-0 ba b--moon-gray pa3 mt2 mb3 f5 w-100 br2"
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
          return setEmail(evt.target.value)
        }}
        required={!hideEmail}
        {...itemProps}
      />
      {!emailIsValid ? 
        <div className="brand-pink f6">Invalid email</div> : null
      }
    </span>
  )
}

Email.defaultProps = {
  hideEmail: false
};

export const Password: React.FunctionComponent<{
  label: string;
  className?: string;
  placeholder?: string;
  passwordIsValid: boolean;
  setPassword: (_: string) => void
  submitFunction?: () => void
}> = ({ label, className, placeholder, passwordIsValid, setPassword, submitFunction }) => {
  return (
    <span className="db pv1">
      <label className="f6 fw5" htmlFor="password">{label}</label>
      <input
        id="password"
        type="password"
        className={className ? className : "outline-0 ba b--moon-gray pa3 mt2 mb3 f5 w-100 br2"}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
          return setPassword(evt.target.value)
        }}
        placeholder={placeholder}
        required
        onKeyDown={e => e.key === 'Enter' ? submitFunction : ''}
      />
      {!passwordIsValid ? 
        <div className="brand-pink f6">Invalid password – minimum 8 characters</div> : null
      }
    </span>
  )
}

// TODO: Treating Username as Email. AWS Cognito hack because both username 
// and email are required. There seems to be no difference between the two. 
// 
export const Username: React.FunctionComponent<{ username?: string; usernameIsValid: boolean; setUsername: (_: string) => void }> = ({
  username,
  usernameIsValid,
  setUsername,
}) => {
  return (
    <span className="db pv2">
      <label className="f6 fw5" htmlFor="username">Email</label>
      <input
        id="username"
        value={username}
        type="text"
        className="outline-0 ba b--moon-gray pa3 mt2 mb3 f5 w-100 br2"
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
          localStorage.setItem("Ten99PolicyDefaultEmail", evt.target.value)
          return setUsername(evt.target.value)
        }}
        required
      />
      {!usernameIsValid ? 
        <div className="brand-pink f6">Invalid email</div> : null
      }
    </span>
  )
}

export const Code: React.FunctionComponent<{ codeIsValid: boolean; setCode: (_: string) => void }> = ({
  codeIsValid,
  setCode,
}) => {
  return (
    <span className="db pv2">
    <label className="f6 fw5" htmlFor="username">Code</label>
    <input
      id="username"
      type="text"
      className="outline-0 ba b--moon-gray pa3 mt2 mb3 f5 w-100 br2"
      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
        return setCode(evt.target.value)
      }}
      required
    />
    {!codeIsValid ? 
      <div className="brand-pink f6">Invalid code – Minimum 6 characters</div> : null
    }
  </span>
  )
}
