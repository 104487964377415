export const columnKeys = [
  "entity_name",
  "first_name",
  "last_name",
  "vendor_company_name",
  "email",
  "job_name",
  "job_description",
  "primary_work_state",
  "vendor_address_line1",
  "vendor_address_line2",
  "vendor_address_locality",
  "vendor_address_region",
  "vendor_address_postalcode",
  "legal_entity_type",
  "tax_id",
  "phone",
  "num_of_employees",
  "effective_start_date",
  "effective_end_date",
];
