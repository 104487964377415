import React, { useContext } from "react";
import { QueryRenderer, CubeContext, useCubeQuery } from "@cubejs-client/react";

import ChartLoading from "./ChartLoading";
import SparkChart from "../../../components/Sparkline";

import { shortFormDate as dateOptions } from "../../../utils/date";

const TotalWCPremium = (props) => {
  const EmptyChart = () => (
    <SparkChart
      label="Total workers comp premium"
      resultSet={[]}
      dataKeyX="x"
      dataKeyLine="Policy.wcTotalPremium"
      formatType="currency"
      staticNumber={0}
      dateRange={props.dateRange}
    />
  );

  const isFreshOrg = props.isFreshOrg;

  if (isFreshOrg) {
    return <EmptyChart />;
  }

  //Returns total workers compe premium as number, for entire period of time
  const {
    resultSet: resultSetNumber,
    isLoading,
    error: errorNumber,
    progress,
  } = useCubeQuery({
    measures: ["Policy.wcTotalPremium"],
  });
  const { cubejsApi } = useContext(CubeContext);

  function renderChart({ staticNumber, resultSet, error }) {
    if (error) {
      console.log("cube error:", error.toString())
      return <div></div>;
    }

    if (!resultSet) {
      return <ChartLoading height={"h4"} />;
    }

    let res = resultSet.chartPivot();
    res = res.map((r) => {
      return {
        ...r,
        x: new Date(r.x).toLocaleDateString("en-US", dateOptions),
      };
    });

    return (
      <SparkChart
        label="Total workers comp premium"
        resultSet={res}
        dataKeyX="x"
        dataKeyLine="Policy.wcTotalPremium"
        formatType="currency"
        staticNumber={staticNumber}
        dateRange={props.dateRange}
      />
    );
  }

  if (isLoading || !resultSetNumber) {
    return <ChartLoading height={"h4"} />;
  }

  if (errorNumber) {
    return <div>{errorNumber.toString()}</div>;
  }

  const totalPremiumNumber =
    resultSetNumber.totalRow()["Policy.wcTotalPremium"];

  const startDate = props?.dateRange?.startDate
    ? props?.dateRange?.startDate
    : "2022-01-01";
  const endDate = props?.dateRange?.endDate;

  return (
    <QueryRenderer
      query={{
        measures: ["Policy.wcTotalPremium"],
        timeDimensions: [
          {
            dimension: "Policy.created",
            granularity: "month",
            dateRange: [startDate, endDate],
          },
        ],
        order: {
          "Policy.created": "asc",
        },
      }}
      cubejsApi={cubejsApi}
      resetResultSetOnChange={false}
      render={(props) =>
        renderChart({
          staticNumber: totalPremiumNumber,
          ...props,
        })
      }
    />
  );
};

export default TotalWCPremium;
