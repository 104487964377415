import React, { useState, useContext } from "react";

import { useMutation } from "@apollo/client";
import { CopyToClipboard } from "react-copy-to-clipboard";

import OutsideAlerter from "./OutsideAlerter";
import AutocompleteOrganizationInput from "./AutocompleteOrganizationInput";

import { SET_ORGANIZATION } from "../pages/OrganizationPage/mutations";

import { useNavigate } from "react-router-dom";

function OrganizationSwitcher(props) {
  const { organization, organizationList, isAdmin, client } = props;

  const [showMenu, setShowMenu] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const navigate = useNavigate();

  const [setOrganization, { reset }] = useMutation(SET_ORGANIZATION, {
    onCompleted: () => {
      reset();
      client.resetStore();
      navigate("/overview");
    },
  });

  const onShowMenu = (event) => {
    event.preventDefault();
    setShowMenu(true);
  };

  const closeMenu = () => {
    setIsCopied(false);
    setShowMenu(false);
  };

  function base64Decode(id) {
    let res = atob(id);
    return res.toString("ascii").split(":")[1];
  }

  const renderOrgAutocomplete = () => {
    if (isAdmin) {
      return (
        <AutocompleteOrganizationInput
          setOrganization={setOrganization}
          menuStyleTop="85px"
          menuStyleLeft="15px"
        />
      );
    } else if (organizationList.length > 1) {
      return (
        <AutocompleteOrganizationInput
          setOrganization={setOrganization}
          menuStyleTop="85px"
          menuStyleLeft="15px"
        />
      );
    }
    return <></>;
  };

  return (
    <div className="pointer relative db w5" onClick={onShowMenu}>
      <div className="f7 ph3 black">
        {organization?.name}
        <img
          style={{ width: "1.4rem" }}
          className="dib v-mid pr1"
          src="/chevron.svg"
          alt="Chevron"
        />
      </div>
      {showMenu ? (
        <OutsideAlerter closeMenu={closeMenu}>
          <div className="absolute shadow-4 bg-white org-menu z-3 br2">
            <div className="ma0 sibling bg-white br1 f6 near-black pt2 pb3 pl3 pr3">
              <CopyToClipboard
                text={base64Decode(organization?.id)}
                onCopy={() => setIsCopied(true)}
              >
                <div className="pt2 f7">
                  Organization ID: {base64Decode(organization?.id)}
                  {isCopied ? (
                    <span className="black-50">&nbsp; – Copied!</span>
                  ) : null}
                </div>
              </CopyToClipboard>
              {renderOrgAutocomplete()}
            </div>
          </div>
        </OutsideAlerter>
      ) : null}
    </div>
  );
}

export default OrganizationSwitcher;
