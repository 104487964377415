import React, { useState, useContext } from "react";

import { Link, useNavigate } from "react-router-dom";

import OutsideAlerter from "./OutsideAlerter";

import { AuthContext } from "../contexts/authContext";
import { gql, useMutation } from "@apollo/client";

const LOGOUT_FROM_BACKEND = gql`
  mutation Logout {
    logout {
      ok
    }
  }
`

const LogoutButton = (props) => (
  <div onClick={props.onLogout}>
    <div className="ma0 mid-black link dim pointer w4 lh-copy">Sign Out</div>
  </div>
);


function NavigationHeader(props) {
  const [showMenu, setShowMenu] = useState(false);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  function logoutFromDashboard() {
    localStorage.removeItem("Ten99PolicyEnvironment");
    localStorage.removeItem("Ten99PolicyOrganization");
    props.client.clearStore();
  
    auth.signOut();
    navigate("/signin");
  }

  const [logoutFromBackend, {reset}] = useMutation(LOGOUT_FROM_BACKEND, {
    onCompleted: (data) => {
      logoutFromDashboard()
    },
    onError: () => {
      logoutFromDashboard()
      reset()
    },
  })

  const onLogout = (event) => {
    logoutFromBackend()
  };

  const onShowMenu = (event) => {
    event.preventDefault();
    setShowMenu(true);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  return (
    <nav className="flex justify-between bb b--white-10">
      <a
        className="link white-70 hover-white no-underline flex items-center ph3 pt3 pb2"
        href=""
      >
        <img
          className="w-80"
          src="/1099Policy-logo.svg"
          alt="1099Policy Logo"
        />
      </a>
      <div
        className="flex-grow ph3 pt3 pb2 flex items-center relative"
        onClick={onShowMenu}
      >
        <a
          className="link dim mr3 f6 near-black"
          style={{ outline: "none" }}
          href="#0"
        >
          <img
            className="dib v-mid pr1"
            style={{ width: "1rem" }}
            src="/user.svg"
            alt="User"
          />
          Account
        </a>
        {showMenu ? (
          <OutsideAlerter closeMenu={closeMenu}>
            <div className="absolute shadow-4 bg-white menu z-3 br2">
              <div className="ma0 sibling bg-white br1 f6 near-black pt2 pb3 pl3 pr3">
                <div className="lh-copy pt3 pb2 truncate">{props.email}</div>
                <hr className="b--black-10 bb bt-0" />
                <div className="ma0 mv3 mid-black link dim pointer w4 lh-copy">
                  <Link className="link dim near-black" to="/profile">
                    Profile
                  </Link>
                </div>
                <LogoutButton onLogout={onLogout} />
              </div>
            </div>
          </OutsideAlerter>
        ) : null}
      </div>
    </nav>
  );
}

export default NavigationHeader;
