export function startAndEnd(str, charCount = 35) {
  const PREPEND_OFFSET = 20;
  const APPEND_OFFSET = 25;

  if (str.length > charCount) {
    return (
      str.substr(0, charCount - PREPEND_OFFSET) +
      "..." +
      str.substr(str.length - (charCount - APPEND_OFFSET), str.length)
    );
  }
  return str;
}
