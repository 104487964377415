import Badge from '../../components/Badge';

export default function CustomBadge(props){
    const { status } = props
  
    let statusLabel = ""
    let color = ""
    let badgeClass = ""
  
    if (status === "APPROVED"){
      statusLabel = <>{"Approved"}</>
      color = "green"
    }
    else if (status === "REJECTED"){
      statusLabel = <>{"Rejected"}</>
      color = "red"
    }
    else if (status === "FLAGGED"){
      statusLabel = <>{"Failed checks"}</>
      color = "red"
    } 
    else if (status === "PROCESSING"){
      statusLabel = <>{"Processing"}</>
      color = "blue"
      badgeClass = "bg-washed-blue blue"
  
    } else if (status === "DENIED"){
        statusLabel = <>{"Denied"}</>
        color = "red"
    }
    else {
      statusLabel = status
      color = "silver"
    }
    return (
      <Badge color={color} className={`fw5 ba ${badgeClass}`}>
        {statusLabel}
      </Badge>
    )
  }