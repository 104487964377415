import React, { useState } from 'react'
import Modal from 'react-modal';

import { useMutation } from "@apollo/client";

import PageHeader from '../../components/PageHeader';
import PDFUpload from '../../components/PDFUpload';

import { CREATE_CONTRACTOR } from './mutations';


let contentStyle = {
  width                 : '640px',
  maxHeight             : '90vh',
  top                   : '50%',
  left                  : '50%',
  right                 : 'auto',
  bottom                : 'auto',
  marginRight           : '-50%',
  transform             : 'translate(-50%, -50%)',
  boxShadow             : '2px 2px 8px 0px rgba( 0, 0, 0, 0.2 )',
  overflow              : 'hidden',
}

let modalStyles = {
  content : contentStyle,
  overlay : {
    backgroundColor : 'rgba(0, 0, 0, 0.5)'
  } 
};

function AddCertificateModal(props){
  const [contractor, setContractor] = useState();
  
  const [createContractor, { loading, error, reset}] = useMutation(CREATE_CONTRACTOR, {
    onCompleted: (data)=> {
      if (!data.createContractor?.ok){
        props.setErrorMessage(true)
        reset()
      } else {
        setContractor(data.createContractor?.contractor)
        props.setSuccessMessage(data.createContractor?.message)
        props.setErrorMessage(false)
      }
    }
  });

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={modalStyles}
      contentLabel={props.contentLabel}
    >
      <div className="roboto">
        <PageHeader title={'Upload Certificate of Insurance'} />
        <PDFUpload 
          createContractor={createContractor} 
          loading={loading} 
          setContractor={setContractor}
          contractor={props.contractor || contractor}
          token={props.token}
          setSuccessMessage={props.setSuccessMessage}
          setErrorMessage={props.setErrorMessage}
          closeModal={props.closeModal}
          uploadOnly={props.uploadOnly}
        />
      </div>
    </Modal>
  )
}

export default AddCertificateModal;