import React, { useState, useEffect } from "react";

import Autocomplete from "react-autocomplete";
import styled from "styled-components";

import { gql, useQuery } from "@apollo/client";

const InputWrapper = styled.div`
  width: 270px;
  display: inline-block;
`;

const ALL_CONTRACTOR_QUERY = gql`
  query AllContractors {
    contractors(sort: CREATED_DESC) {
      unredacted
    }
  }
`;

function matchContractorToTerm(contractor, value) {
  return (
    (contractor.first_name !== null &&
      contractor.first_name.toLowerCase().indexOf(value.toLowerCase()) !==
        -1) ||
    (contractor.last_name !== null &&
      contractor.last_name.toLowerCase().indexOf(value.toLowerCase()) !== -1) ||
    (contractor.email !== null &&
      contractor.email.toLowerCase().indexOf(value.toLowerCase()) !== -1)
  );
}

function AutocompleteContractorInput(props) {
  const [value, setValue] = useState("");
  const [item, setItem] = useState(null);
  const { setAutoCompleteItem, menuTop, menuLeft, inputWidth } = props;

  const { loading, data } = useQuery(ALL_CONTRACTOR_QUERY);

  const top = menuTop ? menuTop : "150px";
  const left = menuLeft ? menuLeft : "30px";
  const width = inputWidth ? inputWidth : "270px";

  return loading ? (
    <InputWrapper>
      <input
        className="input-reset f6 ba b--black-20 pa2 w-100 mv3 db br2"
        type="text"
      />
    </InputWrapper>
  ) : (
    <Autocomplete
      renderInput={(props) => (
        <div
          style={{
            position: "relative",
            display: "inline-block",
            width: width,
          }}
        >
          <img
            style={{
              position: "absolute",
              left: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              height: "16px",
              width: "16px",
            }}
            src="/search.svg"
            alt="search icon"
          />
          <input {...props} style={{ paddingLeft: "30px" }} />
        </div>
      )}
      wrapperProps={{ style: { display: "inline-block", width: width } }}
      inputProps={{
        name: "contractorClassName",
        placeholder: "Enter contractor name or email",
        className: "input-reset f6 ba b--black-20 pa2 w-100 mv3 db br2",
        type: "text",
      }}
      menuStyle={{
        borderRadius: "3px",
        boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
        background: "rgba(255, 255, 255, 0.9)",
        padding: "2px 0",
        fontSize: "40%",
        position: "absolute",
        top: top,
        left: left,
        overflow: "auto",
        width: "200px",
        maxHeight: "200px",
      }}
      getItemValue={(item) =>
        `${item.first_name} ${item.last_name} (${item.email})`
      }
      items={data.contractors.unredacted}
      renderItem={(item, isHighlighted) => (
        <div
          key={item.id}
          className="pa2 f6"
          style={{ background: isHighlighted ? "lightgray" : "white" }}
        >
          {item.first_name} {item.last_name} &nbsp;
          <span className="gray">({item.email})</span>
        </div>
      )}
      value={value}
      shouldItemRender={matchContractorToTerm}
      onChange={(e) => {
        setValue(e.target.value);
        if (e.target.value === "") {
          setAutoCompleteItem(null);
        }
      }}
      onSelect={(val, item) => {
        setValue(val);
        setItem(item);
        setAutoCompleteItem(item);
      }}
    />
  );
}

export default AutocompleteContractorInput;
