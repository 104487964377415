import React, { memo, useState } from "react";

import { Formik, Form, Field } from 'formik';
import { NumericFormat } from 'react-number-format';
import { PieChart } from 'react-minimal-pie-chart';

import Badge from "../../components/Badge";
import { startAndEnd } from "../../utils/truncate";

import { getResultsByCoverageGroup, mapResultsToFields } from "./helpers";

function CertificateDetailForm(props) {
  const { 
    auditData, 
    auditResult, 
    status, 
    publicId,
    updateCertificate 
  } = props;

  const coverageResultsByGroup = getResultsByCoverageGroup(auditResult)
  const formInitialValues = {}

  const auditResultToFieldMap = mapResultsToFields(auditResult)

  formInitialValues.producerName = ""
  formInitialValues.producerAddressLine1 = ""
  formInitialValues.producerAddressLine2 = ""
  formInitialValues.producerAddressLine3 = ""
  formInitialValues.producerAddressLocality = ""
  formInitialValues.producerAddressRegion = ""
  formInitialValues.producerAddressPostalcode = ""

  formInitialValues.insuredName = ""
  formInitialValues.insuredAddressLine1 = ""
  formInitialValues.insuredAddressLine2 = ""
  formInitialValues.insuredAddressLine3 = ""
  formInitialValues.insuredAddressLocality = ""
  formInitialValues.insuredAddressRegion = ""
  formInitialValues.insuredAddressPostalcode = ""

  formInitialValues.entityName = ""
  formInitialValues.entityAddressLine1 = ""
  formInitialValues.entityAddressLine2 = ""
  formInitialValues.entityAddressLine3 = ""
  formInitialValues.entityAddressLocality = ""
  formInitialValues.entityAddressRegion = ""
  formInitialValues.entityAddressPostalcode = ""

  formInitialValues.glPolicyNumber = ""
  formInitialValues.glInsurer = ""
  formInitialValues.glOccurrence = ""
  formInitialValues.glClaimsMade = ""
  formInitialValues.glPolicyEffectiveDate = ""
  formInitialValues.glPolicyExpirationDate = ""
  formInitialValues.glWaiverOfSubrogation = null
  formInitialValues.glAdditionalInsured = null

  formInitialValues.glPolicyLimit = {
    eachOccurrence: "",
    damageToRented: "",
    medExp: "",
    personalInjury: "",
    generalAggregate: "",
    productsCompAggregate: ""
  }

  formInitialValues.wcPolicyNumber = ""
  formInitialValues.wcInsurer = ""
  formInitialValues.wcPolicyEffectiveDate = ""
  formInitialValues.wcPolicyExpirationDate = ""
  formInitialValues.wcWaiverOfSubrogation = null
  formInitialValues.wcAdditionalInsured = null

  formInitialValues.wcPolicyLimit = {
    perStatute: "",
    other: "",
    elEachAccident: "",
    elDiseaseEaEmployee: "",
    elDiseasePolicyLimit: ""
  }

  formInitialValues.autoPolicyNumber = ""
  formInitialValues.autoInsurer = ""
  formInitialValues.autoPolicyEffectiveDate = ""
  formInitialValues.autoPolicyExpirationDate = ""
  formInitialValues.autoWaiverOfSubrogation = null
  formInitialValues.autoAdditionalInsured = null

  formInitialValues.autoPolicyType = {
    ownedAuto: null,
    hiredAuto: null,
    scheduledAuto: null,
    nonOwnedAuto: null
  }

  formInitialValues.autoPolicyLimit = {
    combinedSingleLimit: "",
    bodilyInjuryPerPerson: "",
    bodilyInjuryPerAccident: "",
    propertyDamage: "",
  }

  formInitialValues.umbrellaPolicyNumber = ""
  formInitialValues.umbrellaInsurer = ""
  formInitialValues.umbrellaPolicyType = ""
  formInitialValues.umbrellaPolicyEffectiveDate = ""
  formInitialValues.umbrellaPolicyExpirationDate = ""
  formInitialValues.umbrellaWaiverOfSubrogation = null
  formInitialValues.umbrellaAdditionalInsured = null

  formInitialValues.umbrellaPolicyLimit = {
    eachOccurrence: "",
    aggregate: ""
  }

  formInitialValues.excessPolicyNumber = ""
  formInitialValues.excessInsurer = ""
  formInitialValues.excessPolicyType = ""
  formInitialValues.excessPolicyEffectiveDate = ""
  formInitialValues.excessPolicyExpirationDate = ""
  formInitialValues.excessWaiverOfSubrogation = null
  formInitialValues.excessAdditionalInsured = null

  formInitialValues.excessPolicyLimit = {
    eachOccurrence: "",
    aggregate: ""
  }

  formInitialValues.descriptionOfOperations = ""
  formInitialValues.primaryNonContributory = ""

  const NumberInputComponent = ({ field, form, className }) => (
    <NumericFormat
      {...field}
      thousandSeparator={true}
      onValueChange={({ value }) => {
        form.setFieldValue(field.name, value);
      }}
      className={`${className}`}
    />
  );

  function FormField({ label, name, auditFields, className, component = 'input', ...rest }) {
    const passedRuleClass = name && auditResultToFieldMap[name] == 'pass' ? 'bg-washed-green' : ''
    const failedRuleClass = name && auditResultToFieldMap[name] == 'fail' ? 'bg-washed-red' : ''
    
    return (
      <div className="pv2">
        <span className="w-100">
          <label htmlFor={name} className="f6 fw5 dib w-50 black-50">
            {label}
          </label>
          <CustomField
            name={name}
            auditFields={auditFields}
            className={`outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2 ${passedRuleClass} ${failedRuleClass} ${ className ? className : ''}`}
            component={component}
            {...rest}
          />
        </span>
      </div>
    );
  }
  
  function RadioField({ label, name, value, auditFields, className, ...rest }) {
    const failedRuleClass = name && auditResultToFieldMap[name] == 'fail' ? 'bg-washed-red' : ''

    return (
      <div>
        <label className="f6" htmlFor={`${name}-${value}`}>
        <CustomField
          type="radio"
          id={`${name}-${value}`}
          name={name}
          value={value}
          // checked based on inital value 
          auditFields={auditFields}
          className={`outline-0 pa3 f6 fw3 ba b--black-10 br2 mr2 ${failedRuleClass} ${ className ? className : ''}`}
          {...rest}
        />
          {label}
        </label>
      </div>
    );
  }

  function CheckboxField({ label, name, auditFields, className, ...rest }) {
    const failedRuleClass = name && auditResultToFieldMap[name] == 'fail' ? 'red' : ''
    return (
      <div className="pv2">
        <label className={`f6 fw5 dib w-100 black-50 ${failedRuleClass}`}>
          <Field
            type="checkbox"
            name={name}
            className={`mr2 ${ className ? className : ''}`}
            {...rest}
          />
          {label}
        </label>
      </div>
    );
  }

  function CustomField({ name, auditFields, className, component, ...rest }) {
    return (
      <Field
        name={name}
        className={`${className}`}
        component={component}
        {...rest}
      />
    );
  }

  function InsuredForm(props) {
    const { values, auditFields } = props;
    
    const evalOutcome = 'insured' in coverageResultsByGroup ? coverageResultsByGroup['insured'].meetsCoverageRules : null

    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
      setIsVisible(!isVisible);
    };
  
    return (
      <div className="pb3">
        <div className="dark-gray bb b--black-10 mt0 pb2 pointer" onClick={toggleVisibility}>
          <div className="cf">
            <span className="fl w-60">
              Insured
              <span className="f7 black-30">{!isVisible && (values.insuredName ? ` – ${startAndEnd(values.insuredName, 40)}` : "")}</span>
            </span>
            <span className="fl w-40 tr">
            {makeReviewBadge(evalOutcome)}
            </span>
          </div>
        </div>
        {isVisible && (
          <div className="pv3">
            <FormField label="Name" name="insuredName" auditFields={auditFields} />
            <FormField label="Address line 1" name="insuredAddressLine1" auditFields={auditFields} />
            <FormField label="Address line 2" name="insuredAddressLine2" auditFields={auditFields} />
            <FormField label="Address line 3" name="insuredAddressLine3" auditFields={auditFields} />
            <FormField label="City" name="insuredAddressLocality" auditFields={auditFields} />
            <FormField label="State" name="insuredAddressRegion" auditFields={auditFields} />
            <FormField label="Postal Code" name="insuredAddressPostalcode" auditFields={auditFields} />
          </div>
        )}
      </div>
    );
  }
  
  function EntityForm(props) {
    const { values, auditFields } = props;

    const evalOutcome = 'entity' in coverageResultsByGroup ? coverageResultsByGroup['entity'].meetsCoverageRules : null
    
    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
      setIsVisible(!isVisible);
    };

  
    return (
      <div className="pv3">
        <div className="dark-gray bb b--black-10 mt0 pb2 pointer" onClick={toggleVisibility}>
          <div className="cf">
            <span className="fl w-60">
              Certificate Holder
              <span className="f7 black-30">{!isVisible && (values.entityName ? ` – ${startAndEnd(values.entityName, 40)}` : "")}</span>
            </span>
            <span className="fl w-40 tr">
            {makeReviewBadge(evalOutcome)}
            </span>
          </div>
        </div>
        {isVisible && (
          <div className="pv3">
            <FormField label="Name" name="entityName" auditFields={auditFields} />
            <FormField label="Address line 1" name="entityAddressLine1" auditFields={auditFields} />
            <FormField label="Address line 2" name="entityAddressLine2" auditFields={auditFields} />
            <FormField label="Address line 3" name="entityAddressLine3" auditFields={auditFields} />
            <FormField label="City" name="entityAddressLocality" auditFields={auditFields} />
            <FormField label="State" name="entityAddressRegion" auditFields={auditFields} />
            <FormField label="Postal Code" name="entityAddressPostalcode" auditFields={auditFields} />
          </div>
        )}
      </div>
    );
  }
  
  function GeneralLiabilityForm(props) {
    const { values, auditFields } = props;
    
    const evalOutcome = 'general' in coverageResultsByGroup ? coverageResultsByGroup['general'].meetsCoverageRules : null
    
    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
      setIsVisible(!isVisible);
    };
    
    return (
      <div className="pv3">
        <div className="dark-gray bb b--black-10 mt0 pb2 pointer" onClick={toggleVisibility}>
          <div className="cf">
            <span className="fl w-60">
              General Liability
              <span className="f7 black-30">{!isVisible && (values.glPolicyNumber ? ` – ${startAndEnd(values.glPolicyNumber, 40)}` : "")}</span>
            </span>
            <span className="fl w-40 tr">
            {makeReviewBadge(evalOutcome)}
            </span>
          </div>
        </div>
        {isVisible && (
          <div className="pv3">
            <FormField label="Policy Number" name="glPolicyNumber" auditFields={auditFields} />
            <FormField label="Insurer" name="glInsurer" auditFields={auditFields} />
            <div className="pv2">
              <span className="w-100">
                <div className="f6 fw5 dib w-20 mv2 black-50">Policy type</div>
                <RadioField label="Occurrence" name="glPolicyType" value="occurrence" auditFields={auditFields} checked={values.glPolicyType == "occurrence"} />
                <RadioField label="Claims-made" name="glPolicyType" value="claims_made" auditFields={auditFields} checked={values.glPolicyType == "claims_made"} />
              </span>
            </div>
            <FormField label="Effective Date" name="glPolicyEffectiveDate" auditFields={auditFields} />
            <FormField label="Expiration Date" name="glPolicyExpirationDate" auditFields={auditFields} />
            <CheckboxField
              label="Waiver of Subrogation"
              name="glWaiverOfSubrogation"
              auditFields={auditFields}
              checked={values.glWaiverOfSubrogation}
            />
            <CheckboxField
              label="Additional Insured"
              name="glAdditionalInsured"
              auditFields={auditFields}
              checked={values.glAdditionalInsured}
            />
            <FormField 
              label="Limits: Each Occurrence" 
              name="glPolicyLimit.eachOccurrence" 
              auditFields={auditFields} 
              component={NumberInputComponent}
            />
            <FormField 
              label="Limits: Damage to Rented" 
              name="glPolicyLimit.damageToRented" 
              auditFields={auditFields} 
              component={NumberInputComponent}
            />
            <FormField 
              label="Limits: Medical Expense" 
              name="glPolicyLimit.medExp" 
              auditFields={auditFields} 
              component={NumberInputComponent}
            />
            <FormField 
              label="Limits: Personal Injury" 
              name="glPolicyLimit.personalInjury" 
              auditFields={auditFields} 
              component={NumberInputComponent}
            />
            <FormField 
              label="Limits: General Aggregate" 
              name="glPolicyLimit.generalAggregate" 
              auditFields={auditFields} 
              component={NumberInputComponent}
            />
            <FormField 
              label="Limits: Products Comp Aggregate" 
              name="glPolicyLimit.productsCompAggregate" 
              auditFields={auditFields} 
              component={NumberInputComponent}
            />
          </div>
        )}
      </div>
    );
  }
  
  function WorkersCompensationForm(props) {
    const { values, auditFields } = props;
    
    const evalOutcome = 'workersComp' in coverageResultsByGroup ? coverageResultsByGroup['workersComp'].meetsCoverageRules : null

    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
      setIsVisible(!isVisible);
    };

    return (
      <div className="pv3">
        <div className="dark-gray bb b--black-10 mt0 pb2 pointer" onClick={toggleVisibility}>
          <div className="cf">
            <span className="fl w-60">
              Workers' Compensation
              <span className="f7 black-30">{!isVisible && (values.wcPolicyNumber ? `  – ${startAndEnd(values.wcPolicyNumber, 40)}` : "")}</span>
            </span>
            <span className="fl w-40 tr">
              {makeReviewBadge(evalOutcome)}
            </span>
          </div>
        </div>
        {isVisible && (
          <div className="pv3">
            <FormField label="Policy Number" name="wcPolicyNumber" auditFields={auditFields} />
            <FormField label="Insurer" name="wcInsurer" auditFields={auditFields} />
            <FormField label="Effective Date" name="wcPolicyEffectiveDate" auditFields={auditFields} />
            <FormField label="Expiration Date" name="wcPolicyExpirationDate" auditFields={auditFields} />
            <CheckboxField
              label="Waiver of Subrogation"
              name="wcWaiverOfSubrogation"
              auditFields={auditFields}
              checked={values.wcWaiverOfSubrogation}
            />
          </div>
        )}
      </div>
    );
  }

  function ProfessionalForm(props) {
    const { values, auditFields } = props;
  
    const evalOutcome = 'professional' in coverageResultsByGroup ? coverageResultsByGroup['professional'].meetsCoverageRules : null

    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
      setIsVisible(!isVisible);
    };

  
    return (
      <div className="pv3">
        <div className="dark-gray bb b--black-10 mt0 pb2 pointer" onClick={toggleVisibility}>
          <div className="cf">
            <span className="fl w-50">
              Professional Liability
              <span className="f7 black-30">{!isVisible && (values.plPolicyNumber ? ` – ${startAndEnd(values.plPolicyNumber, 40)}` : "")}</span>
            </span>
            <span className="fl w-50 tr">
            {makeReviewBadge(evalOutcome)}
            </span>
          </div>
        </div>
        {isVisible && (
          <div className="pv3">
            <FormField label="Policy Number" name="plPolicyNumber" auditFields={auditFields} />
            <FormField label="Insurer" name="plInsurer" auditFields={auditFields} />
            <FormField label="Effective Date" name="plPolicyEffectiveDate" auditFields={auditFields} />
            <FormField label="Expiration Date" name="plPolicyExpirationDate" auditFields={auditFields} />
            <div className="pv3">
              <CheckboxField
                label="Waiver of Subrogation"
                name="plWaiverOfSubrogation"
                auditFields={auditFields}
                checked={values.plWaiverOfSubrogation}
              />
              <CheckboxField
                label="Additional Insured"
                name="plAdditionalInsured"
                auditFields={auditFields}
                checked={values.plAdditionalInsured}
              />
            </div>
            <FormField 
              label="Limits: Each Occurrence" 
              name="plPolicyLimit.eachOccurrence" 
              auditFields={auditFields} 
              component={NumberInputComponent}
            />
            <FormField 
              label="Limits: Aggregate" 
              name="plPolicyLimit.aggregate" 
              auditFields={auditFields} 
              component={NumberInputComponent}
            />
          </div>
        )}
      </div>
    );
  }
  
  function AutoForm(props) {
    const { values, auditFields } = props;

    const evalOutcome = 'auto' in coverageResultsByGroup ? coverageResultsByGroup['auto'].meetsCoverageRules : null

    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
      setIsVisible(!isVisible);
    };

    return (
      <div className="pv3">
        <div className="dark-gray bb b--black-10 mt0 pb2 pointer" onClick={toggleVisibility}>
          <div className="cf">
            <span className="fl w-50">
              Automobile Liability
              <span className="f7 black-30">{!isVisible && (values.autoPolicyNumber ? `  – ${startAndEnd(values.autoPolicyNumber, 40)}` : "")}</span>
            </span>
            <span className="fl w-50 tr">
              {makeReviewBadge(evalOutcome)}
            </span>
          </div>
        </div>
        {isVisible && (
          <div className="pv3">
            <FormField label="Policy Number" name="autoPolicyNumber" auditFields={auditFields} />
            <FormField label="Insurer" name="autoInsurer" auditFields={auditFields} />

            <div className="pv3">
              <div className="w-100 flex items-start">
                <label className="f6 fw5 dib w-20 black-50 mt2">Policy Type</label>
                <div role="group" aria-labelledby="checkbox-group" className="outline-0 w-40 f6 fw3 flex flex-wrap">
                  <div className="w-100 fl">
                    <div className="w-100">
                      <CheckboxField
                        label="Owned Autos Only"
                        name="autoPolicyType.ownedAuto"
                        auditFields={auditFields}
                        // checked={values.autoCoverageType}
                      />
                    </div>
                    <div className="w-100">
                      <CheckboxField
                        label="Hired Autos Only"
                        name="autoPolicyType.hiredAuto"
                        auditFields={auditFields}
                        // checked={values.autoCoverageType}
                      />
                    </div>
                    <div className="w-100">
                      <CheckboxField
                        label="Scheduled Autos"
                        name="autoPolicyType.scheduledAuto"
                        auditFields={auditFields}
                        // checked={values.autoCoverageType}
                      />
                    </div>
                    <div className="w-100">
                      <CheckboxField
                        label="Non-owned Autos Only"
                        name="autoPolicyType.nonOwnedAuto"
                        auditFields={auditFields}
                        // checked={values.autoCoverageType}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <FormField label="Effective Date" name="autoPolicyEffectiveDate" auditFields={auditFields} />
            <FormField label="Expiration Date" name="autoPolicyExpirationDate" auditFields={auditFields} />
            
            <div className="pv3">
              <CheckboxField
                label="Waiver of Subrogation"
                name="autoWaiverOfSubrogation"
                auditFields={auditFields}
                checked={values.autoWaiverOfSubrogation}
              />
              <CheckboxField
                label="Additional Insured"
                name="autoAdditionalInsured"
                auditFields={auditFields}
                checked={values.autoAdditionalInsured}
              />
            </div>

            <FormField 
              label="Combined Single Limit" 
              name="autoPolicyLimit.combinedSingleLimit" 
              auditFields={auditFields} 
              component={NumberInputComponent}
            />
            <FormField 
              label="Bodily Injury Per Accident" 
              name="autoPolicyLimit.bodilyInjuryPerAccident" 
              auditFields={auditFields} 
              component={NumberInputComponent}
            />
            <FormField 
              label="Bodily Injury Per Person" 
              name="autoPolicyLimit.bodilyInjuryPerPerson" 
              auditFields={auditFields} 
              component={NumberInputComponent}
            />
            <FormField 
              label="Property Damage" 
              name="autoPolicyLimit.propertyDamage" 
              auditFields={auditFields} 
              component={NumberInputComponent}
            />
          </div>
        )}
      </div>
    );
  }
  
  function UmbrellaForm(props) {
    const { values, auditFields } = props;
  
    const evalOutcome = 'umbrella' in coverageResultsByGroup ? coverageResultsByGroup['umbrella'].meetsCoverageRules : null

    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
      setIsVisible(!isVisible);
    };

  
    return (
      <div className="pv3">
        <div className="dark-gray bb b--black-10 mt0 pb2 pointer" onClick={toggleVisibility}>
          <div className="cf">
            <span className="fl w-50">
              Umbrella Liability
              <span className="f7 black-30">{!isVisible && (values.umbrellaPolicyNumber ? ` – ${startAndEnd(values.umbrellaPolicyNumber, 40)}` : "")}</span>
            </span>
            <span className="fl w-50 tr">
            {makeReviewBadge(evalOutcome)}
            </span>
          </div>
        </div>
        {isVisible && (
          <div className="pv3">
            <FormField label="Policy Number" name="umbrellaPolicyNumber" auditFields={auditFields} />
            <FormField label="Insurer" name="umbrellaInsurer" auditFields={auditFields} />
            <div className="pv2">
              <span className="w-100">
                <label className="f6 fw5 dib w-20 mv2 black-50">Policy type</label>
                <RadioField label="Occurrence" name="umbrellaPolicyType" value="occurrence" auditFields={auditFields} checked={values.umbrellaPolicyType == "occurrence"} />
                <RadioField label="Claims-made" name="umbrellaPolicyType" value="claims_made" auditFields={auditFields} checked={values.umbrellaPolicyType == "claims_made"} />
              </span>
            </div>
            <FormField label="Effective Date" name="umbrellaPolicyEffectiveDate" auditFields={auditFields} />
            <FormField label="Expiration Date" name="umbrellaPolicyExpirationDate" auditFields={auditFields} />

            <div className="pv3">
              <CheckboxField
                label="Waiver of Subrogation"
                name="umbrellaWaiverOfSubrogation"
                auditFields={auditFields}
                checked={values.umbrellaWaiverOfSubrogation}
              />
              <CheckboxField
                label="Additional Insured"
                name="umbrellaAdditionalInsured"
                auditFields={auditFields}
                checked={values.umbrellaAdditionalInsured}
              />
            </div>
            <FormField 
              label="Limits: Each Occurrence" 
              name="umbrellaPolicyLimit.eachOccurrence" 
              auditFields={auditFields} 
              component={NumberInputComponent}
            />
            <FormField 
              label="Limits: Aggregate" 
              name="umbrellaPolicyLimit.aggregate" 
              auditFields={auditFields} 
              component={NumberInputComponent}
            />
          </div>
        )}
      </div>
    );
  }

  function ExcessForm(props) {
    const { values, auditFields } = props;
  
    const evalOutcome = 'excess' in coverageResultsByGroup ? coverageResultsByGroup['excess'].meetsCoverageRules : null

    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
      setIsVisible(!isVisible);
    };

    return (
      <div className="pv3">
        <div className="dark-gray bb b--black-10 mt0 pb2 pointer" onClick={toggleVisibility}>
          <div className="cf">
            <span className="fl w-50">
              Excess Liability
              <span className="f7 black-30">{!isVisible && (values.excessPolicyNumber ? ` – ${startAndEnd(values.excessPolicyNumber, 40)}` : "")}</span>
            </span>
            <span className="fl w-50 tr">
            {makeReviewBadge(evalOutcome)}
            </span>
          </div>
        </div>
        {isVisible && (
          <div className="pv3">
            <FormField label="Policy Number" name="excessPolicyNumber" auditFields={auditFields} />
            <FormField label="Insurer" name="excessInsurer" auditFields={auditFields} />
            <div className="pv2">
              <span className="w-100">
                <label className="f6 fw5 dib w-20 mv2 black-50">Policy type</label>
                <RadioField label="Occurrence" name="excessPolicyType" value="occurrence" auditFields={auditFields} checked={values.excessPolicyType == "occurrence"} />
                <RadioField label="Claims-made" name="excessPolicyType" value="claims_made" auditFields={auditFields} checked={values.excessPolicyType == "claims_made"} />
              </span>
            </div>
            <FormField label="Effective Date" name="excessPolicyEffectiveDate" auditFields={auditFields} />
            <FormField label="Expiration Date" name="excessPolicyExpirationDate" auditFields={auditFields} />

            <div className="pv3">
              <CheckboxField
                label="Waiver of Subrogation"
                name="excessWaiverOfSubrogation"
                auditFields={auditFields}
                checked={values.excessWaiverOfSubrogation}
              />
              <CheckboxField
                label="Additional Insured"
                name="excessAdditionalInsured"
                auditFields={auditFields}
                checked={values.excessAdditionalInsured}
              />
            </div>
            <FormField 
              label="Limits: Each Occurrence" 
              name="excessPolicyLimit.eachOccurrence" 
              auditFields={auditFields} 
              component={NumberInputComponent}
            />
            <FormField 
              label="Limits: Aggregate" 
              name="excessPolicyLimit.aggregate" 
              auditFields={auditFields} 
              component={NumberInputComponent}
            />
          </div>
        )}
      </div>
    );
  }

  function CyberForm(props) {
    const { values, auditFields } = props;
  
    const evalOutcome = 'cyber' in coverageResultsByGroup ? coverageResultsByGroup['cyber'].meetsCoverageRules : null

    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
      setIsVisible(!isVisible);
    };

  
    return (
      <div className="pv3">
        <div className="dark-gray bb b--black-10 mt0 pb2 pointer" onClick={toggleVisibility}>
          <div className="cf">
            <span className="fl w-50">
              Cyber Liability
              <span className="f7 black-30">{!isVisible && (values.cyberPolicyNumber ? ` – ${startAndEnd(values.cyberPolicyNumber, 40)}` : "")}</span>
            </span>
            <span className="fl w-50 tr">
            {makeReviewBadge(evalOutcome)}
            </span>
          </div>
        </div>
        {isVisible && (
          <div className="pv3">
            <FormField label="Policy Number" name="cyberPolicyNumber" auditFields={auditFields} />
            <FormField label="Insurer" name="cyberInsurer" auditFields={auditFields} />
            <FormField label="Effective Date" name="cyberPolicyEffectiveDate" auditFields={auditFields} />
            <FormField label="Expiration Date" name="cyberPolicyExpirationDate" auditFields={auditFields} />
            <div className="pv3">
              <CheckboxField
                label="Waiver of Subrogation"
                name="cyberWaiverOfSubrogation"
                auditFields={auditFields}
                checked={values.cyberWaiverOfSubrogation}
              />
              <CheckboxField
                label="Additional Insured"
                name="cyberAdditionalInsured"
                auditFields={auditFields}
                checked={values.cyberAdditionalInsured}
              />
            </div>
            <FormField 
              label="Limits: Each Occurrence" 
              name="cyberPolicyLimit.eachOccurrence" 
              auditFields={auditFields} 
              component={NumberInputComponent}
            />
            <FormField 
              label="Limits: Aggregate" 
              name="cyberPolicyLimit.aggregate" 
              auditFields={auditFields} 
              component={NumberInputComponent}
            />
          </div>
        )}
      </div>
    );
  }

  function CrimeForm(props) {
    const { values, auditFields } = props;
  
    const evalOutcome = 'crime' in coverageResultsByGroup ? coverageResultsByGroup['crime'].meetsCoverageRules : null

    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
      setIsVisible(!isVisible);
    };

  
    return (
      <div className="pv3">
        <div className="dark-gray bb b--black-10 mt0 pb2 pointer" onClick={toggleVisibility}>
          <div className="cf">
            <span className="fl w-50">
              Crime Liability
              <span className="f7 black-30">{!isVisible && (values.crimePolicyNumber ? ` – ${startAndEnd(values.crimePolicyNumber, 40)}` : "")}</span>
            </span>
            <span className="fl w-50 tr">
            {makeReviewBadge(evalOutcome)}
            </span>
          </div>
        </div>
        {isVisible && (
          <div className="pv3">
            <FormField label="Policy Number" name="crimePolicyNumber" auditFields={auditFields} />
            <FormField label="Insurer" name="crimeInsurer" auditFields={auditFields} />
            <FormField label="Effective Date" name="crimePolicyEffectiveDate" auditFields={auditFields} />
            <FormField label="Expiration Date" name="crimePolicyExpirationDate" auditFields={auditFields} />
            <div className="pv3">
              <CheckboxField
                label="Waiver of Subrogation"
                name="crimeWaiverOfSubrogation"
                auditFields={auditFields}
                checked={values.crimeWaiverOfSubrogation}
              />
              <CheckboxField
                label="Additional Insured"
                name="crimeAdditionalInsured"
                auditFields={auditFields}
                checked={values.crimeAdditionalInsured}
              />
            </div>
            <FormField 
              label="Limits: Each Occurrence" 
              name="crimePolicyLimit.eachOccurrence" 
              auditFields={auditFields} 
              component={NumberInputComponent}
            />
            <FormField 
              label="Limits: Aggregate" 
              name="crimePolicyLimit.aggregate" 
              auditFields={auditFields} 
              component={NumberInputComponent}
            />
          </div>
        )}
      </div>
    );
  }

  function DescriptionOfOperations(props){
    const { values } = props;

    const evalOutcome = 'descriptionOfOperations' in coverageResultsByGroup ? coverageResultsByGroup['descriptionOfOperations'].meetsCoverageRules : null
    
    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
      setIsVisible(!isVisible);
    };

    return (
      <div className="pv3">
        <div className="dark-gray bb b--black-10 mt0 pb2 pointer" onClick={toggleVisibility}>
          <div className="cf">
            <span className="fl w-80">
              Description of Operations
              <span className="f7 black-30">{!isVisible && (values.descriptionOfOperations ? `  – ${startAndEnd(values.descriptionOfOperations, 30)}` : "")}</span>
            </span>
            <span className="fl w-20 tr">
              {makeReviewBadge(evalOutcome)}
            </span>
          </div>
        </div>
        {isVisible && (
        <div className="pv3">
          <div className="pv2">
            <span className="w-100">
              <label htmlFor="descriptionOfOperations" className="f6 fw5 dib w-40 black-50">Description of Operations</label>
              <Field component="textarea" name="descriptionOfOperations" className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2 h5"/>
            </span>
          </div>
        </div>
        )}
      </div>
    )
  }

  function humanize(str) {
    const parts = str.split('.');
    parts[0] = parts[0].charAt(0).toUpperCase() + parts[0].slice(1);
    parts[0] = parts[0].replace(/_/g, ' ');
    return parts.join(' ');
  }

  function AuditResults(props) {
    const { auditResult } = props;
    
    // If there are no edges, return null
    if (!auditResult?.edges || auditResult.edges.length === 0) {
      return null;
    }
    
    let passCount = 0;
    let failCount = 0;

    auditResult.edges.forEach(edge => {
      const resultData = JSON.parse(edge.node.resultJson);
      if (resultData.result === 'pass') {
        passCount++;
      } else {
        failCount++;
      }
    });

    const allTestsPass = passCount === auditResult.edges.length;

    const data = [
      { title: 'Pass', value: passCount, color: '#19a974' },
      { title: 'Fail', value: failCount, color: '#ff4136' },
    ];

    return (
      <div className="ba b--black-10 br2 mt3">
        <div>
          <div className="flex bb b--black-05 bw1">
            <div className="dt w-100 b--black-05">
              <div className="dtc w2 w3-ns v-mid">
                <PieChart
                  data={data}
                  radius={28}
                  lineWidth={40}
                  center={[50, 50]}
                  viewBoxSize={[100, 100]}
                  startAngle={-180}
                />
              </div>
              <div className="dtc v-mid pl3">
                <div className={`mb1 f6 f5-ns fw5 lh-title black tl ${allTestsPass ? 'green' : 'red'}`}>
                  {allTestsPass ? 'All checks pass' : 'One or more of the checks failed.'}
                </div>
                <div className="f6 fw4 mt0 mb0 black-60 tl">
                  {passCount > 0 && `${passCount} pass`}
                  {passCount > 0 && failCount > 0 && ', '}
                  {failCount > 0 && `${failCount} failing checks`}
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-auto h5">
            {auditResult.edges.map(edge => {
              const resultData = JSON.parse(edge.node.resultJson);
              return (
                <div className="f6 fw4 flex" key={edge.node.ruleJson}>
                  <div className="bb b--black-05 pv2 w-10 ph2 tc">
                    {resultData.result === 'pass' ? (
                      <img style={{width: '22px'}} className="green" src='/pass.svg' alt="Pass" />
                    ) : (
                      <img style={{width: '22px'}} className="red" src='/fail.svg' alt="Fail" />
                    )}
                  </div>
                  <div className="bb b--black-05 pv2 pr2 f7 w-90 flex items-center lh-title gray">
                    {resultData.message && `${resultData.message}`}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    )
  }

  function FormSubmitButtonGroup(props){
    const {
      publicId,
      status,
    } = props

    const [isOverrideChecked, setIsOverrideChecked] = useState(false);

    const handleCheckboxChange = () => {
      setIsOverrideChecked(!isOverrideChecked);
    };

    const buttonLabel = status !== 'APPROVED' ? 'Override & Approve' : 'Approve';
    const buttonColor = status !== 'APPROVED' ? 'red bg-white' : 'green bg-washed-green b--green';
    
    const isDisabled = ['FLAGGED', 'DENIED'].includes(status) && !isOverrideChecked; 
    const buttonStyle = isDisabled ? 'bg-light-gray black-50' : `pointer ${buttonColor}`;
    
    const onClickHandler = (reviewStatus) => {
      updateCertificate({
        variables: {
          publicId,
          reviewStatus,
          updatedInputJson: JSON.stringify({}),
        },
      })
    }

    return (
      <div className="pv4">
        <div className="dib fr">
          { status !== 'APPROVED' &&
            <button
              disabled={isDisabled}
              className={`fr outline-0 br2 ba b--black-20 pv2 ph3 ml1 fw6 f6 lh-title border-box ${buttonStyle}`}
              onClick={() => onClickHandler('APPROVED')}
            >
              {buttonLabel}
            </button>
          }
          { status !== 'DENIED' && <button
            className="fr outline-0 pointer br2 ba b--black-20 pv2 ph3 ml1 f6 lh-title border-box bg-white"
            onClick={() => onClickHandler('DENIED')}
          >
            Reject
          </button>}
          </div>
          <div className="dib fr pt2">
          {status !== 'APPROVED' && (
            <div className="flex items-center">
              <input
                type="checkbox"
                id="overrideCheck"
                name="overrideCheck"
                value={isOverrideChecked}
                onChange={handleCheckboxChange}
              />
              <label className="f6 fw4 pl2 black-80" htmlFor="overrideCheck">I want to override the failed checks and manually approve.</label>
            </div>
          )}
          </div>
        </div>
      ) 
  }

  function getInsurer(insurerArray, insurerLetter) {
    if (!insurerArray || !insurerLetter) return null

    return insurerArray.find(insurer => insurer.insurer_letter === insurerLetter).name
  }

  function getRadioValueFromDict(dict, yes_match_list = ['yes', 'Yes', 'YES', 'x', 'X', 'True', 'true', true]) {
    // For data structure e.g. {policy_type: {occurrence: 'x', claims_made: ''}}
    // convert to {policy_type: 'occurrence'} by taking first x'd key
    for (let key in dict) {
      if (dict[key] && yes_match_list.includes(dict[key])) {
        return key
      }
    }
    return null
  }

  function stringToBoolean(str){
    return str == 'true'
  }

  function parseInsured(data) {
    return {
      insuredName: data?.insured?.name,
      insuredAddressLine1: data?.insured?.address?.line1,
      insuredAddressLine2: data?.insured?.address?.line2,
      insuredAddressLine3: data?.insured?.address?.line3,
      insuredAddressLocality: data?.insured?.address?.city,
      insuredAddressRegion: data?.insured?.address?.state,
      insuredAddressPostalcode: data?.insured?.address?.postal_code
    }
  }

  function parseEntity(data) {
    return {
      entityName: data?.certificate_holder?.name,
      entityAddressLine1: data?.certificate_holder?.address?.line1,
      entityAddressLine2: data?.certificate_holder?.address?.line2,
      entityAddressLine3: data?.certificate_holder?.address?.line3,
      entityAddressLocality: data?.certificate_holder?.address?.city,
      entityAddressRegion: data?.certificate_holder?.address?.state,
      entityAddressPostalcode: data?.certificate_holder?.address?.postal_code
    }
  }

    function parseGL(data) {
      const gl = data?.coverages?.commercial_general_liability
      
      return {
      glPolicyNumber: gl?.policy_number_string,
      glInsurer: getInsurer(data?.insurer, gl?.insurer_letter),
      glPolicyEffectiveDate: gl?.policy_effective_date,
      glPolicyExpirationDate: gl?.policy_expiration_date,
      glWaiverOfSubrogation: stringToBoolean(gl?.subr_wvd),
      glAdditionalInsured: stringToBoolean(gl?.addl_insd),
      glPolicyLimit: {
        eachOccurrence: gl?.limits?.each_occurrence_dollars,
        damageToRented: gl?.limits?.damage_to_rented_premises_dollars,
        medExp: gl?.limits?.med_exp_dollars,
        personalInjury: gl?.limits?.personal_injury_dollars,
        generalAggregate: gl?.limits?.general_aggregate_dollars,
        productsCompAggregate: gl?.limits?.products_comp_aggregate_dollars
      },
      glPolicyType: getRadioValueFromDict(gl?.policy_type)
      }
    }

    function parseWC(data) {
      const wc = data?.coverages?.workers_compensation
      return {
      wcPolicyNumber: wc?.policy_number_string,
      wcInsurer: getInsurer(data?.insurer, wc?.insurer_letter),
      wcPolicyEffectiveDate: wc?.policy_effective_date,
      wcPolicyExpirationDate: wc?.policy_expiration_date,
      wcWaiverOfSubrogation: stringToBoolean(wc?.subr_wvd),
      wcAdditionalInsured: stringToBoolean(wc?.addl_insd),
      wcPolicyLimit: {
        perStatute: wc?.limits?.per_statute,
        other: wc?.limits?.other,
        elEachAccident: wc?.limits?.el_each_accident,
        elDiseaseEaEmployee: wc?.limits?.el_disease_ea_employee,
        elDiseasePolicyLimit: wc?.limits?.el_disease_policy_limit
      }
      }
    }
    
    function parseAL(data) {
      const al = data?.coverages?.automobile_liability
      return {
      autoPolicyNumber: al?.policy_number_string,
      autoInsurer: getInsurer(data?.insurer, al?.insurer_letter),
      autoPolicyEffectiveDate: al?.policy_effective_date,
      autoPolicyExpirationDate: al?.policy_expiration_date,
      autoWaiverOfSubrogation: stringToBoolean(al?.subr_wvd),
      autoAdditionalInsured: stringToBoolean(al?.addl_insd),
      autoPolicyLimit: {
        combinedSingleLimit: al?.limits?.combined_single_limit_dollars,
        bodilyInjuryPerPerson: al?.limits?.bodily_injury_per_person,
        bodilyInjuryPerAccident: al?.limits?.bodily_injury_per_accident,
        propertyDamage: al?.limits?.property_damage
      },
      autoPolicyType: {
        ownedAuto: stringToBoolean(al?.owned_auto),
        hiredAuto: stringToBoolean(al?.hired_auto),
        scheduledAuto: stringToBoolean(al?.scheduled_auto),
        nonOwnedAuto: stringToBoolean(al?.non_owned_auto)
      }
    }

  }

  function parseUmbrella(data) {
    const umbrella = data?.coverages?.excess_umbrella_liability
    return {
      umbrellaPolicyNumber: umbrella?.policy_number_string,
      umbrellaInsurer: getInsurer(data?.insurer, umbrella?.insurer_letter),
      umbrellaOccurrence: umbrella?.occurrence,
      umbrellaClaimsMade: umbrella?.claims_made,
      umbrellaPolicyEffectiveDate: umbrella?.policy_effective_date,
      umbrellaPolicyExpirationDate: umbrella?.policy_expiration_date,
      umbrellaWaiverOfSubrogation: umbrella?.subr_wvd,
      umbrellaAdditionalInsured: umbrella?.addl_insd,
      umbrellaPolicyType: getRadioValueFromDict(umbrella?.policy_type),
      umbrellaPolicyLimit: {
        eachOccurrence: umbrella?.limits?.each_occurrence_dollars,
        aggregate: umbrella?.limits?.aggregate_dollars
      },
      umbrellaLiability: umbrella?.umbrella_liability
    }
  }

  function parseExcess(data) {
    const excess = data?.coverages?.excess_umbrella_liability
    return {
      excessPolicyNumber: excess?.policy_number_string,
      excessInsurer: getInsurer(data?.insurer, excess?.insurer_letter),
      excessOccurrence: excess?.occurrence,
      excessClaimsMade: excess?.claims_made,
      excessPolicyEffectiveDate: excess?.policy_effective_date,
      excessPolicyExpirationDate: excess?.policy_expiration_date,
      excessWaiverOfSubrogation: excess?.subr_wvd,
      excessAdditionalInsured: excess?.addl_insd,
      excessPolicyType: getRadioValueFromDict(excess?.policy_type),
      excessPolicyLimit: {
        eachOccurrence: excess?.limits?.each_occurrence_dollars,
        aggregate: excess?.limits?.aggregate_dollars
      },
      excessLiability: excess?.excess_liability
    }
  }

  function produceFlatFields(data) {
    const initial_obj =  {
      ...parseInsured(data),
      ...parseEntity(data),
      ...parseGL(data),
      ...parseWC(data),
      ...parseAL(data),
      ...parseUmbrella(data),
      ...parseExcess(data),
      // and descriptionOfOperations:
      ...{descriptionOfOperations: data?.description_of_operations?.full_text}
    }
    for (let key in initial_obj) {
      if (initial_obj[key] === 'x' || initial_obj[key] === 'X') {
        initial_obj[key] = true;
      }
    }
    return initial_obj
  }

  function makeReviewBadge(evalOutcome) {
    if (evalOutcome === null) {
      return (
        <Badge color="silver">N/A</Badge>
      )
    } else if (evalOutcome) {
      return (
        <Badge color="green">Pass</Badge>
      )
    } else {
      return (
        <Badge color="red">Fail</Badge>
      )
    }
    
  }

  const auditFields = produceFlatFields(auditData)
  const renderForm = () => {
    return (
      <Formik initialValues={produceFlatFields(auditData)}>
        {(formikProps) => {
          const { handleSubmit, values } = formikProps;
          
          return (
            <Form onSubmit={handleSubmit}>
              <div className="w-90">
                <InsuredForm values={values} auditFields={auditFields} />
                <EntityForm values={values} auditFields={auditFields} />
                <GeneralLiabilityForm values={values} auditFields={auditFields} />
                <WorkersCompensationForm values={values} auditFields={auditFields} />
                <ProfessionalForm values={values} auditFields={auditFields} />
                <AutoForm values={values} auditFields={auditFields} />
                <UmbrellaForm values={values} auditFields={auditFields} />
                <ExcessForm values={values} auditFields={auditFields} />
                <CyberForm values={values} auditFields={auditFields} />
                <CrimeForm values={values} auditFields={auditFields} />
                <DescriptionOfOperations values={values} auditFields={auditFields} />
                <AuditResults auditResult={auditResult} />
                <FormSubmitButtonGroup status={status} publicId={publicId} />
              </div>
            </Form>
          )
        }}
      </Formik>
    )
  }

  return (
    <div>
      {renderForm()}
    </div>
  )
}
export default memo(CertificateDetailForm);