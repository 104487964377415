import React, {useState, useEffect} from 'react';
import ReactLoading from 'react-loading';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Modal from 'react-modal';

import { useQuery, useMutation } from "@apollo/client";
import { 
  Form,
  Formik,
  Field,
  ErrorMessage
 } from 'formik';

import * as Yup from 'yup';

import moment from 'moment';
import classNames from 'classnames'
import _debounce from 'lodash/debounce';

import { shortCoverageNames } from './helpers';
import { getPolicies, resolveRedacted } from './helpers'

import AutocompleteContractorInput from '../../components/AutocompleteContractorInput';
import PageHeader from '../../components/PageHeader';
import MainWrapper from '../../components/MainWrapper';
import CSVUpload from '../../components/CSVUpload';
import TabbedTable from '../../components/TabbedTable';
import FilterDropdownMenu from '../../components/FilterDropdownMenu';
import AutocompleteEntityInput from '../../components/AutocompleteEntityInput';
import AutocompleteAgencyRepInput from '../../components/AutocompleteAgencyRepInput';

import { startAndEnd } from '../../utils/truncate'
import { centsToDollars } from '../../utils/currency'

import {
  ADD_PURCHASE_ORDER_NUMBER,
  DELETE_CONTRACTOR,
  SEND_INVITE
} from './mutations';

import {
  AGENCY_PAY_NO_PO_QUERY,
  CONTRACTOR_QUERY,
  CONTRACTOR_BY_ID_QUERY,
  ENTITY_QUERY,
  AGENCY_REP_EMAIL_QUERY
} from './queries';


Modal.setAppElement('#root');

let contentStyle = {
  top                   : '50%',
  left                  : '50%',
  right                 : 'auto',
  bottom                : 'auto',
  marginRight           : '-50%',
  transform             : 'translate(-50%, -50%)',
  boxShadow             : '2px 2px 8px 0px rgba( 0, 0, 0, 0.2 )',
  overflow              : 'hidden',
}

let modalStyles = {
  content : contentStyle,
  overlay : {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  } 
};

let importContentStyle = {
  width                 : '640px',
  maxHeight             : '90vh',
}

let importModalStyles = {
  content: {
    ...contentStyle,
    ...importContentStyle
  },
  overlay : {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  } 
}

let addPOContentStyle = {
  width                 : '980px',
  maxHeight             : '95vh',
}

let addPOModalStyles = {
  content: {
    ...contentStyle,
    ...addPOContentStyle
  },
  overlay : {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  } 
}

const API_DOCS_URL = "docs.1099policy.com"


const Importing = () => (
  <div>
    <div className="tc db mb2">
      Importing...
    </div>
    <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
  </div>
)

function getMessageBody(orgName){
  const MESSAGE_BODY = `To remain eligible for your upcoming assignment with ${orgName}, you'll need to meet certain insurance requirements. ${orgName} works with 1099Policy.com to help you get that coverage in your name on a per assignment basis. It’s fast, easy and affordable. The link below can be used to opt-in and secure coverage you're required to have. You'll need the coverage before you begin work so complete the application at your earliest convenience.`

  return MESSAGE_BODY
}

function InsuranceApplicationInviteModal(props){
  const [sendInvite, { loading, error, reset }] = useMutation(SEND_INVITE, {
    onCompleted: ()=>{
      props.closeModal();
      reset()
  }});

  const renderLoading = () => (
    <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
  )

  const renderError = () => (
    <div className="br2 f6 flex items-center justify-center pa3 bg-lightest-blue navy">
      <span className="lh-title ml3">There was an error sending the email. Please try again.</span>
    </div>
  )

  const renderEmailEditor = () => (
    <div className="roboto">
      <PageHeader title={'Invite Contractor to Apply for Insurance'} />
      <Formik
        initialValues={{
          subject: `Upcoming assignment with ${props.organizationName}`, 
          messageBody: getMessageBody(props.organizationName),
          quoteId: props.quote_id,
          contractor_email: props.email
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          sendInvite({ variables: {...values} })
        }}
        validationSchema={Yup.object().shape({
          subject: Yup.string().required('Required'),
          messageBody: Yup.string().required('Required'),
        })}
      >
        {({ values, isSubmitting }) => (
          <Form className="measure">
            {error ? renderError() : null}
            <div>
              <label className="f6 fw5 db mb2 mt3" htmlFor="contractor_email">Contractor Email</label>
              <Field 
                  type="text" 
                  name="contractor_email" 
                  value={values.contractor_email}
                  className="input-reset ba b--black-20 pa2 mb2 db w-100 br2"
                  placeholder="johndoe@gmail.com"
                  disabled
                />
                <ErrorMessage className="f6 red" name="contractor_email" component="div" />

                <label className="f6 fw5 db mb2 mt3" htmlFor="subject">Subject</label>
                <Field 
                  type="text"
                  name="subject"
                  value={values.subject}
                  className="input-reset ba b--black-20 pa2 mb2 db w-100 br2"
                  placeholder="Invitation to apply for insurance coverage"
                />
                <ErrorMessage className="f6 red" name="subject" component="div"/>

                <label className="f6 fw5 db mb2 mt3" htmlFor="messageBody">Message</label>
                <Field 
                  name="messageBody" 
                  component="textarea" 
                  value={values.messageBody}
                  className="input-reset ba b--black-20 pa2 mb2 db w-100 br2 lh-copy"
                  rows="10" 
                  cols="70"
                  placeholder={getMessageBody(props.organizationName)}
                />
                <small id="comment-desc" className="f6 black-60">
                  The IC will see an invitation link appended to the end of this message.
                </small>
                <ErrorMessage className="f6 red" name="messageBody" component="div"/>

                <div className="mv4">
                  <button 
                    className="f6 link dim br2 ph3 pv2 mb2 ml2 dib white bg-blue b--blue ba pointer fr"
                    type="submit" 
                    disabled={isSubmitting}>
                    Send
                  </button>
                  <button 
                    onClick={props.closeModal}
                    className="f6 link dim br2 ba ph3 pv2 mb2 dib black pointer fr"
                    type="submit" 
                    disabled={isSubmitting}>
                    Cancel
                  </button>
                </div>
            </div> 
          </Form>
        )}   
      </Formik>
    </div>
  )
  
  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={modalStyles}
      contentLabel="Invite Contractor to Apply for Insurance"
    >
      {
        loading ? renderLoading() : renderEmailEditor()
      }
    </Modal>
  )
}

const useDisableBodyScroll = (open) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);
};

function ImportResults(props){
  const {importResults} = props
  if (importResults?.processed === 0){
    return (
      <div>
      <h2 className="f5 fw4 lh-copy mb4">
        Import error - confirm that the CSV file  
        includes the required columns before uploading.
        Contact support@1099policy.com for help.
      </h2>
      </div>
    )
  }

  return(
    <div>
      <h2 className="f5 fw4 lh-copy mb4">
        Import complete - results
      </h2>
      <div className="f6 fw4 lh-copy">
        <span className="f4 fw6 b black-90">{importResults?.processed}</span> row(s) uploaded
      </div>
      <div className="f6 fw4 lh-copy">
        <span className="f4 fw6 b black-90">{importResults?.success}</span> rows successfully processed
      </div>
      <div className="f6 fw4 lh-copy gray">
        <span className="f4 fw6 b">{importResults?.failure?.length}</span> errors
      </div>
      <div className="f6 fw4 lh-copy gray">
        Total premium <span className="f4 fw6 b">{centsToDollars(importResults?.total_premium)}</span>
      </div>
      <div className="f6 fw4 lh-copy gray">
        Platform access fee <span className="f4 fw6 b">{centsToDollars(importResults?.platform_access_fee)}</span>
      </div>
      <div className="f6 fw4 lh-copy black">
        Grand total <span className="f4 fw6 b">{centsToDollars(importResults?.grand_total)}</span>
      </div>

      {importResults?.failure.length > 0 &&
        <div className="f6 fw4 lh-copy black-90 mt4">
          <span className="f5 fw6 b">Fail reason:</span>
          {importResults?.failure.map((label, idx) => 
            <span className="f6 fw4 db">{label}</span>
          )}
        </div>
      }
    </div>
  )
}

const DEFAULT_BULK_UPLOAD_TEMPLATE_URL = "https://docs.google.com/spreadsheets/d/13MBTBBN0i" + 
  "k1EnF2bJkCmyL9N3IJKuEVjNaBFRrPKoAU/edit?usp=sharing";
function BulkImportModal(props){
  const [importStarted, setImportStarted] = useState(false)
  const [importComplete, setImportComplete] = useState(false)
  const [importResults, setImportResults] = useState();

  useDisableBodyScroll(props.modalIsOpen);

  const renderModalBody = () => {
    if(importStarted && !importComplete){
      return(
        <Importing />
      )
    } else if(importResults){
      return(
        <ImportResults importResults={importResults} />
      )
    } else {
      return (
        <CSVUpload resource_type={'contractor'} 
          client={props.client}
          setImportResults={setImportResults}
          toggleImportStarted={() => setImportStarted(!importStarted)}
          toggleImportComplete={() => setImportComplete(!importComplete)}
          closeModal={props.closeModal}
          token={props.token}
          bulkImportTemplateUrl={props.bulkImportTemplateUrl ?? DEFAULT_BULK_UPLOAD_TEMPLATE_URL}
        />
      )
    }
  }

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={() => {
        props.closeModal()
        setImportResults(null)
        setImportStarted(false)
        setImportComplete(false)
      }}
      style={importModalStyles}
      contentLabel="Bulk import contractors"
      preventScroll={true}
    >
      <div className="roboto">
        <PageHeader title={'Bulk Import Contractors'} />
        {renderModalBody()}
      </div>
    </Modal>
  )
}

function GetStarted(props) {
  return (
    <div>
      <p className="lh-copy f6 fw3 black-70">
      Track the status of insurance coverage for independent contractors on your platform from this page.
      </p>
      <p className="lh-copy f6 fw3 black-70">
      Use the <a href={API_DOCS_URL+"/#endpoint-contractor"} target="_blank">contractor API</a> to start adding independent 
      contractors to the 1099Policy platform or bulk upload contractors using the CSV import option below.
      </p>
      <p className="mt4">
      <button 
        type="button" 
        onClick={props.openModal}
        className="f6 link dim br2 ph3 pv2 mb2 dib white bg-blue bn pointer">
        Bulk import contractors
      </button>
      </p>
    </div>
  )
}

function DeleteContractorModal(props){
  const [deleteContractor, { loading, error, reset }] = useMutation(DELETE_CONTRACTOR, {
    onCompleted: ()=>{
      props.closeModal();
      reset()
  }});

  const renderLoading = () => (
    <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
  )

  const renderError = () => (
    <div className="br2 f6 flex items-center justify-center pa3 bg-lightest-blue navy">
      <span className="lh-title ml3">There was an error deleting the contractor. Please try again.</span>
    </div>
  )

  const renderDeleteConfirmation = (props) => (
    <div className="roboto">
      <PageHeader title={'Delete Contractor Confirmation'} />
      {error ? renderError() : null}
      <div className="f6 fw5 db mb2 mt3">
        Are you sure you want to delete <b className="red">{props.firstName} {props.lastName}</b>? This operation can't be undone.
      </div>
      <div className="mv4">
        <button 
          className="f6 link dim br2 ph3 pv2 mb2 ml2 dib white bg-white b--light-red red ba pointer fr"
          type="submit"
          onClick={(e) => {
            const contractor = props.contractor
            deleteContractor({
              variables: {contractor },
              update(cache) {
                const normalizedId = cache.identify({ contractor, __typename: 'Contractor' });
                cache.evict({ contractor: normalizedId });
                cache.gc();
              }
            })
          }}
          >
          YES – DELETE this contractor!
        </button>
        <button 
          onClick={props.closeModal}
          className="f6 link dim br2 ba ph3 pv2 mb2 dib black pointer fr"
          type="submit">
          Cancel
        </button>
      </div>
    </div>
  )

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={modalStyles}
      contentLabel="Delete contractor confirmation"
    >
      {
        loading ? renderLoading() : renderDeleteConfirmation(props)
      }
    </Modal>
  )
}

function AddPurchaseOrderModal(props){
  const {
    modalIsOpen,
    closeModal,
    setSuccessMessage,
    jobs 
  } = props

  useDisableBodyScroll(modalIsOpen);
  const [premium, setPremium] = useState(0)
  const [platformAccessFee, setPlatformAccessFee] = useState(0)
  
  const [poTotalPremium, setPoTotalPremium] = useState({})
  const [poTotalPlatformAccessFee, setPoTotalPlatformAccessFee] = useState({})
  const [poSaveErrorMessage, setPoSaveErrorMessage] = useState(false)

  useEffect(() => {
    setPremium(sumValues(poTotalPremium))
    setPlatformAccessFee(sumValues(poTotalPlatformAccessFee))
  }, [poTotalPremium, poTotalPlatformAccessFee])

  const [addPurchaseOrder, { loading, error, reset }] = useMutation(ADD_PURCHASE_ORDER_NUMBER, {
    onCompleted: (data)=>{
      if (!data.addPurchaseOrderNumber?.ok){
        setPoSaveErrorMessage(data.addPurchaseOrderNumber?.message)
        reset()
      } else {
        closeModal();
        setSuccessMessage(data.addPurchaseOrderNumber?.message)
        reset()
      }
    },
    refetchQueries: ['AgencyPayNoPurchaseOrder']
  })

  const initialValues = () => {
    let res = {}
    jobs?.map((job) => {
      res[job.invoiceId] = '' 
      res[`checkbox-${job.invoiceId}`] = false
    })
    return res
  }

  const renderError = () => (
    <div className="br2 f6 flex items-center justify-center pa2 bg-washed-red red mb3">
      <span className="lh-title ml3">{poSaveErrorMessage || 'There was an error adding the purchase order number. Please try again.'}</span>
    </div>
  )

  const prepSubmitData = (submittedValues) => {
    let purchaseOrderUpdates = []

    for (const [key, value] of Object.entries(submittedValues)) {
      if(value.length > 0){
        purchaseOrderUpdates.push({
          invoiceId: key,
          purchaseOrderId: value
        })
      }
    }

    return purchaseOrderUpdates
  }
  
  const renderRows = (values, jobs, setFieldValue) => {
    const rows = jobs.map((row) => {
      
      // const isSelected = selectedRows.includes(row.invoiceId);
      const isSelected = values[`checkbox-${row.invoiceId}`];
      return (
        <tr className={`w-100 hover-bg-washed-blue pointer ${isSelected ? 'bg-washed-blue': ''}`} 
          key={row.invoiceId}
          onClick={(event) => {
            // Toggle the checkbox
            const newValue = !isSelected;
            
            setFieldValue(`checkbox-${row.invoiceId}`, newValue);
            handleOnChange(event);
          }}
          data-name={row.invoiceId} 
          data-platformaccessfee={row.platformAccessFee}
          data-premium={row.totalPremium}
          data-grosspay={row.grossPay}
        >
          <td>
            <Field 
              name={`checkbox-${row.invoiceId}`}
              render={({ field }) => (
                <input
                  {...field}
                  type="checkbox"
                  id={`checkbox-${row.invoiceId}`}
                  value={values[`checkbox-${row.invoiceId}`]}
                  className="mr2" 
                  checked={isSelected}
                  data-name={row.invoiceId} 
                  data-platformaccessfee={row.platformAccessFee}
                  data-premium={row.totalPremium}
                  data-grosspay={row.grossPay}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOnChange(e);
                  }}
                />
              )}
            />
          </td>
          <td className="f6 pv2">
            <Field
              name={row.invoiceId} 
              id={`poInput-${row.invoiceId}`}
              className="input-reset ba b--black-20 pa2 db w-100 br1" 
              type="text" 
              value={values[row.invoiceId]}
              aria-describedby="purchase-order-desc"
              data-name={row.invoiceId} 
              data-platformaccessfee={row.platformAccessFee}
              data-premium={row.totalPremium}
              data-grosspay={row.grossPay}
              onClick={(e) => {
                e.stopPropagation();
                handleOnChange(e);
              }}
            />
          </td>
          <td className="f6 ph2 pv2">{row.fullName}</td>
          <td className="f6 ph2 pv2">{row.campaign ? row.campaign : "<none>"}</td>
          <td className="f6 ph2 pv2">{row.coveragePeriod}</td>
          <td className="f6 ph2 pv2">
            {row.invoicePaidInFull ? '-' : centsToDollars(row.totalPremium)}
          </td>
          <td className="f6 ph2 pv2">{centsToDollars(row.platformAccessFee)}</td>
        </tr>
      )
    })
    return rows
  }

  const loadingSpinner = () => (
    <ReactLoading type={'spin'} color={'#cccccc'} width={14} height={14} />
  )

  const sumValues = obj => Object.values(obj).reduce((a, b) => a + b, 0);

  const handleOnChange = (event, setFieldValue) => {
    const targetType = event.target?.type ? event.target.type : event.type;
    const target = targetType !== 'click' ? event.target : event.currentTarget;
    
    const key = target.getAttribute('data-name')
    const premium = parseInt(target.getAttribute('data-premium'))
    const accessFee = parseInt(target.getAttribute('data-platformaccessfee'))
    
    const inputField = document.getElementById(`poInput-${key}`);
    const checkbox = document.getElementById(`checkbox-${key}`);
    
    const poInputHasValue = inputField && inputField.value
    const newValueChecked = targetType == 'checkbox' ? target.checked : !checkbox.checked;

    if((targetType === 'text' && target.value === '') || (!newValueChecked && !poInputHasValue)){
      setPoTotalPremium((prevData) => {
        const newData = {...prevData}
        delete newData[key]
        return newData;
      })

      setPoTotalPlatformAccessFee((prevData) => {
        const newData = {...prevData}
        delete newData[key]
        return newData;
      })
    } else {
      setPoTotalPremium({...poTotalPremium, [key]: premium})
      setPoTotalPlatformAccessFee({...poTotalPlatformAccessFee, [key]: accessFee})
    }
  };

  const debouncedOnChange = (event) => {
    if (event.target.type === 'checkbox') {
      handleOnChange(event);
    } else {
      _debounce(handleOnChange, 100)(event);
    }
  };

  const renderTable = (props) => (
    <div className="roboto">
      <PageHeader title={'Add Purchase Order Number'} />
      <Formik
        initialValues={initialValues()}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          addPurchaseOrder({
            variables: {
              purchaseOrder: prepSubmitData(values)
            } 
          })
        }}
      >
      {({ values, isSubmitting, setFieldValue }) => (
        <Form onChange={(event) => {
          if (event.target.type === 'text') {
            setFieldValue(`checkbox-${event.target.getAttribute('data-name')}`, event.target.value !== '');
          }
          debouncedOnChange(event)
        }}>
          {poSaveErrorMessage ? renderError() : null}
            <div className="overflow-y-scroll" style={{ height: "400px" }}>
            <table className="collapse">
              <thead>
                <tr className="bg-white">
                  <th style={{position: "sticky"}} className="bg-white top-0 tl fw6 f6 pv2"></th>
                  <th style={{position: "sticky"}} className="bg-white top-0 tl fw6 f6 pv2 w-20">Purchase Order #</th>
                  <th style={{position: "sticky"}} className="bg-white top-0 tl fw6 f6 pv2 ph2 w-20">Contractor Name</th>
                  <th style={{position: "sticky"}} className="bg-white top-0 tl fw6 f6 pv2 ph2 w-15">Campaign</th>
                  <th style={{position: "sticky"}} className="bg-white top-0 tl fw6 f6 pv2 ph2 w-25">Coverage Period</th>
                  <th style={{position: "sticky"}} className="bg-white top-0 tl fw6 f6 pv2 ph2 w-15">Premium</th>
                  <th style={{position: "sticky"}} className="bg-white top-0 tl fw6 f6 pv2 ph2 w-10">Platform Access Fee</th>
                </tr>
              </thead>
              <tbody>
                {renderRows(values, jobs, setFieldValue)}
              </tbody>
            </table>
            </div>
            <div className="pt3 bt b--black-30">
              <div>
                <div className="dt w-100 pb2 mt2">
                  <div className="dtc tr w-80 fw6">Premium total (contractor amount due):</div>
                  <div className="dtc fr">{centsToDollars(premium)}</div>
                </div>
                <div className="dt w-100 pb2 mt2">
                  <div className="dtc tr w-80 fw6">Platform access fee (org amount due):</div>
                  <div className="dtc fr">{centsToDollars(platformAccessFee)}</div>
                </div>
                <div className="dt w-100 pb4 mt2">
                  <div className="dtc tr w-80 fw6">Total:</div>
                  <div className="dtc fr">{centsToDollars(premium + platformAccessFee)}</div>
                </div>
              </div>
              <div>
                <button 
                  className="f6 link dim br2 ph3 pv2 mb2 ml2 dib white bg-blue hover-bg-blue-80 b--blue ba pointer fr"
                  type="submit"
                  disabled={isSubmitting}>
                  { loading ? loadingSpinner(): 'Add'
                  }
                </button>
                <button 
                  onClick={() => {
                    setPremium(0)
                    setPlatformAccessFee(0)
                    setPoTotalPremium({})
                    setPoTotalPlatformAccessFee({})
                    props.closeModal()
                  }}
                  className="f6 link dim br2 ba ph3 pv2 mb2 dib black pointer fr"
                  type="submit"
                  disabled={isSubmitting}>
                  Cancel
                </button>
              </div>
            </div>
        </Form>
      )}
      </Formik>
      </div>
  )
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => {
        setPremium(0)
        setPlatformAccessFee(0)
        setPoTotalPremium({})
        setPoTotalPlatformAccessFee({})
        closeModal()
      }}
      style={addPOModalStyles}
      contentLabel="Add Purchase Order Number"
      preventScroll={true}
    >
      {
        renderTable(props)
      }
    </Modal>
  )
}

function AgencyPayNoPurchaseOrder(props){
  const {
    successMessage,
    setSuccessMessage
  } = props

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const hash = location.hash;

    if (hash.includes('#PO')) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  }, [location]);

  const { loading, data } = useQuery(AGENCY_PAY_NO_PO_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  })

  const renderSuccess = (message) => (
    <div className="br2 f6 flex items-center justify-center pa2 bg-washed-green green mv3">
      <span className="lh-title ml3">{message}</span>
    </div>
  )

  const closePurchaseOrderModal = () => {
    setModalIsOpen(false);

    // Remove #PO from URL
    const currentPath = location.pathname + location.search;
    navigate(currentPath, { replace: true });
  }

  const renderNotifiction = (data) => {
    const numMissing = data.agencyPayNoPurchaseOrder.length
    return (
      <div className="bg-white shadow-4 pv1 ph4 br2 relative mb3 cf">
        {successMessage ? renderSuccess(successMessage) : null}
        <div className="fl w-80">
          <p className="f6 dark-gray pv2 lh-copy">
            <img className="dib v-mid pr3" style={{ width: '1.4rem'}} src="/alert.svg" alt="Alert" />
            <b>{numMissing}</b> contractors missing purchase order numbers.
          </p>
        </div>
        <div className="fl w-20">
          <button 
            onClick={() => setModalIsOpen(true)}
            type="button"
            className="fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 mv3 f7 lh-title bg-animate hover-bg-light-gray border-box">
            Add Purchase Order #s
          </button>
        </div>
      </div>
    )
  }

  return loading || data?.agencyPayNoPurchaseOrder.length == 0 ? (
    <></>
    ) : (
      <>
        {renderNotifiction(data)}
        <AddPurchaseOrderModal 
          closeModal={closePurchaseOrderModal}
          modalIsOpen={modalIsOpen}
          jobs={data.agencyPayNoPurchaseOrder}
          setSuccessMessage={setSuccessMessage}
        />
      </>
    )
}

function ContractorsTable(props){
  const navigate = useNavigate();

  const ALL_TABBED_TABLE_FILTERS = [
    { label: 'all', value: 'all' },
    { label: 'active', value: 'active' },
    { label: 'in-progress', value: 'in-progress' },
    { label: 'draft', value: 'draft' },
    { label: 'off-platform', value: 'off-platform' },
    { label: 'cancelled', value: 'cancelled' }
  ];
  
  const TABBED_TABLE_FILTERS = ALL_TABBED_TABLE_FILTERS.filter(filter => {
    return filter.label !== 'off-platform' || props.isAdmin;
  }).map(filter => filter.label);

  const [selectedFilter, setSelectedFilter] = useState(TABBED_TABLE_FILTERS[0]);
  const [otherFilters, setOtherFilters] = useState({
    'entities': [],
    'agencyRepEmails': []
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [autoCompleteItem, setAutoCompleteItem] = useState();
  
  const [selectedEntityValues, setSelectedEntityValues] = useState([]);
  const [selectedAgencyRepValues, setSelectedAgencyRepValues] = useState([]);

  const cursor = searchParams.get('starting_after')
  
  const { loading, data, fetchMore } = useQuery(CONTRACTOR_QUERY, {
    skip: autoCompleteItem,
    variables: {
      cursor, 
      filter: selectedFilter, 
      entities: otherFilters.entities, 
      agencyRepEmails: otherFilters.agencyRepEmails
    },
    notifyOnNetworkStatusChange: true
  })

  const { loading: filterdLoading, data: filteredData } = useQuery(CONTRACTOR_BY_ID_QUERY, {
    skip: !autoCompleteItem,
    variables: {id: autoCompleteItem?.id},
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    const filterParam = searchParams.get('filter');
    const entityParam = searchParams.get('entities');
    const agencyRepEmailParam = searchParams.get('agencyRepEmails');

    setSelectedFilter(filterParam || TABBED_TABLE_FILTERS[0]);
    setOtherFilters({
      'entities': entityParam ? entityParam.split(',') : [],
      'agencyRepEmails': agencyRepEmailParam ? agencyRepEmailParam.split(',') : []
    });
  }, []);

  useEffect(() => {
    if (autoCompleteItem) {
      setSelectedFilter(null);
      setOtherFilters({
        'entities': [],
        'agencyRepEmails': []
      });
    }
  }, [autoCompleteItem]);

  const renderEmailButton = (node) => { 
    const policy = node.quotes?.edges?.length > 0 ? node.quotes?.edges[0]?.node.policy?.edges[0]?.node.publicId : null
    const className = node.quotes?.edges?.length > 0 ? node.quotes?.edges[0]?.node.job.jobCategory?.className : null

    if (!className || policy){
      return(
        <button className="pv2 f7 lh-title" style={{visibility: 'hidden'}} ><img className="dib v-btm pr1 ph3 w1" src="/email.svg" alt="Email" /></button>
      )
    }

    //TODO: Order quotes on the BE to make sure only ever select most
    // recently created quote. Inconsistent results with current implementation.
    const quote_id = node.quotes?.edges?.length > 0 ? node.quotes?.edges[0]?.node.publicId : null
    const email = node.email
    
    return (
      <button 
        type="button" 
        onClick={(e) => {
          e.stopPropagation()
          props.setInviteModalValues({inviteModalIsOpen: true, quote_id: quote_id, email: email })
        }}
        className="outline-0 pointer br2 ba b--black-20 bg-white pv2 ph3 ml1 f7 lh-title bg-animate hover-bg-light-gray border-box">
        <img className="dib v-btm pr1 w1" src="/email.svg" alt="Email" />
      </button>
  )}
  
  const renderClassification = (node) => {
    const className = node.quotes?.edges?.length > 0 ? node.quotes?.edges[0]?.node.job.jobCategory?.className : null
    const coverageTypes = shortCoverageNames(node.quotes?.edges[0]?.node?.coverageType?.edges)

    // TODO: pull eligible coverage type for classification (e.g., WC, WC/GL, GL)
      return (
        className ? <span className="pv2 dib ttc">
        {startAndEnd(className)} ({coverageTypes})</span> : null
      )
  }

  const AdminOnlyDeleteBtn = (props) => {
    return (
      <button 
        type="button" 
        onClick={(e) => {
          e.stopPropagation()
          props.setDeleteContractorModalValues({contractorDeleteModalIsOpen: true, contractor: props.node.publicId, firstName: props.node.firstName, lastName: props.node.lastName})
        }}
        className="outline-0 pointer br2 ba b--black-20 bg-white pv2 ph3 ml1 f7 lh-title bg-animate border-box">
        <img className="dib v-btm w1" src="/trash.svg" alt="Delete" />
      </button>
    )
  }

  const CoverageDeleteOnHoverBtn = (props) => {
    const [hover, setHover] = useState(false);
    const onHover = () => {
      setHover(true);
    };
  
    const onLeave = () => {
      setHover(false);
    };

    const renderNoCoverage = () => (
      <span className="outline-0 ph3 pv2 f7 dib ttc mid-gray lh-title border-box">
        No Coverage
      </span>
    )
    
    return (
      <div
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
        role="button"
        tabIndex="-3"
      >
        {hover && props.isAdmin ? <AdminOnlyDeleteBtn {...props} /> : renderNoCoverage()}
      </div>
    )
  }

  const renderCoverage = (node) => {
    const policies = getPolicies(node.quotes?.edges)
    const allCancelled = policies.every(policy => policy.edges[0]?.node?.isActive === false)
    
    if(policies.length > 0 && !allCancelled){
      return (
        <span className="br2 ph3 pv2 dib ttc ba">
          {node.lastActiveCoveragePeriod}
        </span>
      )
    }
    
    return (
      <CoverageDeleteOnHoverBtn node={node} {...props} />
    )
  }

  const renderCancelledBadge = (node) => {
    const policies = getPolicies(node.quotes?.edges)
    const allCancelled = policies.every(policy => policy.edges[0]?.node?.isActive === false)

    if (policies.length > 0 && allCancelled){
      return (
        <span className="ml1 outline-0 br-pill ph2 f8 dib ttc red bg-washed-red b--red lh-title border-box">
          Cancelled
        </span>
      )
    }

    return(<></>)
  }

  const renderNameAndCompany = (row) => {
    let nameAndCompany = '';
    if (row.firstName && row.lastName) {
      nameAndCompany = `${row.firstName} ${row.lastName}`;
    }
    if (row.companyName) {
      nameAndCompany += nameAndCompany ? ` (${row.companyName})` : row.companyName;
    }
    return nameAndCompany;
  }

  const renderEmptyRow = () => (
    <tr>
      <td colSpan="5" className="f6 tc pv3">No results</td>
    </tr>  
  )
  
  const renderRows = (data) => {
    if (data.length === 0) {
      return renderEmptyRow();
    }
    
    const rows = data.map((row) => {
      const policies = getPolicies(row.quotes?.edges)
      
      return (
        <tr 
          key={row.publicId}
          onClick={() => {
            navigate(row.publicId, {state: row});
          }} 
          className="bb b--light-gray hover-bg-washed-blue pointer">
          <td className="tl ph2 f7">{moment(row.created).format('MMM D, YYYY')}</td>
          <td className="tl ph2 pv3 f7 w-30-l">{renderNameAndCompany(row)} {renderCancelledBadge(row)}</td>
          <td className="tl ph2 pv3 f7">{renderClassification(row)}</td>
          <td className="pv3 f7 tc">{renderCoverage(row)}</td>
          <td className="pv3 f7 tc">
            {policies.length == 0 && renderEmailButton(row)}
          </td>
        </tr>
      )}
    )

    return rows
  }

  const handleSetOtherFilters = (entities, agencyRepEmails) => {
    // Step 1: Extract the current URL and search parameters
    const currentUrl = new URL(window.location);
    const searchParams = currentUrl.searchParams;

    const entitiesStr = entities.join(',');
    const agencyRepEmailsStr = agencyRepEmails.join(',');

    // Step 2: Update the search parameters
    if (entities.length > 0) {
      searchParams.set('entities', entitiesStr); // Replace existing or add if not present
    } else {
      searchParams.delete('entities');
    }
    
    if (agencyRepEmails.length > 0) {
      searchParams.set('agencyRepEmails', agencyRepEmailsStr); // Replace existing or add if not present
    } else {
      searchParams.delete('agencyRepEmails');
    }

    // Step 3: Update the browser's URL without reloading the page
    window.history.pushState({}, '', currentUrl.toString());
    
    setOtherFilters((prevOtherFilters) => ({
      ...prevOtherFilters,
      agencyRepEmails: agencyRepEmails,
      entities: entities
    }));
  }

  const renderHeader = (data) => (
      <div className="flex justify-between">
        <div className="flex">
          <AutocompleteContractorInput setAutoCompleteItem={setAutoCompleteItem}/>
        </div>
        <div className="flex">
          <FilterDropdownMenu 
            handleSetOtherFilters={handleSetOtherFilters} 
            otherFilters={otherFilters}
            selectedAgencyRepValues={selectedAgencyRepValues}
            setSelectedAgencyRepValues={setSelectedAgencyRepValues}
            selectedEntityValues={selectedEntityValues}
            setSelectedEntityValues={setSelectedEntityValues} 
          >
            <AutocompleteEntityInput 
              query={ENTITY_QUERY}
              label="Client entity name:"
              placeholder="Search client entity name..."
              setSelectedValues={setSelectedEntityValues}
              selectedValues={selectedEntityValues}
            />
            <AutocompleteAgencyRepInput
              query={AGENCY_REP_EMAIL_QUERY}
              label="Agency rep name:"
              placeholder="Search agency rep name..."
              setSelectedValues={setSelectedAgencyRepValues}
              selectedValues={selectedAgencyRepValues}
            />
          </FilterDropdownMenu>
          <button 
            type="button" 
            onClick={() => {
                if (searchParams.get('filter') !== 'all') {
                  setSelectedFilter('all')
                  searchParams.set('filter', 'all');
                  navigate(`?${searchParams.toString()}`);
                }
                props.openModal()
              }
            }
            className="outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 mv3 f7 lh-title bg-animate hover-bg-light-gray border-box">
            Bulk import contractors
          </button>
        </div>
      </div> 
  )

  const handlePagination = (cursor) => {
    searchParams.set('starting_after', cursor);
    navigate(`/contractors?${searchParams.toString()}`);
  };

  const fetchNextPage = (data) => {
    const cursor = data.contractors.pageInfo.endCursor
    fetchMore({
      variables: {
        cursor: cursor
      },
    })
    handlePagination(cursor)
  }

  const renderTotalResults = (totalCount) => {
    return( 
      totalCount ? 
        <div className="tl dtc">
          <div className="dib">
            <div className="f6"><b>{data.contractors.totalCount}</b> results</div>
          </div>
        </div> : null
    )
  }
  
  const renderPagination = (pageInfo) => {
    const hasNextPage = pageInfo?.hasNextPage
    
    return(
      <div className="tr dtc">
        <div className="dib">
          <button 
          type="button" 
          onClick={() => fetchNextPage(data)}
          disabled={!hasNextPage}
          className={classNames("fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml2 f7 lh-title border-box dib",
            {"bg-animate hover-bg-light-gray": hasNextPage})}>
            Next
          </button>
          <button 
          type="button" 
          onClick={() => navigate(-1)}
          disabled={cursor == null}
          className={classNames("fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 f7 lh-title border-box dib", 
            {"bg-animate hover-bg-light-gray": cursor != null})}>
            Previous
          </button>
        </div>
      </div>
    )
  }

  const loadingRow = () => (
    <tr><td colSpan={5}><ReactLoading type={'spin'} color={'#cccccc'} className="mv4 center" /></td></tr> 
  )

  const onTabChange = (filter) => {
    setAutoCompleteItem(null)
    setSelectedFilter(filter.toLowerCase())
    searchParams.set('filter', filter.toLowerCase());
    navigate(`?${searchParams.toString()}`);
  }
  
  function TableShell(props){
    return(
      <div>
        <TabbedTable 
          selectedFilter={selectedFilter} 
          setSelectedFilter={setSelectedFilter}
          filters={TABBED_TABLE_FILTERS}
          onTabChange={onTabChange}
        >
          <thead>
            <tr className="bb bw1 b--black-10">
              <th className="tl pv3 ph2 fw5 f7 ttu w-10">Created on</th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-30-l">Full Name (Company) </th>
              <th className="tl pv3 ph2 fw5 f7 ttu">Coverage</th>
              <th className="tl pv3 ph2 fw5 f7 ttu tc">Last Active Coverage Period</th>
              <th className="tl pv3 ph2 fw5 f7 ttu tc"></th>
            </tr>
          </thead>
          <tbody>
            {props.children}
          </tbody>
          </TabbedTable>
        <div className="db dt w-100">
          {props.totalCount ? renderTotalResults(props.totalCount) : null}
          {props.pageInfo ? renderPagination(props.pageInfo) : null}
        </div>
      </div>
    )
  }
  const renderLoadingTable = () => {
    return(
      <TableShell data={data}>
        {loadingRow()}
      </TableShell>
    )
  }

  const renderTable = (props) => {
    const contractorsUnredacted = [];

    if (data?.contractors && data?.contractors?.edges?.length > 0) {
      for (let i in data.contractors.edges) {
        contractorsUnredacted[i] = resolveRedacted(
          data.contractors.edges[i],
          data.contractors.unredacted[i]
        );
      }
    }
    
    return (
      (contractorsUnredacted.length > 0 || searchParams.has('filter')) ?
      <TableShell data={contractorsUnredacted} pageInfo={data?.contractors?.pageInfo} totalCount={data?.contractors?.totalCount}>
        {renderRows(contractorsUnredacted)}
      </TableShell>
      :
      <GetStarted 
        openModal={props.openModal}
      />
    )  
  }

  const renderFilteredTable = (props) => {
    const resolvedContractor = resolveRedacted(filteredData?.contractor, 
      JSON.parse(filteredData?.contractor.unredactedDict));
    
    const rowData = [resolvedContractor];
 
    return (
      <TableShell data={rowData}>
        {renderRows(rowData)}
      </TableShell>
    )
  }
  
  let table;
  
  if (autoCompleteItem){
    table = <>{ filterdLoading ?  renderLoadingTable() : renderFilteredTable(props)}</>
  } else {
    table = <>{ loading ?  renderLoadingTable() : renderTable(props)}</>
  }

  return (
    <div>
      {renderHeader(data)}
      {table}
    </div>
  )
}

function Contractors(props){
  const [successMessage, setSuccessMessage] = useState(false)
  
  const [deleteContractorModalValues, setDeleteContractorModalValues] = useState({
    contractorDeleteModalIsOpen: false,
    contractor: '',
    firstName: '',
    lastName: ''
  })
  
  const [inviteModalValues, setInviteModalValues] = useState({
    inviteModalIsOpen: false,
    quote_id: '',
    email: ''
  })

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const organizationName = props.organization?.name
  const bulkImportTemplateUrl = props.organization?.bulkImportTemplateUrl;
  
  return (
    <>
      <AgencyPayNoPurchaseOrder 
        successMessage={successMessage}
        setSuccessMessage={setSuccessMessage}
      />
      <MainWrapper isTestView={props.isTestView}>
        <header className="mb3">
          <h2 className="fw3 dark-gray mt0 mb4">Contractors</h2>
        </header>
        <ContractorsTable 
          openModal={() => setModalIsOpen(true)}
          setInviteModalValues={setInviteModalValues}
          setDeleteContractorModalValues={setDeleteContractorModalValues}
          isAdmin={props.isAdmin}
        />
        <DeleteContractorModal 
          openModal={() => setDeleteContractorModalValues({...deleteContractorModalValues, contractorDeleteModalIsOpen: true})}
          closeModal={() => setDeleteContractorModalValues({...deleteContractorModalValues, contractorDeleteModalIsOpen: false})}
          modalIsOpen={deleteContractorModalValues.contractorDeleteModalIsOpen}
          contractor={deleteContractorModalValues.contractor}
          firstName={deleteContractorModalValues.firstName}
          lastName={deleteContractorModalValues.lastName}
          token={props.token}
        />
        <InsuranceApplicationInviteModal 
          openModal={() => setInviteModalValues({...inviteModalValues, inviteModalIsOpen: true})}
          closeModal={() => setInviteModalValues({...inviteModalValues, inviteModalIsOpen: false})}
          modalIsOpen={inviteModalValues.inviteModalIsOpen}
          quote_id={inviteModalValues.quote_id}
          email={inviteModalValues.email}
          organizationName={organizationName}
          token={props.token}
        />
        <BulkImportModal 
          openModal={() => setModalIsOpen(true)}
          closeModal={() => setModalIsOpen(false)}
          modalIsOpen={modalIsOpen}
          token={props.token}
          client={props.client}
          bulkImportTemplateUrl={bulkImportTemplateUrl}
        />
      </MainWrapper>
    </>
  )
}

export default Contractors
