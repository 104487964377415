import React from 'react';
import ReactTooltip from 'react-tooltip'
import ReactLoading from 'react-loading';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import MainWrapper from '../components/MainWrapper';

class KeysPage extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      hidden: true,
      pk_copied: false,
      sk_copied: false,
      publicKey: props?.organization?.apikeys?.edges[0]?.node.publicKey,
      secretKey: props?.organization?.apikeys?.edges[0]?.node.secretKey,
    }
  }

  toggleHide = () => {
    this.setState({hidden: !this.state.hidden})
  }

  afterHideTip = () => {
    this.setState({sk_copied: false, pk_copied: false});
  }

  render = () => (
      !this.props.user ? 
      <MainWrapper isTestView={this.props.isTestView}>
        <div className="vh-100 dt w-100">
          <div className="dtc v-mid tc">
          <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
          </div>
        </div> 
      </MainWrapper>
      :
      <MainWrapper isTestView={this.props.isTestView}>
          <header className="mb3">
            <h2 className="fw3 dark-gray mt0 mb4">API Keys</h2>
          </header>
          <span className="db">
            <label htmlFor="public-key" className="f6 fw3 dib w-10">Public Key</label>
            <span className="relative">
              <input
                id="public-key"
                className="outline-0 pa2 b--black-10 ba br2 mv2 f6 w-75"
                value={this.state.publicKey}
                type="text"
                readOnly />
                <CopyToClipboard text={this.state.publicKey}
                  onCopy={() => this.setState({pk_copied: true})}>
                  <img data-tip data-for='pk_copy' className="w1 absolute pointer right-1 bottom-0" src="/copy.svg" alt="Copy" />
                </CopyToClipboard>
                <ReactTooltip 
                  id='pk_copy' 
                  type='info'
                  afterHide={this.afterHideTip} 
                  effect='solid'>
                  <span>{this.state.pk_copied ? 'Copied!' : 'Copy'}</span>
                </ReactTooltip>
            </span>
          </span>
          <span className="relative db mt3">
            <label htmlFor="dev-secret" className="f6 fw3 dib w-10">Secret Key</label>
            <span className="relative">
              <input
                id="dev-secret"
                className="outline-0 pa2 b--black-10 ba br2 mv2 f6 w-75"
                value={this.state.secretKey}
                type={this.state.hidden ? "password" : "text"}
                readOnly />
                {this.state.hidden ? <img onClick={this.toggleHide} style={{width: '18px'}} className="absolute pointer right-2 bottom-0 pr2" src="/eye.svg" alt="Copy" /> : 
                  <img onClick={this.toggleHide} style={{width: '18px'}} className="absolute pointer right-2 bottom-0 pr2" src="/eye_on.svg" alt="Copy" /> }
                <CopyToClipboard 
                  text={this.state.secretKey}
                  onCopy={() => this.setState({sk_copied: true})}>
                  <img data-tip data-for='sk_copy' className="w1 absolute pointer right-1 bottom-0" src="/copy.svg" alt="Copy" />
                </CopyToClipboard>
                <ReactTooltip 
                  id='sk_copy' 
                  type='info' 
                  afterHide={this.afterHideTip} 
                  effect='solid'>
                  <span>{this.state.sk_copied ? 'Copied!' : 'Copy'}</span>
                </ReactTooltip>
            </span>
          </span>
      </MainWrapper>
    )
}

export default KeysPage