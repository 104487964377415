import React, { useState } from 'react'

import ReactLoading from 'react-loading';

import { Helmet } from "react-helmet";
import { gql, useMutation } from "@apollo/client";

import {AuthWrapperWithLogo} from '../../components/AuthWrapper';

import OtpInput from 'react-otp-input';

const VERIFY_OTP = gql`
  mutation VerifyOtp($code: String!, $action: String! = "login") {
    verifyOtp(code: $code, action: $action) {
      ok
      message
      action
    }
  }
`

const VERIFY_BACKUP_CODE = gql`
  mutation VerifyBackupCode($code: String!) {
    verifyBackupCode(code: $code) {
      ok
      message
    }
  }
`

const OTP: React.FunctionComponent<{}> = () => {
  const [submitting, setSubmitting] = useState(false)
  const [authCode, setAuthCode] = useState('');
  const [backupCode, setBackupCode] = useState('');
  const [showBackupForm, setShowBackupForm] = useState(false);

  const [verifyOtp] = useMutation(VERIFY_OTP, {
    onCompleted: (data) => {
      setSubmitting(false)

      if (data?.verifyOtp?.ok) {
        window.location.href = '/overview';
      }
    },
  });

  const [verifyBackupCode] = useMutation(VERIFY_BACKUP_CODE, {
    onCompleted: (data) => {
      setSubmitting(false)

      if (data?.verifyBackupCode?.ok) {
        window.location.href = '/overview';
      } else {
        alert(data?.verifyBackupCode?.message);
      }
    },
  });

  const signInClickedUsingAuthCode = async () => {
    setSubmitting(true)
    verifyOtp({
      variables: {
        code: authCode,
        action: "login",
      } 
    })
  }

  const signInClickedUsingBackupCode = async () => {
    setSubmitting(true)
    verifyBackupCode({
      variables: {
        code: backupCode,
      } 
    })
  }

  const SignInButton = (props) => {
    if (props.submitting){
      return (
        <button className="button-reset bg-animate white mt0 bn ttu w-100 mt3 pa3 mb2 hover-white br2">
          <ReactLoading type={'spin'} color={'#cccccc'} height={20} width={20} className="center" />
        </button>
        )
    } else {
      return(
        <input className="pointer button-reset bg-animate bg-brand-pink white mt3 mb2 bn w-100 pa3 hover-white br2" 
          value="Continue" 
          onClick={props.signInClicked}
          type="submit" 
        />
      )
    }
  }
  
  return (
    <AuthWrapperWithLogo>
      <Helmet>
        <meta name="description" content="Create a free account to test integrating our API for on-demand workers comp and liability insurance quotes with your digital workforce platform." />
        <title>Two Factor Authentication | 1099Policy</title>
      </Helmet>

      <fieldset id="otp" className="ba b--transparent ph0 mh0 dark-gray">
        <legend className="f3 fw6 pt3 tc">Authentication code</legend>

        {
          showBackupForm ? (
            <div className="mt3">
              <p className="f12 fw4 gray tc db lh-copy">
                Backup codes are generated at the time of two-factor authentication setup and can only be used once.
              </p>

              <input
                type="text"
                className="input-reset ba b--black-20 pa3 mb2 db w-100 br2"
                placeholder="Enter your backup code"
                onChange={(e) => setBackupCode(e.target.value)}
              />

              <SignInButton
                signInClicked={signInClickedUsingBackupCode}
                submitting={submitting}
              />
              
              <p className="f8 fw4 dark-gray tc db mt3 mb0 pointer" onClick={() => setShowBackupForm(false)}>
                Want to use authenticator app ? Switch to OTP
              </p>
            </div>
          )
          : (
            <div className="otp-area">
              <p className="f12 fw4 gray tc db lh-copy">
                Open your two-factor authenticator (TOTP) app or browser extension to view your authentication code.
              </p>

              <OtpInput
                value={authCode}
                onChange={setAuthCode}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
              />

              <SignInButton
                signInClicked={signInClickedUsingAuthCode}
                submitting={submitting}
              />

              <p className="f8 fw4 dark-gray tc db mt3 mb0 pointer" onClick={() => setShowBackupForm(true)}>
                Can't access your phone ? Try backup codes
              </p>
            </div>
          )
        }
      </fieldset>
    </AuthWrapperWithLogo>
  )
}

export default OTP
