import React from "react";

import { useMutation } from "@apollo/client";

import { ACCEPT_OR_DENY_INVITATION } from "./mutations";

import Banner from "../../components/Banner";

function OrganizationInvitationBanner({ pendingInvitations }) {
  const [acceptOrDenyOrganizationInvitation] = useMutation(
    ACCEPT_OR_DENY_INVITATION,
    {
      onCompleted: (resp) => {
        const data = resp?.acceptOrDenyOrganizationInvitation;

        if (data.ok) {
          window.location.reload();
        } else {
          alert(data.message);
        }
      },
    }
  );

  function acceptOrganizationInvite(invitationId) {
    acceptOrDenyOrganizationInvitation({
      variables: {
        invitationId: invitationId,
        accept: true,
      },
    });
  }

  function denyOrganizationInvite(invitationId) {
    acceptOrDenyOrganizationInvitation({
      variables: {
        invitationId: invitationId,
        accept: false,
      },
    });
  }

  function base64Decode(id) {
    let res = atob(id);
    return Number(res.toString("ascii").split(":")[1]);
  }

  const renderInvitations = (pendingInvitations) =>
    pendingInvitations.map((invitation, index) => {
      return (
        <div className="dt w-100" key={index}>
          <div className="dtc v-mid w-70">
            <p className="f5 dark-gray pv3 lh-copy">
              <b>{invitation.invitor.email}</b> is inviting you to join{" "}
              {invitation.organization.name} to track the insurance coverage
              status of contractors you work with. Click "Accept" to get
              started.
            </p>
          </div>
          <div className="dtc v-mid">
            <button
              className="f6 link dim br2 ph3 pv2 dib red ba bg-white b--red pointer fr ml2"
              onClick={() =>
                denyOrganizationInvite(base64Decode(invitation.id))
              }
            >
              Deny
            </button>
            <button
              className="ml4 f6 link dim br2 ph3 pv2 dib white bg-blue ba b--blue pointer fr"
              onClick={() =>
                acceptOrganizationInvite(base64Decode(invitation.id))
              }
            >
              Accept
            </button>
          </div>
        </div>
      );
    });

  return <Banner>{renderInvitations(pendingInvitations)}</Banner>;
}

export default OrganizationInvitationBanner;
