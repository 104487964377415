import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import Autocomplete from "react-autocomplete";

import { startAndEnd } from "../utils/truncate";

const Pill = ({ key, value, removeItem }) => (
  <div key={key} className="mt1 mr1">
    <button
      type="button"
      className="br4 ba f7 pv1 ph2 bg-washed-blue b--blue blue pointer"
      onClick={() => {
        removeItem(value);
      }}
    >
      <div className="flex items-center space-between">
        <span className="flex-item lh-title">{startAndEnd(value, 42)}</span>
        <img
          className="flex-item pl1"
          style={{ width: "12px", height: "12px" }}
          src="/x.svg"
          alt="Close"
        />
      </div>
    </button>
  </div>
);

const AutocompleteAgencyRepInput = ({
  query,
  label,
  placeholder,
  onSelectionChange,
  menuStyleTop,
  menuStyleLeft,
  setSelectedValues,
  selectedValues,
  ...props
}) => {
  const { loading, error, data } = useQuery(query);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState("");

  useEffect(() => {
    if (data && data.agencyRepEmails) {
      setOptions(data.agencyRepEmails);
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const handleSelect = (value) => {
    setSelectedValues((prevSelectedValues) => {
      if (prevSelectedValues.includes(value)) {
        // If value is already selected, remove it (toggle off)
        return prevSelectedValues.filter(
          (selectedValue) => selectedValue !== value
        );
      } else {
        // Add the new value to the selection
        return [...prevSelectedValues, value];
      }
    });
  };

  const removeItem = (itemName) => {
    setSelectedValues(selectedValues.filter((item) => item !== itemName));
  };

  function matchEmailToTerm(agencyRepEmail, value) {
    return (
      agencyRepEmail.email !== null &&
      agencyRepEmail.email.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  return (
    <div className="flex flex-column mv2 relative">
      <label className="f6 fw5 black-50 db mb2">{label}</label>
      <Autocomplete
        renderInput={(props) => {
          const { key, ...inputProps } = props;

          return (
            <div
              style={{
                position: "relative",
                display: "inline-block",
                width: "290px",
              }}
            >
              <img
                style={{
                  position: "absolute",
                  left: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  height: "16px",
                  width: "16px",
                }}
                src="/search.svg"
                alt="search icon"
              />
              <input
                {...inputProps}
                type="text"
                id="agencyRepEmail"
                placeholder={placeholder}
                className="input-reset f6 ba b--black-20 pa2 w-100 db br2"
                style={{ paddingLeft: "30px" }}
              />
            </div>
          );
        }}
        wrapperProps={{ style: { display: "inline-block", width: "100%" } }}
        inputProps={{
          ...props,
          placeholder: placeholder,
          className: "input-reset f6 ba b--black-20 pa2 w-100 db br2",
          id: "agencyRepEmail",
          type: "text",
        }}
        menuStyle={{
          borderRadius: "3px",
          boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
          background: "rgba(255, 255, 255, 0.9)",
          padding: "2px 0",
          position: "absolute",
          top: menuStyleTop ? menuStyleTop : "66px",
          left: menuStyleLeft ? menuStyleLeft : "0px",
          overflow: "auto",
          width: "200px",
          maxHeight: "200px",
          zIndex: 1000,
        }}
        getItemValue={(item) => item.email} // Assuming each item has a 'name' field
        items={options}
        renderItem={(item, isHighlighted) => (
          <div
            key={item.id}
            className="pa2 f6"
            style={{ background: isHighlighted ? "lightgray" : "white" }}
          >
            {item.email}
          </div>
        )}
        value={value}
        shouldItemRender={matchEmailToTerm}
        onChange={(e) => setValue(e.target.value)}
        onSelect={(val, item) => {
          handleSelect(item.email);
          setValue("");
        }}
      />
      <div className="w-100 mv2 mh0 flex flex-wrap">
        {selectedValues.map((item) => (
          <Pill key={item} value={item} removeItem={removeItem} />
        ))}
      </div>
    </div>
  );
};

export default AutocompleteAgencyRepInput;
