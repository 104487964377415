import React, {useState, useEffect} from 'react';
import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom';

import Modal from 'react-modal';
import StepWizard from "react-step-wizard";
import DayPickerInput from 'react-day-picker/DayPickerInput';

import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { useCSVReader } from "react-papaparse";

import dateFnsFormat from 'date-fns/format';

import classNames from 'classnames'

import PageHeader from '../../components/PageHeader';

import { AdditionalInsuredForm } from '../OrganizationPage/SettingsPage/Requirement';

import { formatDate, parseDate } from '../../utils/date';
import { 
  removeEmpty, 
  transformCsvData, 
  createRequestInit,
  handleResponseStatus 
} from '../../utils/csvHelpers';

import 'react-day-picker/lib/style.css';
import "../../styles/wizard.css";

import { columnKeys } from "../../constants/bulkVendorTemplateColumns";

Modal.setAppElement('#root');

const FORMAT = 'MM/dd/yyyy';
const today = new Date()
let tomorrow = new Date()

let oneYear = new Date()
oneYear.setDate(oneYear.getDate() + 365) 

let contentStyle = {  
  width                 : '640px',
  maxHeight             : '90vh',
  top                   : '50%',
  left                  : '50%',
  right                 : 'auto',
  bottom                : 'auto',
  marginRight           : '-50%',
  transform             : 'translate(-50%, -50%)',
  boxShadow             : '2px 2px 8px 0px rgba( 0, 0, 0, 0.2 )',
  overflow              : 'hidden',
}

let modalStyles = {
  content : contentStyle,
  overlay : {
    backgroundColor : 'rgba(0, 0, 0, 0.5)'
  } 
};

let custom = {
  enterRight: "animated enterRight",
  enterLeft: "animated enterLeft",
  exitRight: "animated exitRight",
  exitLeft: "animated exitLeft",
  intro: "animated intro",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  effectiveDate: Yup.date().required('Effective date is required'),
  endDate: Yup.date().required('End date is required').min(Yup.ref('effectiveDate'), "End date can't be before effective date"),
});

const BULK_VENDOR_IMPORT_TEMPLATE_URL = "https://docs.google.com/spreadsheets/d/1-vox-Un8YltpgjSGWqcyKsCszK44hO1pvBd9CxRlqsU/edit?usp=sharing"

function TrackerForm(formProps) {
  const {
    trackerValues,
    setTrackerValues
  } = formProps
  
  return (
    <Formik
      initialValues={trackerValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        setTrackerValues(values);
        formProps.nextStep();
      }}
    >
      {(props) => {
          const { values, touched, errors, setFieldValue, isValid, dirty } = props
          
          const initialEffectiveDate = values.effectiveDate && new Date(values.effectiveDate)
          const initialEndDate = values.endDate && new Date(values.endDate)
          
          return(
            <Form>
              <div className="w-100 bt b--black-10 pt3">
                <div className="flex flex-wrap mb3">
                  <div className="w-100">
                    <label htmlFor="name" className="f6 mb1 mt3 db">
                      Name
                    </label>
                    <Field
                      id="name"
                      name="name"
                      placeholder="A short name to remember what you're tracking, e.g, Dreamforce."
                      className={classNames(
                        "f6 input-reset ba b--black-20 pa2 mb2 db w-100 br2 border-box",
                        { "bg-washed-red": touched.name && errors.name }
                      )}
                    />
                  </div>
                  <div className="w-100">
                    <label htmlFor="description" className="f6 mb1 mt3 db">
                      Description
                    </label>
                    <Field
                      id="description"
                      name="description"
                      placeholder="A one to two sentence description of the event or project."
                      component="textarea"
                      className={classNames(
                        "f6 input-reset ba b--black-20 pa2 mb2 db w-100 br2 border-box",
                        { "bg-washed-red": touched.description && errors.description }
                      )}
                    />
                  </div>
                  <div className="flex flex-row justify-between w-100">
                    <div className="pb2" style={{width: '49%'}}>
                      <label htmlFor="effectiveDate" className="f6 fw5 db mb2 mt3">Effective Date</label>
                        <DayPickerInput
                          inputProps={{ 
                            name: 'effectiveDate', 
                            className: 'f6 input-reset ba b--black-20 pa2 w-100 br2 border-box' 
                          }}
                          value={initialEffectiveDate}
                          classNames={{ container: '', overlay: '', overlayWrapper: ''}}
                          formatDate={formatDate}
                          parseDate={parseDate}
                          format={FORMAT}
                          dayPickerProps={{ disabledDays: { before: today } }}
                          placeholder={`${dateFnsFormat(today, FORMAT)}`}
                          onDayChange={(date) => (
                            setFieldValue('effectiveDate', date)
                          )}
                        />
                    </div>
                    <div className="pb2" style={{width: '49%'}}>
                      <label htmlFor="endDate" className="f6 fw5 db mb2 mt3">End Date</label>
                        <DayPickerInput
                          inputProps={{ 
                            name: 'endDate', 
                            className: 'f6 input-reset ba b--black-20 pa2 w-100 br2 border-box' 
                          }}
                          value={initialEndDate}
                          classNames={{ container: '', overlay: '', overlayWrapper: ''}}
                          formatDate={formatDate}
                          parseDate={parseDate}
                          format={FORMAT}
                          dayPickerProps={{ 
                            disabledDays: { 
                              before: new Date(values.effectiveDate), 
                              after: new Date(oneYear)
                          } }}
                          placeholder={`${dateFnsFormat(tomorrow, FORMAT)}`}
                          onDayChange={(date) => (
                            setFieldValue('endDate', date)
                          )}
                        />
                    </div>
                  </div>
                </div>
                <div className="fr mt3">
                  <button
                    type="submit"
                    className={classNames("f6 link dim br2 ba ph3 pv2 dib pointer", 
                      {'white bg-blue b--blue': isValid && dirty, 
                        'black bg-light-gray b--light-gray': !isValid || !dirty})
                      }
                  >
                    Next
                  </button>
                </div>
              </div>
            </Form> 
          )
        }}
    </Formik>
  )
}

function ProjectDetailStep(props) {
  const {
    hideModalSteps
  } = props
  
  return (
    <div>
      <div className="dib w-100">
        {!hideModalSteps && <h2 className="f6 fw4 lh-copy ma0">
          <b>Step 1 of 3</b>: Tell us about the event or project you want to track.
        </h2>}
        <TrackerForm {...props} />
      </div>
    </div>
  )
}

function CSVUploader(props) {
  const {
    nextStep,
    setCsvData,
    setColumnMap
  } = props

  const { CSVReader } = useCSVReader();

  function createMap(headerLabels) {
    let labelKeyMap = {};
    headerLabels.map((label, index) => {
      label != "" &&
        (labelKeyMap[label] = columnKeys?.[index]
          ? columnKeys[index]
          : columnKeys[0]);
    });
  
    return labelKeyMap;
  }

  const onUploadAccepted = (results) => {
    const headerLabels = results ? Object.keys(results.data[0]) : [];

    setColumnMap(createMap(headerLabels));
    setCsvData(results.data);
    nextStep();
  };

  return (
    <CSVReader
      onUploadAccepted={onUploadAccepted}
      config={{
        header: true,
        skipEmptyLines: true,
      }}
    >
      {({ getRootProps, acceptedFile, ProgressBar }) => (
        <div className="dib w-100">
          <h2 className="f6 fw3 lh-copy mb4">
            Make a copy of the bulk import{" "}
            <a
              href={props.bulkImportTemplateUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              template
            </a>{" "}
            to add vendors to track. If you have questions, email support@1099policy.com.
          </h2>
          <div className="w-100 dib flex">
            <button
              className="flex-auto f6 button-reset fl pv2 tc ba b--black bg-animate bg-black hover-bg-black-80 white pointer w-30 br2-ns br--left-ns"
              type="button"
              {...getRootProps()}
            >
              Browse & upload
            </button>
            <input
              className="flex-auto f6 input-reset ba b--black-10 fl black-80 bg-white pa2 lh-solid w-70 br2-ns br--right-ns"
              value={acceptedFile ? acceptedFile.name : ""}
              readOnly
            />
          </div>
          <div className="w-100 dib flex mt3">
            <ProgressBar style={{ backgroundColor: "green" }} />
          </div>
        </div>
      )}
    </CSVReader>
  )
}

function UploadContractorStep(props) {
  const { 
    csvData, 
    setCsvData, 
    setColumnMap,
    hideModalSteps 
  } = props;

  return (
    <div>
      <div className="dib w-100">
        {!hideModalSteps && <h2 className="f6 fw4 lh-copy ma0">
          <b>Step 2 of 3</b>: Upload the vendors you want to track.
        </h2>}
        <div className="bt b--black-10 pt3">
          <CSVUploader 
            {...props} 
            setColumnMap={setColumnMap}
            setCsvData={setCsvData}
            csvData={csvData}
            bulkImportTemplateUrl={props.bulkImportTemplateUrl ?? BULK_VENDOR_IMPORT_TEMPLATE_URL}
          />
        </div>
      </div>
      <div className="fr mt5">
        <button
          type="button"
          onClick={() => {
            props.previousStep();
          }}
          className="f6 link dim br2 ba ph3 pv2 mh2 dib black pointer"
        >
          Back
        </button>
        <button
          type="button"
          onClick={() => {
            props.nextStep();
          }}
          className={classNames("f6 link br2 ba ph3 pv2 dib", 
            {
              "white pointer bg-blue b--blue dim": csvData, 
              "black bg-light-gray b--light-gray": !csvData
            })}
          disabled={!csvData}
        >
          Next
        </button>
      </div>
    </div>
  )
}

function ImportResults(props) {
  const { importResults } = props;

  return (
    <div>
      <h2 className="db mb2">
        Import complete – results
      </h2>
      <div className="f6 fw4 lh-copy">
        <span className="f4 fw6 b black-90">{importResults?.processed}</span> row(s) uploaded
      </div>
      <div className="f6 fw4 lh-copy">
        <span className="f4 fw6 b black-90">{importResults?.success}</span> rows successfully processed
      </div>
      <div className="f6 fw4 lh-copy gray">
        <span className="f4 fw6 b">{importResults?.failure?.length}</span> errors
      </div>

      {importResults?.failure.length > 0 &&
        <div className="f6 fw4 lh-copy black-90 mt4">
          <span className="f5 fw6 b">Fail reason:</span>
          {importResults?.failure.map((label, idx) => 
            <span className="f6 fw4 db">{label}</span>
          )}
        </div>
      }
      
    </div>
  )

}

function SpeicifyRequirementsStep(formProps) {
  const {
    onUpload, 
    additionalInsured,
    previousStep,
    hideModalSteps
  } = formProps;
  
  const HELPER_TEXT = 'Do you have project specific named insureds requirements for your vendors? Add them here.';
  
  return (
    <div>
      <div className="dib w-100">
        {!hideModalSteps && <h2 className="f6 fw4 lh-copy ma0 pb0">
          <b>Step 3 of 3</b>: Add named insureds.
        </h2>}
      </div>
      <div className="f6 fw5 db mb2 pt4 bt b--black-10">
        <Formik
          initialValues={{
            additionalInsured: additionalInsured,
          }}
          onSubmit={(values, actions) => {
            onUpload(values.additionalInsured);
          }}
        >
          {(props) => (
            <>
              <Form>
                <div className="flex flex-column">
                <div className="lh-copy black-60 bg-washed-yellow ba br2 b--yellow ph3 pv1 mb4">
                  <p className="f7">
                    <b>How it works?</b>&nbsp;We'll review your vendors' certificate of insurance against the
                    the insurance requirements that you've defined&nbsp; 
                    <Link 
                      className="link blue"
                      to="/organization/requirements" 
                      target="_blank" 
                      rel="noopener noreferrer">
                    here
                    </Link>. If you have job category specific requirements, we'll use only the requirements that apply
                    to the vendor job category.</p> 
                </div>
                <div className="overflow-y-scroll bt bb b--black-20 pv3" style={{ height: "380px" }}>
                  <AdditionalInsuredForm 
                    helperText={HELPER_TEXT}
                    formWrapperStyling="flex flex-column w-100"
                    labelStyling="w-100 pb2" 
                    inputWrapperStyling="w-100"
                    isActiveEdit={true} 
                    editIndexDefault={0}
                    {...props} 
                  />
                </div>
                <div className="self-end mt3">
                  <button
                    type="button"
                    onClick={() => {
                      previousStep();
                    }}
                    className="f6 link dim br2 ba ph3 pv2 mh2 dib black pointer"
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="f6 link dim br2 ba ph3 pv2 dib white pointer bg-blue b--blue"
                  >
                    Complete
                  </button>
                </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  )
}

const useDisableBodyScroll = (open) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);
};

const Importing = () => (
  <div>
    <div className="tc db mb2">
      Importing...
    </div>
    <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
  </div>
)

function getValueByKey(metadata, key) {
  if (!metadata) return null;
  const item = metadata.edges?.find((data) => data?.node?.key === key);
  return item ? JSON.parse(item?.node?.value) : null;
}

function TrackerModal(props){
  const [importStarted, setImportStarted] = useState(false)
  const [importComplete, setImportComplete] = useState(false)
  const [importResults, setImportResults] = useState();
  
  const trackerData = props.trackerData || {};
  const trackerDates = getValueByKey(trackerData.customMetadata, 'TRACKER_DATES');    
  const additionalInsured = getValueByKey(trackerData.customMetadata, 'ADDITIONAL_INSURED');
  
  const [trackerValues, setTrackerValues] = useState({
    name: trackerData?.name || '',
    description: trackerData?.description || '',
    effectiveDate: trackerDates?.effectiveDate || '',
    endDate: trackerDates?.endDate || '',
  });
  
  const [csvData, setCsvData] = useState();
  const [columnMap, setColumnMap] = useState({});

  const [data, setData] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();

  const { 
    client,
    token,
    modalIsOpen, 
    setModalIsOpen,
    requestTypeStr,
    trackerId
  } = props;

  const DEFAULT_IMPORT_RESULTS = {
    processed: 0,
    success: 0,
    failure: [],
  };

  const DEFAULT_HIDE_MODAL_STEPS = false;
  const hideModalSteps = props.hideModalSteps || DEFAULT_HIDE_MODAL_STEPS;

  const PUT_REQUEST_TYPE = 'PUT';
  const POST_REQUEST_TYPE = 'POST';
  const requestType = requestTypeStr || POST_REQUEST_TYPE;

  useDisableBodyScroll(props.modalIsOpen);

  function handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  async function refetchTrackersAndShowResults() {
    await client.refetchQueries({
      include: ["Trackers", "TrackerById"],
    });
  }

  const pollTask = (url, task_id, token) => {
    const getInit = createRequestInit("GET", token);

    const poll = () => {
      fetch(`${url}/${task_id}`, getInit)
        .then(handleErrors)
        .then((taskResponse) => {
          return taskResponse.json();
        })
        .catch((err) => {
          clearInterval(pollTimer);
          setImportResults(DEFAULT_IMPORT_RESULTS);
          refetchTrackersAndShowResults();
          throw new Error({ url: url, err });
        })
        .then((taskData) => {
          if (taskData.task_status === "failed") {
            clearInterval(pollTimer);
            setImportResults(DEFAULT_IMPORT_RESULTS);
            refetchTrackersAndShowResults();
          } else if (taskData.task_status === "finished") {
            clearInterval(pollTimer);
            setImportResults(taskData.import_results);
            setImportComplete(!importComplete);
          }
        });
    };
    const pollTimer = setInterval(() => poll(), 1500);
  };
  
  const onUpload = (additionalInsureds) => {
    const BASE_URL = process.env.REACT_APP_TEN99_BASE_URL;
    const BULK_UPLOAD_PATH = "/api/v1/bulk/tracker";
    const BULK_URL = `${BASE_URL}${BULK_UPLOAD_PATH}`;

    // Use the refactored removeEmpty function
    const cleanedCsvData = removeEmpty(csvData);
    const transformedData = transformCsvData(cleanedCsvData, columnMap);

    let resStatus = 0;
    
    const requestInit = createRequestInit(requestType, token);
    const body = JSON.stringify({
      list: transformedData,
      metaData: { 
        ...trackerValues,
        additionalInsureds: additionalInsureds
      },
      ...(trackerId ? { trackerId: trackerId } : {})
    })
    
    const bulkInit = {
      ...requestInit,
      body: body,
    };

    fetch(BULK_URL, bulkInit)
      .then((response) => {
        resStatus = response.status;
        return response.json();
      })
      .then((data) => {
        handleResponseStatus(
          resStatus,
          data,
          setIsLoading,
          setError,
          setData,
          (data, setIsLoading) => {
            setData(data);
            setIsLoading(false);
            setImportStarted(!importStarted);
            pollTask(BULK_URL, data.task_id, token);
          }
        )
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }

  const pageHeaderLabel = props.pageHeaderLabel || 'Create Tracker';
  
  const renderModalBody = () => {
    if(importStarted && !importComplete){
      return(
        <Importing />
      )
    }
  
    if(importResults){
      return(
        <ImportResults importResults={importResults} />
      )
    }
    
    return(
      <StepWizard transitions={custom}>
        <ProjectDetailStep
          trackerValues={trackerValues}
          setTrackerValues={setTrackerValues}
          hideModalSteps={hideModalSteps}
        />
        <UploadContractorStep 
          csvData={csvData} 
          setCsvData={setCsvData} 
          columnMap={columnMap}
          setColumnMap={setColumnMap}
          hideModalSteps={hideModalSteps}
        />
        <SpeicifyRequirementsStep 
          onUpload={onUpload} 
          hideModalSteps={hideModalSteps}
          additionalInsured={additionalInsured}
        />
      </StepWizard>
    )
  }
  

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => {
        refetchTrackersAndShowResults();
        setModalIsOpen(false)
        setImportResults(null)
        setImportStarted(false)
        setImportComplete(false)
      }}
      style={modalStyles}
      contentLabel={pageHeaderLabel}>
        <div className="roboto">
          <PageHeader title={pageHeaderLabel} />
          {renderModalBody()}  
        </div>
    </Modal>
  )
}

export default TrackerModal;