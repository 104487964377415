import React, { Fragment } from 'react';
import ReactLoading from 'react-loading';
import Slider from 'react-rangeslider'

import DayPickerInput from 'react-day-picker/DayPickerInput';

import PageHeader from '../components/PageHeader';
import AdminDisclaimer from '../components/AdminDisclaimer';
import MainWrapper from '../components/MainWrapper';
import NumberField from '../components/NumberField';

import { centsToDollars, currencyFormat } from '../utils/currency'
import { dateDiffInDays } from '../utils/date'
import  withAuthorization  from '../utils/withAuthorization'

import { states } from '../constants/states'
import { environments } from "../constants/environments";

import 'react-rangeslider/lib/index.css'

const WORKERS_COMP = 'workers-comp'
const GENERAL = 'general'

// const CANCELLED_YES = "yes"
// const CANCELLED_NO = "no"

let tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)

class AuditRates extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      codes: null,
      selectedJobCategory: '',
      selectedCoverageType: '',
      selectedWorkState: '',
      data: null,
      error: null,
      isLoading: false,
      sliderValue: 100,
      premium: 0,
      isCancelled: false,
      effectiveDate: tomorrow,
      cancellationDate: null,
      shortRate: null,
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.fetchCodes();
  }

  fetchCodes = () => {
    const token = this.props.token
    const BASE_URL = process.env.REACT_APP_TEN99_BASE_URL
    const API_QUESTIONS = '/api/v1/classcodes'

    let resStatus = 0

    fetch(`${BASE_URL}${API_QUESTIONS}`, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Ten99Policy-Environment": localStorage.getItem('Ten99PolicyEnvironment') || environments.PRODUCTION,
        "Ten99Policy-Organization": localStorage.getItem(
          "Ten99PolicyOrganization"
        )  || "",
      }
    })
      .then(response => {
        resStatus = response.status
        return response.json()
      })
      .then(data => {
        switch (resStatus) {
          case 401:
            this.setState({ error: data.msg, isLoading: false })
            break
          default:
            this.setState({codes: data, isLoading: false})
            break
        }
      })
      .catch(error => setState({ error, isLoading: false }))
  }

  fetchRates = () => {
    const token = this.props.token
    const BASE_URL = process.env.REACT_APP_TEN99_BASE_URL
    const API_RATES = `/api/v1/rates?coverage_type=${this.state.selectedCoverageType}`

    let resStatus = 0

    fetch(`${BASE_URL}${API_RATES}`, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Ten99Policy-Environment": localStorage.getItem('Ten99PolicyEnvironment') || environments.PRODUCTION,
        "Ten99Policy-Organization": localStorage.getItem(
          "Ten99PolicyOrganization"
        )  || "",
      }
    })
      .then(response => {
        resStatus = response.status
        return response.json()
      })
      .then(data => {
        switch (resStatus) {
          case 401:
            this.setState({ error: data.msg, isLoading: false })
            break
          default:
            this.setState({ data, isLoading: false })
            break
        }
      })
      .catch(error => this.setState({ error, isLoading: false }))
  }

  fetchTaxes = () => {
    const token = this.props.token
    const BASE_URL = process.env.REACT_APP_TEN99_BASE_URL
    const API_RATES = `/api/v1/taxes?state_code=${this.state.selectedHomeState}`

    let resStatus = 0

    fetch(`${BASE_URL}${API_RATES}`, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Ten99Policy-Environment": localStorage.getItem('Ten99PolicyEnvironment') || environments.PRODUCTION,
        "Ten99Policy-Organization": localStorage.getItem(
          "Ten99PolicyOrganization"
        )  || "",
      }
    })
      .then(response => {
        resStatus = response.status
        return response.json()
      })
      .then(data => {
        switch (resStatus) {
          case 401:
            this.setState({ error: data.msg, isLoading: false })
            break
          default:
            console.log(data)
            this.setState({ taxes: data, isLoading: false })
            break
        }
      })
      .catch(error => this.setState({ error, isLoading: false }))
  }
  
  /**
   *  
   * DEPRECATE
   * 
  **/
  fetchShortRate = (used_days) => {
    const token = this.props.token
    const BASE_URL = process.env.REACT_APP_TEN99_BASE_URL
    const API_RATES = `/api/v1/shortrates/${used_days}`

    let resStatus = 0

    fetch(`${BASE_URL}${API_RATES}`, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Ten99Policy-Environment": localStorage.getItem('Ten99PolicyEnvironment') || environments.PRODUCTION,
        "Ten99Policy-Organization": localStorage.getItem('Ten99PolicyOrganization')  || ""
      }
    })
      .then(response => {
        resStatus = response.status
        return response.json()
      })
      .then(data => {
        switch (resStatus) {
          case 401:
            this.setState({ error: data.msg })
            break
          default:
            this.setState({ shortRate: data.ratio })
            break
        }
      })
      .catch(error => this.setState({ error, isLoading: false }))

  }

  onJobCategoryChange = (event) => {
    this.setState({ selectedJobCategory: event.target.value })
  }

  onCoverageTypeChange = (event) => {
    let fetchRates = this.fetchRates
    this.setState({ selectedCoverageType: event.target.value, selectedWorkState: null, selectedHomeState: null }, function(){
      fetchRates()
    })
  }

  onWorkStateChange = (event) => {
    this.setState({ selectedWorkState: event.target.value })
  }

  onHomeStateChange = (event) => {
    let fetchTaxes = this.fetchTaxes
    this.setState({ selectedHomeState: event.target.value }, function(){
      fetchTaxes()
    })
    
  }

  handleSliderChange = value => {
    this.setState({
      sliderValue: value
    })
  };

  handleInputChange = (event) => {
    this.setState({ sliderValue: parseInt(event, 10) });
  }

  handleSelectChange = (event) => {
    this.setState({ isCancelled: event.target.value === 'yes' })
  }

  handleEffectiveDateChange = (date) => {
    this.setState({ effectiveDate: date })
  }

  /**
   *  
   * DEPRECATE
   * 
  **/
  handleCancellationDateChange = (date) => {
    this.setState({ cancellationDate: date })
    const days = dateDiffInDays(this.state.effectiveDate, date)
    this.fetchShortRate(days)
  }

  renderJobCategories = () => (
    <div className="pb4 dt">
      <div className="dt-row">
        <label htmlFor="codes" className="mr2">Select job category:</label>
        <select name="codes" id="states" onChange={this.onJobCategoryChange} value={this.state.selectedJobCategory}>
          <option value="None">--</option>
          {
            this.state.codes.map((code, index) => (
              <option value={code.simplified} key={index}>{code.simplified}</option>
            ))
          }
        </select>
      </div>
    </div>
  )

  renderCoverageType = () => (
    <div className="pb4 dt">
      <div className="dt-row">
        <label htmlFor="states" className="mr2">Select coverage type:</label>
        <select name="coverageType" onChange={this.onCoverageTypeChange}>
          <option value="None">---</option>
          <option value={WORKERS_COMP}>Workers Comp</option>
          <option value={GENERAL}>General Liability</option>
        </select>
      </div>
    </div>
  )

  renderWorkStates = () => (
    <div className="pb4 dt">
      <div className="dt-row">
        <label htmlFor="workStates" className="mr2">Select work state:</label>
        <select name="workStates" id="workStates" onChange={this.onWorkStateChange} value={this.state.selectedWorkState}>
          <option value="None">--</option>
          {
            states.map((state, index) => (
              <option value={state.abbreviation} key={index}>{state.name}</option>
            ))
          }
        </select>
      </div>
    </div>
  )

  renderHomeStates = () => (
    <div className="tr">
      <select name="states" id="states" onChange={this.onHomeStateChange} value={this.state.selectedHomeState}>
        <option value="None">--</option>
        {
          states.map((state, index) => (
            <option value={state.abbreviation} key={index}>{state.name}</option>
          ))
        }
      </select>
    </div>
  )

  renderSliderInput = () => {
    const { sliderValue } = this.state

    return (
      <div className="dib w-100 lh-solid">
        <div className="fr">
          <label className="absolute pa0 ma0 o-0" htmlFor="exampleInputAmount">Amount (in dollars)</label>
          <div className=" relative mv3 dt dib">
            <div className="bg-light-gray b--black-20 bb bt bl pa2 br2 br--left dtc dib">$</div>
            <NumberField value={sliderValue} onChange={this.handleInputChange} />
            <div className="bg-light-gray b--black-20 ba bl-0 pa2 br2 br--right dtc dib">.00</div>
          </div>
        </div>
      </div>

    )
  }

  renderShortRateRows = () => {
    return (
      <Fragment>
        <tr>
          <td className="b pv3 pr3 bb b--black-20">
            Effective date
          </td>
          <td className="pv3 pr3 bb b--black-20 tr">
            <DayPickerInput
              dayPickerProps={{ disabledDays: { before: tomorrow } }}
              onDayChange={this.handleEffectiveDateChange}
            />
          </td>
        </tr>
        <tr>
          <td className="b pv3 pr3 bb b--black-20">
            Cancellation date
          </td>
          <td className="pv3 pr3 bb b--black-20 tr">
            <DayPickerInput
              dayPickerProps={{ disabledDays: { before: this.state.effectiveDate, after: tomorrow } }}
              onDayChange={this.handleCancellationDateChange}
            />
          </td>
        </tr>
        <tr>
          <td className="b pv3 pr3 bb b--black-20">
            Earned premium
          </td>
          <td className="pv3 pr3 bb b--black-20 tr">
            {currencyFormat(this.state.sliderValue * this.state.shortRate)}
          </td>
        </tr>
      </Fragment>
    )
  }

  renderSlider = (selectedRate, isGeneralLiability) => {
    const { sliderValue, shortRate } = this.state

    const horizontalLabels = {
      1000: '$100',
      200000: '$200k',
    }

    function premiumOwed(sliderValue) {
      let premium
      if (selectedRate.max_payroll > 0 && sliderValue > selectedRate.max_payroll / 100) {
        premium = currencyFormat((selectedRate.max_payroll / 10000) * (selectedRate.net_rate / 100), 2)
      } else if (selectedRate.flat_payroll > 0 && sliderValue > selectedRate.flat_payroll / 100) {
        premium = currencyFormat((selectedRate.premium / 100), 2)
      } else {
        premium = currencyFormat((sliderValue / 100) * (selectedRate.net_rate / 100), 2)
      }
      return premium
    }

    /**
     *  
     * DEPRECATE
     * 
    **/
    function minCovered(sliderValue) {
      const premium = (sliderValue / 100) * (selectedRate.net_rate / 100)
      const isCovered = premium >= selectedRate.minimum_premium / 100 ? 'Yes' : `No`
      return isCovered;
    }

    /**
     *  
     * DEPRECATE
     * 
    **/
    function shortfall(sliderValue) {
      const short = selectedRate.minimum_premium / 100 - (sliderValue * shortRate)
      return short
    }

    /**
     *  
     * DEPRECATE
     * 
    **/
    function getCancelled(isCancelled) {
      return isCancelled ? "yes" : "no"
    }

    return (
      <div className="slider w-100 pa4">
        <Slider
          min={100}
          max={200000}
          value={sliderValue}
          onChange={this.handleSliderChange}
          labels={horizontalLabels}
          tooltip={false}
        />
        <table className="f6 w-100 pt5" cellSpacing="0">
          <tbody className="lh-copy">
            <tr>
              <td className="b pv3 pr3 bb b--black-20">
                Income earned
              </td>
              <td className="bb b--black-20">
                {this.renderSliderInput()}
              </td>
            </tr>
            { isGeneralLiability && 
                <tr>
                  <td className="b pv3 pr3 bb b--black-20">Home State</td>
                  <td className="pv3 pr3 bb b--black-20">{this.renderHomeStates()}</td>
                </tr>
            }
            { this.state.taxes && 
                <>
                  <tr>
                    <td className="b pv3 pr3 bb b--black-20">Surplus lines taxes</td>
                    <td className="pv3 pr3 bb b--black-20 tr">{ 
                      this.state.taxes.tax_rate > 0 &&
                      (this.state.taxes.tax_rate * 100).toFixed(2) + '%'
                    }</td>
                  </tr>
                  <tr>
                    <td className="b pv3 pr3 bb b--black-20">Stamping fee</td>
                    <td className="pv3 pr3 bb b--black-20 tr">{
                      this.state.taxes.tax_stamping_fee_rate < 1 ?
                      (this.state.taxes.tax_stamping_fee_rate * 100).toFixed(2) + '%' :
                        this.state.taxes.tax_stamping_fee_rate.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })
                    }</td>
                  </tr>
                  <tr>
                    <td className="b pv3 pr3 bb b--black-20">Purchasing group fee</td>
                    <td className="pv3 pr3 bb b--black-20 tr">$50</td>
                  </tr>
                </>
            }
            {!this.state.isCancelled ?
              (<tr>
                <td className="b pv3 pr3 bb b--black-20">
                  Premium owed
                </td>
                <td className="pv3 pr3 bb b--black-20 tr">
                  {premiumOwed(sliderValue)}
                </td>
              </tr>) :
              this.renderShortRateRows()
            }
          </tbody>
        </table>
      </div >
    )

  }

  renderRates = () => {
    const selectedState = this.state.selectedWorkState
    const isGeneralLiability = this.state.selectedCoverageType === GENERAL
    const codeObj = this.state.codes.find((code) => code.simplified === this.state.selectedJobCategory)
    const categoryKey = isGeneralLiability ? ['gl_code','gl_class_codes'] : ['wc_code','wc_class_codes'];
    const selectedRate = this.state.data.find((rate) => rate.state === selectedState && codeObj[categoryKey[1]].includes(rate[categoryKey[0]]))
    if(!selectedRate){
      return (<div>No coverage available for selected state</div>)
    }
    return (
      <div className="flex">
        <div className="overflow-auto w-50 pt4">
          <table className="f6 w-80" cellSpacing="0">
            <tbody className="lh-copy">
              <tr>
                <td className="b pv3 pr3 bb b--black-20">Class Code</td>
                <td className="pv3 pr3 bb b--black-20">{selectedRate[categoryKey[0]]}</td>
              </tr>
              {/* <tr>
                <td className="b pv3 pr3 bb b--black-20">Waiver of Subrogation</td>
                <td className="pv3 pr3 bb b--black-20">{selectedRate.waiver_of_subrogation}</td>
              </tr> */}
              <tr>
                <td className="b pv3 pr3 bb b--black-20">Net rate</td>
                <td className="pv3 pr3 bb b--black-20">{centsToDollars(selectedRate.net_rate)}</td>
              </tr>
              {/* <tr>
                <td className="b pv3 pr3 bb b--black-20">Premium</td>
                <td className="pv3 pr3 bb b--black-20">{centsToDollars(selectedRate.premium)}</td>
              </tr>
              <tr>
                <td className="b pv3 pr3 bb b--black-20">Minimum Premium</td>
                <td className="pv3 pr3 bb b--black-20">{centsToDollars(selectedRate.minimum_premium)}</td>
              </tr> */}
              {
                selectedRate.min_payroll > 0 &&
                <tr>
                  <td className="b pv3 pr3 bb b--black-20">Minimum Payroll</td>
                  <td className="pv3 pr3 bb b--black-20">{centsToDollars(selectedRate.min_payroll)}</td>
                </tr>
              }
              {
                selectedRate.max_payroll > 0 &&
                <tr>
                  <td className="b pv3 pr3 bb b--black-20">Maximum Payroll</td>
                  <td className="pv3 pr3 bb b--black-20">{centsToDollars(selectedRate.max_payroll)}</td>
                </tr>
              }
              {
                selectedRate.min_payroll <= 0 && selectedRate.max_payroll <= 0 ?
                  < tr >
                    <td className="b pv3 pr3 bb b--black-20">Flat payroll</td>
                    <td className="pv3 pr3 bb b--black-20">Yes ({centsToDollars(selectedRate.flat_payroll)})</td>
                  </tr> : null
              }
              {
                selectedRate.notes && selectedRate.notes.length > 1 ? <tr>
                  <td className="b pv3 pr3 bb b--black-20">Notes</td>
                  <td className="pv3 pr3 bb b--black-20">{selectedRate.notes}</td>
                </tr> : null
              }
            </tbody>
          </table>
        </div>
        <div className="w-50">
          <div className="center mw5 mw6-ns br3 hidden ba b--black-10">
            <div className="f4 fw8 pa4 bg-near-white br3 br--top black-60 mv0 pv2 ph3">Premium calculator</div>
            {this.renderSlider(selectedRate, isGeneralLiability)}
          </div>
        </div>
      </div >
    )
  }

  render = () => {
    if (this.state.error) {
      return <MainWrapper><p>{this.state.error}</p></MainWrapper>
    }

    return (
      this.state.isLoading || !this.state.codes ?
        <MainWrapper isTestView={this.props.isTestView}>
          <div className="dtc v-mid tc">
            <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
          </div>
        </MainWrapper>
        :
        <MainWrapper isTestView={this.props.isTestView}>
          <AdminDisclaimer />
          <PageHeader title={'Audit Rates'} />
          <div>
            <label className="dn">Review net rate by state.</label>
            {this.state.codes && this.renderJobCategories()}
            {this.state.selectedJobCategory && this.renderCoverageType()}
            {this.state.selectedCoverageType && this.renderWorkStates()}
            {this.state.selectedWorkState && this.renderRates()}
          </div>
        </MainWrapper>
    )
  }
}

export default withAuthorization(AuditRates, ['admin'])