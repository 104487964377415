import React, {useState, useEffect} from 'react';
import ReactLoading from 'react-loading';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';

import { useQuery } from "@apollo/client";

import moment from 'moment';
import classNames from 'classnames'

import MainWrapper from '../../components/MainWrapper';

import TrackerModal from './TrackerModal';

import 'react-day-picker/lib/style.css';
import "../../styles/wizard.css";

import { TRACKER_QUERY } from './queries';
import { INSURANCE_REQUIREMENT_QUERY } from '../OrganizationPage/SettingsPage/queries';

import  withAuthorization  from '../../utils/withAuthorization';

function TrackerTable(props){
  const { setErrorMessage } = props

  const ADDITIONAL_INSURED = 'ADDITIONAL_INSURED'
  const TRACKER_DATES = 'TRACKER_DATES'

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const cursor = searchParams.get('starting_after')

  const { loading: requirementsLoading, data: requirementsData } = useQuery(INSURANCE_REQUIREMENT_QUERY, {
    notifyOnNetworkStatusChange: true
  })

  const { loading, data, error, fetchMore } = useQuery(TRACKER_QUERY, {
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    if (requirementsData?.insuranceRequirements?.length === 0 && data?.trackers?.edges?.length > 0){
      setErrorMessage("No insurance requirements found. Add insurance requirements to get started.")
    }
  }, [requirementsData, data])

  function renderAdditionalInsureds(customMetadata, key) {
    const additionalInsuredsRaw =  customMetadata.edges.find((item) => item.node.key === key);

    try {
      const items = JSON.parse(additionalInsuredsRaw?.node?.value);
      return items?.map((item, index) => (
        <span key={index}>
          {item.name}
        </span>
      ));
    } catch (error) {
      console.error("Error parsing JSON string:", error);
      return null;
    }
  }

  function renderDueDate(customMetadata, key) {
    const trackerDates = customMetadata.edges.find((item) => item.node.key === key);
    const dates = JSON.parse(trackerDates?.node?.value);
    return moment(dates?.effectiveDate).format('MMM D, YYYY');
  }

  function countApprovedCertificates(assignments) {
    let approvedCount = 0;
  
    assignments.edges.forEach((row) => {
      const externalCertificates = row?.node?.assignment?.contractor?.externalCertificates?.edges || [];
  
      externalCertificates.forEach((certificateEdge) => {
        const certificateAudits = certificateEdge?.node?.certificateAudits?.edges || [];
  
        if (certificateAudits.length > 0) {
          // Find the most recent certificate audit
          const mostRecentAudit = certificateAudits.reduce((latest, current) => {
            return new Date(latest.node.created) > new Date(current.node.created) ? latest : current;
          });
          
          // Check if the most recent audit is approved
          if (mostRecentAudit?.node?.status === 'APPROVED') {
            approvedCount++;
          }
        }
      });
    });
  
    return approvedCount;
  }

  const renderRows = (data) => {
    const rows = data.trackers.edges.map((row) => 
      <tr key={row.node.publicId}
        onClick={() => {
          navigate(row.node.publicId, {state: row.node})
        }} 
        className="bb b--light-gray hover-bg-washed-blue pointer">
        <td className="tl ph2 f7">{moment(row.node.created).format('MMM D, YYYY')}</td>
        <td className="tl ph2 f7">
          {row.node?.name}
        </td>
        <td className="tc pv3 f7">
          {row.node?.assignments.edges?.length}
        </td>
        <td className="tc pv3 f7">
        {countApprovedCertificates(row.node?.assignments)}/{row.node?.assignments.edges?.length}
        </td>
        <td className="tc pv3 f7">
          {renderAdditionalInsureds(row.node?.customMetadata, ADDITIONAL_INSURED)}
        </td>
        <td className="tc pv3 f7">
          {renderDueDate(row.node?.customMetadata, TRACKER_DATES)}
        </td>
      </tr>
    )

    return rows
  }

  const renderEmptyTable = (msg) => {
    if (msg){
      return (
        <tr>
          <td colSpan="6" className="tc pv3 f6">
            <div className="flex flex-column items-center mv4">
              {msg}
            </div>
          </td>
        </tr>
      )
    }

    return (
      <tr>
        <td colSpan="6" className="tc pv3 f7">
          <div className="flex flex-column items-center mv4">
            Create your first tracker to track insurance compliance for your events and projects.
          </div>
          <div className="w-3 f6">
            <button 
              className="outline-0 pointer br2 ba b--black-20 bg-blue white pa2 ml1 f7 lh-title border-box"
              onClick={props.openModal}>Create New Tracker</button>
          </div>
        </td>
      </tr>
    )
  }

  const fetchNextPage = (data) => {
    const cursor = data.trackers.pageInfo.endCursor
    fetchMore({
      variables: {
        cursor: cursor
      },
    })
    navigate(`/trackers?starting_after=${cursor}`)
  }

  const renderTotalResults = (totalCount) => {
    return( 
      totalCount ? 
        <div className="tl dtc">
          <div className="dib">
            <div className="f6"><b>{totalCount}</b> results</div>
          </div>
        </div> : null
    )
  }

  const renderPagination = (pageInfo) => {
    const hasNextPage = pageInfo?.hasNextPage
    
    return(
      <div className="tr dtc">
        <div className="dib">
          <button 
          type="button" 
          onClick={() => fetchNextPage(data)}
          disabled={!hasNextPage}
          className={classNames("fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml2 f7 lh-title border-box dib",
            {"bg-animate hover-bg-light-gray": hasNextPage})}>
            Next
          </button>
          <button 
          type="button" 
          onClick={() => navigate(-1)} // TODO: Fix this. We need to pass prev cursor and navigate to it instead of back
          disabled={cursor == null} // this should be !pageInfo?.hasPreviousPage but it will break because of navigate(-1)
          className={classNames("fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 f7 lh-title border-box dib", 
            {"bg-animate hover-bg-light-gray": cursor != null})}>
            Previous
          </button>
        </div>
      </div>
    )
  }

  const renderHeader = () => {
    return (
      <div className="flex justify-between">
        <div className="flex"></div>
        <div className="flex">
          <button 
            className="outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 f7 lh-title bg-animate hover-bg-light-gray border-box"
            onClick={props.openModal}>
              Create New Tracker
          </button>
        </div>
      </div> 
    )
  }

  function TableShell(props) {
    const {
      children,
      totalCount,
      pageInfo
    } = props
  
    return (
      <div>
        <table className="w-100 ba b--light-gray collapse ph2 mv2">
          <thead>
            <tr className="bb bw1 b--black-10">
              <th className="tl pv3 ph2 fw5 f7 ttu">Created on</th>
              <th className="tl w-30 ph2 fw5 f7 ttu">Name</th>
              <th className="tc pv3 fw5 f7 ttu"># of vendors</th>
              <th className="tc pv3 fw5 f7 ttu">Approved Certificates</th>
              <th className="tc pv3 fw5 f7 ttu">Additional insureds</th>
              <th className="tc pv3 fw5 f7 ttu">Due Date</th>
            </tr>
          </thead>
          <tbody>
            {children}
          </tbody>
        </table>
        <div className="db dt w-100">
          {totalCount ? renderTotalResults(totalCount) : null}
          {pageInfo ? renderPagination(pageInfo) : null}
        </div>
      </div>
    )
  }

  const renderTable = (data) => {
    const trackers = data.trackers.edges

    if (requirementsData?.insuranceRequirements?.length === 0 && trackers?.length == 0){
      const msg = (<div>No insurance requirements found. Click&nbsp;
        <Link to="/organization/requirements">here</Link> to add requirements and get started.</div>)
      return (
        <TableShell data={data}>
          {renderEmptyTable(msg)}
        </TableShell>
      )
    }

    if (!trackers || trackers.length === 0) {
      return (
        <TableShell data={data}>
          {renderEmptyTable()}
        </TableShell>
      )
    }
    
    return (
      <div>
        {renderHeader()}
        <TableShell 
          data={data}
          pageInfo={data?.trackers?.pageInfo} 
          totalCount={data?.trackers?.totalCount}
        >
          {renderRows(data)}
        </TableShell>
      </div>
    )
  }

  return (
    loading || error ? 
      <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
    :
      renderTable(data)
  )
}

function Trackers(props){
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)

  return (
    <>
    <MainWrapper isTestView={props.isTestView}>
      <header className="mb3">
        <h2 className="fw3 dark-gray mt0 mb4">Trackers</h2>
      </header>
      <TrackerTable 
        openModal={() => setModalIsOpen(true)}
        setErrorMessage={setErrorMessage}
      />
    </MainWrapper>
    <TrackerModal
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      setErrorMessage={setErrorMessage}
      setSuccessMessage={setSuccessMessage} 
      {...props}
    />
    </>
  )  
}

export default withAuthorization(Trackers, ['admin']);