import React, { useState, useRef } from 'react';
import ReactLoading from 'react-loading';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Modal from 'react-modal';

import PageHeader from '../../components/PageHeader';
import MainWrapper from '../../components/MainWrapper';
import TabbedTable from '../../components/TabbedTable';
import PopoverMenu from '../../components/PopoverMenu';

import { Email } from '../../components/AuthComponents'

import { roles } from '../../constants/roles';

import { gql, useMutation } from "@apollo/client";
import moment from 'moment';

import { base64Decode } from '../../utils/base64decode';
import { useValidEmail } from '../../hooks/useAuthHooks'

import { 
  SEND_USER_INVITATION, 
  LEAVE_ORGANIZATION, 
  REMOVE_USER_FROM_ORGANIZATION, 
  RESEND_ORGANIZATION_INVITATION,
  UPDATE_ORGANIZATION_USER_ROLE 
} from './mutations';

const modalStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    boxShadow             : '2px 2px 8px 0px rgba( 0, 0, 0, 0.2 )',
    width                 : '540px',
  },
  overlay : {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  } 
};

function UpdateOrganizationUserRoleModal(props){
  const [selectedRole, setSelectedRole] = useState(roles.MEMBER);

  // Change handler updates the selectedRole state
  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  return (
    <Modal 
      isOpen={props.updateRoleModalIsOpen} 
      onRequestClose={() => {
        props.closeUpdateRoleModal()
      }}
      style={modalStyles}
      contentLabel="Update member role"
      preventScroll={true}
    >
      <div className="roboto">
        <PageHeader title={'Update role'} />
        <div className="fw3">
          <div className="pb3">
            <label className="f6 fw5 db">Select user role</label>
            <select 
              className='outline-0 pa2 b--black-10 ba br2 mv2 f6 w-100 db pointer ttc' 
              id="userRole" 
              value={selectedRole} 
              onChange={(e) => handleRoleChange(e)}>
                {Object.entries(roles).filter(([key, value]) => key !== 'ADMIN').map(([key, value]) => (
                  <option key={key} value={value}>{value}</option>
                ))}
            </select>
          </div>
          <div className="mv4">
            <button 
              className="f6 link dim br2 ph3 pv2 mb2 ml2 dib white bg-blue ba b--blue pointer fr"
              type="submit" 
              onClick={() => props.handleUpdateRole(props.organizationId, selectedRole, props.userId)}
              id="update-member-role">
              {props.updateRoleIsLoading ? loadingSpinner() : 'Update'}
            </button>

            <button 
              onClick={props.closeUpdateRoleModal}
              className="f6 link dim br2 ba ph3 pv2 mb2 dib black pointer fr"
              disabled={props.updateRoleIsLoading}
              type="submit">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}


function InviteMemberModal(props){
  const inputProps = {
    "className": "outline-0 pa2 b--black-10 ba br2 mv2 f6 w-100 db",
    "ref": props.invitedUserEmail,
    "id": "invite-user",
    "placeholder": "e.g., joe@1099policy.com",
  }

  const loadingSpinner = () => (
    <ReactLoading type={'spin'} color={'#cccccc'} width={16} height={16} />
  )

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={() => {
        props.closeModal()
      }}
      style={modalStyles}
      contentLabel="Invite team member"
      preventScroll={true}
    >
      <div className="roboto">
        <PageHeader title={'Invite team member'} />
        <div className="fw3">
          <div className="pb3">
            <label className="f6 fw5 db">Select user role</label>
            <select
              className="outline-0 pa2 b--black-10 ba br2 mv2 f6 w-100 db pointer"
              ref={props.invitedUserRole}
            >
              <option value={roles.MEMBER}>{roles.MEMBER.charAt(0).toUpperCase() + roles.MEMBER.slice(1)}</option>
              <option value={roles.OWNER}>{roles.OWNER.charAt(0).toUpperCase() + roles.OWNER.slice(1)}</option>
            </select>
          </div>

          <div>
            <Email
              email={props.email}
              itemProps={inputProps}
              emailLabel="Add team email address"
              emailIsValid={props.emailIsValid}
              setEmail={props.setEmail} />{' '}
          </div>

          <div className="mv4">
            <button 
              className="f6 link dim br2 ph3 pv2 mb2 ml2 dib white bg-blue ba b--blue pointer fr"
              type="submit" 
              onClick={props.email.length && props.emailIsValid ? props.inviteUserToOrganization : () => false}
              id="send-invitation">
              {props.isLoading ? loadingSpinner() : 'Send Invite'}
            </button>

            <button 
              onClick={props.closeModal}
              className="f6 link dim br2 ba ph3 pv2 mb2 dib black pointer fr"
              disabled={props.isLoading}
              type="submit">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const OrganizationUsers = (props) => {
  const navigate = useNavigate();
  
  const ACTIVE = 'active';
  const PENDING = 'pending';
  const EXPIRED = 'expired';

  const ALL_TABBED_TABLE_FILTERS = [
    { label: ACTIVE, value: ACTIVE },
    { label: PENDING, value: PENDING },
    { label: EXPIRED, value: EXPIRED },
  ];

  const TABBED_TABLE_FILTERS = ALL_TABBED_TABLE_FILTERS.map(filter => filter.label);

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedFilter, setSelectedFilter] = useState(searchParams.get('filter') || TABBED_TABLE_FILTERS[0]);
  
  const [lastRowClicked, setLastRowClicked] = useState(null)
  
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [updateRoleModalIsOpen, setUpdateRoleModalIsOpen] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const { email, setEmail, emailIsValid } = useValidEmail('')

  const { user, isTestView, organization, isAdmin } = props;
  
  const selectedOrg = user?.organizations?.edges.find(data => data?.node.organization.id === organization.id)
  
  const roleName = isAdmin ? 'admin' : selectedOrg?.node?.organizationRole?.role?.name

  const [sendOrganizationInvite, {loading: inviteLoading, reset: inviteReset}] = useMutation(SEND_USER_INVITATION, {
    onCompleted: (resp) => {
      inviteReset()
      setModalIsOpen(false)
      setIsLoading(false)
      
      setSelectedFilter(PENDING)
      searchParams.set('filter', PENDING);
      navigate(`?${searchParams.toString()}`);
    },
    onError: (resp) => {
      console.log(resp)
    },
    refetchQueries: ['CurrentUser', 'OrganizationByID']
  });

  const [leaveOrganizationRequest, {loading: leaveOrgLoading, reset: leaveReset}] = useMutation(LEAVE_ORGANIZATION, {
    onCompleted: (resp) => {
      leaveReset()
    },
    onError: (resp) => {
      console.log(resp)
    },
    refetchQueries: ['CurrentUser', 'OrganizationByID']
  });

  const [removeUserFromOrganizationRequest, {loading: removeUserLoading, reset: removeUserReset}] = useMutation(REMOVE_USER_FROM_ORGANIZATION, {
    onCompleted: (resp) => {
      removeUserReset()
    },
    onError: (resp) => {
      console.log(resp)
    },
    refetchQueries: ['CurrentUser', 'OrganizationByID']
  });

  const [resendOrganizationInviteRequest, {loading: resendInviteLoading, reset: resendInviteReset}] = useMutation(RESEND_ORGANIZATION_INVITATION, {
    onCompleted: (resp) => {
      console.log(resp);
      resendInviteReset();
    },
    onError: (resp) => {
      console.log(resp)
    },
    refetchQueries: ['CurrentUser', 'OrganizationByID']
  });

  const [updateOrganizationUserRole, {loading: updateRoleIsLoading, reset: updateRoleReset}] = useMutation(UPDATE_ORGANIZATION_USER_ROLE, {
    onCompleted: (resp) => {
      console.log(resp);
      updateRoleReset();
      setUpdateRoleModalIsOpen(false)
      setIsLoading(false)
    },
    onError: (resp) => {
      console.log(resp)
    },
    refetchQueries: ['CurrentUser', 'OrganizationByID']
  });

  const invitedUserEmail = useRef(null);
  const invitedUserRole = useRef(roles.MEMBER);

  function handleUpdateRole(organizationId, newRole, userId) {
    setIsLoading(true)
    
    updateOrganizationUserRole({
      variables: {
        userId: base64Decode(userId),
        organizationId: base64Decode(organizationId),
        newRole: newRole,
      }
    })
  }

  function inviteUserToOrganization() {
    setIsLoading(true)

    sendOrganizationInvite({
      variables: {
        email: invitedUserEmail.current.value,
        role: invitedUserRole.current.value,
      },
    });
  }

  function removeUserFromOrganization(organizationId, userId) {
    removeUserFromOrganizationRequest({
      variables: {
        organizationId: organizationId,
        userId: userId,
      },
    });
  }

  function leaveOrganization(organizationId) {
    leaveOrganizationRequest({
      variables: {
        organizationId: organizationId,
      },
    });
  }

  function resendOrganizationInvite(organizationId, invitationId) {
    resendOrganizationInviteRequest({
      variables: {
        organizationId: organizationId,
        invitationId: invitationId,
      },
    });
  }

  const loadingSpinner = () => (
    <ReactLoading type={'spin'} color={'#cccccc'} width={14} height={14} />
  )

  function openModal() {
    setModalIsOpen(true)
    setEmail('');
  }

  function OrganizationActions(props) {    
    const renderInviteButton = () => (
      <button 
        type="button" 
        onClick={props.openModal}
        className="fr outline-0 pointer br2 ba b--black-20 white bg-blue pa2 ml1 mv3 f7 lh-title border-box dim">
        ＋ New member
      </button>
    )

    return (
      <div>
        {[roles.ADMIN, roles.OWNER].includes(roleName) ?
          renderInviteButton()
          :
          <div className="fw3">
            <div className="pt4">
              <button
                onClick={() => leaveOrganization(base64Decode(organization?.id))}
                id="leave-organization"
                type="button"
                className="outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 f7 lh-title bg-animate hover-bg-light-gray border-box"
                disabled={leaveOrgLoading}
                >
                {
                  leaveOrgLoading ? loadingSpinner() : 'Leave Organization'
                }  
              </button>
            </div>
          </div>
        }
      </div>
    )
  }

  function MembersTable(){
    const onTabChange = (filter) => {
      setSelectedFilter(filter.toLowerCase())
      searchParams.set('filter', filter.toLowerCase());
      navigate(`?${searchParams.toString()}`);
    }

    const TableShell = (props) => {
      return (
        <div>
          <TabbedTable 
            selectedFilter={selectedFilter} 
            setSelectedFilter={setSelectedFilter}
            filters={TABBED_TABLE_FILTERS}
            onTabChange={onTabChange}
          >
            <thead>
              <tr className="bb bw1 b--black-10">
                <th className="tl pv3 ph2 fw5 f7 ttu w-40-l">Email</th>
                <th className="tl pv3 ph2 fw5 f7 ttu w-15-l">Role</th>
                <th className="tl pv3 ph2 fw5 f7 ttu w-20">Created on</th>

                {[roles.ADMIN, roles.OWNER].includes(roleName) &&
                  <th className="tl pv3 ph2 fw5 f7 ttu w-15">Actions</th>
                }

                {[roles.ADMIN].includes(roleName) &&
                  <th className="tl pv3 ph2 fw5 f7 ttu tc w-10"></th>
                }
              </tr>
            </thead>
            <tbody>
              {props.children}
            </tbody>
          </TabbedTable>
        </div>
    )}

    const popoverMenuContent = (userId) => {
      return (
        <ul className="f7 list pv2 pl0 measure center">
          <li 
            className="lh-copy pv1 fw5 link dim pointer"
            onClick={(e) => {
              e.stopPropagation();
              setUpdateRoleModalIsOpen(true)
              setLastRowClicked(userId)
            }}
            >
              Edit member role
            </li>
        </ul>
      )
    }
    
    const renderRows = (organization) => {
      const ownersOrganized = organization?.users?.edges.filter(u => u.node.organizationRole.role.name == roles.OWNER);
      const membersOrganized = organization?.users?.edges.filter(u => u.node.organizationRole.role.name != roles.OWNER);
      const usersOrganized = [...ownersOrganized, ...membersOrganized];
      
      let organizationMembers = usersOrganized.map((row) => {
        const isMatch = lastRowClicked == row.node.user.id 
        const isLoading = removeUserLoading && isMatch

        return (row?.node?.user && 
        <tr className="bb b--light-gray" key={row.node.user.id}>
          <td className="tl ph2 pv3 f7 w-40-l">{row.node.user.email}</td>
          <td className="tl ph2 pv3 f7 w-15-l">
            <small className="dib ph3 pv2 ba br-pill ttc">{row.node.organizationRole.role.name}</small>
          </td>
          <td className="tl ph2 f7 w-20">{moment(row.node.created).format('MMM D, YYYY')}</td>

          <td className="tl ph2 f7">
            {[roles.ADMIN, roles.OWNER].includes(roleName) && row.node.organizationRole.role.name !== roles.OWNER &&
                <button 
                  className="pointer pa1" 
                  onClick={() => {
                    setLastRowClicked(row.node.user.id)
                    removeUserFromOrganization(base64Decode(organization.id), base64Decode(row.node.user.id))
                  }}
                  disabled={isLoading}
                >
                  { isLoading ? 
                    loadingSpinner() : 'Remove user'}
                </button>
            }
          </td>

          <td className="tl ph2 f7">
            {[roles.ADMIN].includes(roleName) &&
              <PopoverMenu 
                menuContent={() => popoverMenuContent(row.node.user.id)}
              >
                <img className="w1 pointer" src="/ellipsis.svg" alt="Edit Member Menu" />
              </PopoverMenu>
            }
          </td>
        </tr>)
      })

      let awaitingInvitations = organization?.invitations?.filter(
          invitation => selectedFilter === 'expired'? 
          invitation.status === 'expired' : invitation.status === 'pending').map((row) => {
        const isExpired = row.status == "expired"
        
        const isMatch = lastRowClicked == row.id
        const isLoading = resendInviteLoading && isMatch

        return (
          <tr className="bb b--light-gray" key={row.id}>
            <td className="tl ph2 pv3 f7 w-40-l">
              <span className="db mt1">{row.email}</span>
            </td>
            <td className="tl ph2 pv3 f7 w-15">
              {isExpired ?
                <span className="white bg-washed-red red br-pill ph3 pv2 dib ttc mr2"><small>expired</small></span> :
                <small className="navy bg-lightest-blue br-pill ph3 pv2 dib ttc">Pending Invite</small>
              }
              </td>
            
            <td className="tl ph2 f7 w-20">
              Invited on {moment(row.invited_at).format('MMM D, YYYY')}
            </td>

            {[roles.ADMIN, roles.OWNER].includes(roleName) && isExpired &&
              <td className="tl ph2 f7">
                <button 
                  className="pointer pa1" 
                  onClick={() => {
                    setLastRowClicked(row.id)
                    resendOrganizationInvite(base64Decode(organization.id), row.id)
                  }}
                  disabled={isLoading}
                >
                  { isLoading ? loadingSpinner() : 'Resend invitation'
                  }
                </button>
              </td>
            }
          </tr>
        )
      })
      
      organizationMembers = organizationMembers || []
      awaitingInvitations = awaitingInvitations || []

      if (organizationMembers.length == 0){
        organizationMembers = [
          <tr>
            <td colSpan="4" className="tc pv3 f7">No users found</td>
          </tr>
        ]
      }

      if (awaitingInvitations.length == 0){
        awaitingInvitations = [
          <tr>
            <td colSpan="4" className="tc pv3 f7">No results</td>
          </tr>
        ]
      }

      if (selectedFilter === ACTIVE) {
        return organizationMembers
      } else if (selectedFilter === PENDING) {
        return awaitingInvitations
      } else if (selectedFilter === EXPIRED) {
        return awaitingInvitations
      }
    }

    const renderTable = () => {
      return (
        <TableShell>
          {renderRows(organization)}
        </TableShell>
      )  
    }
    
    return (
      <>{renderTable()}</>
    )
  }

  return (
    !user ? 
    <MainWrapper isTestView={isTestView}>
      <div className="vh-100 dt w-100">
        <div className="dtc v-mid tc">
        <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
        </div>
      </div> 
    </MainWrapper>
    :
    <MainWrapper isTestView={isTestView}>
      <header className="flex justify-between">
        <h2 className="fw3 dark-gray mt0 mb0 content-center">Team</h2>
        {
        organization ?
          <OrganizationActions 
            openModal={openModal}
          />
        : 
          <span>You don't belong to an organization</span>
      }
      </header>

      <MembersTable/>
      <InviteMemberModal 
        invitedUserEmail={invitedUserEmail}
        invitedUserRole={invitedUserRole}
        email={email}
        setEmail={setEmail}
        emailIsValid={emailIsValid}
        inviteUserToOrganization={inviteUserToOrganization}
        openModal={openModal}
        closeModal={() => setModalIsOpen(false)}
        modalIsOpen={modalIsOpen}
        isLoading={isLoading}
      />
      <UpdateOrganizationUserRoleModal
        organizationId={organization.id}
        userId={lastRowClicked}
        openModal={() => setUpdateRoleModalIsOpen(true)}
        updateRoleModalIsOpen={updateRoleModalIsOpen}
        closeUpdateRoleModal={() => setUpdateRoleModalIsOpen(false)}
        handleUpdateRole={handleUpdateRole}
        isLoading={isLoading}
      />
    </MainWrapper>
  )
}

export default OrganizationUsers