import { gql } from "@apollo/client";

export const PENDING_INVITATIONS_QUERY = gql`
  query PendingInvitations(
    $email: String!,
  ){
    pendingInvitations(
      email: $email,
    ){
      id
      email
      invitor {
          email
      }
      organization {
          id
          name
      }
    }
  }
`;