import React, { useState, useContext } from 'react'

import { useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";

import { useValidCode, useValidUsername } from '../../hooks/useAuthHooks'
import { Code, Username } from '../../components/AuthComponents'
import {AuthWrapperWithLogo} from '../../components/AuthWrapper';

import { AuthContext } from '../../contexts/authContext'


const VerifyCode: React.FunctionComponent<{}> = () => {
  const defaultEmail = localStorage.getItem("Ten99PolicyDefaultEmail")
  
  const { username, setUsername, usernameIsValid } = useValidUsername(defaultEmail || '')
  const { code, setCode, codeIsValid } = useValidCode('')
  const [error, setError] = useState('')
  const [resetSent, setResetSent] = useState(false)

  const isValid = !usernameIsValid || username.length === 0 || !codeIsValid || code.length === 0

  const navigate = useNavigate()

  const authContext = useContext(AuthContext)

  const sendClicked = async () => {
    try {
      await authContext.verifyCode(username, code)
      navigate('/signin')
    } catch (err) {
      setError('Invalid Code')
    }
  }
  
  const sendCodeClicked = async () => {
    try {
      await authContext.sendCode(username)
      setResetSent(true)
    } catch (err) {
      setError('Unknown email')
    }
  }

  const CancelButton = (props) => {
    return (
      <input className="pointer button-reset mt0 pa2 bn fr f6 mr3 br2"
        value="Cancel"
        onClick={() => navigate(-1)}
        type="button"
      />
    )
  }

  const VerifyButton = (props) => {
    return (
      <input className="pointer button-reset mt0 pa2 bn bg-brand-pink white fr f6 ph3 br2"
        value="Verify" 
        onClick={sendClicked}
        type="button"
      />
    )
  }

  const ResendCode = (props) => {
    return (
      <input className="pointer button-reset mt0 bn pv2 underline" 
        value="Resend code" 
        onClick={sendCodeClicked}
      />
    )
  }

  return (
    <AuthWrapperWithLogo>
      <Helmet>
        <meta name="description" content="Create a free account to test integrating our API for on-demand workers comp and liability insurance quotes with your digital workforce platform." />
        <title>Verify code – 1099Policy</title>
      </Helmet>
      <fieldset id="verify" className="ba b--transparent ph0 mh0 dark-gray">
        <legend className="f3 pv3 fw7">Verify Code</legend>
        <Username username={username} usernameIsValid={usernameIsValid} setUsername={setUsername} />{' '}
        <Code codeIsValid={codeIsValid} setCode={setCode} />
        {error ? (<div className="brand-pink pb3">{error}</div>) : null}
        
        <div className="w-100">
          {resetSent ? 'Email sent' : <ResendCode /> }
        </div>

        <div className="w-100">
          <VerifyButton />
          <CancelButton />
        </div>
      </fieldset>
    </AuthWrapperWithLogo>
  )
}

export default VerifyCode
