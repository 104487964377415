import React, {useState} from 'react';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';

import Modal from 'react-modal';
import ShowMore from 'react-show-more';

import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";

import moment from 'moment';
import classNames from 'classnames'

import MainWrapper from '../../components/MainWrapper';

Modal.setAppElement('#root');


const JOB_CODE_QUERY = gql`
  query JobCategory($hideReviewed: Boolean!) {
    jobCategory(hideReviewed: $hideReviewed) {
      id
      code
      wcName
      className
      classId
      reviewStatus
      created
      wcNetRates
      glNetRates
      jobSamples {
        edges {
          node {
            id
            name
            description
          }
        }
      }
    }
  }
`;

function GetStarted() {
  return (
    <div>
      <p className="lh-copy f6 fw3 black-70">
        To start, you'll need one or more approved job class codes which
        you'll use to categorize the types of jobs that require insurance. 
        To get started with your pre-approved job codes contact us at 
        support@1099policy.com.
      </p>
    </div>
  )
}

function BaseJobCodeTable(props){
  const navigate = useNavigate();
  
  const getJobNames = (jobSamples) => {
    const names = jobSamples.edges.map((sample) => 
      <div className="pv2" key={sample.node.name}>
        {sample.node.name}
        <span className="db moon-gray lh-copy">
          <ShowMore anchorClass="dark-gray">
            {sample.node.description}
          </ShowMore>
        </span>
      </div>
    )

    return names
  }

  const renderRows = (data) => {
    const rows = data.jobCategory.map((row) => 
      <tr onClick={() => {
        navigate(row.code, {state: row})
      }} 
        className="bb b--light-gray hover-bg-washed-blue pointer" key={row.id}>
        <td className="tl ph2 f7">{moment(row.created).format('MMM D, YYYY')}</td>
        <td className="tl ph2 f7">{getJobNames(row.jobSamples)}</td>
        <td className="pv3 f7">
          <span className={classNames(
            "br-pill ph3 pv1 dib ttc",
            {"navy bg-lightest-blue": row.reviewStatus.toLowerCase() === 'reviewed'},
            {"ba mid-gray": row.reviewStatus.toLowerCase() !== 'reviewed'}
          )}>
            {row.reviewStatus}
          </span>
        </td>
        <td className="pv3 f7">{row.code}</td>
      </tr>
    )

    return rows
  }

  const renderTable = (data) => {
    return (
      data.jobCategory.length > 0 ?
        <div>
          <table className="w-100 ba b--light-gray collapse ph2 mv4">
            <thead>
              <tr className="bb bw1 b--black-10">
                <th className="tl pv3 ph2 fw5 f7 ttu">Created on</th>
                <th className="tl w-50 ph2 fw5 f7 ttu">Job Category</th>
                <th className="tl pv3 fw5 f7 ttu">Review Status</th>
                <th className="tl pv3 fw5 f7 ttu">Category Code</th>
              </tr>
            </thead>
            <tbody>
              {renderRows(data)}
            </tbody>
          </table>
        </div>
        :
        <GetStarted 
          openModal={props.openModal}
        />
    )
  }

  const { data } = props
  
  return (
    data.loading ? 
      <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
    :
      renderTable(data)
  )
}

function JobCodesPage(props){
  const [modalIsOpen, setModalIsOpen] = useState(false)

  return (
    <MainWrapper isTestView={props.isTestView}>
      <header className="mb3">
        <h2 className="fw3 dark-gray mt0 mb4">Job Codes</h2>
      </header>
      <JobCodeTable 
        openModal={() => setModalIsOpen(true)}
      />
    </MainWrapper>
  )  
}

const JobCodeTable =  
  graphql(JOB_CODE_QUERY, {
    options: () => ({ 
      variables: { 
        hideReviewed: false 
      }, 
      notifyOnNetworkStatusChange: true,
    })
  })(BaseJobCodeTable)

export default JobCodesPage