import React from 'react';

import { 
  Field
} from 'formik';


export const encodeQuestionCode = (name) => {
  const updated = name.replace('.','-')
  return updated
}
  
export const decodeQuestionCode = (name) => {
  const updated = name.replace('-','.')
  return updated
}

export const formatAnswerJSON = (values) => {
  const answers = {}
  
  Object.entries(values.chubbClassAnswers).forEach(([key, value]) => {
    let decode_key = decodeQuestionCode(key)
    answers[decode_key] = value
  });
  
  return {
    'answers': answers
  }
}

export const formatAnswerJSONv2 = (values) => {
  const all_answers = []
  
  Object.entries(values.chubbClassAnswers).forEach(([key, value]) => {
    let decode_key = decodeQuestionCode(key)
    const answer = {
      questionCodeChubb: decode_key,
      answer: value
    }
    
    all_answers.push(answer)
  });
  
  return {
    'answers': all_answers
  }
}

export const RadioButton = ({name, value}) => (
  <div className="mv2">
    <Field type="radio" name={name} value={value} />
    <label className="f6 fw5 mb2 mt3 ml2" htmlFor={name}>{value}</label>
  </div>
)


export const EXCLUDE_QUESTIONS = ['DT04']

