import React, { useState, useContext } from 'react'

import { useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

import { useValidCode, useValidPassword, useValidUsername } from '../../hooks/useAuthHooks'
import { Code, Password, Username } from '../../components/AuthComponents'
import {AuthWrapperWithLogo} from '../../components/AuthWrapper';

import { AuthContext } from '../../contexts/authContext'

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
})

export default function ForgotPassword() {
  const classes = useStyles()

  const { code, setCode, codeIsValid } = useValidCode('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const { username, setUsername, usernameIsValid } = useValidUsername('')
  const [error, setError] = useState('')
  const [reset, setReset] = useState(false)

  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword('')

  const isValid =
    !codeIsValid ||
    code.length === 0 ||
    !usernameIsValid ||
    username.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0

  const navigate = useNavigate()

  const authContext = useContext(AuthContext)

  const resetPassword = async () => {
    try {
      await authContext.forgotPassword(username, code, password)
      setReset(true)
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }

  const updatePassword = (
    <>
      <Box>
        <Code codeIsValid={codeIsValid} setCode={setCode} />
      </Box>
      <Box>
        <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />
      </Box>
      <Box>
        <Password label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
      </Box>
      <Box>
        <Password label="Confirm Password" passwordIsValid={passwordConfirmIsValid} setPassword={setPasswordConfirm} />
      </Box>

      {/* Error */}
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>

      {/* Buttons */}
      <Box mt={2}>
        <Grid container direction="row" justify="center">
          <button 
            className="pointer button-reset bg-animate bg-brand-pink white mt0 bn w-100 pa3 hover-white br2"
            onClick={resetPassword}>
              Continue
          </button>
          <div className="link pointer tc pv3 f6 fw5" onClick={() => navigate('/signin')}>
            <span className="brand-pink">Return to sign in</span>
          </div>
        </Grid>
      </Box>
    </>
  )

  const passwordReset = (
    <>
      <p className="f6 gray lh-copy pb4">
        Password reset. Sign in to access the dashboard.
      </p>
      <button 
        className="pointer button-reset bg-animate bg-brand-pink white mt0 bn w-100 pa3 hover-white br2"
        onClick={() => navigate('/signin')}>
        Sign in
      </button>
    </>
  )

  return (
    <AuthWrapperWithLogo>
      <Helmet>
        <meta name="description" content="Create a free account to test integrating our API for on-demand workers comp and liability insurance quotes with your digital workforce platform." />
        <title>Reset Password | 1099Policy</title>
      </Helmet>
      <fieldset id="login" className="ba b--transparent ph0 mh0 dark-gray">
        <legend className="f3 pv3 fw7">Reset password</legend>
        {!reset ? updatePassword : passwordReset}
      </fieldset>
    </AuthWrapperWithLogo>
  )
}
