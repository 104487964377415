import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  Legend,
  ResponsiveContainer,
} from "recharts";

//Placeholder data
const data = [
  {
    name: "Jan 2022",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Dec 2022",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const tooltipStyle = {
  border: "none",
  outline: "none",
  visibility: "visible",
};

const currencyOptions = { style: "currency", currency: "USD" };

const formatVal = (formatType, val) => {
  let retVal = val;

  if (formatType == "currency") {
    retVal = val.toLocaleString("en-US", currencyOptions);
  }

  if (formatType == "percent") {
    retVal = `${val}%`;
  }
  return retVal;
};

const CustomTooltip = ({
  active,
  payload,
  label,
  formatType = "number",
  staticNumber = 0,
}) => {
  let staticVal = formatVal(formatType, staticNumber);

  if (active && payload && payload.length) {
    let val = payload[0].value;
    val = formatVal(formatType, val);

    const date = payload[0].payload.x;

    // if this is true, you have access to chart data
    return (
      <div className="nt3">
        <div className="f4 fw3">{val}</div>
        <div className="f7 black-50 pt1">{date}</div>
      </div>
    );
  } else {
    // There is no data here, but we can still display the same style tooltip
    return <div className="f4 fw3 nt3">{staticVal}</div>;
  }
};

// const CustomTooltipCurrency = ({ active, payload, label }) => {
//   return CustomTooltip({ active, payload, label, isCurrency: true });
// };

const CustomXAxisTick = ({ x, y, payload }) => {
  if (payload && payload.value) {
    const textAnchor = x < 50 ? "start" : "end";
    return (
      <text
        fill={"#afb8c1"}
        fontSize={"12px"}
        width={"12px"}
        x={x || ""}
        y={y}
        dy={10}
        textAnchor={textAnchor}
      >
        {payload.value}
      </text>
    );
  }
  return null;
};

export default class SparkChart extends PureComponent {
  render() {
    const chartHeight = this.props.height || "h4";
    const resultSet = this.props.resultSet || data;
    const dataKeyX = this.props.dataKeyX || "name";
    const dataKeyLine = this.props.dataKeyLine || "pv";
    const formatType = this.props.formatType || null;
    const staticNumber = this.props.staticNumber || 0;

    const currentMonth = new Date().toLocaleString("default", {
      month: "short",
    });
    const currentYear = new Date().getFullYear();
    const currentMonthYear = currentMonth + " " + currentYear;

    const startMonthYear = this.props.dateRange?.startMonthYear || "Jan 2022";
    const endMonthYear = this.props.dateRange.endMonthYear || currentMonthYear;

    return (
      <div className={chartHeight}>
        <div className="pl2 pb3 f6 fw5 black-50 nt3">{this.props.label}</div>
        <ResponsiveContainer className="mt3" width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={resultSet}
            margin={{
              top: 5,
              right: 10,
              left: 10,
              bottom: 5,
            }}
          >
            <XAxis
              style={{
                fontSize: "12px",
              }}
              dataKey={dataKeyX}
              stroke="#d0d7de"
              tick={<CustomXAxisTick />}
              ticks={[startMonthYear, endMonthYear]}
              interval={0}
              padding={{ left: 0, right: 0 }}
              tickLine={false}
              verticalAnchor="start"
            />
            <Tooltip
              active={true}
              wrapperStyle={tooltipStyle}
              position={{ x: 10, y: -10 }}
              content={
                <CustomTooltip
                  formatType={formatType}
                  staticNumber={staticNumber}
                />
              }
            />
            <Line
              type="linear"
              dataKey={dataKeyLine}
              stroke="#8884d8"
              activeDot={{ r: 4 }}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
