import React from "react";
import styled from "styled-components";

import Logo from "./Logo";

const AuthContainer = styled.div`
  background-image: none;
  @media (min-width: 768px) {
    background: url(/support.svg) no-repeat right;
  }
`;

const AuthWrapper = ({ children }) => (
  <div className="measure center roboto bg-white ph0-ns">
    <div className="pv3-ns ph4-ns pa3 shadow-30 br2">{children}</div>
  </div>
);

export const AuthWrapperWithLogo = ({ children }) => (
  <section className="w-100 mw8-ns center pa3 ph5-ns mt4">
    <div className="mt5-l mb4 center tc">
      <Logo />
    </div>
    <AuthWrapper>{children}</AuthWrapper>
  </section>
);

export const AuthWrapperRegister = ({ children }) => (
  <AuthContainer>
    <section className="w-100 mw8-ns center pa3 ph5-ns mt4">
      <div className="cf">
        <div className="fl-l w-100 w-third-ns db dn-m roboto">
          <div className="tc tl-l mt3-l mb3 mh3 mh0-ns">
            <Logo />
          </div>
          <div className="mt4 f5 dark-gray dn db-l">
            <p className="lh-copy">Create a free account to start:</p>
            <dl className="lh-copy">
              <dt className="b tr truncate fl">
                <img className="w1" src="/checkmark.svg" alt="checkmark" />
              </dt>
              <dd className="pr1 tl lh-copy mb4">
                Using 1099Policy API to fetch pay-as-you-go insurance rates
              </dd>
              <dt className="b tr truncate fl">
                <img className="w1" src="/checkmark.svg" alt="checkmark" />
              </dt>
              <dd className="pr1 tl lh-copy mb4">
                Quickly testing the quote and bind API in a test environment
                using sample data
              </dd>
              <dt className="b tr truncate fl">
                <img className="w1" src="/checkmark.svg" alt="checkmark" />
              </dt>
              <dd className="pr1 tl lh-copy mb3">
                Integrating 1099Policy API to grow your pool of eligible
                contractors
              </dd>
            </dl>
          </div>
        </div>
        <div className="fl-l center-m w-100 w-two-thirds-l">
          <AuthWrapper>{children}</AuthWrapper>
        </div>
      </div>
    </section>
  </AuthContainer>
);

export default AuthWrapper;
