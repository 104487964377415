import React, {useState, useEffect} from 'react';
import ReactLoading from 'react-loading';
import { CopyToClipboard } from "react-copy-to-clipboard";

import Modal from 'react-modal';

import { gql, useQuery, useMutation } from "@apollo/client";
import { 
  Form,
  Formik, 
  Field,
  ErrorMessage
 } from 'formik';

import * as Yup from 'yup';
import moment from 'moment';
import classNames from 'classnames'

import PageHeader from '../components/PageHeader';
import MainWrapper from '../components/MainWrapper';
import PopoverMenu from '../components/PopoverMenu';

Modal.setAppElement('#root');

const modalStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    boxShadow             : '2px 2px 8px 0px rgba( 0, 0, 0, 0.2 )',
  },
  overlay : {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  } 
};

const WEBHOOKS_QUERY = gql`
  query WebhookEndpoints {
    webhookEndpoints{
      id
      targetUrl
      createdAt
      updatedAt
      description
      status
      secret
      events
    }
  }
`

const CREATE_OR_UPDATE_WEBHOOK_ENDPOINT = gql`
  mutation CreateOrUpdateWebhookEndpoint(
    $url: String!,
    $description: String!,
    $events: [EventInput!],
    $uid: String,
  ){
    createOrUpdateWebhookEndpoint(
        url: $url, 
        description: $description, 
        events: $events,
        uid: $uid
      ){
      ok
      endpoint {
        targetUrl
        updatedAt
        createdAt
      }
	}
}`;

const DELETE_WEBHOOK_ENDPOINT = gql`
  mutation DeleteWebhookEndpoint(
    $uid: String,
  ){
    deleteWebhookEndpoint(
      uid: $uid
    ){
      ok
    }
}`;

function CreateOrUpdateEndpointModal(props){
  const [webhookFormDefaults, setWebhookFormDefaults] = useState({
    targetUrl: '',
    description: ''
  })
  const [createOrUpdateWebhookEndpoint, {loading, error, reset}] = useMutation(CREATE_OR_UPDATE_WEBHOOK_ENDPOINT, {
    onCompleted: ()=> {
      props.closeModal()
      reset()
    },

    refetchQueries: ['WebhookEndpoints']
  })

  useEffect(() => {
    setWebhookFormDefaults({
      targetUrl: '',
      description: ''
    })

    if (props.endpointId){
      const webhook = props.client.readFragment({
        fragment: gql`
          fragment WebhookEndpoint on WebhookEndpoints {
            id
            targetUrl
            description
          }
        `,
        fragmentName: 'WebhookEndpoint',
        id: `WebhookEndpoint:${props.endpointId}` 
      })

      setWebhookFormDefaults({
        targetUrl: webhook.targetUrl,
        description: webhook.description
      })
    }
  }, [props.endpointId])

  const renderLoading = () => (
    <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
  )

  const renderError = (errorMsg) => (
    <div className="br2 f6 flex items-center justify-center pa3 bg-lightest-blue navy">
      <span className="lh-title ml3"> { errorMsg ?? "There was an error saving the webhook endpoint. Please try again." }</span>
    </div>
  )
  const renderForm = () => (
    <div className="roboto">
      <PageHeader title={'Create Webhook Endpoint URL'} />
      <Formik
        initialValues={{
          uid: props.endpointId,
          url: webhookFormDefaults.targetUrl, 
          description: webhookFormDefaults.description,
          events: [{eventType: 'ALL'}] 
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          createOrUpdateWebhookEndpoint({ variables: {...values}})
        }}
        validationSchema={Yup.object().shape({
          url: Yup.string().required('Required'),
        })}
        enableReinitialize={true}
      >
        {({ isSubmitting, values, handleChange }) => (
          <Form className="measure">
            {error ? renderError(error.message) : null}
            <div>
            <Field 
              type="hidden" 
              name="uid" 
              className="input-reset ba b--black-20 pa2 mb2 db w-100 br2"
            />
            <label className="f6 fw5 db mb2 mt3" htmlFor="url">Endpoint URL</label>
              <Field 
                type="text" 
                name="url" 
                className="input-reset ba b--black-20 pa2 mb2 db w-100 br2"
                placeholder="https://"
                value={values.url}
                onChange={handleChange}
              />
              <ErrorMessage className="f6 red" name="url" component="div" />

              <label className="f6 fw5 db mb2 mt3" htmlFor="description">Description</label>
              <Field 
                name="description" 
                component="textarea" 
                className="input-reset ba b--black-20 pa2 mb2 db w-100 br2"
                rows="5" 
                cols="70"
                placeholder="A description of how you use the webhook endpoint."
              />
              <ErrorMessage className="f6 red" name="description" component="div"/>

              <div className="mv4">
                <button 
                  className="f6 link dim br2 ba ph3 pv2 mb2 ml2 dib white bg-blue bn pointer fr"
                  type="submit" 
                  disabled={isSubmitting}>
                  {props.endpointId ? 'Update' : 'Save'}
                </button>
                <button 
                  onClick={props.closeModal}
                  className="f6 link dim br2 ba ph3 pv2 mb2 dib black pointer fr"
                  type="submit" 
                  disabled={isSubmitting}>
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={modalStyles}
      contentLabel="Create Webhook Endpoint URL"
    >
      {
        loading ? renderLoading() : renderForm()
      }
    </Modal>
  ) 
}

function DeleteWebhookEndpointModal(props){
  const [deleteWebhookEndpoint, {loading, reset}] = useMutation(DELETE_WEBHOOK_ENDPOINT, {
    onCompleted: ()=> {
      props.closeModal()
      reset()
    },
    refetchQueries: ['WebhookEndpoints']
  })
  return (
      <Modal
        isOpen={props.modalIsOpen}
        onRequestClose={props.closeModal}
        style={modalStyles}
        contentLabel="Delete Webhook Endpoint URL"
      >
        <div className="roboto">
        <PageHeader title={'Delete Webhook Endpoint URL'} />
          <div className="measure f5 lh-copy">
          Are you sure you want to delete this webhook? You'll no longer 
          receive webhook events to this URL.
          </div>
          <div className="mv4">
            <button 
              onClick={() => {
                deleteWebhookEndpoint({variables: {uid: props.endpointId}})
              }}
              className="f6 link dim ba b--red br2 ph3 pv2 mb2 ml2 dib white bg-red pointer fr"
              type="submit" 
              disabled={loading}>
              Delete
            </button>
            <button 
              onClick={props.closeModal}
              className="f6 link dim br2 ba ph3 pv2 mb2 dib black pointer fr"
              type="submit" 
              disabled={loading}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
  )
}

function GetStarted(props) {
  return (
    <div>
      <p className="lh-copy f6 fw3 black-70">
      Set up your webhook endpoint to receive live events from 1099Policy or learn more from
      our <a href="https://docs.1099policy.com" target="_blank" rel="noopener noreferrer">docs</a> under the sections titled "Events".
      </p>
      <p className="mt4">
      <button 
        type="button" 
        onClick={() => props.openModal()}
        className="f6 link dim br2 ph3 pv2 mb2 dib white bg-blue bn pointer">
        Add an endpoint URL
      </button>
      </p>
    </div>
  )
}

function EndpointsTable(props){
  const [isCopied, setIsCopied] = useState(false);

  const { loading, data } = useQuery(WEBHOOKS_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  })

  function mapEvent(event){ 
    let res;

    switch (event){
      case "*":
        res = 'All events';
        break;
      default:
        res = event
    }
    return res
  }
  
  const renderEvent = (events) => {
    const rows = events.map((event) => 
      <span key={event} className={"br4 ph2 pv1 dib ttc ba fw4 dark-gray bg-light-gray"}>
        {mapEvent(event)}
      </span>
    )
    
    return rows
  }
  
  const renderStatus = (status) => {
    return (
      <span className={classNames("br2 ph2 pv2 dib ttc ba", 
        {"dark-green bg-washed-green": status === "active"}, 
        {"dark-red bg-washed-red": status !== "active"})}>
        {status}
      </span>
    )
  }

  const menuContent = (secret, webhookEndpointId) => (
      <ul className="f7 list pv2 pl0 measure center">
        <CopyToClipboard
          text={secret}
          onCopy={() => {
            setIsCopied(true);
          }}
        >
          <li
            data-open={true}
            className="lh-copy pv1 fw5 link dim pointer"
          >
            {isCopied ? "Copied! ...... " : "Copy secret"}
          </li>
        </CopyToClipboard>
        <li
          className="lh-copy pv1 fw5 link dim pointer"
          onClick={(e) => {
            e.stopPropagation();
            props.openCreateUpdateModal(webhookEndpointId);
          }}
        >
          Update
        </li>
        <li
          className="lh-copy pv1 fw5 link dim pointer"
          onClick={(e) => {
            e.stopPropagation();
            props.openDeleteModal(webhookEndpointId);
          }}
        >
          Delete
        </li>
      </ul>
  )

  const onClose = () => {
    setIsCopied(false);
  }

  const renderRows = (data) => {
    const rows = data.webhookEndpoints.map((row) => 
      <tr className="bb b--light-gray" key={row.id} id={row.id}>
        <td style={{wordBreak: "break-all"}} className="tl ph2 f7 w-40">{row.targetUrl}</td>
        <td className="tl ph2 pv3 f7 w-10-l">{moment(row.createdAt).format('MMM D, YYYY')}</td>
        <td className="tl ph2 pv3 f7 w-10-l">{moment(row.updatedAt).format('MMM D, YYYY')}</td>
        <td className="tl ph2 pv3 f7 w-20-l">{renderEvent(row.events)}</td>
        <td className="pv3 f7 tc">{renderStatus(row.status)}</td>
        <td className="pv3 ph3 f7 tc w-10-l">
          <PopoverMenu 
            onClose={onClose}
            menuContent={() => menuContent(row.secret, row.id)}
          >
            <img className="w1 pointer" src="/ellipsis.svg" alt="Edit Endpoint Menu" />
          </PopoverMenu>
        </td>
      </tr>
    )

    return rows
  }

  const renderButton = () => (
    <button 
      type="button" 
      onClick={() => props.openCreateUpdateModal()}
      className="fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 mv3 f7 lh-title bg-animate hover-bg-light-gray border-box">
      Create endpoint
    </button>
  )

  const loadingRow = () => (
    <tr><td colSpan={5}><ReactLoading type={'spin'} color={'#cccccc'} className="mv4 center" /></td></tr> 
  )

  function TableShell(props){
    return(
      <div>
        {renderButton()}
        <table className="w-100 ba b--light-gray collapse ph2 mt4 mb3">
          <thead>
            <tr className="bb b--light-gray bg-black-05">
              <th className="tl pv3 ph2 fw5 f7 ttu w-40">Endpoint URL</th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-10-l">Created At</th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-10-l">Updated At</th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-30-l">Endpoint Events</th>
              <th className="tl pv3 ph2 fw5 f7 ttu tc">Status</th>
              <th className="tl pv3 ph2 fw5 f7 ttu tc"></th>
            </tr>
          </thead>
          <tbody>
            {props.children}
          </tbody>
        </table>
      </div>
    )
  }

  const renderLoadingTable = () => {
    return(
      <TableShell data={data}>
        {loadingRow()}
      </TableShell>
    )
  }

  const renderTable = (props) => {
    return (
      data?.webhookEndpoints && data.webhookEndpoints?.length > 0 ?
      <TableShell data={data}>
        {renderRows(data)}
      </TableShell>
      :
      <GetStarted 
        openModal={props.openCreateUpdateModal}
      />
    )  
  }

  return (
    <>{ loading ?  renderLoadingTable() : renderTable(props)}</>
  )
}

function WebhooksPage(props){
  const [createUpdateModalValues, setCreateUpdateModalValues] = useState({
    isOpen: false,
    endpointId: ''
  })
  const [deleteModalValues, setDeleteModalValues] = useState({
    isOpen: false,
    endpointId: ''
  })
  
  return (
    <MainWrapper isTestView={props.isTestView}>
      <header className="mb3">
        <h2 className="fw3 dark-gray mt0 mb4">Webhooks</h2>
      </header>
      <EndpointsTable 
        openCreateUpdateModal={(endpointId) => {
          setCreateUpdateModalValues({
            endpointId: endpointId,
            isOpen: true
          })
        }}
        openDeleteModal={(endpointId) => {
          setDeleteModalValues({
            endpointId: endpointId,
            isOpen: true
          })
        }}
      />
      <CreateOrUpdateEndpointModal 
        openModal={() => {
          setCreateUpdateModalValues({
            endpointId: '',
            isOpen: true
          })
        }}
        closeModal={() => {
          setCreateUpdateModalValues({
            endpointId: '',
            isOpen: false
          })
        }}
        modalIsOpen={createUpdateModalValues.isOpen}
        endpointId={createUpdateModalValues.endpointId}
        token={props.token}
        client={props.client}
      />
      <DeleteWebhookEndpointModal
        closeModal={() => {
          setDeleteModalValues({
            endpointId: '',
            isOpen: false
          })
        }}
        modalIsOpen={deleteModalValues.isOpen}
        endpointId={deleteModalValues.endpointId}
        token={props.token}
        client={props.client}
      />
    </MainWrapper>
  )
}


export default WebhooksPage