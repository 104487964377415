import React, { useState, useContext } from 'react'

import ReactLoading from 'react-loading';

import { useNavigate, useSearchParams } from 'react-router-dom'
import { Helmet } from "react-helmet";

import { useValidPassword, useValidEmail } from '../../hooks/useAuthHooks'
import { Password, Email } from '../../components/AuthComponents'
import {AuthWrapperWithLogo} from '../../components/AuthWrapper';

import { AuthContext } from '../../contexts/authContext'

const SignIn: React.FunctionComponent<{}> = () => {
  const defaultEmail = localStorage.getItem("Ten99PolicyDefaultEmail")

  const { email, setEmail, emailIsValid } = useValidEmail(defaultEmail || '')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')

  const isValid = !emailIsValid || email.length === 0 || !passwordIsValid || password.length === 0

  const navigate = useNavigate()

  const authContext = useContext(AuthContext)

  const signInClicked = async () => {
    setSubmitting(true)
    try {
      await authContext.signInWithEmail(email, password)
      setSubmitting(false)
      navigate('/overview')
    } catch (err: any) {
      if (err.code === 'UserNotConfirmedException') {
        navigate('/complete-registration')
      } else {
        setError(err.message)
      }
      setSubmitting(false)
    }
  }

  const passwordResetClicked = async () => {
    navigate('/reset')
  }

  const ForgotPasswordLink = () => {
    return (
      <div className="link pointer pt2" onClick={passwordResetClicked}>
        <span className="no-underline fw4 brand-pink f6 pv3">Forgot your password?</span>
      </div>
    )
  }

  const RegisterLink = () => {
    return (
      <div className="link pointer" onClick={() => navigate('/signup')}>
      <p className="black-40 pt3 f6">Don't have an account? <span className="brand-pink">Register a new account</span></p>
      </div>
    )
  }

  const SignInButton = (props) => {
    if (props.submitting){
      return (
        <button className="button-reset bg-animate white mt0 bn ttu w-100 pa3 hover-white br2">
          <ReactLoading type={'spin'} color={'#cccccc'} height={20} width={20} className="center" />
        </button>
        )
    } else {
      return(
        <input className="pointer button-reset bg-animate bg-brand-pink white mt0 bn w-100 pa3 hover-white br2" 
          value="Continue" 
          disabled={props.isValid}
          onClick={props.signInClicked}
          type="submit" 
        />
      )
    }
  }
  
  return (
    <AuthWrapperWithLogo>
      <Helmet>
        <meta name="description" content="Create a free account to test integrating our API for on-demand workers comp and liability insurance quotes with your digital workforce platform." />
        <title>Sign in | 1099Policy</title>
      </Helmet>
      {/* Sign In Form */}
      <fieldset id="login" className="ba b--transparent ph0 mh0 dark-gray">
        <legend className="f3 pv4 fw6">Sign in to your account</legend>
        <Email email={email} emailIsValid={emailIsValid} setEmail={setEmail} />{' '}
        {/* <Username usernameIsValid={usernameIsValid} setUsername={setUsername} /> */}

        <Password
          label="Password"
          passwordIsValid={passwordIsValid}
          setPassword={setPassword}
          submitFunction={signInClicked}
        />

        {/* Error */}
        {error ? (<div className="brand-pink pb3 f6">{error}</div>) : null}
        
      </fieldset>
      {/* Buttons */}
      
        <SignInButton disabled={isValid} signInClicked={signInClicked} submitting={submitting} />

      <div className="mt2">
        <ForgotPasswordLink />
        <RegisterLink />
        
      </div>
    </AuthWrapperWithLogo>
  )
}

export default SignIn
