import React from 'react';
import classNames from 'classnames'

const MainWrapper = ({children, isTestView}) => (
  <div className={classNames(
    "bg-white shadow-4 pa4 br2 relative",
    {"bt b--light-blue bw2": isTestView}
  )} >
    { isTestView ? 
      <span 
        className="w4 tc bg-light-blue white pa1 absolute top-0 f7 left-0 right-0 br1 br--bottom"
        style={{margin: 'auto'}}
      >
      Test Data View
      </span> : null 
    }
    {children}
  </div>
)

export default MainWrapper;