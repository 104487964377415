import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import Modal from 'react-modal';
import { environments } from "../constants/environments";

import {
  Formik,
  Form
} from 'formik';


import moment from 'moment';
import classNames from 'classnames'

import { graphql } from '@apollo/client/react/hoc';

import { gql, useMutation } from "@apollo/client";

import ShowMore from 'react-show-more';
import Autocomplete from 'react-autocomplete';

import {
  EXCLUDE_QUESTIONS,
  RadioButton,
  encodeQuestionCode,
  formatAnswerJSONv2
} from '../components/AdminQuestionUtils';

import PageHeader from '../components/PageHeader';
import MainWrapper from '../components/MainWrapper';
import withAuthorization from '../utils/withAuthorization';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    maxHeight: '600px',
    maxWidth: '560px',
    transform: 'translate(-50%, -50%)',
    boxShadow: '2px 2px 8px 0px rgba( 0, 0, 0, 0.2 )',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  }
};

const REVIEW_QUERY = gql`
  query JobCategory($hideReviewed: Boolean!) {
    jobCategory(hideReviewed: $hideReviewed) {
      id
      code
      reviewStatus
      created
      jobSamples {
        edges {
          node {
            id
            name
            description
          }
        }
      }
    }
  }
`;

const UPDATE_CATEGORY = gql`
  mutation UpdateJobCategory(
    $jobCategory: [JobCategoryInput!]
  ){
    updateJobCategory(
      jobCategory: $jobCategory
    ){
      jobCategories {
        id
      }
      ok
    }
  }
`;

function matchJobToTerm(job, value) {
  return (
    job.simplified.toLowerCase().indexOf(value.toLowerCase()) !== -1
  )
}

export function sortJobs(a, b, value) {
  const aLower = a.simplified.toLowerCase()
  const bLower = b.simplified.toLowerCase()
  const valueLower = value.toLowerCase()
  const queryPosA = aLower.indexOf(valueLower)
  const queryPosB = bLower.indexOf(valueLower)
  if (queryPosA !== queryPosB) {
    return queryPosA - queryPosB
  }
  return aLower < bLower ? -1 : 1
}

function AnswerDefaultsModal(props) {
  const renderQuestions = (modalQuestions) => {
    const category_id = modalQuestions.category_id
    const questionForm = modalQuestions.questions.map((question) =>
      (EXCLUDE_QUESTIONS.indexOf(question.rule_code) < 0) ?
        (
          <div key={question.rule_code}>
            <label className="f6 fw5 db mb2 mt3 lh-copy" htmlFor="wage">{question.rule_description}</label>
            <RadioButton name={`chubbClassAnswers.${encodeQuestionCode(question.rule_code)}`} value={'Yes'} />
            <RadioButton name={`chubbClassAnswers.${encodeQuestionCode(question.rule_code)}`} value={'No'} />
          </div>
        ) :
        (null)
    )

    return (
      <div>
        <Formik
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false)
            props.updateDefaultAnswers(category_id, values)
            props.closeModal()
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              {questionForm}
              <div className="mt4 fr">
                <button className="f6 link dim br2 ba ph3 pv2 mb2 dib black pointer" onClick={props.closeModal}>Cancel</button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="f6 link dim br2 ph3 pv2 mb2 dib white bg-blue bn pointer ml2">
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  }

  const renderPrompt = () => (
    <p className="lh-copy dark-gray fw4">
      Assign a class code to the job sample to set default answers
      to the job class insurance questions.
      <span role="img" aria-labelledby="pointer">👆</span>
    </p>
  )


  const {
    modalQuestions
  } = props

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={modalStyles}
      contentLabel="Set Default Answers"
    >
      <div className="roboto">
        <PageHeader title={'Set Default Answers'} />
        {
          Object.entries(modalQuestions).length > 0 && modalQuestions.questions.length > 0 ?
            renderQuestions(modalQuestions)
            :
            renderPrompt()
        }
      </div>
    </Modal>
  )
}

function BaseReviewJobsAdmin(props) {
  const [checkedCount, setCheckedCount] = useState(0)
  const [isSubmittingReview, setIsSubmittingReview] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalQuestions, setModalQuestions] = useState({})
  const [defaultAnswers, setDefaultAnswers] = useState({})
  const [value, setValue] = useState('')
  const [item, setItem] = useState(null)
  const [codes, setCodes] = useState(null)
  const [codeAdded, setCodeAdded] = useState(false)
  const [codedSamples, setCodedSamples] = useState([])
  const [selectedSamples, setSelectedSamples] = useState([])

  const [state, setState] = useState({
    error: null,
    isLoading: false
  })

  const [updateCategory, { loading, error, reset }] = useMutation(
    UPDATE_CATEGORY,
    {
      onCompleted: () => {
        setIsSubmittingReview(false);
        setValue("");
        setItem(null);
        reset();
      },
      refetchQueries: ["JobCategory"],
    },
  );

  useEffect(() => {
    const token = props.token
    const BASE_URL = process.env.REACT_APP_TEN99_BASE_URL
    const API_QUESTIONS = '/api/v1/classcodes'

    let resStatus = 0

    fetch(`${BASE_URL}${API_QUESTIONS}`, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Ten99Policy-Environment": localStorage.getItem('Ten99PolicyEnvironment') || environments.PRODUCTION,
        "Ten99Policy-Organization": localStorage.getItem(
          "Ten99PolicyOrganization"
        )  || "",
      }
    })
      .then(response => {
        resStatus = response.status
        return response.json()
      })
      .then(data => {
        switch (resStatus) {
          case 401:
            setState({ error: data.msg, isLoading: false })
            break
          default:
            setCodes(data)
            setState({ isLoading: false })
            break
        }
      })
      .catch(error => setState({ error, isLoading: false }))
  }, [])

  function openAnswerDefaultsModal(event, category_id, questions) {
    if (event.target.tagName !== 'INPUT' && event.target.tagName !== 'A') {
      const modalQuestions = {
        category_id: category_id,
        questions: questions
      }
      setModalQuestions(modalQuestions)
      openModal()
    }
  }

  function openModal() {
    setModalIsOpen(true)
  }

  function closeModal() {
    setModalIsOpen(false)
  }

  const incrementCount = () => {
    setCheckedCount(checkedCount + 1)
  }

  const decrementCount = () => {
    setCheckedCount(checkedCount - 1)
  }

  const updateDefaultAnswers = (category_id, answers) => {
    const formatted_answers = formatAnswerJSONv2(answers)

    const da = defaultAnswers
    da[category_id] = formatted_answers
    setDefaultAnswers(da)
  }

  const updateSelectedArray = (e) => {
    // current array of options
    const options = selectedSamples
    const category_id = e.target.getAttribute('data-category-id')

    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      options.push({
        'job_sample_id': e.target.value,
        'category_id': category_id
      })
      setSelectedSamples(options)
    } else {
      // or remove the value from the unchecked checkbox from the array
      const filteredOptions = options.filter((option) =>
        option.job_sample_id !== e.target.value
      )
      setSelectedSamples(filteredOptions)
    }
  }

  const handleChange = (event) => {
    event.target.checked ?
      incrementCount() :
      decrementCount()

    updateSelectedArray(event)
  }

  const addCodeToSelected = (code) => {
    function merge(a, b, prop) {
      var reduced = a.filter(aitem => !b.find(bitem => aitem[prop] === bitem[prop]))
      return reduced.concat(b);
    }

    const samples = selectedSamples
    const newCodedSamples = samples.map((sample) => {
      return {
        category_id: sample.category_id,
        job_sample_id: sample.job_sample_id,
        class_id: code.class_id,
        gl_class_codes: code.gl_class_codes,
        wc_class_codes: code.wc_class_codes,
        class_name: code.simplified,
        questions: code.questions
      }
    })
    setCodedSamples(merge(codedSamples, newCodedSamples, 'job_sample_id'))
  }

  const addCode = (event) => {
    const code = item
    addCodeToSelected(code)
    setCodeAdded(true)
  }

  const onMarkReviewed = (event) => {
    event.preventDefault()
    setIsSubmittingReview(true)

    const data = codedSamples.map((sample) => {
      const resp = {
        categoryId: sample.category_id,
        classId: sample.class_id,
        className: sample.class_name,
        reviewStatus: 'REVIEWED',
        jobSamples: [{
          jobSampleId: sample.job_sample_id,
          status: 'REVIEWED'
        }]
      }

      const answers = defaultAnswers[sample.category_id] ?
        defaultAnswers[sample.category_id].answers :
        []

      resp['defaultAnswers'] = answers

      return resp
    })
    updateCategory({ variables: { 'jobCategory': data } })
  }

  const getJobSamples = (row) => {
    const samples = row.jobSamples.edges.map((sample) => {
      const code = codedSamples.filter(item => item.job_sample_id === sample.node.id)
      const da = defaultAnswers[row.id]
      
      return (
        <tr
          onClick={(event) => {
            const questions = code.length > 0 ? code[0].questions : []
            openAnswerDefaultsModal(event, row.id, questions)
          }}
          className="bb b--light-gray hover-bg-washed-blue pointer w3" key={sample.node.id}>
          <td className="tc">
            <input type="checkbox" value={sample.node.id} data-category-id={row.id} onChange={handleChange} />
          </td>
          <td className="tl ph2 pv3 f7">{sample.node.name}</td>
          <td className="tl ph2 pv3 f7 w-30 lh-copy">
            <ShowMore anchorClass="dark-gray">
              {sample.node.description}
            </ShowMore>
          </td>
          <td className="tl ph2 pv3 f7">
            {
              code.length > 0 ?
              `${code[0].class_name}` :
              null
            }
          </td>
          <td className="tl ph2 pv3 f7">
            {
              code.length > 0 ?
                `${code[0].gl_class_codes}` :
                null
            }
          </td>
          <td className="tl ph2 pv3 f7">
            {
              code.length > 0 ?
                `${code[0].wc_class_codes}` :
                null
            }
          </td>
          <td className="tl ph2 pv3 f7">
            {
              da && da?.answers?.length > 0 ?
                <span className="br-pill ph3 pv1 dib bg-lightest-blue w3 tc">
                  <span className="dib navy v-top pt1 pr1">Added</span>
                  <img className="w1" src="/checkmark.svg" alt="checkmark" />
                </span>
                :
                null
            }
          </td>
          <td className="tl ph2 pv3 f7">
            {moment(row.created).format('MMM D, YYYY')}
          </td>
        </tr>
      )
    })

    return samples
  }

  const renderRows = (jobCategory) => {
    const rows = jobCategory.map((row) =>
      getJobSamples(row)
    )

    return rows
  }

  const renderHeader = () => {
    return (
      <div>
        <div className="fl relative">
          {checkedCount > 0 ?
            <div className="fw6 f6 pt4">{checkedCount} Selected</div> :
            null
          }
        </div>
        <div className="fr relative">
          {!codes ?
            <input className="input-reset ba b--black-20 pa2 mb2 dib br2" type="text" />
            :
            <Autocomplete
              wrapperProps={{ style: { display: 'inline-block', width: '270px' } }}
              inputProps={{
                name: "jobClassCode",
                placeholder: "e.g., Technician",
                className: "input-reset f6 ba b--black-20 pa2 w-100 mv3 db br2",
                type: "text"
              }}
              menuStyle={{
                borderRadius: '3px',
                boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                background: 'rgba(255, 255, 255, 0.9)',
                padding: '2px 0',
                fontSize: '40%',
                position: 'absolute',
                top: '60px',
                left: '0',
                overflow: 'auto',
                width: '200px',
                maxHeight: '200px',
              }}
              getItemValue={(item) => item.simplified}
              items={codes}
              renderItem={(item, isHighlighted) =>
                <div
                key={item.class_id}  
                className="pa2 f5"
                  style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                  {item.simplified}
                </div>
              }
              value={value}
              onChange={(e) => {
                setValue(e.target.value)
              }}
              sortItems={sortJobs}
              shouldItemRender={matchJobToTerm}
              onSelect={(val, item) => {
                setValue(val)
                setItem(item)
              }}
            />
          }
          <button
            type="button"
            onClick={addCode}
            disabled={checkedCount > 0 ? null : "disabled"}
            className={classNames(
              "f6 br2 ba b--black-20 bg-white pa2 ml1 border-box",
              { "cursor-not-allowed o-60": checkedCount <= 0 }
            )
            }>
            Add Class Code
          </button>
        </div>
      </div>
    )
  }

  const renderTable = (jobCategory) => {
    return (
      jobCategory?.length > 0 ?
        <div>
          {renderHeader()}
          <table className="w-100 ba b--light-gray collapse ph2 mv4">
            <thead>
              <tr className="bb b--light-gray bg-black-05">
                <th className="w3">&nbsp;</th>
                <th className="tl pv3 ph2 fw5 f7 ttu">Job Name</th>
                <th className="tl pv3 ph2 fw5 f7 ttu w-30">Job Description</th>
                <th className="tl pv3 ph2 fw5 f7 ttu">Category Code Name</th>
                <th className="tl pv3 ph2 fw5 f7 ttu">GL Code</th>
                <th className="tl pv3 ph2 fw5 f7 ttu">WC Code</th>
                <th className="tl pv3 ph2 fw5 f7 ttu">Default Answers</th>
                <th className="tl pv3 fw5 f7 ttu">Date</th>
              </tr>
            </thead>
            <tbody>
              {renderRows(jobCategory)}
            </tbody>
          </table>
          <div className="db tr">
            <button
              type="button"
              onClick={(event) => onMarkReviewed(event)}
              disabled={checkedCount > 0 ? null : "disabled"}
              className={classNames(
                "dib f6 br2 ba b--dark-blue bg-dark-blue white pa2 ml1 mv1 border-box",
                {
                  "cursor-not-allowed o-60": checkedCount <= 0 || codeAdded === false || isSubmittingReview,
                  "pointer": checkedCount > 0 && codeAdded === true && !isSubmittingReview
                }
              )
              }>
              Mark Reviewed
              {isSubmittingReview ?
                <ReactLoading type={'spin'} color={'#cccccc'} className="ml1 dib" height={12} width={12} />
                :
                null
              }
            </button>
          </div>
        </div>
        :
        <p className="gray">
          No more sample jobs to review.
          <span role="img" aria-labelledby="champagne">🍾</span>
        </p>
    )
  }


  const {
    isTestView,
    isAdmin,
    data
  } = props

  if(!isAdmin){
    return(
      <MainWrapper isTestView={isTestView}>
        <p>Permission denied</p>
      </MainWrapper>
    )
  }

  return (
    <MainWrapper isTestView={isTestView}>
      <header className="mb3">
        <h2 className="fw3 dark-gray mt0 mb4">Review Jobs</h2>
      </header>
      {
        data.loading ?
          <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
          :
          renderTable(data.jobCategory)
      }
      <AnswerDefaultsModal
        openModal={openModal}
        closeModal={closeModal}
        updateDefaultAnswers={updateDefaultAnswers}
        modalIsOpen={modalIsOpen}
        modalQuestions={modalQuestions}
      />
    </MainWrapper>
  )

}

export default withAuthorization(
  graphql(REVIEW_QUERY, {
    options: () => ({ variables: { hideReviewed: true } })
  })(BaseReviewJobsAdmin),
  ['admin']
)
  