import React, { useContext, createContext, useState, useMemo, useEffect, useCallback, useRef } from 'react';
import Autocomplete from 'react-autocomplete';
import ReactLoading from 'react-loading';
import Modal from 'react-modal';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import DataGrid, { Row } from 'react-data-grid';

import dateFnsFormat from 'date-fns/format';

import { Formik, Form, Field, getIn } from 'formik';
import * as Yup from 'yup';

import { gql, useQuery, useMutation } from "@apollo/client";

import { currencyStringToCents, strToCurrencyFormatter } from '../../utils/currency'

import InvoicePdfModal from './InvoicePdfModal';

import { formatDate, parseDate } from '../../utils/date'
import {base64Decode} from '../../utils/base64decode'
import withAuthorization from '../../utils/withAuthorization';

import OutsideAlerter from "../../components/OutsideAlerter";
import PageHeader from '../../components/PageHeader';
import MainWrapper from '../../components/MainWrapper';
import AdminDisclaimer from '../../components/AdminDisclaimer';

import { environments } from '../../constants/environments';

import 'react-day-picker/lib/style.css';
import 'react-data-grid/lib/styles.css';

import { AuthContext } from '../../contexts/authContext';

const FORMAT = 'MM/dd/yyyy';
const today = new Date()
let lastMonth = new Date()

lastMonth.setDate(lastMonth.getDate() - 30)

const MARK_PAID_IN_FULL = gql`
  mutation MarkPaidInFull(
    $paidInFull: [PaidInFullInput!]
  ){
    markPaidInFull(
      paidInFull: $paidInFull
    ){
      ok
      message
    }
}`

const ADD_PURCHASE_ORDER_NUMBER = gql`
  mutation AddPurchaseOrderNumber(
    $purchaseOrder: [PurchaseOrderInput!]
  ){
    addPurchaseOrderNumber(
      purchaseOrder: $purchaseOrder
    ){
      ok
      message
      purchaseOrder {
        jobId
        purchaseOrderId
      }
    }
}`

const ALL_ORGANIZATION_QUERY = gql`
  query AllOrganizations {
    organizations {
      id
      active
      name
    }
  }
`;

const REPORTING_QUERY = gql`
  query FinancialReporting(
    $startDate: String, 
    $endDate: String,
    $unpaidOnly: Boolean,
    $allOrgs: Boolean,
    $organization: String,
  ){
    financialReport(
      startDate: $startDate,
      endDate: $endDate,
      unpaidOnly: $unpaidOnly,
      allOrgs: $allOrgs,
      organization: $organization,
    ){
      created
      firstName
      lastName
      workState
      state
      methodOfPayment
      paidInFull
      agencyPayInvoiceId
      agencyPayInvoiceUrl
      agencyPayInvoicePaymentDate
      purchaseOrderId
      purchaseOrderContactName
      purchaseOrderContactEmail
      remuneration
      glCode
      glNetRate
      glTaxOwed
      glStampingFeeOwed
      glPremiumOnly
      glPgFee
      glPremiumTotalCost
      wcCode
      wcNetRate
      wcPremium
      contractorId
      jobId
      organizationName
      organizationAddressLine1
      organizationAddressLine2
      organizationAddressLine3
      organizationLocality
      organizationRegion
      organizationPostalcode
      campaign
      jobClassName
      saasFeeAmount
      saasFeePaidInFull
      policyIsActive
      policyCancelledDate
    }
  }
`

let contentStyle = {
  width                 : '40%',
  top                   : '50%',
  left                  : '50%',
  right                 : 'auto',
  bottom                : 'auto',
  marginRight           : '-50%',
  transform             : 'translate(-50%, -50%)',
  boxShadow             : '2px 2px 8px 0px rgba( 0, 0, 0, 0.2 )'
}

let paidModalStyles = {
  content : {...contentStyle, height: '75vh'},
}

let modalStyles = {
  content : contentStyle,
  overlay : {
    backgroundColor : 'rgba(0, 0, 0, 0.5)'
  } 
};

function prepValues(values){
  let organization = '';
  
  if (values.organization){
    organization = base64Decode(values.organization.id)
  }
  
  return ({...values, organization: organization})
}

const PaidInFullForm = (props) => {
  const {
    paidSaveErrorMessage,
    selectedContractors,
    errors,
    errorMessage,
    isSubmitting,
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = props;

  const renderError = () => (
    <div className="br2 f6 flex items-center justify-center pa2 bg-washed-red red mb3">
      <span className="lh-title ml3">{paidSaveErrorMessage || 'There was an error marking one or more paid in full. Please try again.'}</span>
    </div>
  )

  const renderContractorList = () => {
    function getFullName(row){
      return `${row.firstName} ${row.lastName}`
    }
    const contractorList = Object.keys(selectedContractors).map(function(k){return getFullName(selectedContractors[k])}).join(", ")
    return contractorList
  }

  function getStyles(errors, fieldName) {
    if (getIn(errors, fieldName)) {
      return {
        border: '1px solid red',
        backgroundColor: '#ffdfdf'
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="pb2">
        {(paidSaveErrorMessage || errorMessage) ? renderError() : null}
        <h2 className="f5 fw5 lh-copy ph0">Confirm that you want to mark the following contractors as <b>paid in full</b>, including:&nbsp;
        <b>{renderContractorList()}</b></h2>
      </div>
      <span className="w-100">
        <label htmlFor="billPayDate" className="f6 fw5 db mb2">Payment received on:</label>
        <DayPickerInput
          style={getStyles(errors, 'billPayDate')}
          value={new Date(values.billPayDate)}
          inputProps={{ name: 'billPayDate', autoComplete: 'off'}}
          formatDate={formatDate}
          parseDate={parseDate}
          format={FORMAT}
          placeholder={`${dateFnsFormat(today, FORMAT)}`}
          dayPickerProps={{ disabledDays: { after: today } }}
          onDayChange={(date) => {
            setFieldValue('billPayDate', date)
          }}
          keepFocus={true}
          showOverlay={true}
        />
      </span>
      <div className="mt4 absolute bottom-1 right-1">
        <button className="f6 link dim br2 ba ph3 pv2 mb2 dib black pointer" onClick={props.closeModal}>Cancel</button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="f6 link dim br2 ph3 pv2 mb2 dib white bg-blue bn pointer ml2">
          Confirm
        </button>
      </div>
    </Form>
  )
}

function PaidInFullModal(props){
  const { 
    jobs,
    selectedContractors,
    setSuccessMessage,
    contentLabel,
    modalIsOpen,
    closeModal
  } = props
  
  const [paidSaveErrorMessage, setPaidSaveErrorMessage] = useState(false)
  const formInitialValues = {
    billPayDate: today
  }

  const [markPaidInFull, {loading, error, reset }] = useMutation(MARK_PAID_IN_FULL, {
    onCompleted: (data)=>{
      if (!data.markPaidInFull?.ok){
        setPaidSaveErrorMessage(data.markPaidInFull?.message)
        reset()
      } else {
        closeModal();
        setSuccessMessage(data.markPaidInFull?.message)
        reset()
      }
    },
    refetchQueries: ['FinancialReporting']
  })

  const prepSelectedJobs = (billPayDate) => {
    let markAsPaidUpdates = []

    jobs.map((job) => {
      markAsPaidUpdates.push({
        jobId: job,
        billPayDate: billPayDate
      })
    });
    
    return markAsPaidUpdates
  }

  const renderLoading = () => (
    <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
  )

  const renderModalBody = () => (
    <div className="roboto">
      <PageHeader title={'Confirm "Paid in Full"'} />
      <Formik
        initialValues={formInitialValues}
        onSubmit={(values, { setSubmitting }) => {
          const jobs = prepSelectedJobs(values.billPayDate)

          setSubmitting(true)
          markPaidInFull({
            variables: {
              paidInFull: jobs
            } 
          })
        }}
        validationSchema={Yup.object().shape({
          billPayDate: Yup.string().required('Required')
        })}
      >
        {(formikProps ) => (
          <PaidInFullForm 
            {...props} 
            {...formikProps} 
            paidSaveErrorMessage={paidSaveErrorMessage} 
            selectedContractors={selectedContractors}
          />
        )}
      </Formik>
    </div>
  )

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => {
        setPaidSaveErrorMessage(false)
        closeModal()
      }}
      style={paidModalStyles}
      contentLabel={contentLabel}
    >
      {loading ? renderLoading() : renderModalBody()}
    </Modal>
  )
}

const PurchaseOrderForm = (props) => {
  const {
    selectedContractors,
    purchaseOrderAmount,
    poSaveErrorMessage,
    errors,
    errorMessage,
    isSubmitting,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
  } = props;

  const renderError = () => (
    <div className="br2 f6 flex items-center justify-center pa2 bg-washed-red red mb3">
      <span className="lh-title ml3">{poSaveErrorMessage || 'There was an error adding the purchase order number. Please try again.'}</span>
    </div>
  )

  const renderContractorList = () => {
    function getFullName(row){
      return `${row.firstName} ${row.lastName}`
    }
    const contractorList = Object.keys(selectedContractors).map(function(k){return getFullName(selectedContractors[k])}).join(", ")
    return contractorList
  }

  function getStyles(errors, fieldName) {
    if (getIn(errors, fieldName)) {
      return {
        border: '1px solid red',
        backgroundColor: '#ffdfdf'
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="pb2">
        {(poSaveErrorMessage || errorMessage) ? renderError() : null}
        <h2 className="f5 fw5 mb4 lh-copy ph0">This purchase order will apply to premium in the amount totaling <b>{purchaseOrderAmount}</b> for 
        the following contractors: <b>{renderContractorList()}</b></h2>
        <span className="w-100">
        <label htmlFor="purchaseOrderId" className="f6 fw5 db mb2 mt3">Purchase Order Number</label>
          <Field name="purchaseOrderId"
            style={getStyles(errors, 'purchaseOrderId')}
            className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
            type="text"
            value={values.purchaseOrderId}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="e.g., 4501055913"
          />  
        </span>
      </div>
      <div className="mt4 fr">
        <button className="f6 link dim br2 ba ph3 pv2 mb2 dib black pointer" onClick={props.closeModal}>Cancel</button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="f6 link dim br2 ph3 pv2 mb2 dib white bg-blue bn pointer ml2">
          Submit
        </button>
      </div>
    </Form>
  )
}

function PurchaseOrderModal(props){
  const { 
    jobs, 
    selectedContractors,
    setSelectedContractors,
    setSuccessMessage,
    modalIsOpen,
    contentLabel,
    closeModal
  } = props

  const [poSaveErrorMessage, setPoSaveErrorMessage] = useState(false)
  const formInitialValues = {
    purchaseOrderId: "" 
  }

  const [addPurchaseOrder, { loading, error, reset }] = useMutation(ADD_PURCHASE_ORDER_NUMBER, {
    onCompleted: (data) => {
      if (!data.addPurchaseOrderNumber?.ok) {
        setPoSaveErrorMessage(data.addPurchaseOrderNumber?.message)
        reset()
      } else {
        // Create a map of jobId to purchaseOrderId
        const purchaseOrderMap = data.addPurchaseOrderNumber.purchaseOrder.reduce((acc, po) => {
          acc[po.jobId] = po.purchaseOrderId;
          return acc;
        }, {});

        // Update selectedContractors with new purchaseOrderIds
        const updatedSelectedContractors = Object.entries(selectedContractors).reduce((acc, [jobId, contractor]) => {
          acc[jobId] = {
            ...contractor,
            purchaseOrderId: purchaseOrderMap[jobId] || contractor.purchaseOrderId
          };
          return acc;
        }, {});

        setSelectedContractors(updatedSelectedContractors);
        
        closeModal();
        setSuccessMessage(data.addPurchaseOrderNumber?.message)
        reset()
      }
    },
    refetchQueries: ['FinancialReporting']
  })

  const prepSubmitData = (purchaseOrder) => {
    let purchaseOrderUpdates = []

    jobs.map((job) => {
      purchaseOrderUpdates.push({
        jobId: job,
        purchaseOrderId: purchaseOrder
      })
    })
    
    return purchaseOrderUpdates
  }

  const renderLoading = () => (
    <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
  )

  const renderModalBody = () => (
    <div className="roboto">
      <PageHeader title={'Add Purchase Order Number'} />
      <Formik
        initialValues={formInitialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true)
          addPurchaseOrder({
            variables: {
              purchaseOrder: prepSubmitData(values.purchaseOrderId)
            } 
          })
        }}
        validationSchema={Yup.object().shape({
          purchaseOrderId: Yup.string().required('Required')
        })}
      >
        {(formikProps ) => (
          <PurchaseOrderForm 
            {...props} 
            {...formikProps} 
            poSaveErrorMessage={poSaveErrorMessage} 
            selectedContractors={selectedContractors}
          />
        )}
      </Formik>
    </div>
  )

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => {
        setPoSaveErrorMessage(false)
        closeModal()
      }}
      style={modalStyles}
      contentLabel={contentLabel}
    >
      {loading ? renderLoading() : renderModalBody()}
    </Modal>
  )
}

function Checkbox(props){
  const {checked, row, checkboxChange} = props
  
  return (
    <input type="checkbox" 
      name={row.jobId}
      checked={checked}
      data-gl-cost={row.wcPremium}
      data-wc-cost={row.glPremiumTotalCost}
      data-full-name={`${row.firstName} ${row.lastName}`}
      data-job-id={row.jobId} 
      onChange={checkboxChange(row)}
    />
  )
}

const AutocompletePlaceholder = () => (
  <input
    className="input-reset f6 ba b--black-20 pa2 w-100 mv3 db br2"
    type="text"
  />
)

function matchOrganizationtoTerm(organization, value) {
  return (
    (organization.name !== null &&
      organization.name.toLowerCase().indexOf(value.toLowerCase()) !==
        -1)
  );
}

function AutocompleteOrganizationInput(props){
  const { values, setFieldValue } = props

  const [value, setValue] = useState(values?.organization?.name);
  const [item, setItem] = useState(null);

  const { loading, data } = useQuery(ALL_ORGANIZATION_QUERY);

  return loading ? (
    <AutocompletePlaceholder />
  ) : (
    <Autocomplete
      wrapperProps={{ style: { display: "inline-block", width: "100%" } }}
      inputProps={{
        placeholder: "Search organization name",
        className: "input-reset f6 ba b--black-20 pa2 w-100 mv3 db br2",
        type: "text",
      }} 
      getItemValue={(item) => item.name}
      items={data.organizations}
      renderItem={(item, isHighlighted) => (
        <div
          key={item.id}
          className="pa2 f6"
          style={{ background: isHighlighted ? "lightgray" : "white" }}
        >
          {item.name}
        </div>
      )}
      value={value}
      shouldItemRender={matchOrganizationtoTerm}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onSelect={(val, item) => {
        setValue(val);
        setItem(item); 
        setFieldValue('organization', item);
      }}
    />
  )
}

function FilterDropdownMenu(props){
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  
  const onShowFilterMenu = (event) => {
    event.preventDefault();
    setShowFilterMenu(true);
  };

  const closeFilterMenu = () => {
    setShowFilterMenu(false);
  };
  
  const Menu = (props) => {
    const {
      values,
      setFieldValue
    } = props
    
    return (
      <OutsideAlerter closeMenu={closeFilterMenu}>
        <div className="absolute shadow-4 bg-white filter-menu z-3 br2">
          <div className="ma0 sibling bg-white br1 f6 near-black pt2 pb3 pl3 pr3">
            <div className="dt w-100 pv2">
              <div className="dtc">
                <div className="w-100">
                  <div className="tl">Show unpaid only:</div>
                </div>
              </div>
              <div className="dtc tr">
                <input type="checkbox" 
                  checked={values.unpaidOnly}
                  onChange={(event) => {
                    setFieldValue('unpaidOnly', event.target.checked)
                  }} 
                />
              </div>
            </div>
            <div className="dt w-100 pt2 pb0">
              <div className="dtc">
                <div className="w-100">
                  <div className="tl">Show all orgs:</div>
                </div>
              </div>
              <div className="dtc tr">
                <input type="checkbox" 
                  checked={values.allOrgs}
                  onChange={(event) => {
                  setFieldValue('allOrgs', event.target.checked)
                }} />
              </div>
            </div>
            {!values.allOrgs && 
              <div className="dt w-100">
                <AutocompleteOrganizationInput values={values} setFieldValue={setFieldValue} />
              </div>
            }
            <div className="dt w-100 pb1 pt4">
              <div className="dtc">
                <button type="submit"
                  className="outline-0 pointer br2 ba b--black-20 bg-blue white pv2 ph3 f6
                  bg-animate border-box w-100">
                  Update Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </OutsideAlerter>
    )
  }

  const FilterButton = (props) => {
    return (
      <div className="dib relative">
        <button type="button" onClick={onShowFilterMenu}
          className="outline-0 pointer br2 ba b--black-20 bg-white pv2 ph3 ml1 f5 lh-title bg-animate border-box">
          <img className="dib v-btm w1" src="/filter.svg" alt="Filter" />
        </button>
        {showFilterMenu ? (
            <Menu {...props} />
          ) : null}
      </div>
    )
  }

  return (
    <FilterButton {...props} />
  )
}

function TableFilters(props){
  const { 
    filterOptions, 
    setFilterOptions,
    openPurchaseOrderModal,
    openPaidInFullModal,
    openInvoicePdfModal,
    checkboxStates,
    checkedPremiumOwed
  } = props;

  const endDateInputRef = useRef(null);
  const [tempStartDate, setTempStartDate] = useState(filterOptions.startDate);
  const [tempEndDate, setTempEndDate] = useState(filterOptions.endDate);

  const handleStartDateChange = useCallback((date) => {
    setTempStartDate(date);
    // Set a timeout to focus the end date input after the start date picker closes
    setTimeout(() => {
      if (endDateInputRef.current && endDateInputRef.current.input) {
        endDateInputRef.current.input.focus();
        endDateInputRef.current.input.click(); // This should open the calendar
      }
    }, 0);
  }, []);

  const handleEndDateChange = useCallback((date) => {
    setTempEndDate(date);
    if (tempStartDate && date) {
      setFilterOptions(prev => ({...prev, startDate: tempStartDate, endDate: date}));
    }
  }, [tempStartDate, setFilterOptions]);

  const FiltersForm = (props) => {
    const {
      setSuccessMessage,
      errorMessage,
      values,
      setFieldValue,
      handleSubmit,
    } = props

    const renderError = () => (
      <div className="br2 f6 flex items-center justify-center pa2 bg-washed-red red mb3">
        <span className="lh-title ml3">There was an error applying one or more filters. Please try again.</span>
      </div>
    )

    const getModalData = () => {
      let jobs = []
      
      Object.entries(checkboxStates).forEach(([key, value]) => {
        if(value){
          jobs.push(key)
        }
      });

      return {
        totalOwed: strToCurrencyFormatter.format(checkedPremiumOwed),
        jobs: jobs
      }
    }

    const onClickAddPO = () => {
      const {
        totalOwed,
        jobs
      } = getModalData()
  
      openPurchaseOrderModal(jobs, totalOwed)
    }

    const onClickPaidInFull = () => {
      const {
        totalOwed,
        jobs
      } = getModalData()
  
      openPaidInFullModal(jobs, totalOwed)
    }

    const onClickGenerateAgencyPayInvoicePdf = () => {
      const {
        totalOwed,
        jobs
      } = getModalData()

      openInvoicePdfModal(jobs, totalOwed)
    }

    return (
      <Form onSubmit={handleSubmit}>
        {errorMessage ? renderError() : null}
        <div className="db w-100 cf">
        <div className="fl">
          {checkedPremiumOwed > 0 ?
            <div className="pt3 f5">Total Premium: {strToCurrencyFormatter.format(checkedPremiumOwed)}</div> :
            null
          }
        </div>
          <div className="fr">
            <div className="dib relative">
              <DayPickerInput
                value={tempStartDate}
                inputProps={{ name: 'startDate', autoComplete: 'off' }}
                formatDate={formatDate}
                parseDate={parseDate}
                format={FORMAT}
                placeholder={`${dateFnsFormat(lastMonth, FORMAT)}`}
                dayPickerProps={{ disabledDays: { after: today } }}
                onDayChange={handleStartDateChange}
              />
            </div>
            <div className="dib"> &nbsp; – &nbsp;</div>
            <div className="dib relative">
              <DayPickerInput
                ref={endDateInputRef}
                value={tempEndDate}
                inputProps={{ 
                  name: 'endDate', 
                  autoComplete: 'off'
                }}
                formatDate={formatDate}
                parseDate={parseDate}
                format={FORMAT}
                dayPickerProps={{ 
                  disabledDays: { before: tempStartDate || filterOptions.startDate, after: today },
                  month: tempStartDate || filterOptions.startDate,
                }}
                placeholder={`${dateFnsFormat(today, FORMAT)}`}
                onDayChange={handleEndDateChange}
              />
            </div>
            <FilterDropdownMenu {...props} />
            <div className="dib">
              <button type="button"
                onClick={onClickAddPO} 
                disabled={checkedPremiumOwed > 0 ? null : "disabled"}
                className="outline-0 pointer br2 ba b--black-20 bg-white pv2 ph3 ml1 f5
                 bg-animate border-box">
                Add PO #
              </button>
            </div>
            <div className="dib">
              <button type="button"
                onClick={onClickPaidInFull}
                disabled={checkedPremiumOwed > 0 ? null : "disabled"}
                className="outline-0 pointer br2 ba b--black-20 bg-white pv2 ph3 ml1 f5
                 bg-animate border-box">
                Mark as paid
              </button>
            </div>
            <div className="dib">
              <button type="button"
                onClick={onClickGenerateAgencyPayInvoicePdf}
                disabled={checkedPremiumOwed > 0 ? null : "disabled"}
                className="outline-0 pointer br2 ba b--black-20 bg-white pv2 ph3 ml1 f5
                 bg-animate border-box">
                Invoice PDF
              </button>
            </div>
          </div>
        </div>
      </Form>
    )
  }

  return (
    <Formik
      initialValues={filterOptions}
      onSubmit={(values, {setSubmitting})=> {
        setSubmitting(true)
        setFilterOptions(prepValues(values))
      }}
    >{(formikProps) => (
      <FiltersForm {...props} {...formikProps}/>
    )}
    </Formik>
)}

const FilterContext = createContext(undefined)

function inputStopPropagation(event) {
  if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
    event.stopPropagation();
  }
}

function selectStopPropagation(event) {
  if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
    event.stopPropagation();
  }
}

function Table(props){
  const [selectedRows, setSelectedRows] = useState(() => new Set());
  const filterDefaults = {
    created: '',
    fullName: '',
    workState: '',
    methodOfPayment: 'All',
    paidInFull: 'All',
    remuneration: '',
    jobId: '',
    jobClassName: '',
    wcCode: '',
    wcNetRate: '',
    wcPremium: '',
    glCode: '',
    glNetRate: '',
    glTaxOwed: '',
    glStampingFeeOwed: '',
    glPremiumOnly: '',
    glPgFee: '',
    glPremiumTotalCost: '',
    campaign: '',
    agency: '',
    agencyPayId: '',
    agencyPayInvoicePaymentDate: '',
    purchaseOrderId: '',
    saasFeePaidInFull: 'All',
    saasFeeAmount: '',
    policyIsActive: 'All',
    policyCancelledDate: '',
  }

  const [tableFilters, setTableFilters] = useState(
    () => (filterDefaults)
  );

  const {
    filterOptions,
    selectedContractors,
    setSelectedContractors,
    checkboxStates,
    setCheckboxStates,
    updateTotals,
  } = props

  const { loading, data, refetch } = useQuery(REPORTING_QUERY, {
    variables: {filterOptions},
    notifyOnNetworkStatusChange: true
  })
  useEffect(() => {refetch(filterOptions) }, [filterOptions])

  //When checkbox is clicked, add to local checkbox states object
  const checkboxChange = (row) => event => {
    updateSelected(row)
  };
  
  const callbackCheckboxChange = useCallback(checkboxChange)

  const renderLoadingRows = () => {
    return (
      <ReactLoading type={'spin'} color={'#cccccc'} className="mv4 center" />
    )
  }

  function clearFilters() {
    setTableFilters(filterDefaults);
  }

  const { sessionInfo } = useContext(AuthContext);

  const handleInvoiceClick = useCallback(async (event, url, invoiceId) => {
    event.stopPropagation();
    
    try {
      const token = sessionInfo.idToken;
      
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Ten99Policy-Environment': localStorage.getItem('Ten99PolicyEnvironment') || environments.PRODUCTION,
          'Ten99Policy-Organization': localStorage.getItem("Ten99PolicyOrganization") || "",
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch signed URL');
      }

      const data = await response.json();
      const signedUrl = data.signed_url;

      window.open(signedUrl, '_blank');

    } catch (error) {
      console.error('Error fetching invoice:', error);
      // Optionally, show an error message to the user
    }
  }, [sessionInfo]);

  const columns = useMemo(() => {
    return [
      {
        key: 'selectRow',
        frozen: true,
        cellClass: 'tc',
        renderCell(props){
          const checked = checkboxStates[props.row.jobId] ? checkboxStates[props.row.jobId] : false;
          
          return (
            <Checkbox 
              key={props.row.jobId}
              checked={checked}
              row={props.row} 
              checkboxChange={callbackCheckboxChange}
            />
          )
        }
      },
      {
        key: 'created', 
        name: 'Created on',
        frozen: true,
        headerCellClass: 'lh-copy ph0',
        renderCell(props) {
          const created = new Date(props.row.created).toLocaleDateString('en-US')
          return created
        },
        renderHeaderCell: (p) => (
          <div className="pa2">
            <button className="pa1 mt4 ba b-solid br2 b--black-40" type="button" onClick={clearFilters}>
              Clear Filters
            </button>
          </div>
        ),
        renderSummaryCell() {
          return <strong>Total</strong>;
        }
      },
      {
        key: 'fullName', 
        name: 'Full Name',
        frozen: true,
        headerCellClass: 'lh-copy ph0',
        renderCell(props) {
          return `${props.row.firstName} ${props.row.lastName}`
        },
        renderSummaryCell({ row }) {
          return `${row.totalCount} records`;
        },
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.fullName}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  fullName: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'workState', 
        name: 'Work State',
        headerCellClass: 'lh-copy ph0',
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.workState}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  workState: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'state', 
        name: 'Home State',
        headerCellClass: 'lh-copy ph0',
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.state}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  state: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'methodOfPayment', 
        name: 'Payment type',
        headerCellClass: 'lh-copy ph0',
        renderCell(props){
          return props.row.methodOfPayment == 'ap' ? 'Agency Remit' : 'Credit Card'
        },
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <select
                {...rest}
                className='ba b--black-20 br2 pa1'
                value={filters.methodOfPayment}
                onChange={(e) =>
                  setTableFilters({
                    ...filters,
                    methodOfPayment: e.target.value
                  })
                }
                onKeyDown={selectStopPropagation}
              >
                <option value="All">All</option>
                <option value="Agency Remit">Agency Remit</option>
                <option value="Credit Card">Credit Card</option>
              </select>
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'paidInFull', 
        name: 'Premium Paid in full?',
        headerCellClass: 'lh-copy ph0',
        renderCell(props){
          return props.row.paidInFull ? 'True' : 'False'
        },
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <select
                {...rest}
                className='ba b--black-20 br2 pa1 w-100'
                value={filters.paidInFull}
                onChange={(e) =>
                  setTableFilters({
                    ...filters,
                    paidInFull: e.target.value
                  })
                }
                onKeyDown={selectStopPropagation}
              >
                <option value="All">All</option>
                <option value="True">True</option>
                <option value="False">False</option>
              </select>
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'remuneration', 
        name: 'Remuneration',
        headerCellClass: 'lh-copy ph0',
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.remuneration}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  remuneration: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'saasFeeAmount', 
        name: 'Platform Fee',
        headerCellClass: 'lh-copy ph0',
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.saasFeeAmount}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  saasFeeAmount: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'saasFeePaidInFull', 
        name: 'Platform Fee Paid in full?',
        headerCellClass: 'lh-copy ph0',
        renderCell(props){
          return props.row.saasFeePaidInFull ? 'True' : 'False'
        },
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <select
                {...rest}
                className='ba b--black-20 br2 pa1 w-100'
                value={filters.saasFeePaidInFull}
                onChange={(e) =>
                  setTableFilters({
                    ...filters,
                    saasFeePaidInFull: e.target.value
                  })
                }
                onKeyDown={selectStopPropagation}
              >
                <option value="All">All</option>
                <option value="True">True</option>
                <option value="False">False</option>
              </select>
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'jobId', 
        name: 'Job ID',
        headerCellClass: 'lh-copy ph0',
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.jobId}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  jobId: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'jobClassName', 
        name: 'Job Class Name',
        headerCellClass: 'lh-copy ph0',
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.jobClassName}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  jobClassName: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'wcCode', 
        name: 'WC Code',
        headerCellClass: 'lh-copy ph0',
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.wcCode}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  wcCode: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'wcNetRate', 
        name: 'WC Net Rate',
        headerCellClass: 'lh-copy ph0',
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.wcNetRate}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  wcNetRate: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'wcPremium', 
        name: 'WC Premium Total Cost',
        headerCellClass: 'lh-copy ph0',
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.wcPremium}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  wcPremium: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'glCode', 
        name: 'GL Code',
        headerCellClass: 'lh-copy ph0',
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.glCode}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  glCode: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'glNetRate', 
        name: 'GL Net Rate',
        headerCellClass: 'lh-copy ph0',
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.glNetRate}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  glNetRate: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'glTaxOwed', 
        name: 'GL Tax Owed',
        headerCellClass: 'lh-copy ph0',
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.glTaxOwed}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  glTaxOwed: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'glStampingFeeOwed', 
        name: 'GL Stamp Fee Owed',
        headerCellClass: 'lh-copy ph0',
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.glStampingFeeOwed}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  glStampingFeeOwed: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'glPremiumOnly', 
        name: 'GL Premium Owed',
        headerCellClass: 'lh-copy ph0',
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.glPremiumOnly}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  glPremiumOnly: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'glPgFee', 
        name: 'GL PG Fee',
        headerCellClass: 'lh-copy ph0',
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.glPgFee}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  glPgFee: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'glPremiumTotalCost', 
        name: 'GL Premium Total Cost',
        headerCellClass: 'lh-copy ph0',
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.glPremiumTotalCost}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  glPremiumTotalCost: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'campaign', 
        name: 'Ultimate Client',
        headerCellClass: 'lh-copy ph0',
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.campaign}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  campaign: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'organizationName', 
        name: 'Organization',
        headerCellClass: 'lh-copy ph0',
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.organizationName}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  organizationName: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'agencyPayInvoiceId', 
        name: '1099P AP Invoice #',
        headerCellClass: 'lh-copy ph0',
        renderCell(props) {
          if (props.row.agencyPayInvoiceId) {
            return (
              <button
                className="link blue underline bg-transparent bn pointer"
                onClick={(event) => handleInvoiceClick(event, props.row.agencyPayInvoiceUrl, props.row.agencyPayInvoiceId)}
              >
                {props.row.agencyPayInvoiceId}
              </button>
            );
          }
          return null;
        },
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.agencyPayInvoiceId}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  agencyPayInvoiceId: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'purchaseOrderId', 
        name: 'Purchase Order #',
        headerCellClass: 'lh-copy ph0',
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.purchaseOrderId}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  purchaseOrderId: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'agencyPayInvoicePaymentDate', 
        name: 'Agency Invoice Payment Date',
        headerCellClass: 'lh-copy ph0',
        renderCell(props) {
          if(props.row.agencyPayInvoicePaymentDate){
            const paymentDate = new Date(props.row.agencyPayInvoicePaymentDate).toLocaleDateString('en-US')
            return paymentDate
            
          }
          return null
        },
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.agencyPayInvoicePaymentDate}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  agencyPayInvoicePaymentDate: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
       {
        key: 'policyIsActive', 
        name: 'Policy is active?',
        headerCellClass: 'lh-copy ph0',
        renderCell(props){
          return props.row.policyIsActive ? 'True' : 'False'
        },
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <select
                {...rest}
                className='ba b--black-20 br2 pa1 w-100'
                value={filters.policyIsActive}
                onChange={(e) =>
                  setTableFilters({
                    ...filters,
                    policyIsActive: e.target.value
                  })
                }
                onKeyDown={selectStopPropagation}
              >
                <option value="All">All</option>
                <option value="True">True</option>
                <option value="False">False</option>
              </select>
            )}
          </FilterRenderer>
        )
      },
      {
        key: 'policyCancelledDate', 
        name: 'Policy cancelled date',
        headerCellClass: 'lh-copy ph0',
        renderCell(props) {
          const policyCancelledDate = props.row.policyCancelledDate ? new Date(props.row.policyCancelledDate).toLocaleDateString('en-US') : null
          return policyCancelledDate
        },
        renderHeaderCell: (p) => (
          <FilterRenderer {...p}>
            {({ filters, ...rest }) => (
              <input
              {...rest}
              className='ba b--black-20 br2 pa1'
              value={filters.policyCancelledDate}
              onChange={(e) =>
                setTableFilters({
                  ...filters,
                  policyCancelledDate: e.target.value
                })
              }
              onKeyDown={inputStopPropagation}
            />
            )}
          </FilterRenderer>
        )
      },
    ];
  }, [handleInvoiceClick, checkboxStates]);

  function renderCheckbox({ onChange, ...props }) {
    function handleChange(e) {
      onChange(e.target.checked, e.nativeEvent.shiftKey);
    }
    
    return <input type="checkbox" {...props} onChange={handleChange} />;
  }

  const filteredRows = useMemo(() => {
    return data?.financialReport?.filter((r) => {
      
      const fullName = `${r.firstName} ${r.lastName}`
      const methodOfPayment = r.methodOfPayment == 'ap' ? 'Agency Remit' : 'Credit Card'
      const paidInFull = r.paidInFull ? 'True' : 'False'
      const saasFeePaidInFull = r.saasFeePaidInFull ? 'True' : 'False'
      const policyIsActive = r.policyIsActive ? 'True' : 'False'

      return (
        (tableFilters.fullName ? fullName?.includes(tableFilters.fullName) : true) &&
        (tableFilters.workState ? r.workState?.includes(tableFilters.workState) : true) &&
        (tableFilters.state ? r.state?.includes(tableFilters.state) : true) &&
        (tableFilters.methodOfPayment !== 'All' ? methodOfPayment === tableFilters.methodOfPayment : true) &&
        (tableFilters.paidInFull !== 'All' ? paidInFull === tableFilters.paidInFull : true) &&
        (tableFilters.remuneration ? r.remuneration?.includes(tableFilters.remuneration) : true) &&
        (tableFilters.jobId ? r.jobId?.includes(tableFilters.jobId) : true) &&
        (tableFilters.jobClassName ? r.jobClassName?.includes(tableFilters.jobClassName) : true) &&
        (tableFilters.wcCode ? r.wcCode?.includes(tableFilters.wcCode) : true) &&
        (tableFilters.wcNetRate ? r.wcNetRate?.includes(tableFilters.wcNetRate) : true) &&
        (tableFilters.wcPremium ? r.wcPremium?.includes(tableFilters.wcPremium) : true) &&
        (tableFilters.glCode ? r.glCode?.includes(tableFilters.glCode) : true) &&
        (tableFilters.glNetRate ? r.glNetRate?.includes(tableFilters.glNetRate) : true) &&
        (tableFilters.glTaxOwed ? r.glTaxOwed?.includes(tableFilters.glTaxOwed) : true) &&
        (tableFilters.glStampingFeeOwed ? r.glStampingFeeOwed?.includes(tableFilters.glStampingFeeOwed) : true) &&
        (tableFilters.glPremiumOnly ? r.glPremiumOnly?.includes(tableFilters.glPremiumOnly) : true) &&
        (tableFilters.glPgFee ? r.glPgFee?.includes(tableFilters.glPgFee) : true) &&
        (tableFilters.glPremiumTotalCost ? r.glPremiumTotalCost?.includes(tableFilters.glPremiumTotalCost) : true) &&
        (tableFilters.campaign ? r.campaign?.includes(tableFilters.campaign) : true) &&
        (tableFilters.organizationName ? r.organizationName?.includes(tableFilters.organizationName) : true) &&
        (tableFilters.agencyPayInvoiceId ? r.agencyPayInvoiceId?.includes(tableFilters.agencyPayInvoiceId) : true) &&
        (tableFilters.purchaseOrderId ? r.purchaseOrderId?.includes(tableFilters.purchaseOrderId) : true) &&
        (tableFilters.agencyPayInvoicePaymentDate ? r.agencyPayInvoicePaymentDate?.includes(tableFilters.agencyPayInvoicePaymentDate) : true) &&
        (tableFilters.saasFeeAmount ? r.saasFeeAmount?.includes(tableFilters.saasFeeAmount) : true) &&
        (tableFilters.saasFeePaidInFull !== 'All' ? saasFeePaidInFull === tableFilters.saasFeePaidInFull : true) &&
        (tableFilters.policyIsActive !== 'All' ? policyIsActive === tableFilters.policyIsActive : true) && 
        (tableFilters.policyCancelledDate ? r.policyCancelledDate?.includes(tableFilters.policyCancelledDate) : true) 
      )
    })
  }, [data?.financialReport, tableFilters])

  const summaryRows = useMemo(() => {
    return [
      {
        id: 'total_0',
        totalCount: data?.financialReport?.length,
      }
    ];
  }, [data?.financialReport]);

  const handleChange = (row, checked) => {
    const wcCost = row.wcPremium
    const glCost = row.glPremiumTotalCost
    updateTotals(checked, wcCost, glCost)
  }

  const updateSelectedContractor = (row, checked) => {
    const fullName = `${row.firstName} ${row.lastName}`
    const key = row.jobId

    if(checked){
      setSelectedContractors({...selectedContractors, [key]: row})  
    } else {
      const copySelectedContractors = {...selectedContractors}
      delete copySelectedContractors[key]
      setSelectedContractors(copySelectedContractors)
    }
  }

  function rowKeyGetter(row) {
    const timestamp = Date.parse(row.created)
    return `${row.contractorId}-${row.jobId}-${timestamp}`
  }

  const updateSelected = (row) => {
    const rowKey = rowKeyGetter(row)
    const key = row.jobId
    let checked = true

    let selectedRowsSet = new Set(selectedRows);
    if (selectedRowsSet.has(rowKey)) {
      selectedRowsSet.delete(rowKey);
      setCheckboxStates({ ...checkboxStates, [key]: !checked }) 
      updateSelectedContractor(row, !checked)
      handleChange(row, !checked)
    } else {
      selectedRowsSet.add(rowKey);  
      setCheckboxStates({ ...checkboxStates, [key]: checked }) 
      updateSelectedContractor(row, checked)
      handleChange(row, checked)
    }
    setSelectedRows(selectedRowsSet);
  }

  const onCellClick = (args) => {
    updateSelected(args.row)
  }

  const renderTable = () => {
    let tableContent = null

    if(loading){
      tableContent = (
        <div className="w-100">
          {renderLoadingRows()}
        </div>
      )
    } else if( data?.financialReport.length == 0){
      tableContent = (
        <div className="db w-100 tc cf pa4">
          No job activity data for this time window.
        </div>
      )
    } else {
      tableContent = (
        <FilterContext.Provider value={tableFilters}>
          <DataGrid 
            headerRowHeight={74}
            rowKeyGetter={rowKeyGetter}
            columns={columns} 
            rows={filteredRows} 
            onCellClick={onCellClick}
            className="rdg-light vh-50"
            rowClass={(row, index) =>
              !row.policyIsActive ? 'bg-washed-red' :
              row.paidInFull ? 'bg-washed-green' : undefined
            }
            renderers={{ renderCheckbox }}
            selectedRows={selectedRows}
            onSelectedRowsChange={setSelectedRows}
            bottomSummaryRows={summaryRows}
          />
        </FilterContext.Provider>
      )
    }

    return (
      <>
        {tableContent}
      </>
    )
  }

  return (renderTable())
}

function FilterRenderer({tabIndex,column,children}){
  const filters = useContext(FilterContext);
  
  return (
    <>
      <div className="pa2 bb b--black-20">{column.name}</div>
      {<div className="pa1">{children({ tabIndex, filters })}</div>}
    </>
  );
}


function FinancialReportingInner(props){
  const { 
    filterOptions,
    setFilterOptions, 
    openPurchaseOrderModal,
    openPaidInFullModal,
    openInvoicePdfModal,
    selectedContractors, 
    setSelectedContractors
  } = props
  const [checkedPremiumOwed, setCheckedPremiumOwed] = useState(0)
  const [checkboxStates, setCheckboxStates] = useState({})

  const incrementPremiumOwed = (premiumCost) => {
    setCheckedPremiumOwed(checkedPremiumOwed + premiumCost)
  }

  const decrementPremiumOwed = (premiumCost) => {
    setCheckedPremiumOwed(checkedPremiumOwed - premiumCost)
  }

  const updateTotals = (checked, wcCost, glCost) => {
    if (!wcCost){
      wcCost = '0' 
    }
    if (!glCost){
      glCost = '0'
    }

    const wcCostNum = currencyStringToCents(wcCost)
    const glCostNum = currencyStringToCents(glCost)
    const totalCost = wcCostNum + glCostNum

    checked ?
    incrementPremiumOwed(totalCost) :
    decrementPremiumOwed(totalCost)
  }

  return(
    <>
        <TableFilters 
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          checkboxStates={checkboxStates}
          selectedContractors={selectedContractors}
          checkedPremiumOwed={checkedPremiumOwed} 
          openPurchaseOrderModal={openPurchaseOrderModal}
          openPaidInFullModal={openPaidInFullModal}
          openInvoicePdfModal={openInvoicePdfModal}
        />
        <Table 
          filterOptions={filterOptions}
          setSelectedContractors={setSelectedContractors}
          selectedContractors={selectedContractors}
          setCheckboxStates={setCheckboxStates}
          checkboxStates={checkboxStates} 
          updateTotals={updateTotals}
        />
      </>
  )
}

function FinancialReporting(props){
  const [poModalIsOpen, setPoModalIsOpen] = useState(false)
  const [paidModalIsOpen, setPaidModalIsOpen] = useState(false)
  const [pdfModalIsOpen, setPdfModalIsOpen] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const [purchaseOrderAmount, setPurchaseOrderAmount] = useState('$0')
  const [selectedJobs, setSelectedJobs] = useState([])
  const [selectedContractors, setSelectedContractors] = useState({})
  
  const formInitialValues = { 
    startDate: lastMonth,
    endDate: today,
    coverage: '',
    organization: '',
    invoice: '',
    unpaidOnly: false,
    allOrgs: true
  }

  const [filterOptions, setFilterOptions] = useState(formInitialValues)
  
  const openPurchaseOrderModal = (jobs, totalOwed) => {
    setSelectedJobs(jobs)
    setPurchaseOrderAmount(totalOwed)
    setPoModalIsOpen(true)
  }

  const openPaidInFullModal = (jobs, totalOwed) => {
    setSelectedJobs(jobs)
    setPurchaseOrderAmount(totalOwed)
    setPaidModalIsOpen(true)
  }

  const openInvoicePdfModal = (jobs, totalOwed) => {
    setSelectedJobs(jobs)
    setPurchaseOrderAmount(totalOwed)
    setPdfModalIsOpen(true)
  }

  const renderSuccess = (message) => (
    <div className="br2 f6 flex items-center justify-center pa2 bg-washed-green green mb4">
      <span className="lh-title ml3">{message}</span>
    </div>
  )

  return (
    <MainWrapper isTestView={props.isTestView}>
      {successMessage ? renderSuccess(successMessage) : <AdminDisclaimer />}
      <FinancialReportingInner 
        {...props} 
        filterOptions={filterOptions} 
        setFilterOptions={setFilterOptions}
        openPurchaseOrderModal={(jobs, totalOwed) => openPurchaseOrderModal(jobs, totalOwed)} 
        openPaidInFullModal={(jobs, totalOwed) => openPaidInFullModal(jobs, totalOwed)}
        openInvoicePdfModal={(jobs, totalOwed) => openInvoicePdfModal(jobs, totalOwed)}
        setSuccessMessage={setSuccessMessage}
        selectedContractors={selectedContractors}
        setSelectedContractors={setSelectedContractors}
      />
      <PurchaseOrderModal 
        {...props}
        openModal={() => setPoModalIsOpen(true)}
        closeModal={() => {
          setPoModalIsOpen(false)
        }}
        setSuccessMessage={setSuccessMessage}
        modalIsOpen={poModalIsOpen}
        contentLabel={"Add Purchase Order Number"}
        purchaseOrderAmount={purchaseOrderAmount}
        selectedContractors={selectedContractors}
        setSelectedContractors={setSelectedContractors}
        jobs={selectedJobs}
      />
      <PaidInFullModal
        {...props}
        openModal={() => setPaidModalIsOpen(true)}
        closeModal={() => {
          setPaidModalIsOpen(false)
        }}
        setSuccessMessage={setSuccessMessage}
        modalIsOpen={paidModalIsOpen}
        contentLabel={"Confirm 'Paid in Full'"}
        purchaseOrderAmount={purchaseOrderAmount}
        selectedContractors={selectedContractors}
        jobs={selectedJobs}
      />
      <InvoicePdfModal
        {...props}
        openModal={() => setPdfModalIsOpen(true)}
        closeModal={() => {
          setPdfModalIsOpen(false)
        }}
        setSuccessMessage={setSuccessMessage}
        modalIsOpen={pdfModalIsOpen}
        contentLabel={"Verify Invoice Details"}
        purchaseOrderAmount={purchaseOrderAmount}
        selectedContractors={selectedContractors}
        jobs={selectedJobs}
      />
    </MainWrapper>
  )
}

export default withAuthorization(FinancialReporting, ['admin'])