import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function withAuthorization(WrappedComponent, allowedRoles) {
  return function (props) {
    const navigate = useNavigate();

    const userRoles = props.user?.roles?.edges;
    const orgRoles =
      props.user?.organizations?.edges[0]?.node?.organizationRole?.role;

    const roles = [...userRoles, { node: orgRoles }];

    useEffect(() => {
      const isAllowed = roles.some((userRole) =>
        allowedRoles.includes(userRole.node.name)
      );

      if (!isAllowed) {
        navigate("/not-authorized");
      }
    }, [navigate, userRoles, allowedRoles]);

    const isAllowed = roles.some((userRole) =>
      allowedRoles.includes(userRole.node.name)
    );
    if (!isAllowed) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
}

export default withAuthorization;
