import React, { useState } from "react";

import OutsideAlerter from "./OutsideAlerter";

function FilterDropdownMenu({
  handleSetOtherFilters,
  otherFilters,
  selectedAgencyRepValues,
  setSelectedAgencyRepValues,
  selectedEntityValues,
  setSelectedEntityValues,
  children,
  ...props
}) {
  const [showFilterMenu, setShowFilterMenu] = useState(false);

  const onShowFilterMenu = (event) => {
    event.preventDefault();
    setShowFilterMenu(true);
  };

  const closeFilterMenu = () => {
    setShowFilterMenu(false);
  };

  const handleApplyFilter = () => {
    handleSetOtherFilters(selectedEntityValues, selectedAgencyRepValues);
    closeFilterMenu();
  };

  const handleClearFilter = () => {
    setSelectedAgencyRepValues([]);
    setSelectedEntityValues([]);
    handleSetOtherFilters([], []);
    closeFilterMenu();
  };

  const Menu = (props) => {
    return (
      <OutsideAlerter closeMenu={closeFilterMenu}>
        <div className="absolute shadow-4 bg-white filter-menu-contractor z-3 br4">
          <div className="ma0 sibling bg-white br1 f6 near-black pt2 pb3 pl3 pr3">
            <div className="f5 lh-title pv2">Filters</div>
            {children}
            <div className="flex justify-between w-100 pb1 pt4 items-end">
              <div className="flex-item">
                <button
                  className="button-reset bn b--none bg-transparent pointer hover-black-80 lh-title"
                  onClick={handleClearFilter}
                >
                  Clear
                </button>
              </div>
              <div className="flex-item">
                <button
                  type="submit"
                  className="outline-0 pointer br2 ba b--black-20 bg-blue white pv2 ph3 f6
                  bg-animate border-box"
                  onClick={handleApplyFilter}
                >
                  Apply Filters
                </button>
              </div>
            </div>
          </div>
        </div>
      </OutsideAlerter>
    );
  };

  const FilterButton = (props) => {
    const totalItems =
      (otherFilters.entities.length || 0) +
      (otherFilters.agencyRepEmails.length || 0);

    return (
      <div className="dib relative">
        <button
          type="button"
          onClick={onShowFilterMenu}
          className="outline-0 pointer br2 ba b--black-20 bg-white pv2 ph3 ml1 mv3 f7 lh-title bg-animate border-box"
        >
          <img className="dib v-btm w1" src="/filter.svg" alt="Filter" />
          &nbsp;Filter{" "}
          {totalItems > 0 && (
            <span className="br-100 dib ph1 fw5 bg-light-gray">
              {totalItems}
            </span>
          )}
        </button>
        {showFilterMenu ? <Menu {...props} /> : null}
      </div>
    );
  };

  return <FilterButton {...props} />;
}

export default FilterDropdownMenu;
