export function base64Decode(id) {
  let res = atob(id);
  let bit = res.toString("ascii").split(":")[1];

  if (isNaN(bit)) {
    return bit;
  }

  return Number(bit);
}
