import React, { useState, useEffect } from "react";

import { centsToDollars } from "../utils/currency";

const CurrencyInput = ({ field, form, ...props }) => {
  const [displayValue, setDisplayValue] = useState("");

  // Format the value for display
  const formatValue = (value) => `$${value / 100}`;

  // Parse the display value to a format suitable for Formik state
  const parseValue = (displayValue) => {
    const parsedValue = displayValue.replace(/^\$/, "");
    return isNaN(parsedValue) ? "" : parsedValue * 100;
  };

  useEffect(() => {
    // Initialize display value with formatted field value on mount
    setDisplayValue(formatValue(field.value));
  }, [field.value]);

  const handleChange = (e) => {
    const parsedValue = parseValue(e.target.value);
    form.setFieldValue(field.name, parsedValue);
    setDisplayValue(e.target.value);
  };

  return (
    <input {...props} {...field} value={displayValue} onChange={handleChange} />
  );
};

export default CurrencyInput;
