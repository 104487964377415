import React, { useEffect, useState } from 'react'
import ReactLoading from 'react-loading';
import Skeleton from 'react-loading-skeleton';
import Pusher from 'pusher-js';

import { useNavigate, useSearchParams, Link } from 'react-router-dom';

import moment from 'moment';
import classNames from 'classnames'

import { useQuery } from "@apollo/client";


import MainWrapper from '../../components/MainWrapper';

import withAuthorization from '../../utils/withAuthorization';

import { usePusherChannel } from '../../utils/pusher';
import { pusherEvents } from '../../constants/pusherEvents';

import CustomBadge from './CustomBadge';
import AddCertificateModal from './AddCertificateModal';

import { CERTIFICATE_AUDIT_QUERY } from './queries';
import { INSURANCE_REQUIREMENT_QUERY } from '../OrganizationPage/SettingsPage/queries';

import 'react-loading-skeleton/dist/skeleton.css'
import { startAndEnd } from "../../utils/truncate";

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || "", {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER || "",
});

const renderError = (message) => (
  <div className="br2 f6 flex items-center justify-center pa2 bg-washed-red red mv3">
    <span className="lh-title ml3">{message}</span>
  </div>
)

const renderSuccess = (message) => (
  <div className="br2 f6 flex items-center justify-center pa2 bg-washed-green green mv3">
    <span className="lh-title ml3">{message}</span>
  </div>
)

function SkeletonRow(props){
  return(
    <tr className="bb b--light-gray hover-bg-washed-blue" key="skeleton">
      <td className="tl ph2 f7"><Skeleton /></td>
      <td className="tl ph2 pv3 f7 w-30-l"><Skeleton /></td>
      <td className="tl ph2 pv3 f7"><Skeleton /></td>
      <td className="tl ph2 pv3 f7"><Skeleton /></td>
      <td className="tl ph2 pv3 f7"><Skeleton /></td>
      <td className="pv3 f7 tc w-10">
        <div className="flex items-center justify-center">
          <div className="w1"><Skeleton /></div>
        </div>
      </td>
    </tr>
  )
}

// Helper function to render job category pills
function renderJobCategoryPills(jobCategories) {
  if (!jobCategories || jobCategories.length === 0) {
    return (
      <span className="br3 mh1 mb1 pa1 bg-washed-blue ba b--blue blue">
        All Job Categories
      </span>
    )
  }

  return jobCategories.map((category, index) => (
    <span key={index} className="br3 mh1 mb1 pa1 bg-washed-blue ba b--blue blue">
      {startAndEnd(category?.node?.className)}
    </span>
  ));
}

function CertificateReviewTable(props){
  const { 
    successMessage, 
    setSuccessMessage, 
    setErrorMessage, 
    orgId 
  } = props
  
  const channelName = `certificate_audit-${orgId}`;

  const navigate = useNavigate();
  const [loadingRow, setLoadingRow] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const cursor = searchParams.get('starting_after')

  const { loading: requirementsLoading, data: requirementsData } = useQuery(INSURANCE_REQUIREMENT_QUERY, {
    notifyOnNetworkStatusChange: true
  })

  const { loading, data, fetchMore, refetch } = useQuery(CERTIFICATE_AUDIT_QUERY, {
    variables: {cursor},
    notifyOnNetworkStatusChange: true
  })

  usePusherChannel(
    pusher,
    channelName,
    pusherEvents.AUDIT_REVIEW_COMPLETE,
    function (data) {
      if (data) {
        refetch()
      }
    }
  );

  useEffect(() => {
    if (successMessage){
      setLoadingRow(true);

      // Set a timeout to delay the refetch
      const timeoutId = setTimeout(() => {
        refetch();
        setSuccessMessage(false);
        setLoadingRow(false);
      }, 15000); // 15000 milliseconds = 15 seconds

      // Clear the timeout when the component unmounts or when successMessage changes
      return () => clearTimeout(timeoutId);
    }
  }, [successMessage]);

  useEffect(() => {
    if (requirementsData?.insuranceRequirements?.length === 0 && data?.certificateAudits?.edges?.length > 0){
      setErrorMessage("No insurance requirements found. Add insurance requirements to get started.")
    }
  }, [requirementsData, data])

  
  const renderRows = (data) => {
    let rows = data.map((row) => {
      const contractor = JSON.parse(row.node?.certificate?.contractor?.unredactedDict);
      const requirement = row.node?.insuranceRequirement;
      return (
        <tr 
          onClick={() => {
            navigate(row.node.publicId, {state: row});
          }} 
          className="bb b--light-gray hover-bg-washed-blue pointer" key={row.node.publicId}>
          <td className="tl ph2 f7">{moment(row.node.created).format('MMM D, YYYY')}</td>
          <td className="tl ph2 pv3 f7 w-30-l">{startAndEnd(row.node?.certificate?.filename, 42)}</td>
          <td className="tl ph2 pv3 f7">{`${contractor.first_name} ${contractor.last_name}`}</td>
          <td className="tl ph2 pv3 f7 flex flex-wrap">
            {renderJobCategoryPills(requirement?.jobCategories?.edges)}
          </td>
          <td className="tl ph2 pv3 f7">
            <CustomBadge status={row.node.status} />
          </td>
          <td className="pv3 f7 tc">
            <a 
              href={row.node?.certificate?.pdfUrl}
              target='_blank'
              onClick={(event) => {
                event.stopPropagation();
              }} 
            >
              <img className="dib v-btm pr1 w1" src="/document.svg" alt="Document" />
            </a>
          </td>
        </tr>
      )}
    )

    if (loadingRow) {
      const skeletonRow = (
       <SkeletonRow />
      );
      rows = [skeletonRow, ...rows];
    }

    return rows
  }

  const fetchNextPage = (data) => {
    const cursor = data.certificateAudits.pageInfo.endCursor
    fetchMore({
      variables: {
        cursor: cursor
      },
    })
    navigate(`/certificates?starting_after=${cursor}`)
  }

  const renderTotalResults = (totalCount) => {
    return( 
      totalCount ? 
        <div className="tl dtc">
          <div className="dib">
            <div className="f6"><b>{data.certificateAudits.totalCount}</b> results</div>
          </div>
        </div> : null
    )
  }

  const renderPagination = (pageInfo) => {
    const hasNextPage = pageInfo?.hasNextPage
    
    return(
      <div className="tr dtc">
        <div className="dib">
          <button 
          type="button" 
          onClick={() => fetchNextPage(data)}
          disabled={!hasNextPage}
          className={classNames("fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml2 f7 lh-title border-box dib",
            {"bg-animate hover-bg-light-gray": hasNextPage})}>
            Next
          </button>
          <button 
          type="button" 
          onClick={() => navigate(-1)} // TODO: Fix this. We need to pass prev cursor and navigate to it instead of back
          disabled={cursor == null} // this should be !pageInfo?.hasPreviousPage but it will break because of navigate(-1)
          className={classNames("fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 f7 lh-title border-box dib", 
            {"bg-animate hover-bg-light-gray": cursor != null})}>
            Previous
          </button>
        </div>
      </div>
    )
  }

  function renderHeader(){
    return(
      <>
        <div className="fr relative mv3">
          <button
            type="button"
            onClick={props.openModal}
            disabled={requirementsData?.insuranceRequirements?.length === 0}
            className={classNames("outline-0 pointer br2 ba b--black-20 pa2 ml1 f7 lh-title border-box bg-white bg-animate hover-bg-light-gray"
              , {"pointer": requirementsData?.insuranceRequirements?.length > 0}
            )}>
            Upload Certificates
          </button>
        </div>
      </>
    )
  }

  function TableShell(props){
    const {
      children,
      totalCount,
      pageInfo
    } = props

    return(
      <div>
        {renderHeader()}
        <table className="w-100 ba b--light-gray collapse ph2 mt4 mb3">
          <thead>
            <tr className="bb bw1 b--black-10">
              <th className="tl pv3 ph2 fw5 f7 ttu w-10">Created on</th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-30-l">Filename</th>
              <th className="tl pv3 ph2 fw5 f7 ttu">Contractor</th>
              <th className="tl pv3 ph2 fw5 f7 ttu">Job Category</th>
              <th className="tl pv3 ph2 fw5 f7 ttu">Status</th>
              <th className="tl pv3 ph2 fw5 f7 ttu tc">PDF</th>
            </tr>
          </thead>
          <tbody>
            {children}
          </tbody>
        </table>
        <div className="db dt w-100">
          {totalCount ? renderTotalResults(totalCount) : null}
          {pageInfo ? renderPagination(pageInfo) : null}
        </div>
      </div>
    )
  }

  const loadingTable = () => (
    <tr><td colSpan={5}><ReactLoading type={'spin'} color={'#cccccc'} className="mv4 center" /></td></tr> 
  )

  const renderLoadingTable = () => {
    return(
      <TableShell data={data} refetch={refetch}>
        {loadingTable()}
      </TableShell>
    )
  }

  const renderEmptyTable = (msg="No certificates found") => {
    const NoRows = () => (
      <tr>
        <td colSpan={6} className="f6 tc pv4">
        {msg}
        </td>
      </tr>
      )

    return(
      <TableShell data={data} refetch={refetch}>
        {loadingRow ? <SkeletonRow /> : <NoRows />}
      </TableShell>
    )
  }

  const renderTable = (props) => {
    if (requirementsData?.insuranceRequirements?.length === 0 && data?.certificateAudits?.edges?.length == 0){
      const msg = (<div className="f6">No insurance requirements found. Click&nbsp;
        <Link to="/organization/requirements">here</Link> to add requirements and get started.</div>)
      return renderEmptyTable(msg)
    }

    if (data?.certificateAudits?.edges?.length === 0){
      return renderEmptyTable()
    }

    return (
      data?.certificateAudits && data?.certificateAudits?.edges?.length > 0 &&
      <TableShell 
        data={data} 
        pageInfo={data?.certificateAudits?.pageInfo} 
        totalCount={data?.certificateAudits?.totalCount}
        refetch={refetch}
      >
        {renderRows(data?.certificateAudits?.edges)}
      </TableShell>
    )
  }

  return (
    <>
    { loading || requirementsLoading ? renderLoadingTable() : renderTable(props)}
    </>
  )
}

function CertificateReview(props){
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  
  return (
    <>
      <MainWrapper isTestView={props.isTestView}>
        {successMessage && renderSuccess(successMessage)}
        {errorMessage && renderError(errorMessage)}
        <header className="mb3">
          <h2 className="fw3 dark-gray mt0 mb4">Uploaded Certificates</h2>
        </header>
        <CertificateReviewTable 
          openModal={() => setModalIsOpen(true)}
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          errorMessage={errorMessage}
          orgId={props.orgId}
        />
      </MainWrapper>
      <AddCertificateModal 
        closeModal={() => {
          setModalIsOpen(false)
        }}
        modalIsOpen={modalIsOpen}
        contentLabel={"Add a Certificate"} 
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
        token={props.token}
      />
    </>     
  )
}

export default withAuthorization(CertificateReview, ['admin']);