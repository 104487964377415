import React, { useState } from "react";
import Autocomplete from "react-autocomplete";

import styled from "styled-components";

import { gql, useQuery } from "@apollo/client";

const InputWrapper = styled.div`
  width: 270px;
  display: inline-block;
`;

const ALL_ORGANIZATION_QUERY = gql`
  query AllOrganizations {
    organizations {
      id
      active
      name
    }
  }
`;

function base64Decode(id) {
  let res = atob(id);
  return res.toString("ascii").split(":")[1];
}

function matchOrganizationtoTerm(organization, value) {
  return (
    organization.name !== null &&
    organization.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
  );
}

function AutocompleteOrganizationInput(props) {
  const [value, setValue] = useState("");
  const [item, setItem] = useState(null);
  const { setOrganization, menuStyleTop, menuStyleLeft } = props;

  const { loading, data } = useQuery(ALL_ORGANIZATION_QUERY);

  const setOrganizationLocalStorage = (organizationId) => {
    localStorage.setItem("Ten99PolicyOrganization", organizationId);
  };

  return loading ? (
    <InputWrapper>
      <input
        className="input-reset f6 ba b--black-20 pa2 w-100 mv3 db br2"
        type="text"
      />
    </InputWrapper>
  ) : (
    <Autocomplete
      wrapperProps={{ style: { display: "inline-block", width: "270px" } }}
      inputProps={{
        name: "organizationClassName",
        placeholder: "Search organization name",
        className: "input-reset f6 ba b--black-20 pa2 w-100 mv3 db br2",
        type: "text",
      }}
      menuStyle={{
        borderRadius: "3px",
        boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
        background: "rgba(255, 255, 255, 0.9)",
        padding: "2px 0",
        fontSize: "40%",
        position: "absolute",
        top: menuStyleTop ? menuStyleTop : "96px",
        left: menuStyleLeft ? menuStyleLeft : "0",
        overflow: "auto",
        width: "200px",
        maxHeight: "200px",
      }}
      getItemValue={(item) => item.name}
      items={data.organizations}
      renderItem={(item, isHighlighted) => (
        <div
          key={item.id}
          className="pa2 f6"
          style={{
            background: isHighlighted ? "lightgray" : "white",
            color: item.active ? "black" : "gray",
          }}
        >
          {item.name} {item.active ? "" : "(Inactive)"}
        </div>
      )}
      value={value}
      shouldItemRender={matchOrganizationtoTerm}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onSelect={(val, item) => {
        setValue(val);
        setItem(item);
        setOrganizationLocalStorage(base64Decode(item.id));
        setOrganization({ variables: { organizationId: item.id } });
      }}
    />
  );
}
export default AutocompleteOrganizationInput;
