import React from 'react';
import { gql, useQuery } from "@apollo/client";
import { environments } from "../../constants/environments";

import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";

import { 
  ApplicationCompletionRate, 
  CoverageByState,
  NumberOfContractors,
  TotalWages,
  AverageAssignmentDuration,
  AverageWages,
  TotalPremium,
  TotalGLPremium,
  AverageGLPremium,
  TotalWCPremium,
  AverageWCPremium
} from './Insights';

import MainWrapper from '../../components/MainWrapper';
import { shortFormDate as dateOptions } from "../../utils/date";

export const ANALYTICS_TOKEN_QUERY = gql`
  query AnalyticsToken {
    analyticsToken {
      token
    }
  }
`

class OverviewBase extends React.Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false
    }

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  render = () => {
    const organization = this.props.organization

    // consider fresh if at most 3 hours old
    let currentTimestamp = new Date()
    let orgCreatedTimestamp = new Date(organization.created+'Z')

    const THREE_HOURS = 1000 * (60 * 60 * 3)
  
    const isFreshOrg = organization && organization.created && (currentTimestamp - orgCreatedTimestamp < THREE_HOURS);

    if (!organization){
      return <div>Select an organization to get started.</div>
    }

    let start = new Date(organization.created)
    start.setMonth(start.getMonth() - 1)

    const dateRange = {
      'startMonthYear' : start.toLocaleDateString("en-US", dateOptions),
      'endMonthYear': new Date(Date.now() - 1000 * (60 * 2)).toLocaleDateString("en-US", dateOptions),
      'startDate': start.toISOString().split('T')[0],
      'endDate': new Date(Date.now() - 1000 * (60 * 2)).toISOString().split('T')[0]
    }

    return (
      <div>
        <MainWrapper isTestView={this.props.isTestView}>
          <header className="mb3">
            <h2 className="fw3 dark-gray mt0 mb4">Insights</h2>
          </header>

          <div className="mw9 center">
            <div className="cf ph2-ns">
              <div className="fl w-100 w-two-thirds-ns pv4 ph2 bt bl b--black-10"  style={{paddingBottom: "90px"}}>
                <ApplicationCompletionRate isFreshOrg={isFreshOrg} dateRange={dateRange} />
              </div>
              <div className="fl w-50 w-third-ns pv4 ph2 bt bl br b--black-10">
                <CoverageByState isFreshOrg={isFreshOrg} />
              </div>
            </div>
          </div>
          
          <div className="mw9 center">
            <div className="cf ph2-ns">
              <div className="fl w-100 w-third-ns pt4 pb5 ph2 bt bl b--black-10">
                <NumberOfContractors isFreshOrg={isFreshOrg} dateRange={dateRange} />
              </div>
              <div className="fl w-50 w-third-ns pt4 pb5 ph2 bt bl b--black-10">
                <TotalWages isFreshOrg={isFreshOrg} dateRange={dateRange} />
              </div>
              <div className="fl w-50 w-third-ns pt4 pb5 ph2 bt bl br b--black-10">
                <TotalPremium isFreshOrg={isFreshOrg} dateRange={dateRange} />
              </div>
            </div>
          </div>

          <div className="mw9 center">
            <div className="cf ph2-ns">
              <div className="fl w-100 w-third-ns pt4 pb5 ph2 bt bl bb b--black-10">
                <AverageAssignmentDuration isFreshOrg={isFreshOrg} dateRange={dateRange} />
              </div>
              <div className="fl w-50 w-third-ns pt4 pb5 ph2 bt bl bb b--black-10">
                <AverageWCPremium isFreshOrg={isFreshOrg} dateRange={dateRange} />
              </div>
              <div className="fl w-50 w-third-ns pt4 pb5 ph2 bt bl br bb b--black-10">
                <TotalWCPremium isFreshOrg={isFreshOrg} dateRange={dateRange} />
              </div>
            </div>
          </div>

          <div className="mw9 center">
            <div className="cf ph2-ns">
              <div className="fl w-100 w-third-ns pt4 pb5 ph2 bt bl bb b--black-10">
                <AverageWages isFreshOrg={isFreshOrg} dateRange={dateRange} />
              </div>
              <div className="fl w-50 w-third-ns pt4 pb5 ph2 bt bl bb b--black-10">
                <AverageGLPremium isFreshOrg={isFreshOrg} dateRange={dateRange} />
              </div>
              <div className="fl w-50 w-third-ns pt4 pb5 ph2 bt bl br bb b--black-10">
                <TotalGLPremium isFreshOrg={isFreshOrg} dateRange={dateRange} />
              </div>
            </div>
          </div>
        </MainWrapper>
      </div>
    )
  }
}

const OverviewPage = (props) => {
  const {data, loading} = useQuery(ANALYTICS_TOKEN_QUERY)

  if(loading){
    return(<></>)
  }

  const {
    analyticsToken
  } = data

  const currentEnvironment = localStorage.getItem("Ten99PolicyEnvironment") || environments.PRODUCTION

  const cubeJSProductionURL = process.env.REACT_APP_TEN99_ANALYTICS_URL_PRODUCTION
  const cubeJSSandboxURL = process.env.REACT_APP_TEN99_ANALYTICS_URL_SANDBOX
  const cubeJSInstance = currentEnvironment == environments.PRODUCTION ? cubeJSProductionURL : cubeJSSandboxURL

  const cubejsApi = cubejs(
    analyticsToken.token,
    {
      apiUrl: cubeJSInstance,
    }
  );

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <OverviewBase {...props} />
    </CubeProvider>
)}

export default OverviewPage
