import React from "react";

import { useCubeQuery } from "@cubejs-client/react";

import ChartLoading from "./ChartLoading";
import MapChart from "../../../components/USMap";


const CoverageByState = (props) => {
  const EmptyChart = () => (
    <MapChart
      label="Coverage by state"
      height="h5"
      activeStates={[]}
      dataKeyX="x"
      dataKeyLine="Policy.activeStates"
      formatType="number"
      staticNumber={0}
    />
  );
  
  const isFreshOrg = props.isFreshOrg;

  if (isFreshOrg) {
    return <EmptyChart />;
  }

  const pivotConfig = {
    x: ["Quote.ratingState"],
    y: ["measures"],
    fillMissingDates: true,
    joinDateRange: false,
  };

  const { resultSet, isLoading, error, progress } = useCubeQuery({
    measures: ["Policy.activeStates"],
    dimensions: ["Quote.ratingState"],
    order: {
      "Policy.activeStates": "desc",
    },
  });

  if (isLoading || !resultSet) {
    return <ChartLoading height={"h5"} />;
  }

  const activeStates = resultSet.tablePivot(pivotConfig);

  return <MapChart label="Coverage by state" activeStates={activeStates} />;
};

export default CoverageByState;
