import React from "react";

const AdminDisclaimer = () => (
  <div className="mb4 br2 flex items-center justify-center pa1 bg-lightest-blue navy">
    <p className="tc mv1 lh-copy">
      Admin pages are only visible to 1099Policy employees. Use the admin to
      test functionality and update information.
    </p>
  </div>
);

export default AdminDisclaimer;
