import React, {useState} from 'react';
import Autocomplete from "react-autocomplete";

import ReactTooltip from 'react-tooltip'
import {useLocation} from 'react-router-dom';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import MainWrapper from '../../components/MainWrapper';

import { states } from '../../constants/states';
import { centsToDollars } from '../../utils/currency'

const WC_LABEL = 'Workers Compensation'
const GL_LABEL = 'General Liability'

function matchStateToTerm(state, value) {
  return (
    (state.name !== null &&
      state.name.toLowerCase().indexOf(value.toLowerCase()) !==
        -1) ||
    (state.abbreviation !== null &&
      state.abbreviation.toLowerCase().indexOf(value.toLowerCase()) !==
        -1)
  );
}

function NetRateAutocomplete(props){
  const [value, setValue] = useState("");
  const [item, setItem] = useState(null);
  const { setAutoCompleteItem } = props;

  return (
    <Autocomplete
      wrapperProps={{ style: { display: "inline-block", width: "270px" } }}
      inputProps={{
        name: "netrateClassName",
        placeholder: "Select a state",
        className: "input-reset f6 ba b--black-20 pa2 w-100 mt3 mb4 db br2",
        type: "text",
      }}
      menuStyle={{
        borderRadius: "3px",
        boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
        background: "rgba(255, 255, 255, 0.9)",
        padding: "2px 0",
        fontSize: "40%",
        position: "absolute",
        top: "60px",
        left: "0",
        overflow: "auto",
        width: "200px",
        maxHeight: "200px",
      }}
      getItemValue={(item) =>
        `${item.name} (${item.abbreviation})`
      }
      items={states}
      renderItem={(item, isHighlighted) => (
        <div
          key={item.name}
          className="pa2 f6"
          style={{ background: isHighlighted ? "lightgray" : "white" }}
        >
          {item.name}
        </div>
      )}
      value={value}
      shouldItemRender={matchStateToTerm}
      onChange={(e) => {
        setValue(e.target.value);
        if (e.target.value === "") {
          setAutoCompleteItem(null);
        }
      }}
      onSelect={(val, item) => {
        setValue(val);
        setItem(item);
        setAutoCompleteItem(item);
      }}
    />
  )
}

function renderRateForState(state, rates, label){
  const rateForState = rates.filter(rate => rate.state === state);
  return (
    <div className="pv2">
      <div className="overflow-auto">
      <table className="f6 w-50 mw8" cellSpacing="0">
        <tbody className="lh-copy">
          <tr>
            <td className="fw6 pv1 pr3 mw4 dark-gray">{label}</td>
            <td className="pv1 pr3 tr">{rateForState[0]?.net_rate ?
              `${centsToDollars(rateForState[0]?.net_rate)} (per $100)` :
              `Unavailable in  ${state}`
            }
            </td>
          </tr>
        </tbody>
      </table>
      {
        label == GL_LABEL ? <small className="pt3 db">General liability net rate does not includes taxes and fees</small> : ''
      }
      </div>
    </div>
  );
}

const renderPendingNetRates = () => (
  <div>
    Net rates will be available when the job category is approved.
  </div>
)

function Job(props){
  let [idCopied, setIdCopied] = useState()
  const [autoCompleteItem, setAutoCompleteItem] = useState()
  const location = useLocation()

  const createdOn = new Date(location.state.created).toDateString();

  function afterHideTip() {
    setIdCopied(false)
  }
  
  return (
    <MainWrapper isTestView={props.isTestView}>
      <article className="dib w-100 mt3">
      <header className="fn fl-ns w-30-ns mb3 min-h-100">
      <h2 className="fw6 dark-gray mt0 mb2 pr1">
        {location.state.className ? location.state.className : 'Pending approval' }
      </h2>
      <CopyToClipboard text={location.state.code} onCopy={() => setIdCopied(true)}>
        <div data-tip data-for='contractor_id_copy' className="mv3 fw2 f6 gray ba br2 lh-copy dib pv1 ph2 b--black-10 pointer">
        {location.state.code}
        </div>
      </CopyToClipboard>
      <ReactTooltip 
        id='contractor_id_copy' 
        place='bottom'
        type='info'
        afterHide={afterHideTip} 
        effect='solid'>
        <span>{idCopied ? 'Copied!' : 'Copy'}</span>
      </ReactTooltip>
      <div className="mt3 fw2 f6 gray lh-copy db pv1 b--black-10">
        Created on: {createdOn}
      </div>
      <div className="fw2 f6 gray lh-copy db pv1 b--black-10">
        Review status: {location.state.reviewStatus}
      </div>
      
      </header>
      <div className="fn fl-ns w-70-ns">
        <div>
          <h3 className="dark-gray bb b--black-10 mt0 pb3">
            Job Description
          </h3>
          <div className="dark-gray f6 fw2">
          {location.state.jobSamples.edges.map((sample) => 
            <>
              <div className="pv2" key={sample.node.name}>
                {sample.node.name}
              </div>
              <div className="lh-copy" key={`${sample.node.name}-description`}>
                {sample.node.description}
              </div>
            </>
          )}
          </div>
        </div>
        <div className="mt5">
          <h3 className="dark-gray bb b--black-10 mt0 pb3">
            Net Rates
          </h3>
          <div className="gray f6 fw2 relative">
            {location.state.classId ? <NetRateAutocomplete setAutoCompleteItem={setAutoCompleteItem}/> : renderPendingNetRates()}
            {autoCompleteItem ? renderRateForState(autoCompleteItem.abbreviation, location.state.wcNetRates, WC_LABEL) : <></>}
            {autoCompleteItem ? renderRateForState(autoCompleteItem.abbreviation, location.state.glNetRates, GL_LABEL) : <></>}
          </div>
        </div>
      </div>
      </article>
    </MainWrapper>
  )
}

export default Job