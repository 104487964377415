// utils/pusher.js
import { useEffect } from "react";

export function usePusherChannel(pusher, channelName, eventName, eventHandler) {
  useEffect(() => {
    const channel = pusher.subscribe(channelName);
    channel.bind(eventName, eventHandler);

    // Clean up function
    return () => {
      channel.unbind(eventName, eventHandler);
      pusher.unsubscribe(channelName);
    };
  }, [pusher, channelName, eventName, eventHandler]);
}
