import React from "react";
import classNames from "classnames";

function Badge(props) {
  const { className } = props;

  return (
    <span
      className={classNames(
        `f7 link br2 ph2 pv1 dib lh-title v-mid ${className}`,
        {
          "blue bg-washed-blue": props?.status?.toLowerCase() === "processing",
        },
        {
          "green bg-washed-green":
            props?.status?.toLowerCase() === "reviewed" ||
            props.color === "green",
        },
        {
          "red bg-washed-red":
            props?.status?.toLowerCase() === "rejected" ||
            props.color === "red",
        },
        {
          "silver bg-light-gray":
            props?.status?.toLowerCase() === "pending" ||
            props.color === "silver",
        }
      )}
    >
      {props.status || props.children}
    </span>
  );
}

export default Badge;
