import { gql } from "@apollo/client";
import { CORE_TRACKER_FIELDS } from "./fragments";

const TRACKER_QUERY = gql`
  ${CORE_TRACKER_FIELDS}
  query Trackers($cursor: String) {
    trackers(first: 25, after: $cursor, sort: CREATED_DESC) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...CoreTrackerFields
        }
      }
    }
  }
`;

const TRACKER_BY_ID_QUERY = gql`
  ${CORE_TRACKER_FIELDS}
  query TrackerById($id: ID!) {
    tracker(id: $id) {
      ...CoreTrackerFields
    }
  } 
`;

export {
    TRACKER_QUERY,
    TRACKER_BY_ID_QUERY
}