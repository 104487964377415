import React from "react";
import ReactLoading from "react-loading";

const ChartLoading = ({ height }) => (
  <div className={`dt w-100 ${height}`}>
    <div className="dtc v-mid tc">
      <ReactLoading
        type={"spin"}
        color={"#cccccc"}
        className="center"
        width={24}
        height={24}
      />
    </div>
  </div>
);

export default ChartLoading;
