import React from "react";
const OnboardingHeader = (props) => (
  <div className="roboto">
    <nav className="flex justify-between bb b--white-10 ph3 pt3 pb2">
      <div className="flex items-center">
        <img
          className="w-80"
          src="/1099Policy-logo.svg"
          alt="1099Policy Logo"
        />
      </div>
      <div className="flex items-center f6 tr">
        <div onClick={props.onLogout}>
          <div className="ma0 mid-black link dim pointer w4 lh-copy">
            Sign Out
          </div>
        </div>
      </div>
    </nav>
    {props.children}
  </div>
);

export default OnboardingHeader;
