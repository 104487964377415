import React, { useState } from "react";

import { ComposableMap, Geographies, Geography } from "react-simple-maps";

import { scaleLinear } from "d3-scale";

import { geoStates } from "../constants/geoStates";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

export default function MapChart(props) {
  const [content, setContent] = useState("");

  const { label, activeStates } = props;
  const maxObj =
    activeStates && activeStates.length > 0 ? activeStates[0] : null;

  const maxCount = maxObj ? maxObj["Policy.activeStates"] : 0;

  const colorScale = scaleLinear()
    .domain([0, maxCount])
    .range(["#9ecae1", "#084594"]);

  return (
    <div>
      <div className="pl2 pb1 f6 fw5 black-50 nt3">{label}</div>
      <div className="pl2 f4 fw3">
        {content ? (
          content
        ) : (
          <span>
            {maxObj
              ? `${maxObj["Quote.ratingState"]} - ${maxObj["Policy.activeStates"]} (most active)`
              : "No data"}
          </span>
        )}
      </div>
      <ComposableMap projection="geoAlbersUsa">
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const cur = geoStates.find((s) => s.val === geo.id);
              const statePolicyCount = activeStates.find(
                (s) => s["Quote.ratingState"] === cur.id
              );
              const tooltipVal = statePolicyCount
                ? `${statePolicyCount["Quote.ratingState"]} - ${statePolicyCount["Policy.activeStates"]}`
                : `${cur.id} - 0`;

              return (
                <Geography
                  key={geo.rsmKey}
                  stroke="#FFF"
                  geography={geo}
                  onMouseEnter={() => {
                    setContent(tooltipVal);
                  }}
                  onMouseLeave={() => {
                    setContent("");
                  }}
                  fill={
                    statePolicyCount
                      ? colorScale(statePolicyCount["Policy.activeStates"])
                      : "#9ecae1"
                  }
                  style={{
                    hover: {
                      fill: "#084594",
                      outline: "none",
                    },
                    pressed: {
                      fill: "#E42",
                      outline: "none",
                    },
                  }}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
    </div>
  );
}
