const loadCheckout = (callback) => {
  const existingScript = document.getElementById("checkout");
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = "https://cdn.checkout.com/js/framesv2.min.js";
    script.id = "checkout";
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};
export default loadCheckout;
