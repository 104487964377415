import React, { Component, useState, useEffect } from "react";
import { useCSVReader } from "react-papaparse";
import StepWizard from "react-step-wizard";

import { columnKeys } from "../constants/bulkTemplateColumns";

import { startAndEnd } from "../utils/truncate";
import {
  removeEmpty,
  transformCsvData,
  handleResponseStatus,
  createRequestInit,
} from "../utils/csvHelpers";

import "../styles/wizard.css";

function CSVTransformStep(props) {
  const { CSVReader } = useCSVReader();

  const onUploadAccepted = (results) => {
    props.setCsvData(results.data);
    props.nextStep();
  };

  return (
    <CSVReader
      onUploadAccepted={onUploadAccepted}
      config={{
        header: true,
        skipEmptyLines: true,
      }}
    >
      {({ getRootProps, acceptedFile, ProgressBar }) => (
        <div className="dib w-100">
          <h2 className="f6 fw3 lh-copy mb4">
            Make a copy of the bulk import{" "}
            <a
              href={props.bulkImportTemplateUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              template
            </a>{" "}
            to get started. If you have questions, email support@1099policy.com.
          </h2>
          <div className="measure">
            <button
              className="f6 button-reset fl pv2 tc ba b--black bg-animate bg-black hover-bg-black-80 white pointer w-100 w-25-m w-30-l br2-ns br--left-ns"
              type="button"
              {...getRootProps()}
            >
              Browse & upload
            </button>
            <input
              className="mb3 f6 input-reset ba b--black-10 fl black-80 bg-white pa2 lh-solid w-100 w-75-m w-60-l br2-ns br--right-ns"
              value={acceptedFile ? acceptedFile.name : ""}
              readOnly
            />
            <ProgressBar style={{ backgroundColor: "green" }} />
          </div>
        </div>
      )}
    </CSVReader>
  );
}

function RenderSelect(props) {
  const [value, setValue] = useState(columnKeys[props.index]);
  const { headerLabel } = props;

  const handleChange = (event) => {
    setValue(event.target.value);
    props.onChangeSelect(props.headerLabel, event.target.value);
  };

  return (
    <select
      className="h2 pa1 f6 br1 br--right b--navy"
      value={value}
      onChange={handleChange}
    >
      <option value="none">Select an option</option>
      {columnKeys.map((key) => {
        return (
          <option className="f7" value={key} key={key + headerLabel.trim()}>
            {key}
          </option>
        );
      })}
    </select>
  );
}

function createMap(headerLabels) {
  let labelKeyMap = {};
  headerLabels.map((label, index) => {
    label != "" &&
      (labelKeyMap[label] = columnKeys?.[index]
        ? columnKeys[index]
        : columnKeys[0]);
  });

  return labelKeyMap;
}

function ColumnMappingStep(props) {
  const { csvData } = props;

  const headerLabels = csvData ? Object.keys(csvData[0]) : [];

  const onChangeSelect = (label, key) => {
    let newMap = {};
    newMap[label] = key;

    props.setColumnMap({
      ...props.columnMap,
      ...newMap,
    });
  };

  useEffect(() => {
    headerLabels.length !== 0 && props.setColumnMap(createMap(headerLabels));
  }, [csvData]);

  if (headerLabels.length === 0) {
    return (
      <div className="f6">
        Upload error - check that you've included a header row in your CSV.
      </div>
    );
  }
  return (
    <div>
      <div
        className="overflow-y-scroll bt bb b--black-20 pb4"
        style={{ height: "400px" }}
      >
        <h2 className="f6 fw3 lh-copy mb4">
          Check that the template column headers match the 1099Policy
          definitions.
        </h2>
        <div className="cf">
          <div className="f6 fw5 fl w-100 w-50-l pv2 ttu">Imported</div>
          <div className="f6 fw5 w-100 w-40-l dib pv2 pl2 ttu">
            1099Policy mapping
          </div>
        </div>
        {headerLabels.map(
          (label, idx) =>
            label != "" && (
              <div className="cf mv1" key={idx}>
                <div className="h2 lh-title f6 fl pa2 bg-lightest-blue bl bt bb b--navy navy w-100 w-50-l br1 br--left">
                  {startAndEnd(label, 40)}
                </div>
                <div className="w-100 w-40-l dib">
                  <RenderSelect
                    index={idx}
                    headerLabel={label}
                    onChangeSelect={onChangeSelect}
                  />
                </div>
              </div>
            )
        )}
      </div>
      <div className="fr mt2">
        <button
          type="button"
          onClick={() => props.nextStep()}
          className="f6 link dim br2 ph3 pv2 dib white bg-blue hover-bg-blue-80 bn pointer"
        >
          Confirm
        </button>
      </div>
    </div>
  );
}

function DataUploadStep(props) {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();
  const {
    client,
    token,
    resource_type,
    setImportResults,
    toggleImportStarted,
    toggleImportComplete,
    csvData,
    columnMap,
    previousStep,
  } = props;

  const DEFAULT_IMPORT_RESULTS = {
    processed: 0,
    success: 0,
    total_premium: 0,
    platform_access_fee: 0,
    failure: [],
  };

  async function refetchContractorAndShowResults() {
    await client.refetchQueries({
      include: ["Contractor"],
    });
    await toggleImportComplete();
  }

  const pollTask = (url, task_id, token) => {
    const getInit = createRequestInit("GET", token);

    const poll = () => {
      fetch(`${url}/${task_id}`, getInit)
        .then(handleErrors)
        .then((taskResponse) => {
          return taskResponse.json();
        })
        .catch((err) => {
          clearInterval(pollTimer);
          setImportResults(DEFAULT_IMPORT_RESULTS);
          refetchContractorAndShowResults();
          throw new Error({ url: url, err });
        })
        .then((taskData) => {
          if (taskData.task_status === "failed") {
            clearInterval(pollTimer);
            setImportResults(DEFAULT_IMPORT_RESULTS);
            refetchContractorAndShowResults();
          } else if (taskData.task_status === "finished") {
            clearInterval(pollTimer);
            setImportResults(taskData.import_results);
            refetchContractorAndShowResults();
          }
        });
    };
    const pollTimer = setInterval(() => poll(), 1500);
  };

  const onUpload = () => {
    const FILING = "filing";
    const BASE_URL = process.env.REACT_APP_TEN99_BASE_URL;
    const BULK_UPLOAD_PATH = "/api/v1/bulk/" + resource_type;
    const BULK_URL = `${BASE_URL}${BULK_UPLOAD_PATH}`;

    //Ignore empty rows i.e., ",,,," etc
    const cleanedCsvData = removeEmpty(csvData);
    const transformedData = transformCsvData(cleanedCsvData, columnMap);

    let resStatus = 0;

    const postInit = createRequestInit("POST", token);

    const bulkInit = {
      ...postInit,
      body: JSON.stringify({ list: transformedData }),
    };

    fetch(BULK_URL, bulkInit)
      .then((response) => {
        resStatus = response.status;
        return response.json();
      })
      .then((data) => {
        handleResponseStatus(
          resStatus,
          data,
          setIsLoading,
          setError,
          setImportResults,
          (data, setIsLoading) => {
            setData(data);
            setIsLoading(false);
            toggleImportStarted();
            if (resource_type == FILING) {
              toggleImportComplete();
              return;
            }
            pollTask(BULK_URL, data.task_id, token);
          }
        );
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <h2 className="f5 fw3 lh-copy mb4">
        This process may take time, depending on the number of contractors
        you're uploading. ☕
      </h2>
      <div className="fr mt5">
        <button
          type="button"
          onClick={() => previousStep()}
          className="f6 link dim br2 ba ph3 pv2 mh2 dib black pointer"
        >
          Back
        </button>
        <button
          type="button"
          onClick={() => onUpload()}
          className="f6 link dim br2 ph3 pv2 dib white bg-blue hover-bg-blue-80 bn pointer"
        >
          Complete upload
        </button>
      </div>
    </div>
  );
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

function CSVUpload(props) {
  const [csvData, setCsvData] = useState();
  const [columnMap, setColumnMap] = useState({});
  // Set default columnMapping to true if not provided
  const { columnMapping = true } = props;

  let custom = {
    enterRight: "animated enterRight",
    enterLeft: "animated enterLeft",
    exitRight: "animated exitRight",
    exitLeft: "animated exitLeft",
    intro: "animated intro",
  };

  return (
    <StepWizard transitions={custom}>
      <CSVTransformStep
        setCsvData={setCsvData}
        instructions={props.instructions}
        bulkImportTemplateUrl={props.bulkImportTemplateUrl}
      />
      {columnMapping ? (
        <ColumnMappingStep
          columnMap={columnMap}
          setColumnMap={setColumnMap}
          csvData={csvData}
        />
      ) : null}
      <DataUploadStep
        {...props}
        csvData={csvData}
        columnMap={columnMapping ? columnMap : null}
      />
    </StepWizard>
  );
}

export default CSVUpload;
