import React, {useEffect, useState, useMemo} from 'react';
import Skeleton from 'react-loading-skeleton';
import Pusher from 'pusher-js';
import classNames from 'classnames';

import { format } from 'date-fns';

import ReactLoading from 'react-loading';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import { useMutation, useQuery } from "@apollo/client";

import CustomBadge from '../CertificateReviewPage/CustomBadge';
import MainWrapper from '../../components/MainWrapper';
import TrackerModal from './TrackerModal';

import { resolveRedacted } from '../ContractorPage/helpers';
import AddCertificateModal from '../CertificateReviewPage/AddCertificateModal';

import { usePusherChannel } from '../../utils/pusher';
import { pusherEvents } from '../../constants/pusherEvents';

import { TRACKER_BY_ID_QUERY } from './queries';
import { REMOVE_ASSIGNMENTS_FROM_TRACKER } from './mutations';

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || "", {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER || "",
});

const renderError = (message) => (
  <div className="br2 f6 flex items-center justify-center pa2 bg-washed-red red mb3">
    <span className="lh-title ml3">{message}</span>
  </div>
)

const renderSuccess = (message) => (
  <div className="br2 f6 flex items-center justify-center pa2 bg-washed-green green mv3">
    <span className="lh-title ml3">{message}</span>
  </div>
)

function VendorTable(props){
  const { 
    data,
    trackerId,
    removeAssignmentFromTracker,
    openAddCertificateModal,
    uploadingCertificate,
    navigate,
  } = props;
  
  const [selectedAssignmentIds, setSelectedAssignmentIds] = useState([]);

  const headerStyle = {
    visibility: selectedAssignmentIds.length > 0 ? 'visible' : 'hidden',
  };

  function removeAssignment(trackerId, selectedAssignmentIds) {
    removeAssignmentFromTracker({
      variables: {
        trackerId: trackerId,
        assignmentIds: selectedAssignmentIds,
      },
    });
  }

  const renderHeader = (data) => {
    return (
      <div className="flex justify-end w-100" style={headerStyle}>
        <div className="flex-item">
          <button 
            onClick={() => removeAssignment(trackerId, selectedAssignmentIds)}
            className="f7 fw5 br2 ba b--black-20 pa2 ml1 pointer bg-white bg-animate hover-bg-light-gray v-btm"
          >
            <img className="dib pr2 v-mid" style={{width: '0.8rem'}} src="/user-minus.svg" alt="Remove user" />Remove
          </button>
          <button className="f7 fw5 br2 ba b--black-20 pa2 ml1 pointer bg-white bg-animate hover-bg-light-gray">
          <img className="dib pr2 v-mid" style={{width: '0.8rem'}} src="/send.svg" alt="Send message" />Send reminder
          </button>
        </div>
      </div>
    )
  }

  const handleCheckboxChange = (publicId, isChecked) => {
    if (isChecked) {
      // Add publicId to the selected list if not already included
      setSelectedAssignmentIds(prev => [...prev, publicId]);
    } else {
      // Remove publicId from the selected list
      setSelectedAssignmentIds(prev => prev.filter(id => id !== publicId));
    }
  };

  const renderCompanyAndName = (companyName, cn) => {
    let companyAndName = '';

    if (companyName){
      companyAndName = companyName
    }

    if (cn.firstName && cn.lastName) {
      companyAndName += companyAndName ? ` (${cn.firstName} ${cn.lastName})` : `${cn.firstName} ${cn.lastName}`;
    }
    
    return companyAndName;
  }

  function getMostRecentCertificateAudit(certificateAudits) {
    const sortedAudits = [...certificateAudits].sort((a, b) => {
      return new Date(b.created) - new Date(a.created);
    });
  
    return sortedAudits[0];
  }

  function TableShell(props) {
    return (
      <table className="w-100 ba b--light-gray collapse ph2 mv2">
        <thead>
          <tr className="bb bw1 b--black-10">
            <th className="ph2 w3"></th>
            <th className="tl w-30 ph2 fw5 f7 ttu w-25">Vendor (Contact name)</th>
            <th className="tl pv3 ph2 fw5 f7 ttu">Job Name</th>
            <th className="tl pv3 ph2 fw5 f7 ttu w-15">Review Status</th>
            <th className="tc pv3 ph2 fw5 f7 ttu w-25">Certificate</th>
            <th className="ph2"></th>
          </tr>
        </thead>
        <tbody>
          {props.children}
        </tbody>
      </table>
    )
  }

  const renderStatus = (publicId, mostRecentCertificateAudit) => {
    if (publicId === uploadingCertificate) {
      return(<Skeleton width={50} height={20} />)
    } 

    return (
      mostRecentCertificateAudit?.status &&
      <CustomBadge status={mostRecentCertificateAudit?.status} 
      />
    )
  }

  const renderPdfUrl = (publicId, pdfUrl) => {
    if (publicId === uploadingCertificate) {
      return(<Skeleton width={50} height={20} />)
    }
    
    return (
      pdfUrl ?
        <a className="dark-gray" href={pdfUrl} target="_blank" rel="noopener noreferrer">
          <img className="dib v-btm pr1 ph3 w1" src="/document.svg" alt="certificate-of-insurance" />
        </a> : null
    )
  }

  const renderRows = (data) => {
    const assignments = data?.assignments;
    if (assignments.length === 0) {
      return (
        <tr>
          <td colSpan="4" className="tc pv3">No tracked vendors.</td>
        </tr>
      )
    }
    
    const rows = assignments.edges?.map((row) => {
      const companyName = row?.node?.assignment?.contractor?.companyName;
      const resolvedContractor = resolveRedacted(row?.node?.assignment?.contractor, 
        JSON.parse(row?.node?.assignment?.contractor?.unredactedDict));  
      
      const publicId = row?.node?.assignment?.publicId;
      
      const allExternalCertificates = row?.node?.assignment?.contractor?.externalCertificates?.edges
      const certificateAudits = allExternalCertificates.flatMap(
        (externalCertificate) => externalCertificate.node.certificateAudits.edges.map(
          (auditEdge) => auditEdge.node
        )
      )
      const mostRecentCertificateAudit = getMostRecentCertificateAudit(certificateAudits);
      const pdfUrl = mostRecentCertificateAudit?.certificate?.pdfUrl;
      
      const handleClick = () => {
        navigate(`/certificates/${mostRecentCertificateAudit?.publicId}`, {state: {'node': mostRecentCertificateAudit}})
      }

      return (
        <tr 
          key={publicId}
          className={classNames(
            "bb b--light-gray hover-bg-washed-blue",
            {"pointer": mostRecentCertificateAudit},
           )}
          onClick={mostRecentCertificateAudit ? handleClick : null}  
        >
          <td className="tc pv3 w3">
            <input 
              type="checkbox" 
              checked={selectedAssignmentIds.includes(publicId)}
              onChange={(e) => handleCheckboxChange(publicId, e.target.checked)}
            />
          </td>
          <td className="ph2 tl pv3 f7 w-25">{renderCompanyAndName(companyName, resolvedContractor)}</td>
          <td className="ph2 tl pv3 f7">{row?.node?.assignment?.job?.name}</td>
          <td className="ph2 tl pv3 f7 w-15"> 
            {renderStatus(publicId, mostRecentCertificateAudit)}
          </td>
          <td className="ph2 tc pv3 f7">
            {renderPdfUrl(publicId, pdfUrl)}
          </td>
          <td className="ph2 tc pv3 f7">
            <button 
              type="button" 
              onClick={(e) => {
                e.stopPropagation()
                openAddCertificateModal(resolvedContractor, publicId)
              }}
              className="outline-0 pointer br2 ba b--black-20 bg-white pv2 ph3 ml1 f7 lh-title bg-animate hover-bg-light-gray border-box">
              <img className="dib v-btm" style={{width: '0.8rem'}} src="/upload.svg" alt="Upload" />
            </button>
          </td>
        </tr>
        )
    });
    
    return rows;
  }

  return (
    <div>
      {renderHeader()}
      <TableShell data={data}>
        {renderRows(data)}
      </TableShell>
    </div>
  )
}

function TrackerDetail(props){
  const initialState = {
    isOpen: false,
    contractorInfo: null,
  };

  const [addCertificateModalState, setAddCertificateModalState] = useState(initialState)

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const [uploadingCertificate, setUploadingCertificate] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const { trackerId } = useParams();
  const [data, setData] = useState(null);

  const ADD_CERTIFICATE_STEP = 2;

  const ADDITIONAL_INSURED = 'ADDITIONAL_INSURED';
  const TRACKER_DATES = 'TRACKER_DATES';

  const { loading, error, data: queryData, fetchMore, refetch } = useQuery(TRACKER_BY_ID_QUERY, {
    variables: { id: trackerId }, // replace with your actual contractor ID
    fetchPolicy: 'cache-and-network', // or 'cache-first' for initial load from cache then network
    skip: location.state !== null, // skip the query if location.state is not undefined
  });
  
  const channelName = `certificate_audit-${props.orgId}`;
  
  usePusherChannel(
    pusher,
    channelName,
    pusherEvents.AUDIT_REVIEW_COMPLETE,
    function (data) {
      if (data) {
        refetch()
        setUploadingCertificate(null)
      }
    }
  );


  const [removeAssignmentFromTracker, {loading: removeAssignmentLoading, reset }] = useMutation(REMOVE_ASSIGNMENTS_FROM_TRACKER, {
    onCompleted: (data) => {
      if (data.removeAssignmentFromTracker.ok) {
        setSuccessMessage('Assignments removed successfully');
      } else {
        setErrorMessage('Failed to remove vendor from tracker.');
      }
    },
    onError: (error) => {
      setErrorMessage('Failed to remove vendor from tracker.');
    },
    refetchQueries: ['TrackerById']
  });

  useEffect(() => {
    if (location.state) {
      setData(location.state);    
      // Navigate to the same path without state to clear it
      navigate(location.pathname, { replace: true });
    }
    else if (!loading && !error) {
      setData(queryData?.tracker);
    }
  }, [navigate, location, loading, error, queryData]);

  if (loading || !data){
    return (
        <>
          <MainWrapper isTestView={props.isTestView}>
            <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
          </MainWrapper>
        </>
      )
  }

  function renderAdditionalInsuredCards(customMetadata) {
    const additionalInsureds = customMetadata.edges.find(item => item?.node?.key === ADDITIONAL_INSURED);
    if (!additionalInsureds) return null;

    const values = JSON.parse(additionalInsureds?.node?.value);
    
    return (
      <div className="flex overflow-x-scroll pv3">
        {values.map((insured, index) => (
          <div key={index} className="pa2 mr3 ba b--black-10 br-pill">
            <div className="lh-copy pv0 mv0 black-50 f7">
            <b className="black">{insured.name}</b>&nbsp;{`${insured.line1}${insured.line2 ? `, ${insured.line2}` : ''}${insured.line3 ? `, ${insured.line3}` : ''}, ${insured.locality}, ${insured.region} ${insured.postalcode}`}
            </div>
          </div>
        ))}
      </div>
    );
  }

  function formatTrackerDates(customMetadata) {
    const trackerDates = customMetadata.edges.find(item => item?.node?.key === TRACKER_DATES);
    if (!trackerDates) return '';
  
    const dates = JSON.parse(trackerDates?.node?.value);
    const effectiveDate = format(new Date(dates.effectiveDate), 'MMM dd');
    const endDate = format(new Date(dates.endDate), 'dd, yyyy');
  
    return ` (${effectiveDate} – ${endDate})`;
  }

  // Function to handle back navigation
  const handleBackClick = () => {
    navigate(-1);
  };

  const openAddCertificateModal = (contractor, assignmentId) => {
    setAddCertificateModalState({
      isOpen: true,
      contractorInfo: contractor,
      assignmentId: assignmentId,
    });
  }

  const onSuccessfulUpload = (successMessage) => {
    setSuccessMessage(successMessage);
    setUploadingCertificate(addCertificateModalState.assignmentId);
  }
  
  return (
    <>
      <MainWrapper isTestView={props.isTestView}>
        <div className="flex-item content-center absolute top-1 left-1">
          <div className="back-arrow" onClick={handleBackClick}>
            <span className="f6 hover-bg-light-gray pointer pv2 ph3 br4">← Back</span>
          </div>
        </div>

        <article className="dib w-100">
        
        {successMessage && renderSuccess(successMessage)}
        {errorMessage && renderError(errorMessage)}
        
          <header className="mt4">
            <h2 className="flex justify-between fw3 dark-gray mt0 mb0">
              <div className="flex-item">
                {data.name}<span className="black-50">{formatTrackerDates(data.customMetadata)}</span>
                <span className="f7 db pv2">
                  {data.description}
                </span>
                <span>
                  {renderAdditionalInsuredCards(data.customMetadata)}
                </span>
              </div>
              <div>
                <button 
                  onClick={() => setModalIsOpen(true)}
                  className="f7 fw5 br2 ba b--black-20 pa2 ml1 pointer bg-white bg-animate hover-bg-light-gray">
                  Edit
                </button>
              </div>
            </h2>
          </header>
          <VendorTable 
            data={data} 
            trackerId={trackerId}
            removeAssignmentFromTracker={removeAssignmentFromTracker}
            openAddCertificateModal={openAddCertificateModal}
            uploadingCertificate={uploadingCertificate}
            navigate={navigate}
          />
        </article>
      </MainWrapper>
      <TrackerModal
        trackerData={data}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
        pageHeaderLabel={'Update Tracker'} 
        hideModalSteps={true}
        requestTypeStr={'PUT'}
        trackerId={trackerId}
        {...props}
      />
      <AddCertificateModal 
        closeModal={() => {
          setAddCertificateModalState({
            isOpen: false,
            contractorInfo: null,
          });
        }}
        modalIsOpen={addCertificateModalState.isOpen}
        contentLabel={"Add a Certificate"} 
        setErrorMessage={setErrorMessage}
        setSuccessMessage={onSuccessfulUpload}
        token={props.token}
        uploadOnly={true}
        selectedStep={ADD_CERTIFICATE_STEP}
        contractor={addCertificateModalState.contractorInfo}
      />
    </>
  )
}

export default TrackerDetail;