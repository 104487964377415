import React, { useState, useContext } from 'react'

import { useNavigate } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { useValidPassword } from '../../hooks/useAuthHooks'
import { Password } from '../../components/AuthComponents'

import { AuthContext } from '../../contexts/authContext'

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
})

export default function ChangePassword() {
  const classes = useStyles()

  const [error, setError] = useState('')
  const [reset, setReset] = useState(false)

  const {
    password: oldPassword,
    setPassword: setOldPassword,
    passwordIsValid: oldPasswordIsValid,
  } = useValidPassword('')

  const {
    password: newPassword,
    setPassword: setNewPassword,
    passwordIsValid: newPasswordIsValid,
  } = useValidPassword('')

  const isValid = !oldPasswordIsValid || oldPassword.length === 0 || !newPasswordIsValid || newPassword.length === 0

  const navigate = useNavigate()

  const authContext = useContext(AuthContext)

  const changePassword = async () => {
    try {
      await authContext.changePassword(oldPassword, newPassword)
      setReset(true)
    } catch (err: any) {
      setError(err.message)
    }
  }

  const signOut = async () => {
    try {
      await authContext.signOut()
      navigate('/')
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }

  const updatePassword = (
    <>
      <Password 
        label="Old Password" 
        placeholder="8+ characters (upper, lower, number, and special)"
        className="input-reset ba b--black-20 pa2 mt2 mb3 db w-100 br2"
        passwordIsValid={oldPasswordIsValid} 
        setPassword={setOldPassword} 
      />
    
      <Password 
        label="New Password"
        className="input-reset ba b--black-20 pa2  mt2 mb3 db w-100 br2"
        passwordIsValid={newPasswordIsValid} 
        setPassword={setNewPassword} />
        
      {/* Error */}
      
      <Typography color="error" variant="body2">
        {error}
      </Typography>
      

      {/* Buttons */}
      <button 
        className="f6 link dim br2 ph3 pv2 mt3 dib black bn pointer white bg-blue" 
        disabled={isValid}
        onClick={changePassword}
        type="submit">
        Set new password
      </button>
      {/* <Button disabled={isValid} color="primary" variant="contained" onClick={changePassword}>
        Change Password
      </Button> */}  
    </>
  )

  const passwordReset = (
    <>
      <Typography variant="h5">{`Password Changed`}</Typography>

      <Box m={4}>
        <Button onClick={signOut} color="primary" variant="contained">
          Sign In
        </Button>
      </Box>
    </>
  )

  return (<div>{!reset ? updatePassword : passwordReset}</div>
    
  )
}
