import { gql } from "@apollo/client";

export const LOGOUT_FROM_BACKEND = gql`
  mutation Logout {
    logout {
      ok
    }
  }
`

export const SET_ORGANIZATION = gql`
  mutation SetOrganization(
    $organizationId: ID!,
  ){
    setOrganization(
      organizationId: $organizationId,
    ){
      ok
      message
    }
  }
`

export const ACCEPT_OR_DENY_INVITATION = gql`
  mutation AcceptOrDenyOrganizationInvitation(
    $invitationId: Int!
    $accept: Boolean!
  ) {
    acceptOrDenyOrganizationInvitation(
      invitationId: $invitationId
      accept: $accept
    ) {
      ok
      message
    }
  }
`;

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization(
    $name: String!,
  ){
    createOrganization(
      name: $name,
    ){
      ok
      message
    }
  }
`

export const JOIN_ORGANIZATION = gql`
  mutation JoinOrganization(
    $name: String!,
  ){
    joinOrganization(
      name: $name,
    ){
      ok
    }
  }
`;

export const SEND_USER_INVITATION = gql`
  mutation SendOrganizationInvite($email: String!, $role: String!) {
    sendOrganizationInvite(email: $email, role: $role) {
      ok
      message
    }
  }
`;

export const LEAVE_ORGANIZATION = gql`
  mutation LeaveOrganization($organizationId: String!) {
    leaveOrganization(organizationId: $organizationId) {
      ok
      message
    }
  }
`;

export const REMOVE_USER_FROM_ORGANIZATION = gql`
  mutation RemoveUserFromOrganization($organizationId: String!, $userId: Int!) {
    removeUserFromOrganization(organizationId: $organizationId, userId: $userId) {
      ok
      message
    }
  }
`;

export const RESEND_ORGANIZATION_INVITATION = gql`
  mutation ResendOrganizationInvite($organizationId: String!, $invitationId: Int!) {
    resendOrganizationInvite(organizationId: $organizationId, invitationId: $invitationId) {
      ok
      message
    }
  }
`;

export const UPDATE_ORGANIZATION_USER_ROLE = gql`
  mutation UpdateOrganizationUserRole($organizationId: String!, $userId: Int!, $newRole: String!) {
    updateOrganizationUserRole(organizationId: $organizationId, userId: $userId, newRole: $newRole) {
      ok
      message
    }
  }
`;