import React, { useState, useEffect, useRef } from "react";
import { useLocation, Route, Routes, NavLink } from "react-router-dom";

export const Tabs = ({ children }) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");
  const [indicatorStyle, setIndicatorStyle] = useState({});
  const tabRefs = useRef([]);

  useEffect(() => {
    const currentPath = location.pathname;
    const activeChild = React.Children.toArray(children).find((child) =>
      currentPath.startsWith(child.props.navPath)
    );

    if (activeChild) {
      setActiveTab(currentPath);
      updateIndicator(currentPath);
    }
  }, [location, children]);

  const changeTab = (newTab) => {
    updateIndicator(newTab);
  };

  const updateIndicator = (currentPath) => {
    const activeTabRef = tabRefs.current.find(
      (ref) => ref && currentPath.startsWith(ref.dataset.url)
    );
    if (activeTabRef) {
      setIndicatorStyle({
        left: activeTabRef.offsetLeft,
        width: activeTabRef.offsetWidth,
      });
    }
  };

  return (
    <div className="tabs">
      <ul className="list flex ma0 pa0 bb b--black-10 relative">
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child, {
            isActive: child.props.navPath === activeTab,
            ref: (el) => (tabRefs.current[index] = el),
          })
        )}
        <div className="indicator" style={indicatorStyle} />
      </ul>
      <div className="tab-content mt4">
        <Routes>
          {React.Children.map(children, (child) => (
            <Route
              key={child.props.routePath}
              path={child.props.routePath}
              element={child.props.children}
            />
          ))}
        </Routes>
      </div>
    </div>
  );
};

export const Tab = React.forwardRef(({ label, navPath }, ref) => {
  return (
    <li
      key={label}
      className={"pointer pv2 ph3"}
      ref={ref}
      data-label={label}
      data-url={navPath}
    >
      <NavLink
        to={navPath}
        className={"pv2 ph3 link no-underline dark-gray hover-black-50"}
        end
      >
        {label}
      </NavLink>
    </li>
  );
});
