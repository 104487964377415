import { gql } from '@apollo/client';

export const CORE_TRACKER_FIELDS = gql`
  fragment CoreTrackerFields on Tracker {
    publicId
    created
    name
    description
    customMetadata {
      edges {
        node {
          key
          value
        }
      }
    }
    assignments {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          assignment {
            publicId
            effectiveDate
            endDate
            totalPremiumOwed
            certificate {
              edges {
                node {
                  wcCoiPdfUrl
                  glCoiPdfUrl
                }
              }
            }
            contractor {
              publicId
              email
              created
              firstName
              lastName
              companyName
              address {
                line1
                line2
                line3
                locality
                region
                postalcode
              }
              unredactedDict
              externalCertificates {
                edges {
                  node {
                    certificateAudits {
                      edges {
                        node {
                          publicId
                          created
                          status
                          certificate {
                            filename
                            pdfUrl
                          }
                          parsedCertificateJson
                          auditResults {
                            edges {
                              node {
                                ruleJson
                                resultJson
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              } 
            }
            job {
              publicId
              name
              description 
            }
          }
        }
      }
    }
  }
`;