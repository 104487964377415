const COVERAGE_RULE_MAP = {
  "generalLiabilityOccurrenceLimit": "coverages.commercial_general_liability.limits.each_occurrence_dollars",
  "generalLiabilityDamageToRentedLimit": "coverages.commercial_general_liability.limits.damage_to_rented_premises_dollars",
  "generalLiabilityMedExpLimit": "coverages.commercial_general_liability.limits.medical_expense_dollars",
  "generalLiabilityPersonalInjuryLimit": "coverages.commercial_general_liability.limits.personal_injury_dollars",
  "generalLiabilityGeneralAggregateLimit": "coverages.commercial_general_liability.limits.general_aggregate_dollars",
  "generalLiabilityProductsCompAggregateLimit": "coverages.commercial_general_liability.limits.products_comp_aggregate_dollars",
  "generalWaiverOfSubrogation": "coverages.commercial_general_liability.subr_wvd",
  "generalAdditionalInsured": "coverages.commercial_general_liability.addl_insd",
  "generalAggregateAppliesPer": {
    "project": "coverages.commercial_general_liability.aggregate_applies_per.project",
    "policy": "coverages.commercial_general_liability.aggregate_applies_per.policy",
    "location": "coverages.commercial_general_liability.aggregate_applies_per.loc",
  },
  "generalPolicyType": {
    "claimsMade": "coverages.commercial_general_liability.policy_type.claims_made",
    "occurrence": "coverages.commercial_general_liability.policy_type.occurrence",
  },
  "generalPolicyExpirationDate": "coverages.commercial_general_liability.policy_expiration_date",
  "workersCompPerStatuteLimit": "coverages.workers_compensation.limits.per_statute",
  "workersCompOtherLimit": "coverages.workers_compensation.limits.other",
  "workersCompEachAccidentLimit": "coverages.workers_compensation.limits.el_each_accident_dollars",
  "workersCompDiseaseEachEmployeeLimit": "coverages.workers_compensation.limits.el_disease_each_employee_dollars",
  "workersCompDiseasePolicyLimit": "coverages.workers_compensation.limits.el_disease_policy_limit_dollars",
  "workersCompWaiverOfSubrogation": "coverages.workers_compensation.subr_wvd",
  "workersCompPolicyType": {
    "perStatute": "coverages.workers_compensation.policy_type.per_statute",
    "other": "coverages.workers_compensation.policy_type.other",
  },
  "workersCompPolicyExpirationDate": "coverages.workers_compensation.policy_expiration_date",
  "professionalLiabilityOccurrenceLimit": "coverages.professional_liability.limits.each_occurrence_dollars",
  "professionalLiabilityAggregateLimit": "coverages.professional_liability.limits.aggregate_dollars",
  "professionalWaiverOfSubrogation": "coverages.professional_liability.subr_wvd",
  "professionalAdditionalInsured": "coverages.professional_liability.addl_insd",
  "professionalPolicyExpirationDate": "coverages.professional_liability.policy_expiration_date",
  "autoLiabilityCombinedSingleLimit": "coverages.auto_liability.limits.combined_single_limit_dollars",
  "autoLiabilityBodilyInjuryPerAccidentLimit": "coverages.auto_liability.limits.bodily_injury_per_accident_dollars",
  "autoLiabilityBodilyInjuryPerPersonLimit": "coverages.auto_liability.limits.bodily_injury_per_person_dollars",
  "autoLiabilityPropertyDamageLimit": "coverages.auto_liability.limits.property_damage_dollars",
  "autoWaiverOfSubrogation": "coverages.auto_liability.subr_wvd",
  "autoAdditionalInsured": "coverages.auto_liability.addl_insd",
  "autoCoverageType": {
    "ownedAuto": "coverages.automobile_liability.all_owned_auto",
    "hiredAuto": "coverages.automobile_liability.hired_auto",
    "scheduledAuto": "coverages.automobile_liability.scheduled_auto",
    "nonOwnedAuto": "coverages.automobile_liability.non_owned_auto",
  },
  "autoPolicyExpirationDate": "coverages.automobile_liability.policy_expiration_date",
  "umbrellaLiabilityOccurrenceLimit": "coverages.excess_umbrella_liability.limits.each_occurrence_dollars",
  "umbrellaLiabilityAggregateLimit": "coverages.excess_umbrella_liability.limits.aggregate_dollars",
  "umbrellaWaiverOfSubrogation": "coverages.excess_umbrella_liability.subr_wvd",
  "umbrellaAdditionalInsured": "coverages.excess_umbrella_liability.addl_insd",
  "umbrellaPolicyType": {
    "claimsMade": "coverages.excess_umbrella_liability.policy_type.claims_made",
    "occurrence": "coverages.excess_umbrella_liability.policy_type.occurrence",
  },
  "umbrellaPolicyExpirationDate": "coverages.excess_umbrella_liability.policy_expiration_date",
  "excessLiabilityOccurrenceLimit": "coverages.excess_liability.limits.each_occurrence_dollars",
  "excessLiabilityAggregateLimit": "coverages.excess_liability.limits.aggregate_dollars",
  "excessWaiverOfSubrogation": "coverages.excess_liability.subr_wvd",
  "excessAdditionalInsured": "coverages.excess_liability.addl_insd",
  "excessPolicyType": {
    "claimsMade": "coverages.excess_liability.policy_type.claims_made",
    "occurrence": "coverages.excess_liability.policy_type.occurrence",
  },
  "excessPolicyExpirationDate": "coverages.excess_liability.policy_expiration_date",
  "cyberLiabilityOccurrenceLimit": "coverages.cyber_liability.limits.each_occurrence_dollars",
  "cyberLiabilityAggregateLimit": "coverages.cyber_liability.limits.aggregate_dollars",
  "cyberWaiverOfSubrogation": "coverages.cyber_liability.subr_wvd",
  "cyberAdditionalInsured": "coverages.cyber_liability.addl_insd",
  "cyberPolicyExpirationDate": "coverages.cyber_liability.policy_expiration_date",
  "crimeLiabilityOccurrenceLimit": "coverages.crime_liability.limits.each_occurrence_dollars",
  "crimeLiabilityAggregateLimit": "coverages.crime_liability.limits.aggregate_dollars",
  "crimeWaiverOfSubrogation": "coverages.crime_liability.subr_wvd",
  "crimeAdditionalInsured": "coverages.crime_liability.addl_insd",
  "crimePolicyExpirationDate": "coverages.crime_liability.policy_expiration_date",
  "insuredName": "insured.name",
  "entityName": "certificate_holder.name",
  "descriptionOfOperations": "description_of_operations.full_text",
  "additionalInsured": "description_of_operations.additional_insured_names",
}

// Helper function to determine if a value is a number
function isNumber(value) {
  return !isNaN(parseInt(value));
}

//TODO (rgv): Update this to be more maintainable
function getCondition(rule_name, value) {
  if (rule_name == 'description_of_operations.full_text'){
    return 'is_content_match_in_substance'
  } else if (rule_name == 'description_of_operations.additional_insured_names'){
    return 'is_content_match_in_substance'
  }
  return isNumber(value) ? 'greater_than_or_equal_to' : 'is_true'
}

function getValue(rule_name, value) {
  if (rule_name == 'description_of_operations.full_text'){
    return value
  } else if (rule_name == 'description_of_operations.additional_insured_names'){
    return JSON.stringify(value)
  }
  return isNumber(value) ? parseInt(value) : ''
}

// Helper function to create a rule object
function createRuleObject(rule_name, value) {
  const condition = getCondition(rule_name, value);
  const ruleObject = {
    ruleName: rule_name,
    ruleJson: JSON.stringify({
      path: rule_name,
      condition: condition,
      value: getValue(rule_name, value)
    })
  };

  // Convert the rule object to a JSON string
  return ruleObject;
}

// Main function to map values to rules
export function mapValuesToRules(values) {
  const output = [];
  for (let key in values) {
    // Check if the key exists in the COVERAGE_RULE_MAP
    if (COVERAGE_RULE_MAP.hasOwnProperty(key)) {
      const value = values[key];
      
      // Skip the current iteration if the value is undefined, an empty string, or null
      if (value === undefined || value === '' || value === null) continue;
      
      // If the value is an array, map each item to a rule name, otherwise use the value as a rule name
      let ruleNames = Array.isArray(value) ? value.map(item => COVERAGE_RULE_MAP[key][item]) : [COVERAGE_RULE_MAP[key]];

      // Iterate over the rule names
      ruleNames.forEach(ruleName => {
        if (!ruleName) {
          ruleName = COVERAGE_RULE_MAP[key];
        }
        // If the value is a string and exists as a key in the rule name, use it to access the rule name
        if (typeof value === 'string' && ruleName[value]) {
          ruleName = ruleName[value];
        }
        // Push a new rule object to the output array
        output.push(createRuleObject(ruleName, value));
      });
    }
  }
  
  return output;
}

// Helper function to recursively find a key in the COVERAGE_RULE_MAP
function findKeyInCoverageRuleMap(path, map, parentKey = null) {
  for (const key in map) {
    if (typeof map[key] === 'object') {
      const result = findKeyInCoverageRuleMap(path, map[key], key);
      if (result) {
        return result;
      }
    } else if (map[key] === path) {
      return { parentKey: parentKey || key, key };
    }
  }
  return null;
}

// Main function to map rules to values
export function mapRulesToValues(rules) {
  const output = {};

  // Check if rules is null or undefined
  if (!rules) {
    return output;
  }

  rules.forEach(rule => {
    // Parse the rule JSON
    const ruleJson = JSON.parse(rule.ruleJson);

    // Split the rule path into parts
    const rulePathParts = ruleJson.path.split('.');

    // Use the last part of the rule path as the value
    const value = rulePathParts[rulePathParts.length - 1];

    // Find the original key that corresponds to the rule path
    const originalKey = findKeyInCoverageRuleMap(ruleJson.path, COVERAGE_RULE_MAP);

    // If the original key was found, map it to its original value
    if (originalKey) {
      // If the condition is "is_true" and the value is an empty string, return the last part of the path
      if (ruleJson.condition === 'is_true' && ruleJson.value === '') {
        output[originalKey.parentKey] = value;
      } else if (ruleJson.condition === 'is_content_match_in_substance') {
        output[originalKey.parentKey] = ruleJson.value.toString();
      } else {
        output[originalKey.parentKey] = isNumber(ruleJson.value) ? ruleJson.value.toString() : value;
      }
    }
  });
  return output;
}

export function getCoveragesFromRules(rules) {
  const coverages = [];

  // Check if rules is null or undefined
  if (!rules) {
    return coverages;
  }

  const coverageLabelMap = {
    'commercial_general_liability': 'general',
    'workers_compensation': 'workers-comp',
    'professional_liability': 'professional',
    'cyber_liability': 'cyber',
    'auto_liability': 'auto',
    'excess_umbrella_liability': 'umbrella',
    'excess_liability': 'excess',
    'crime_liability': 'crime'
  };

  rules.forEach(rule => {
    const ruleJson = JSON.parse(rule.ruleJson);
    const rulePathParts = ruleJson.path.split('.');

    // The coverage type is the second part of the rule path
    const coverageType = rulePathParts[1];

    if (coverageLabelMap[coverageType] && !coverages.includes(coverageLabelMap[coverageType])) {
      coverages.push(coverageLabelMap[coverageType]);
    }
  });

  return coverages;
}

export function getAutoCoveragesFromRules(rules) {
  const coverages = [];

  // Check if rules is null or undefined
  if (!rules) {
    return coverages;
  }

  const coverageMap = {
    'all_owned_auto': 'ownedAuto',
    'hired_auto': 'hiredAuto',
    'scheduled_auto': 'scheduledAuto',
    'non_owned_auto': 'nonOwnedAuto'
  };

  rules.forEach(rule => {
    const ruleJson = JSON.parse(rule.ruleJson);
    const rulePathParts = ruleJson.path.split('.');

    // The coverage type is the second part of the rule path
    const coverageType = rulePathParts[1];

    // The specific auto coverage is the third part of the rule path
    const autoCoverage = rulePathParts[2];

    if ((coverageType === 'automobile_liability') && coverageMap[autoCoverage] && !coverages.includes(coverageMap[autoCoverage])) {
      coverages.push(coverageMap[autoCoverage]);
    }
  });

  return coverages;
}

export function getResultsByCoverageGroup(data) {
  const pathMappings = {
    "insured": "insured",
    "certificate_holder": "entity",
    "coverages": {
      "commercial_general_liability": "general",
      "workers_compensation": "workersComp",
      "automobile_liability": "auto",
      "excess_umbrella_liability": "umbrella",
      "excess_liability": "excess",
      "cyber_liability": "cyber",
      "crime_liability": "crime",
      "professional_liability": "professional"
    }
  };

  let coverageTypes = {};

  if (!data?.edges) {
    return coverageTypes;
  }

  const getPathMapping = (pathParts, mappings) => {
    const mapping = mappings[pathParts[0]];
    if (typeof mapping === 'string') {
      return mapping;
    } else if (typeof mapping === 'object' && pathParts.length > 1) {
      return getPathMapping(pathParts.slice(1), mapping);
    } else if (typeof mapping === 'object') {
      return pathParts[0];
    } else {
      return null;
    }
  };

  data?.edges?.forEach(edge => {
    const ruleJson = JSON.parse(edge.node.ruleJson);
    const resultJson = JSON.parse(edge.node.resultJson);
    const pathParts = ruleJson.path.split(".");
    const coverageTypeKey = getPathMapping(pathParts, pathMappings);
    const meetsCoverageRules = resultJson.result !== "fail";

    // Check if this coverage type is already in the object
    if (coverageTypeKey in coverageTypes) {
      // If it is, update the meetsCoverageRules property if necessary
      if (!meetsCoverageRules) {
        coverageTypes[coverageTypeKey].meetsCoverageRules = false;
      }
    } else {
      // If it's not, add it to the object
      coverageTypes[coverageTypeKey] = { meetsCoverageRules };
    }
  });
  
  return coverageTypes;
}

const FIELD_TO_RULE_MAP = {
    "glPolicyNumber": "coverages.commercial_general_liability.policy_number_string",
    "glInsurer": "coverages.commercial_general_liability.insurer_name",
    "glPolicyLimit.eachOccurrence": "coverages.commercial_general_liability.limits.each_occurrence_dollars",
    "glPolicyLimit.damageToRented": "coverages.commercial_general_liability.limits.damage_to_rented_premises_dollars",
    "glPolicyLimit.medExp": "coverages.commercial_general_liability.limits.medical_expense_dollars",
    "glPolicyLimit.personalInjury": "coverages.commercial_general_liability.limits.personal_injury_dollars",
    "glPolicyLimit.generalAggregate": "coverages.commercial_general_liability.limits.general_aggregate_dollars",
    "glPolicyLimit.productsCompAggregate": "coverages.commercial_general_liability.limits.products_comp_aggregate_dollars",
    "glWaiverOfSubrogation": "coverages.commercial_general_liability.subr_wvd",
    "glAdditionalInsured": "coverages.commercial_general_liability.addl_insd",
    "glPolicyType.claims_made": "coverages.commercial_general_liability.policy_type.claims_made",
    "glPolicyType.occurrence": "coverages.commercial_general_liability.policy_type.occurrence",
    "glPolicyEffectiveDate": "coverages.commercial_general_liability.policy_effective_date",
    "glPolicyExpirationDate": "coverages.commercial_general_liability.policy_expiration_date",
    "workersCompPerStatuteLimit": "coverages.workers_compensation.limits.each_occurrence_dollars",
    "wcPolicyNumber": "coverages.workers_compensation.policy_number_string",
    "wcInsurer": "coverages.workers_compensation.insurer_name",
    "wcWaiverOfSubrogation": "coverages.workers_compensation.subr_wvd",
    "workersCompPolicyType.per_statute": "coverages.workers_compensation.policy_type.per_statute",
    "workersCompPolicyType.other": "coverages.workers_compensation.policy_type.other",
    "wcPolicyEffectiveDate": "coverages.workers_compensation.policy_effective_date",
    "wcPolicyExpirationDate": "coverages.workers_compensation.policy_expiration_date",
    "plPolicyNumber": "coverages.professional_liability.policy_number_string",
    "plInsurer": "coverages.professional_liability.insurer_name",
    "plOccurrenceLimit": "coverages.professional_liability.limits.each_occurrence_dollars",
    "plAggregateLimit": "coverages.professional_liability.limits.aggregate_dollars",
    "plWaiverOfSubrogation": "coverages.professional_liability.subr_wvd",
    "plAdditionalInsured": "coverages.professional_liability.addl_insd",
    "plPolicyEffectiveDate": "coverages.professional_liability.policy_effective_date",
    "plPolicyExpirationDate": "coverages.professional_liability.policy_expiration_date",
    "autoPolicyNumber": "coverages.automobile_liability.policy_number_string",
    "autoInsurer": "coverages.automobile_liability.insurer_name",
    "autoPolicyEffectiveDate": "coverages.automobile_liability.policy_effective_date",
    "autoPolicyExpirationDate": "coverages.automobile_liability.policy_expiration_date",
    "autoPolicyType.anyAuto": "coverages.automobile_liability.any_auto",
    "autoPolicyType.ownedAuto": "coverages.automobile_liability.all_owned_auto",
    "autoPolicyType.hiredAuto": "coverages.automobile_liability.hired_auto",
    "autoPolicyType.scheduledAuto": "coverages.automobile_liability.scheduled_auto",
    "autoPolicyType.nonOwnedAuto": "coverages.automobile_liability.non_owned_auto",
    "autoPolicyLimit.combinedSingleLimit": "coverages.auto_liability.limits.combined_single_limit_dollars",
    "autoPolicyLimit.bodilyInjuryPerAccident": "coverages.auto_liability.limits.bodily_injury_per_accident_dollars",
    "autoPolicyLimit.bodilyInjuryPerPerson": "coverages.auto_liability.limits.bodily_injury_per_person_dollars",
    "autoPolicyLimit.propertyDamage": "coverages.auto_liability.limits.property_damage_dollars",
    "autoWaiverOfSubrogation": "coverages.auto_liability.subr_wvd",
    "autoAdditionalInsured": "coverages.auto_liability.addl_insd",
    "umbrellaPolicyNumber": "coverages.excess_umbrella_liability.policy_number_string",
    "umbrellaInsurer": "coverages.excess_umbrella_liability.insurer_name",
    "umbrellaPolicyType.claims_made": "coverages.excess_umbrella_liability.policy_type.claims_made",
    "umbrellaPolicyType.occurrence": "coverages.excess_umbrella_liability.policy_type.occurrence",
    "umbrellaPolicyEffectiveDate": "coverages.excess_umbrella_liability.policy_effective_date",
    "umbrellaPolicyExpirationDate": "coverages.excess_umbrella_liability.policy_expiration_date",
    "umbrellaWaiverOfSubrogation": "coverages.excess_umbrella_liability.subr_wvd",
    "umbrellaAdditionalInsured": "coverages.excess_umbrella_liability.addl_insd",
    "umbrellaPolicyLimit.eachOccurrence": "coverages.excess_umbrella_liability.limits.each_occurrence_dollars",
    "umbrellaPolicyLimit.aggregate": "coverages.excess_umbrella_liability.limits.aggregate_dollars",
    "excessPolicyNumber": "coverages.excess_liability.policy_number_string",
    "excessInsurer": "coverages.excess_liability.insurer_name",
    "excessPolicyType.claims_made": "coverages.excess_liability.policy_type.claims_made",
    "excessPolicyType.occurrence": "coverages.excess_liability.policy_type.occurrence",
    "excessPolicyEffectiveDate": "coverages.excess_liability.policy_effective_date",
    "excessPolicyExpirationDate": "coverages.excess_liability.policy_expiration_date",
    "excessWaiverOfSubrogation": "coverages.excess_liability.subr_wvd",
    "excessAdditionalInsured": "coverages.excess_liability.addl_insd",
    "excessPolicyLimit.eachOccurrence": "coverages.excess_liability.limits.each_occurrence_dollars",
    "excessPolicyLimit.aggregate": "coverages.excess_liability.limits.aggregate_dollars",
    "cyberPolicyNumber": "coverages.cyber_liability.policy_number_string",
    "cyberInsurer": "coverages.cyber_liability.insurer_name",
    "cyberPolicyEffectiveDate": "coverages.cyber_liability.policy_effective_date",
    "cyberPolicyExpirationDate": "coverages.cyber_liability.policy_expiration_date",
    "cyberPolicyLimit.eachOccurrence": "coverages.cyber_liability.limits.each_occurrence_dollars",
    "cyberPolicyLimit.aggregate": "coverages.cyber_liability.limits.aggregate_dollars",
    "cyberWaiverOfSubrogation": "coverages.cyber_liability.subr_wvd",
    "cyberAdditionalInsured": "coverages.cyber_liability.addl_insd",
    "crimePolicyNumber": "coverages.crime_liability.policy_number_string",
    "crimeInsurer": "coverages.crime_liability.insurer_name",
    "crimePolicyEffectiveDate": "coverages.crime_liability.policy_effective_date",
    "crimePolicyExpirationDate": "coverages.crime_liability.policy_expiration_date",
    "crimePolicyLimit.eachOccurrence": "coverages.crime_liability.limits.each_occurrence_dollars",
    "crimePolicyLimit.aggregate": "coverages.crime_liability.limits.aggregate_dollars",
    "crimeWaiverOfSubrogation": "coverages.crime_liability.subr_wvd",
    "crimeAdditionalInsured": "coverages.crime_liability.addl_insd",
    "insuredName": "insured.name",
    "entityName": "certificate_holder.name",
};


// Function to parse results from SAMPLE_INPUT_OBJECT and map them to paths
function parseResults(inputObject) {
  const pathToResult = {};

  if (!inputObject?.edges) {
      return pathToResult;
  }

  inputObject.edges.forEach(edge => {
      const rule = JSON.parse(edge.node.ruleJson);
      const result = JSON.parse(edge.node.resultJson);
      pathToResult[rule.path] = result.result;
  });
  return pathToResult;
}

// Function to create the output object based on COVERAGE_RULE_MAP and the results from parseResults
export function mapResultsToFields(auditResults, ruleMap=FIELD_TO_RULE_MAP) {
  const results = parseResults(auditResults);
  const output = {};
  function fillOutput(currentMap, currentOutput) {
      Object.keys(currentMap).forEach(key => {
          const value = currentMap[key];
          if (typeof value === 'object' && value !== null) {
              currentOutput[key] = {};
              fillOutput(value, currentOutput[key]);
          } else {
              currentOutput[key] = results[value] || "";
          }
      });
  }
  fillOutput(ruleMap, output);
  return output;
}