import React from "react";

// Utility function to measure text width
function getTextWidth(text, font) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

// The AutoSizingInputAdvanced component compatible with Formik
const AutoSizingInputAdvanced = ({ field, form, ...props }) => {
  const { font, fontWeight, minwidth, padding, style } = props;
  const inputValue = field.value;
  const width = getTextWidth(inputValue, font) + (padding || 18); // Calculate width based on input

  return (
    <input
      {...field} // Spread Formik field props
      {...props} // Spread any custom props
      type="text"
      style={{
        maxWidth: "200px", // Ensure input doesn't overflow container
        width: `${Math.max(width, minwidth || 20)}px`,
        font: font,
        fontWeight: fontWeight,
        ...style, // Spread any custom styles
      }} // Ensure minimum width
    />
  );
};

export default AutoSizingInputAdvanced;
