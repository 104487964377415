import { environments } from "../constants/environments";

export function createRequestInit(method, token) {
  return {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Ten99Policy-Environment":
        localStorage.getItem("Ten99PolicyEnvironment") ||
        environments.PRODUCTION,
      "Ten99Policy-Organization":
        localStorage.getItem("Ten99PolicyOrganization") || "",
    },
  };
}

export function handleResponseStatus(
  statusCode,
  data,
  setIsLoading,
  setError,
  setImportResults,
  defaultCallback
) {
  switch (statusCode) {
    case 401:
      setError(data.msg);
      setIsLoading(false);
      setImportResults({
        processed: 0,
        success: 0,
        total_premium: 0,
        platform_access_fee: 0,
        failure: [],
      });
      break;
    case 400:
      setError(data.msg);
      setIsLoading(false);
      setImportResults({
        processed: 0,
        success: 0,
        total_premium: 0,
        platform_access_fee: 0,
        failure: [],
      });
      break;
    case 403:
      setError(data.msg);
      setIsLoading(false);
      setImportResults({
        processed: 0,
        success: 0,
        total_premium: 0,
        platform_access_fee: 0,
        failure: [],
      });
      break;
    default:
      defaultCallback(data, setIsLoading, setError);
      break;
  }
}

export const removeEmpty = (dataArray) => {
  const cleanedArray = dataArray.filter((item) => {
    for (let key in item) {
      if (item.hasOwnProperty(key) && item[key].length > 0) {
        return true; // Keep this item because it has at least one non-empty field
      }
    }
    return false; // Remove this item because all fields are empty
  });
  return cleanedArray;
};

const swapObjectKey = (o, old_key, new_key) => {
  if (old_key !== new_key && new_key !== "none") {
    Object.defineProperty(
      o,
      new_key,
      Object.getOwnPropertyDescriptor(o, old_key)
    );
    delete o[old_key];
  }
};

export function transformCsvData(data, columnMap) {
  return data.map((item) => {
    let newItem = { ...item };

    // Swap keys based on columnMap if provided
    if (columnMap) {
      Object.entries(columnMap).forEach(([key, value]) => {
        swapObjectKey(newItem, key, value);
      });
    }

    // Nest the item under "rows"
    return { rows: newItem };
  });
}
