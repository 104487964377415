import React, { useState, Fragment } from "react";
import { useMutation } from '@apollo/client';
import { Formik, Field } from 'formik';

import classNames from 'classnames'


import EditButton from './EditButton';

import { UPDATE_ORGANIZATION } from './mutations';
import EmailInput from './EmailInput'; 

function _handleSubmit(values, actions) {
  actions.setSubmitting(false)
}

const GeneralForm = (props) => {  
  const [isActiveEdit, setIsActiveEdit] = useState(false)
  const {
    organization
  } = props;

  const [updateOrganization, {loading: isUpdateOrgLoading, error: savingError, reset}] = useMutation(UPDATE_ORGANIZATION, {
    onCompleted() {
      reset()
      setIsActiveEdit(false)
    },
    onError(error){
      console.log(error)
      setIsActiveEdit(false)
    },
    refetchQueries: ['CurrentUser', 'OrganizationByID'],
  })

  const {
    name,
    withholdPremium,
    isPurchaseOrderWorkflowEnabled,
    billToEmailPrimary,
    billToEmailCc,
    creatorIqApikey,
    certificateHolderName,
    certificateHolderAddress,
    companyAddress,
  } = organization
  
  const {
    line1,
    line2,
    line3,
    locality,
    region,
    postalcode
  } = certificateHolderAddress ? certificateHolderAddress : {}

  const {
    line1: orgLine1,
    line2: orgLine2,
    line3: orgLine3,
    locality: orgLocality,
    region: orgRegion,
    postalcode: orgPostalcode,
  } = companyAddress ? companyAddress : {}

  const formInitialValues = {}

  formInitialValues.organizationName = name || ""
  formInitialValues.withholdPremium = withholdPremium || false
  formInitialValues.creatorIqApikey = creatorIqApikey || ""
  formInitialValues.certificateHolderName = certificateHolderName || ""
  
  formInitialValues.certificateHolderAddressLine1 = line1 || ""
  formInitialValues.certificateHolderAddressLine2 = line2 || ""
  formInitialValues.certificateHolderAddressLine3 = line3 || ""
  formInitialValues.certificateHolderLocality = locality || ""
  formInitialValues.certificateHolderRegion = region || ""
  formInitialValues.certificateHolderPostalcode = postalcode || ""

  formInitialValues.organizationAddressLine1 = orgLine1 || ""
  formInitialValues.organizationAddressLine2 = orgLine2 || ""
  formInitialValues.organizationAddressLine3 = orgLine3 || ""
  formInitialValues.organizationLocality = orgLocality || ""
  formInitialValues.organizationRegion = orgRegion || ""
  formInitialValues.organizationPostalcode = orgPostalcode || ""
  formInitialValues.billToEmailPrimary = billToEmailPrimary || []
  formInitialValues.billToEmailCc = billToEmailCc || []

  function saveOrganizationDetails(values){
    const certificateHolder = {
      name: values.certificateHolderName,
      line1: values.certificateHolderAddressLine1,
      line2: values.certificateHolderAddressLine2,
      line3: values.certificateHolderAddressLine3,
      locality: values.certificateHolderLocality,
      region: values.certificateHolderRegion,
      postalcode: values.certificateHolderPostalcode,
    }

    const companyAddress = {
      line1: values.organizationAddressLine1,
      line2: values.organizationAddressLine2,
      line3: values.organizationAddressLine3,
      locality: values.organizationLocality,
      region: values.organizationRegion,
      postalcode: values.organizationPostalcode,
    }
    
    // Ensure that empty arrays are converted to null
    const billToEmailPrimary = values.billToEmailPrimary.length > 0 ? values.billToEmailPrimary : null;
    const billToEmailCc = values.billToEmailCc.length > 0 ? values.billToEmailCc : null;
    
    updateOrganization({
      variables: {
        name: values.organizationName,
        withholdPremium: values.withholdPremium,
        creatorIqApikey: values.creatorIqApikey,
        certificateHolder: certificateHolder,
        companyAddress: companyAddress,
        billToEmailPrimary: billToEmailPrimary,
        billToEmailCc: billToEmailCc,
      },
    })
  }

  const [premiumPayment, setPremiumPayment] = useState(
    withholdPremium,
    false
  );

  function setPremiumPaymentChoice(e, setFieldValue) {
    const withholdPremium = e?.target?.value === "Yes";
    setFieldValue("withholdPremium", withholdPremium);
    setPremiumPayment(withholdPremium);
  }

  const renderError = () => (
    <div className="br2 f6 flex items-center justify-center pa3 bg-lightest-blue navy">
      <span className="lh-title ml3">There was an error saving the organization settings. Please try again.</span>
    </div>
  )
  
  return (
    <>
      <Formik
        initialValues={formInitialValues}
        onSubmit={_handleSubmit}
      >
        {(props) => {
          const {
            values,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props
          
          return (
            <form onSubmit={handleSubmit}>
              {savingError ? renderError() : null}
              <Fragment>
                <div className="relative dib w-100 pv3 f6">
                  <div className="mt2">
                    <h3 className="flex justify-between dark-gray bb b--black-10 mt0 pb3">
                      <span className="content-end">Organization</span>
                      <EditButton
                        isLoading={isUpdateOrgLoading}
                        save={saveOrganizationDetails}
                        setIsActiveEdit={setIsActiveEdit} 
                        isActiveEdit={isActiveEdit}
                        values={values}
                      />
                    </h3>
                    <div className="pb3">
                      <span className="w-100">
                        <label htmlFor="organizationName" className="f6 fw5 dib w-20">Organization name</label>
                        <Field name="organizationName" 
                        className={classNames("outline-0 pa2 w-30 f6 fw3", 
                        {"ba b--black-10 br2": isActiveEdit},
                        {"ba b--transparent": !isActiveEdit})}
                        type="text" 
                        readOnly={!isActiveEdit}/>
                      </span>
                    </div>
                  </div>
                  <div className="mt5">
                    <h3 className="dark-gray bb b--black-10 mt0 pb3">
                      Certificate Holder
                    </h3>
                    <div className="pb2">
                      <span className="w-100">
                        <label htmlFor="certificateHolderName" className="f6 fw5 dib w-20">Certificate holder name</label>
                        <input name="certificateHolderName" 
                        className={classNames("outline-0 pa2 w-30 f6 fw3 border-box", 
                        {"ba b--black-10 br2": isActiveEdit},
                        {"ba b--transparent": !isActiveEdit})}
                        type="text" 
                        value={values.certificateHolderName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={!isActiveEdit}
                        placeholder="No certificate holder name (e.g., Acme co.)"
                        />
                      </span>
                    </div>
                    <div className="pb2">
                      <span className="w-100">
                        <label htmlFor="certificateHolderAddressLine1" className="f6 fw5 dib w-20">Address line 1</label>
                        <input name="certificateHolderAddressLine1" 
                        className={classNames("outline-0 pa2 w-30 f6 fw3 border-box", 
                        {"ba b--black-10 br2": isActiveEdit},
                        {"ba b--transparent": !isActiveEdit})}
                        type="text" 
                        value={values.certificateHolderAddressLine1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={!isActiveEdit}
                        placeholder="No certificate holder address"
                        />
                      </span>
                    </div>

                    <div className="pb2">
                      <span className="w-100">
                        <label htmlFor="certificateHolderAddressLine2" className="f6 fw5 dib w-20">Address line 2</label>
                        <input name="certificateHolderAddressLine2" 
                        className={classNames("outline-0 pa2 w-30 f6 fw3 border-box", 
                        {"ba b--black-10 br2": isActiveEdit},
                        {"ba b--transparent": !isActiveEdit})}
                        type="text" 
                        value={values.certificateHolderAddressLine2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={!isActiveEdit}/>
                      </span>
                    </div>

                    <div className="pb2">
                      <span className="w-100">
                        <label htmlFor="certificateHolderAddressLine3" className="f6 fw5 dib w-20">Address line 3</label>
                        <input name="certificateHolderAddressLine3" 
                        className={classNames("outline-0 pa2 w-30 f6 fw3 border-box", 
                        {"ba b--black-10 br2": isActiveEdit},
                        {"ba b--transparent": !isActiveEdit})}
                        type="text" 
                        value={values.certificateHolderAddressLine3}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={!isActiveEdit}/>
                      </span>
                    </div>

                    <div className="pb2">
                      <span className="w-100">
                        <label htmlFor="certificateHolderLocality" className="f6 fw5 dib w-20">City</label>
                        <input name="certificateHolderLocality" 
                        className={classNames("outline-0 pa2 w-30 f6 fw3 border-box", 
                        {"ba b--black-10 br2": isActiveEdit},
                        {"ba b--transparent": !isActiveEdit})}
                        type="text" 
                        value={values.certificateHolderLocality}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={!isActiveEdit}/>
                      </span>
                    </div>

                    <div className="pb2">
                      <span className="w-100">
                        <label htmlFor="certificateHolderRegion" className="f6 fw5 dib w-20">State</label>
                        <input name="certificateHolderRegion" 
                        className={classNames("outline-0 pa2 w-30 f6 fw3 border-box", 
                        {"ba b--black-10 br2": isActiveEdit},
                        {"ba b--transparent": !isActiveEdit})}
                        type="text" 
                        value={values.certificateHolderRegion}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={!isActiveEdit}/>
                      </span>
                    </div>

                    <div className="pb2">
                      <span className="w-100">
                        <label htmlFor="certificateHolderPostalcode" className="f6 fw5 dib w-20">Postal code</label>
                        <input name="certificateHolderPostalcode" 
                        className={classNames("outline-0 pa2 w-30 f6 fw3 border-box", 
                        {"ba b--black-10 br2": isActiveEdit},
                        {"ba b--transparent": !isActiveEdit})}
                        type="text" 
                        value={values.certificateHolderPostalcode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={!isActiveEdit}/>
                      </span>
                    </div>
                  </div>
                  <div className="mt5">
                    <h3 className="dark-gray bb b--black-10 mt0 pb3">
                      Insurance Premium Payment
                    </h3>
                    <div className="pv3">
                      <label className="ml0 lh-title db pb2 fw6">Withhold insurance premium payments?</label>
                      <div className="mt3 mt0-l">
                        <Field type="hidden" name="withholdPremium" />
                        <label className="db pv2 pointer">
                          <input
                            type="radio"
                            value="No"
                            checked={!premiumPayment}
                            disabled={!isActiveEdit}
                            name="withholdPremiumToggle"
                            onChange={(e) => setPremiumPaymentChoice(e, setFieldValue)}
                          />{" "}
                          No, collect payment direct from contractor.
                        </label>
                        <label className="db pv2 pointer">
                          <input
                            type="radio"
                            value="Yes"
                            checked={premiumPayment}
                            disabled={!isActiveEdit}
                            name="withholdPremiumToggle"
                            onChange={(e) => setPremiumPaymentChoice(e, setFieldValue)}
                          />{" "}
                          Yes
                        </label>
                      </div>
                    </div>
                  </div>
                  {(premiumPayment || isPurchaseOrderWorkflowEnabled) && 
                  <div className="mt3">
                    <label className="ml0 lh-title db pb2 fw6">Company billing address</label>
                    <div className="pb2">
                      <span className="w-100">
                        <label htmlFor="organizationAddressLine1" className="f6 fw5 dib w-20">Address line 1</label>
                        <input name="organizationAddressLine1" 
                        className={classNames("outline-0 pa2 w-30 f6 fw3 border-box", 
                        {"ba b--black-10 br2": isActiveEdit},
                        {"ba b--transparent": !isActiveEdit})}
                        type="text" 
                        value={values.organizationAddressLine1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={!isActiveEdit}
                        placeholder="No organization address"
                        />
                      </span>
                    </div>

                    <div className="pb2">
                      <span className="w-100">
                        <label htmlFor="organizationAddressLine2" className="f6 fw5 dib w-20">Address line 2</label>
                        <input name="organizationAddressLine2" 
                        className={classNames("outline-0 pa2 w-30 f6 fw3 border-box", 
                        {"ba b--black-10 br2": isActiveEdit},
                        {"ba b--transparent": !isActiveEdit})}
                        type="text" 
                        value={values.organizationAddressLine2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={!isActiveEdit}/>
                      </span>
                    </div>

                    <div className="pb2">
                      <span className="w-100">
                        <label htmlFor="organizationAddressLine3" className="f6 fw5 dib w-20">Address line 3</label>
                        <input name="organizationAddressLine3" 
                        className={classNames("outline-0 pa2 w-30 f6 fw3 border-box", 
                        {"ba b--black-10 br2": isActiveEdit},
                        {"ba b--transparent": !isActiveEdit})}
                        type="text" 
                        value={values.organizationAddressLine3}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={!isActiveEdit}/>
                      </span>
                    </div>

                    <div className="pb2">
                      <span className="w-100">
                        <label htmlFor="organizationLocality" className="f6 fw5 dib w-20">City</label>
                        <input name="organizationLocality" 
                        className={classNames("outline-0 pa2 w-30 f6 fw3 border-box", 
                        {"ba b--black-10 br2": isActiveEdit},
                        {"ba b--transparent": !isActiveEdit})}
                        type="text" 
                        value={values.organizationLocality}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={!isActiveEdit}/>
                      </span>
                    </div>

                    <div className="pb2">
                      <span className="w-100">
                        <label htmlFor="organizationRegion" className="f6 fw5 dib w-20">State</label>
                        <input name="organizationRegion" 
                        className={classNames("outline-0 pa2 w-30 f6 fw3 border-box", 
                        {"ba b--black-10 br2": isActiveEdit},
                        {"ba b--transparent": !isActiveEdit})}
                        type="text" 
                        value={values.organizationRegion}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={!isActiveEdit}/>
                      </span>
                    </div>

                    <div className="pb2">
                      <span className="w-100">
                        <label htmlFor="organizationPostalcode" className="f6 fw5 dib w-20">Postal code</label>
                        <input name="organizationPostalcode" 
                        className={classNames("outline-0 pa2 w-30 f6 fw3 border-box", 
                        {"ba b--black-10 br2": isActiveEdit},
                        {"ba b--transparent": !isActiveEdit})}
                        type="text" 
                        value={values.organizationPostalcode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={!isActiveEdit}/>
                      </span>
                    </div>

                    <div className="mt3">
                      <label className="ml0 lh-title db pb2 fw6">BILL TO email addresses</label>
                      <div className="pb2">
                      <span className="w-100">
                        <label htmlFor="billToEmailPrimary" className="f6 fw5 dib w-20">Primary</label> 
                        <EmailInput
                          name="billToEmailPrimary"
                          value={values.billToEmailPrimary}
                          onChange={(emails) => setFieldValue('billToEmailPrimary', emails)}
                          isActiveEdit={isActiveEdit}
                          placeholder="Enter primary email(s)"
                        />
                      </span>
                    </div>
                    <div className="pb2">
                      <span className="w-100">
                        <label htmlFor="billToEmailCc" className="f6 fw5 dib w-20">CC</label>
                        <EmailInput
                          name="billToEmailCc"
                          value={values.billToEmailCc}
                          onChange={(emails) => setFieldValue('billToEmailCc', emails)}
                          isActiveEdit={isActiveEdit}
                          placeholder="Enter cc email(s)"
                        />
                      </span>
                    </div>
                    </div>
                  </div>
                  }
                  <div className="mt5">
                    <h3 className="dark-gray bb b--black-10 mt0 pb3">
                      Platform Integrations
                    </h3>
                  </div>
                  <div className="pb2">
                    <span className="w-100">
                      <label htmlFor="creatorIqApikey" className="f6 fw5 dib w-20">CreatorIQ API Key</label>
                      <input name="creatorIqApikey" 
                      className={classNames("outline-0 pa2 w-30 f6 fw3 border-box", 
                      {"ba b--black-10 br2": isActiveEdit},
                      {"ba b--transparent": !isActiveEdit})}
                      type="text" 
                      value={values.creatorIqApikey}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Visit Creator IQ to get your API key"
                      readOnly={!isActiveEdit}/>
                    </span>
                  </div>
                </div>
              </Fragment>
            </form>
          )
        }}
      </Formik>
    </>
  );
}

export default GeneralForm;