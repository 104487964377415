import { gql } from '@apollo/client';

export const CORE_CERTIFICATE_AUDIT_FIELDS = gql`
  fragment CoreCertificateAuditFields on CertificateAudit {
    publicId
    created
    status
    certificate {
      filename
      pdfUrl
      contractor {
        publicId
        unredactedDict
      }
    }
    parsedCertificateJson
    insuranceRequirement {
      publicId
      name
      jobCategories {
        edges {
          node {
            className
          }
        }
      }
    }
    auditResults {
      edges {
        node {
          ruleJson
          resultJson
        }
      }
    }
  }
`