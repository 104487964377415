const WORKERS_COMP = 'workers-comp'
const GENERAL = 'general'
const PROFESSIONAL = 'professional'

export const shortCoverageNamesAndStates = (coverageTypes, workState, residenceState) => {
  const states = [workState, residenceState]
  const filteredStates = states.filter(state => state !== null).join('/');

  let names = coverageTypes?.map(function(coverage) {
    let name = ""
    
    if (coverage?.node?.name == WORKERS_COMP){
      name = `WC`
    } else if (coverage?.node?.name == GENERAL){
      name = `GL`
    }

    return name;
  }).join(', ');
  
  return `${names} (${filteredStates})`;

}

export const shortCoverageNames = (coverageTypes) => {
  const names = coverageTypes?.map(function(coverage) {
    let name = ""
    
    if (coverage?.node?.name == WORKERS_COMP){
      name = 'WC'
    } else if (coverage?.node?.name == GENERAL){
      name = 'GL'
    }

    return name;
  }).join(', ');
  
  return names;
}

export function getPolicies(quotes){
  if (!quotes || quotes.length == 0){
    return []
  }

  const policies = quotes
    .map(obj => {
      const converageTypes = obj?.node?.coverageType?.edges
      const workState = obj?.node?.quoteJsonWorkState
      const residenceState = obj?.node?.quoteJsonResidenceState

      return {
        ...obj?.node.policy,
        ['quoteId']: obj?.node.publicId,
        ['jobCategory']: obj?.node.job?.jobCategory?.className,
        ['workState']: obj?.node.workState,
        ['residenceState']: obj?.node.residenceState,
        ['coverageTypes']: shortCoverageNamesAndStates(converageTypes, workState, residenceState),
      }
    })
    .filter(value => value.edges[0] !== null && value.edges[0] !== undefined)
    .filter(value => value !== null && value !== undefined);

  return policies
}

export const resolveRedacted = (contractor, resolvedDict) => {
  let clone = { 
    ...(contractor?.node ? contractor.node : contractor),
   };

   let clone_address = {
    ...contractor.address
   };

   clone.publicId = contractor?.node?.publicId || contractor?.publicId
   clone.firstName = resolvedDict.first_name
   clone.middleName = resolvedDict.middle_name
   clone.lastName = resolvedDict.last_name
   clone.email = resolvedDict.email

   clone_address.line1 = resolvedDict.address.line1
   clone_address.line2 = resolvedDict.address.line2
   clone_address.line3 = resolvedDict.address.line3
   clone_address.region = resolvedDict.address.region
   clone_address.locality = resolvedDict.address.locality
   clone_address.postalcode = resolvedDict.address.postalcode


  return {
    ...clone,
    'address': {
      ...clone_address
    }
  }
}
