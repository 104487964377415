import { gql } from "@apollo/client";

export const REMOVE_ASSIGNMENTS_FROM_TRACKER = gql`
  mutation RemoveAssignmentsFromTracker(
      $trackerId: String!,
      $assignmentIds: [String]!
  ){
      removeAssignmentsFromTracker(
        trackerId: $trackerId,
        assignmentIds: $assignmentIds
      ){
        ok
      }
  }  
`;
