import React, {useState, useContext} from 'react';
import { useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';

import { gql, useMutation } from "@apollo/client";

import { 
  Form,
  Formik, 
  Field,
  ErrorMessage
 } from 'formik';

import * as Yup from 'yup';

import { AuthContext } from "../../contexts/authContext";
import OnboardingHeader from "../../components/OnboardingHeader";
import { CREATE_ORGANIZATION } from './mutations';

function CreateOrganization(props){
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState()

  const [createOrg, {loading, error, reset}] = useMutation(CREATE_ORGANIZATION, {
    onCompleted: (data) =>{
      if (!data?.createOrganization?.ok){
        setErrorMessage(data?.createOrganization?.message)
      } else {
        reset()
      }
    },
    onError: () => {
      reset()
    },
    refetchQueries: ['CurrentUser']
  })

  const onLogout = (event) => {
    localStorage.removeItem("Ten99PolicyEnvironment");
    localStorage.removeItem("Ten99PolicyOrganization");
    props.client.clearStore();
    auth.signOut();
    navigate("/signin");
  };

  const renderLoading = () => (
    <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
  )

  const renderError = (errorMsg) => {
    const defaultMsg = "There was an error saving the organization name. Please try again."
    const msg = errorMsg || defaultMsg
    
    return(
      <div className="br2 f6 flex items-center justify-center bg-lightest-blue navy br1 pa2 lh-title">
        <span className="f6">{msg}</span>
      </div>
    )
  }

  const renderCreateOrgForm = () => (
    <div className="mw5 center">
    <Formik
        initialValues={{ name: ''}}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          createOrg({ variables: {...values}})
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required'),
        })}
      >
        {({ isSubmitting }) => (
          <Form className="measure">
            {error || errorMessage ? renderError(errorMessage) : null}
            
            <label className="f5 fw6 db mb2 mt3 black-70 dib" htmlFor="name">
              Organization Name </label>
            <ErrorMessage className="f6 red dib fr mt3" name="name" component="div" />
              <Field 
                type="text" 
                name="name" 
                className="input-reset ba b--black-20 pa2 mb2 db w-100 br2"
                placeholder="Vanguard & Co"
              />
              <button 
                className="w-100 f6 link dim br2 ph3 pv2 mb2 ml2 dib white bg-blue bn pointer fr"
                type="submit" 
                disabled={isSubmitting}>
                Create Organization
              </button>
          </Form>
        )}
      </Formik>
    </div>
  )

  return (
    <OnboardingHeader onLogout={onLogout}>
      <div className="vh-75 dt w-100">
        <div className="dtc v-mid">
        {
          loading ? renderLoading() : renderCreateOrgForm()
        }
        </div>
      </div>  
    </OnboardingHeader>
  )
}

export default CreateOrganization