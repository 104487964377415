function isInt(n) {
  return n % 1 === 0;
}

export const centsToDollars = (cents, withoutSymbol = false) => {
  const options = { style: "currency", currency: "USD" };

  let dollars = cents / 100;

  if (withoutSymbol) {
    return dollars;
  }

  if (isInt(dollars)) {
    return dollars.toLocaleString("en-US", options).slice(0, -3);
  } else {
    return dollars.toLocaleString("en-US", options);
  }
};

export function currencyFormat(num, digits = 0) {
  return "$" + num.toFixed(digits).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function currencyStringToCents(currencyString) {
  const number = Number(currencyString.replace(/[^0-9.-]+/g, ""));

  return number;
}

export const strToCurrencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
