import React, {useContext} from 'react';
import { useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';

import { gql, useQuery, useMutation } from "@apollo/client";
import {getCurrentUserEmail} from "../../libs/cognito"

import { AuthContext } from "../../contexts/authContext";
import OnboardingHeader from "../../components/OnboardingHeader";

import { ACCEPT_OR_DENY_INVITATION, JOIN_ORGANIZATION } from "./mutations";
import { PENDING_INVITATIONS_QUERY } from "./queries";


function JoinOrganization(props){
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const currentUserEmail = getCurrentUserEmail()

  const [acceptOrDenyOrganizationInvitation] = useMutation(
    ACCEPT_OR_DENY_INVITATION,
    {
      onCompleted: (resp) => {
        const data = resp?.acceptOrDenyOrganizationInvitation;

        if (data.ok) {
          window.location.reload();
        } else {
          alert(data.message);
        }
      },
    }
  );
  
  const { data } = useQuery(PENDING_INVITATIONS_QUERY, {
    variables: {
      email: currentUserEmail,
    }
  });

  const [joinOrg, {loading, error, reset}] = useMutation(JOIN_ORGANIZATION, {
    onCompleted: () =>{
      reset()
    },
    onError: () => {
      reset()
    },
    refetchQueries: ['CurrentUser']
  })
  
  const onLogout = (event) => {
    localStorage.removeItem("Ten99PolicyEnvironment");
    localStorage.removeItem("Ten99PolicyOrganization");
    props.client.clearStore();
    auth.signOut();
    navigate("/signin");
  };

  const renderLoading = () => (
    <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
  )

  const renderError = () => (
    <div className="flex items-center justify-center pa1 bg-lightest-blue navy">
      <span className="f6 lh-title ml3">There was an error saving the organization name. Please try again.</span>
    </div>
  )

  function acceptOrganizationInvite(invitationId) {
    acceptOrDenyOrganizationInvitation({
      variables: {
        invitationId: invitationId,
        accept: true,
      },
    });
  }

  function denyOrganizationInvite(invitationId) {
    acceptOrDenyOrganizationInvitation({
      variables: {
        invitationId: invitationId,
        accept: false,
      },
    });
  }

  function base64Decode(id) {
    let res = atob(id);
    return Number(res.toString("ascii").split(":")[1]);
  }

  const renderJoinOrgForm = (pendingInvitations) => (
    <div className="mw7 center">
      <div className="measure center">
        <ul className="list pl0 mt0 mb4">
          {pendingInvitations && pendingInvitations.map((invitation, index) => {
              return (
                <li key={index} className="mb2">
                  <span className="lh-copy"><b>{invitation.invitor.email}</b> is inviting you to join {invitation.organization.name} to track the insurance 
                  coverage status of contractors you work with. Click "Accept" to get started.</span>
                  <div className="flex mt4">
                    <button 
                    className="w-50 f6 link dim br2 ph3 pv2 dib red ba bg-white b--red pointer fr"
                    onClick={() => denyOrganizationInvite(base64Decode(invitation.id))}>Deny</button>
                    <button 
                    className="w-50 ml4 f6 link dim br2 ph3 pv2 dib white bg-blue ba b--blue pointer fr"
                    onClick={() => acceptOrganizationInvite(base64Decode(invitation.id))}>Accept</button>
                  </div>
                </li>
              );
            }
          )}
        </ul>
      </div>
    </div>
  )

  return (
    <OnboardingHeader onLogout={onLogout}>
      <div className="vh-75 dt w-100 roboto">
        <div className="dtc v-mid">
        {
          loading ? renderLoading() : renderJoinOrgForm(data?.pendingInvitations)
        }
        </div>
      </div>  
    </OnboardingHeader>
  )
}

export default JoinOrganization