import { gql } from '@apollo/client'

const CREATE_CONTRACTOR = gql`
  mutation CreateContractor(
    $contractor: ContractorInput!,
  ){
    createContractor(
      contractor: $contractor
    ){
      ok
      message
      contractor {
        publicId
        firstName
        lastName
      }
    }
  }
`

const UPDATE_CERTIFICATE = gql`
  mutation UpdateCertificateAudit($updatedInputJson: JSONString!, $publicId: String!, $reviewStatus: String!) {
    updateCertificateAudit(updatedInputJson: $updatedInputJson, publicId: $publicId, reviewStatus: $reviewStatus) {
      ok
    }
  }
`;

export { CREATE_CONTRACTOR, UPDATE_CERTIFICATE }