import React from "react";
import { useLocation } from "react-router-dom";

import ReactLoading from 'react-loading';

function EditButton(props) {
  const { isLoading, setIsActiveEdit, isActiveEdit, save, values } = props;

  const loadingSpinner = () => (
    <ReactLoading type={"spin"} color={"#cccccc"} width={14} height={14} />
  );

  const renderEdit = () => {
    return (
      <button
        id="editSettings"
        type="button"
        onClick={() => setIsActiveEdit(true)}
        className="fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 f7 lh-title bg-animate hover-bg-light-gray border-box"
      >
        Edit
      </button>
    );
  };

  const renderCancelSave = () => {
    return (
      <div className="flex-item">
        <button
          id="cancelEditSettings"
          type="button"
          onClick={() => setIsActiveEdit(false)}
          className="fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 f7 lh-title bg-animate hover-bg-light-gray border-box"
        >
          Cancel
        </button>
        <button
          id="saveedit"
          type="button"
          onClick={() => save(values)}
          className="fr outline-0 pointer br2 ba b--black-20 bg-blue white pa2 ml1 f7 lh-title border-box"
        >
          {isLoading ? loadingSpinner() : "Save"}
        </button>
      </div>
    );
  };

  return isActiveEdit ? renderCancelSave() : renderEdit();
}

export default EditButton;
