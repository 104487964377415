import React from 'react';
import ReactLoading from 'react-loading';

import PageHeader from '../components/PageHeader';
import AdminDisclaimer from '../components/AdminDisclaimer';
import MainWrapper from '../components/MainWrapper';

import Autocomplete from 'react-autocomplete';

import { environments } from "../constants/environments";
import withAuthorization from '../utils/withAuthorization';

function matchQuestionToTerm(question, value) {
  return (
    question.simplified.toLowerCase().indexOf(value.toLowerCase()) !== -1
  )
}

export function sortQuestions(a, b, value) {
  const aLower = a.simplified.toLowerCase()
  const bLower = b.simplified.toLowerCase()
  const valueLower = value.toLowerCase()
  const queryPosA = aLower.indexOf(valueLower)
  const queryPosB = bLower.indexOf(valueLower)
  if (queryPosA !== queryPosB) {
    return queryPosA - queryPosB
  }
  return aLower < bLower ? -1 : 1
}

class QuestionsAdminForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      data: null,
      item: null,
      error: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    const token = this.props.token
    const BASE_URL = process.env.REACT_APP_TEN99_BASE_URL
    const API_QUESTIONS = '/api/v1/questions'

    let resStatus = 0

    fetch(`${BASE_URL}${API_QUESTIONS}`, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Ten99Policy-Environment": localStorage.getItem('Ten99PolicyEnvironment') || environments.PRODUCTION,
        "Ten99Policy-Organization": localStorage.getItem(
          "Ten99PolicyOrganization"
        )  || "",
      }
    })
      .then(response => {
        resStatus = response.status
        return response.json()
      })
      .then(data => {
        switch (resStatus) {
          case 401:
            this.setState({ error: data.msg, isLoading: false })
            break
          default:
            this.setState({ data, isLoading: false })
            break
        }
      })
      .catch(error => this.setState({ error, isLoading: false }))
  }

  renderQuestions = (questions) => (
    <ul>
      {
        questions.map((question, index) => (
          <li key={index}>{question.rule_description}</li>
        ))
      }
    </ul>
  )

  render = () => {
    if (this.state.error) {
      return <MainWrapper><p>{this.state.error}</p></MainWrapper>
    }

    return (
      this.state.isLoading || !this.state.data ?
        <MainWrapper isTestView={this.props.isTestView}>
          <div className="dtc v-mid tc">
            <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
          </div>
        </MainWrapper>
        :
        <MainWrapper isTestView={this.props.isTestView}>
          <AdminDisclaimer />
          <PageHeader title={'Class Questions'} />
          <div>
            <label className="dn">Select a job classification to see required questions</label>
            <Autocomplete
              wrapperProps={{ style: { display: 'block' } }}
              inputProps={{
                placeholder: "Select a job classification to see required questions",
                style: { background: "url(/search.svg) no-repeat scroll 14px 14px", paddingLeft: 45 },
                className: "outline-0 pa3 b--black-10 bw1 br2 mv2 f5 w-100",
                type: "text"
              }}
              getItemValue={(item) => item.simplified}
              items={this.state.data}
              renderItem={(item, isHighlighted) =>
                <div
                  className="pa2 f5"
                  style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                  {item.simplified}
                </div>
              }
              value={this.state.value}
              sortItems={sortQuestions}
              shouldItemRender={matchQuestionToTerm}
              onChange={(e) => this.setState({ 'value': e.target.value })}
              onSelect={(val, item) => { this.setState({ 'value': val, 'item': item }) }}
            />
          </div>
          {this.state.item ? this.renderQuestions(this.state.item.questions) : null}
        </MainWrapper>
    )
  }
}

export default withAuthorization(QuestionsAdminForm, ['admin'])