import React, { Component, useState, useEffect, useRef } from "react";
import ReactLoading from "react-loading";

import * as Yup from "yup";
import classNames from "classnames";

import { FilePond } from "react-filepond";

import { Formik, Field, Form } from "formik";

import StepWizard from "react-step-wizard";

import AutocompleteContractorInput from "../components/AutocompleteContractorInput";
import { environments } from "../constants/environments";
import { states } from "../constants/states";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

import "../styles/wizard.css";

const loadingSpinner = () => (
  <ReactLoading type={"spin"} color={"#cccccc"} width={14} height={14} />
);

const newContractorValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  locality: Yup.string().required("City is required"),
  region: Yup.string().required("State is required"),
});

function FileUpload(props) {
  const {
    setHasFile,
    contractor,
    setIsComplete,
    setIsLoading,
    setErrorMessage,
    setSuccessMessage,
  } = props;
  const [files, setFiles] = useState([]);

  const contractorId = contractor?.id || contractor?.publicId;

  const { token, pond } = props;
  const BASE_URL = process.env.REACT_APP_TEN99_BASE_URL;
  const BULK_UPLOAD_PATH = "/api/v1/bulk/certificate";
  const BULK_URL = `${BASE_URL}${BULK_UPLOAD_PATH}`;

  return (
    <div className="App">
      <FilePond
        ref={pond}
        instantUpload={false}
        onaddfile={() => setHasFile(true)}
        onremovefile={() => setHasFile(pond.current.getFiles().length > 1)}
        onprocessfilestart={() => {
          setIsComplete(false);
          setErrorMessage(null);
        }}
        onprocessfile={(error, file) => {
          setIsLoading(false);
          setIsComplete(true);

          if (file.serverId) {
            console.log("File uploaded", file.serverId);
          }
        }}
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={false}
        maxFiles={1}
        server={{
          url: BULK_URL,
          method: "POST",
          withCredentials: true,
          process: {
            headers: {
              Authorization: `Bearer ${token}`,
              "Ten99Policy-Environment":
                localStorage.getItem("Ten99PolicyEnvironment") ||
                environments.PRODUCTION,
              "Ten99Policy-Organization":
                localStorage.getItem("Ten99PolicyOrganization") || "",
            },
            ondata: (formData) => {
              formData.append("contractorId", contractorId);
              return formData;
            },
            onload: (response) => {
              const responseObject = JSON.parse(response);

              // Handle response here and set an error message if necessary
              if (!responseObject.task_id) {
                setErrorMessage(responseObject.message || "Upload failed.");
              } else {
                setSuccessMessage("Certificate uploaded successfully");
              }

              return responseObject.serverId || response;
            },
            onerror: (response) => {
              const responseObject = JSON.parse(response);

              setErrorMessage(
                responseObject.message || "An error occurred during the upload."
              );
            },
          },
        }}
        name="certificates"
        labelIdle='Drag & Drop your certificates or <span class="filepond--label-action">Browse</span>'
        credits={false}
      />
    </div>
  );
}

function ContractorForm(props) {
  const {
    isDisabled,
    isNewContractor,
    autoCompleteItem,
    createContractor,
    contractor,
    isLoading,
  } = props;

  const UPLOAD_STEP = 2;
  const { first_name, last_name, email, phone, company_name, address } =
    autoCompleteItem || {};

  const { line1, line2, locality, region, postalcode } = address || {};

  useEffect(() => {
    if (contractor) {
      props.nextStep();
    }
  }, [contractor]);

  const validationSchema = isNewContractor
    ? newContractorValidationSchema
    : Yup.object().shape({});

  return (
    <Formik
      initialValues={{
        firstName: first_name || "",
        lastName: last_name || "",
        email: email || "",
        phone: phone || "",
        companyName: company_name || "",
        line1: line1 || "",
        line2: line2 || "",
        locality: locality || "",
        region: region || "",
        postalcode: postalcode || "",
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        if (!isNewContractor) {
          props.goToStep(UPLOAD_STEP);
          return;
        }

        const { address, ...newValues } = values;

        createContractor({
          variables: {
            contractor: {
              ...newValues,
              locality: values.locality,
              region: values.region,
              postalcode: values.postalcode,
            },
          },
        });
      }}
    >
      {({ touched, errors, isValid, handleSubmit }) => (
        <Form>
          <div className="w-100 bt b--black-10 pt3">
            <div className="dn">
              <Field
                id="contractorId"
                name="contractorId"
                value={autoCompleteItem?.id}
              />
            </div>
            <div className="flex flex-wrap mb3">
              <div className="w-50">
                <label htmlFor="firstName" className="mb1 dn">
                  First Name
                </label>
                <Field
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  className={classNames(
                    "input-reset ba b--black-20 pa2 mb2 db w-100 br2 border-box",
                    { "bg-washed-red": touched.firstName && errors.firstName }
                  )}
                  disabled={isDisabled}
                />
              </div>
              <div className="w-50 pl2">
                <label htmlFor="lastName" className="mb1 dn">
                  Last Name
                </label>
                <Field
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  className={classNames(
                    "input-reset ba b--black-20 pa2 mb2 db w-100 br2 border-box",
                    { "bg-washed-red": touched.lastName && errors.lastName }
                  )}
                  disabled={isDisabled}
                />
              </div>
            </div>

            <div className="flex flex-wrap mb2">
              <div className="w-50 mb2">
                <label htmlFor="email" className="dn mb1">
                  Email
                </label>
                <Field
                  id="email"
                  name="email"
                  placeholder="Email"
                  className={classNames(
                    "input-reset ba b--black-20 pa2 mb2 db w-100 br2 border-box",
                    { "bg-washed-red": touched.email && errors.email }
                  )}
                  disabled={isDisabled}
                />
              </div>
              <div className="w-50 pl2 mb2">
                <label htmlFor="phone" className="dn mb1">
                  Phone
                </label>
                <Field
                  id="phone"
                  name="phone"
                  placeholder="Phone"
                  className="input-reset ba b--black-20 pa2 mb2 db w-100 br2 border-box"
                  disabled={isDisabled}
                />
              </div>
            </div>

            <div className="flex flex-wrap mb3">
              <label htmlFor="companyName" className="dn mb1">
                Company name
              </label>
              <Field
                id="companyName"
                name="companyName"
                placeholder="Company Name"
                className="input-reset ba b--black-20 pa2 mb2 db w-100 br2 border-box"
                disabled={isDisabled}
              />
            </div>

            <div className="flex flex-wrap mb3">
              <label htmlFor="line1" className="dn mb1">
                Line 1
              </label>
              <Field
                id="line1"
                name="line1"
                placeholder="Line 1"
                className="input-reset ba b--black-20 pa2 mb2 db w-100 br2"
                disabled={isDisabled}
              />
            </div>

            <div className="flex flex-wrap mb3">
              <label htmlFor="line2" className="dn mb1">
                Line 2
              </label>
              <Field
                id="line2"
                name="line2"
                placeholder="Line 2"
                className="input-reset ba b--black-20 pa2 mb2 db w-100 br2"
                disabled={isDisabled}
              />
            </div>

            <div className="flex flex-wrap mb3">
              <div className="w-40 mb2">
                <label htmlFor="locality" className="dn mb1">
                  City
                </label>
                <Field
                  id="locality"
                  name="locality"
                  placeholder="City"
                  className={classNames(
                    "input-reset ba b--black-20 pa2 mb2 db w-100 br2 border-box",
                    {
                      "bg-washed-red": touched.locality && errors.locality,
                    }
                  )}
                  disabled={isDisabled}
                />
              </div>
              <div className="w-20 pl2 mb2">
                <label htmlFor="region" className="dn mb1">
                  State
                </label>
                <Field
                  component="select"
                  id="region"
                  name="region"
                  placeholder="State"
                  className={classNames(
                    "input-reset ba b--black-20 pa2 mb2 db w-100 br2 border-box",
                    {
                      "bg-washed-red": touched.region && errors.region,
                    }
                  )}
                  disabled={isDisabled}
                >
                  <option value="None">State </option>
                  {states.map((state, index) => (
                    <option value={state.abbreviation} key={index}>
                      {state.abbreviation}
                    </option>
                  ))}
                </Field>
              </div>
              <div className="w-40 pl2 mb2">
                <label htmlFor="postalcode" className="dn mb1">
                  Postal Code
                </label>
                <Field
                  id="postalcode"
                  name="postalcode"
                  placeholder="Zip Code"
                  className="input-reset ba b--black-20 pa2 mb2 db w-100 br2 border-box"
                  disabled={isDisabled}
                />
              </div>
            </div>
            <div className="fr mt2">
              <button
                type="submit"
                className={classNames("f6 br2 ph3 pv2 dib white bg-blue bn", {
                  "cursor-not-allowed o-60": isNewContractor && !isValid,
                  "pointer hover-bg-blue-80 link dim":
                    !isNewContractor || isValid,
                })}
                disabled={isNewContractor && !isValid}
              >
                {isLoading ? loadingSpinner() : "Next"}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

function ContractorSelectStep(props) {
  const {
    hideModalSteps,
    autoCompleteItem,
    setAutoCompleteItem,
    createContractor,
    setContractor,
    contractor,
    isLoading,
  } = props;
  const [isNewContractor, setIsNewContractor] = useState(false);

  useEffect(() => {
    if (autoCompleteItem) {
      setIsNewContractor(false);
      setContractor(autoCompleteItem);
    }
  }, [autoCompleteItem]);

  const newContractor = () => {
    return <>Add new contractor</>;
  };

  const existingContractor = () => {
    return (
      <>
        <b>Contractor</b>: &nbsp; {autoCompleteItem?.first_name}{" "}
        {autoCompleteItem?.last_name} ({autoCompleteItem?.id})
      </>
    );
  };

  return (
    <div>
      <div
        className="dib w-100"
        style={{
          height:
            autoCompleteItem !== null || isNewContractor ? "540px" : "300px",
        }}
      >
        {!hideModalSteps && (
          <h2 className="f6 fw4 lh-copy ma0">
            <b>Step 1 of 2</b>: Select a contractor or{" "}
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                setIsNewContractor(true);
                setAutoCompleteItem(null);
              }}
            >
              create a new contractor
            </a>
          </h2>
        )}
        <div className="relative">
          <div className="flex flex-column justify-between items-end w-100">
            <div className="w-100 flex-item">
              <AutocompleteContractorInput
                setAutoCompleteItem={setAutoCompleteItem}
                menuTop="50px"
                menuLeft="0px"
                inputWidth="100%"
              />
            </div>
            <div className="flex-item pb2 f7">
              {isNewContractor && newContractor()}
              {autoCompleteItem && existingContractor()}
            </div>
          </div>
          {(isNewContractor || autoCompleteItem) && (
            <ContractorForm
              isLoading={isLoading}
              contractor={contractor}
              createContractor={createContractor}
              isNewContractor={isNewContractor}
              isDisabled={!isNewContractor}
              {...props}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function FileUploadStep(props) {
  const pond = useRef(null);

  const [hasFile, setHasFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const {
    hideModalSteps,
    setContractor,
    contractor,
    closeModal,
    setErrorMessage,
    setSuccessMessage,
    hideBackButton,
  } = props;

  const firstName = contractor?.first_name || contractor?.firstName;
  const lastName = contractor?.last_name || contractor?.lastName;
  const contractorId = contractor?.id || contractor?.publicId;

  const handleUpload = () => {
    if (pond.current) {
      setIsLoading(true);
      pond.current.processFiles();
    }
  };

  useEffect(() => {
    if (isComplete) {
      setContractor(null);
      closeModal();
    }
  }, [isComplete]);

  const existingContractor = () => {
    return (
      <>
        <b>Contractor</b>: &nbsp; {firstName} {lastName} ({contractorId})
      </>
    );
  };

  return (
    <div>
      <div className="pb4" style={{ height: "320px" }}>
        <div className="flex flex-wrap justify-between items-center">
          {!hideModalSteps && (
            <h2 className="f6 fw4 lh-copy">
              <b>Step 2 of 2</b>: Upload certificate of insurance
            </h2>
          )}
          <div className="f7">{contractor && existingContractor()}</div>
        </div>
        <div className="mv2">
          <FileUpload
            token={props.token}
            pond={pond}
            setHasFile={setHasFile}
            contractor={contractor}
            setIsComplete={setIsComplete}
            setIsLoading={setIsLoading}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
          />
        </div>
      </div>
      <div className="fr mt5">
        {!hideBackButton && (
          <button
            type="button"
            onClick={() => {
              setContractor(null);
              props.previousStep();
            }}
            className="f6 link dim br2 ba ph3 pv2 mh2 dib black pointer"
          >
            Back
          </button>
        )}
        <button
          type="button"
          onClick={handleUpload}
          className={classNames("f6 br2 ph3 pv2 dib white bg-blue bn", {
            "pointer hover-bg-blue-80 link dim": hasFile,
            "cursor-not-allowed o-60": !hasFile,
          })}
          disabled={!hasFile}
        >
          {isLoading ? loadingSpinner() : "Complete upload"}
        </button>
      </div>
    </div>
  );
}

function PDFUpload(props) {
  const {
    createContractor,
    loading,
    contractor,
    setContractor,
    setSuccessMessage,
    setErrorMessage,
    token,
    closeModal,
    uploadOnly,
  } = props;

  const stepWizard = useRef(null);
  const [autoCompleteItem, setAutoCompleteItem] = useState(null);

  const UPLOAD_STEP = 2;
  const hideModalSteps = uploadOnly ? uploadOnly : false;
  const selectedStep = uploadOnly ? UPLOAD_STEP : null;
  const hideBackButton = uploadOnly ? uploadOnly : false;

  let custom = {
    enterRight: "animated enterRight",
    enterLeft: "animated enterLeft",
    exitRight: "animated exitRight",
    exitLeft: "animated exitLeft",
    intro: "animated intro",
  };

  // Define goToSpecificStep function
  const goToSpecificStep = (stepNumber) => {
    if (stepWizard.current && stepWizard.current.goToStep) {
      stepWizard.current.goToStep(stepNumber);
    }
  };

  // Use useEffect to navigate to selectedStep when component mounts
  useEffect(() => {
    if (selectedStep !== null && selectedStep !== undefined) {
      goToSpecificStep(selectedStep);
    }
  }, [selectedStep]);

  return (
    <div>
      <StepWizard transitions={custom} ref={stepWizard}>
        <ContractorSelectStep
          hideModalSteps={hideModalSteps}
          autoCompleteItem={autoCompleteItem}
          setAutoCompleteItem={setAutoCompleteItem}
          createContractor={createContractor}
          setContractor={setContractor}
          contractor={contractor}
          isLoading={loading}
        />
        <FileUploadStep
          hideModalSteps={hideModalSteps}
          setContractor={setContractor}
          contractor={contractor}
          token={token}
          closeModal={closeModal}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          hideBackButton={hideBackButton}
        />
      </StepWizard>
    </div>
  );
}

export default PDFUpload;
