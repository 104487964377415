import React, { useState } from "react";
import classNames from "classnames";

const TabbedTable = ({
  filters,
  selectedFilter,
  setSelectedFilter,
  onTabChange,
  children,
}) => {
  const onClickHandler = (filter) => {
    setSelectedFilter(filter);
    onTabChange(filter);
  };

  return (
    <div>
      <div className="w-100 ba b--light-gray bb-0 br2 br--top">
        {filters.map((filter) => (
          <button
            key={filter}
            onClick={() => onClickHandler(filter)}
            className={classNames("f6 br1 ph3 pv3 bg-white pointer ttc", {
              "b--blue blue fw5 bb bt-0 bl-0 br-0": filter === selectedFilter,
              "b--light-gray black-50 bn": filter !== selectedFilter,
            })}
          >
            {filter}
          </button>
        ))}
      </div>
      <table className="w-100 ba b--light-gray collapse ph2 mb3">
        {children}
      </table>
    </div>
  );
};

export default TabbedTable;
