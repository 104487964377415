import React, { useState, useEffect, useRef } from "react";
import ReactLoading from 'react-loading';

import { Frames } from "frames-react";

import MainWrapper from '../../components/MainWrapper';
import AdminDisclaimer from '../../components/AdminDisclaimer';

import withAuthorization from '../../utils/withAuthorization';

import loadCheckout from "./loadCheckout";

import { gql, useMutation } from "@apollo/client";

const UPDATE_CREDIT_CARD = gql`
  mutation UpdateCreditCard( 
    $contractor: String!,
    $checkoutToken: String!
    ){
      updateCreditCard(
        contractor: $contractor,
        checkoutToken: $checkoutToken,
      ){
        ok
        message
      }
    }
`

function PaymentAdmin(props){
  const publicKey =
        process.env.REACT_APP_CHECKOUT_PUBLIC_KEY ||
        "pk_sbox_3pyuzxh7xdoaovh5dcr6vc2egyy";

  const [contractorInput, setContractorInput] = useState('');
  const contractorId = useRef();

  const [statusMessage, setStatusMessage] = useState()
  const [loaded, setLoaded] = useState(false);

  const [updateCreditCard, {loading, error, reset}] = useMutation(UPDATE_CREDIT_CARD, {
    onCompleted: (resp) => {
      const data = resp?.updateCreditCard;
      if(data.ok){
        setStatusMessage('Card updated successfully!')
      } else {
        setStatusMessage(`Failed to update card. ${data.message}`)
      }
      reset()
    },
    onError: () => {
      reset()
    },
  })

  useEffect(() => {
    loadCheckout(() => {
      setLoaded(true);
    });

    return function cleanup() {
      setLoaded(false);
    };
  }, []);

  function onCardTokenized(event){
    updateCreditCard({variables: {'contractor': contractorId.current.value, 'checkoutToken': event.token}})
  }

  function onCardTokenizationFailed(error) {
    setStatusMessage("CARD_TOKENIZATION_FAILED: %o", error);
  }

  function renderLoading(){
    return (
      <ReactLoading type={'spin'} color={'#cccccc'} className="ml1 dib" height={12} width={12} />
    )
  }

  function renderCheckout(){
    return (
      <MainWrapper isTestView={props.isTestView}>
        <AdminDisclaimer />
  
        <header className="mb3">
          <h2 className="fw3 dark-gray mt0 mb4">Update Payment Method</h2>
        </header>
  
        <Frames config={{
           publicKey: publicKey,
          }}
          cardTokenized={onCardTokenized}
          cardTokenizationFailed={onCardTokenizationFailed}
        >
          {
            statusMessage && (
            <div className="bg-washed-green dark-green measure pa2 tc">
              {statusMessage}
            </div>
            )
          }
            <div className="measure">
              <span className="f6 fw5 db mb2 mt3">Contractor ID</span>
              <div style={{height: '40px'}} className="ba b--black-50 pl2 mr2 pr0 mb4 db w-100 br2" >
                <input 
                  ref={contractorId}
                  id="contractor-id" 
                  value={contractorInput} 
                  onInput={e => {
                    setContractorInput(e.target.value)}
                  }
                  className="input-reset bn lh-copy pv2 bg-transparent w-100 outline-transparent" 
                  placeholder="e.g., cn_NtUB3n28B" 
                />
              </div>
            </div>
            <div className="measure">
              <div style={{height: '40px'}} className="card-frame ba b--black-50 br2"></div>
            </div>
            <div className="measure h3 mv3">
              <button 
              onClick={(event) => {
                event.preventDefault()
                Frames.submitCard();
              }}
              className="fr f6 link dim br2 ph3 pv2 mb2 dib white bg-blue bn pointer"
              type="button">
                Update Card
              </button>
            </div>
        </Frames>
      </MainWrapper>
    )
  }

  if(!loaded){
    return renderLoading()
  } else {
    return renderCheckout()
  }
}

export default withAuthorization(PaymentAdmin, ['admin']);