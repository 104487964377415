import React, { useState, useEffect } from 'react';

import MainWrapper from '../../../components/MainWrapper';
import { Tabs, Tab } from '../../../components/Tab'; // Adjust the import path as necessary

import InsuranceRequirementsTable from './Requirements';
import GeneralForm from './General';

const SettingsFormWrapper = (props) => {
  const {
    organization,
    isAdmin
  } = props;

  return (
    <>
      <>
        <header className="mb3">
        <h2 className="fw3 dark-gray mt0 mb4">Organization Settings</h2>
        <Tabs>
          <Tab label="General" routePath="settings" navPath="/organization/settings">
            <GeneralForm 
              isAdmin={isAdmin}
              {...props} />
          </Tab>
          <Tab label="Insurance Requirements" routePath="requirements/*" navPath="/organization/requirements">
            <InsuranceRequirementsTable
              isAdmin={isAdmin}
              {...props} />
          </Tab>
        </Tabs>
        </header>
      </>
    </>
  )
}

const Settings = (props) => {
  const { isTestView, organization } = props;
  
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1))
      if (elem) {
        elem.scrollIntoView({behavior: "smooth"})
      }
    } else {
      window.scrollTo({top: 0, left: 0, behavior: "smooth"})
    }
  }, [location,]);

  return (
    <MainWrapper isTestView={isTestView}>
      <SettingsFormWrapper organization={organization} isAdmin={props.isAdmin} />
    </MainWrapper>
  )
}

export { Settings }