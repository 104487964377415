import { gql } from "@apollo/client";
import { CORE_CERTIFICATE_AUDIT_FIELDS } from "./fragments";

export const CERTIFICATE_AUDIT_QUERY = gql`
  ${CORE_CERTIFICATE_AUDIT_FIELDS}
  query CertificateAudits($cursor: String) {
    certificateAudits(first:25, after: $cursor, sort:CREATED_DESC) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...CoreCertificateAuditFields
        }
      }
    }
  }
`

export const COVERAGE_RULES_QUERY = gql`
  query CoverageRules {
    coverageRules {
      id
    }
  }
`