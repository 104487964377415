import { gql } from "@apollo/client";
import { CORE_CONTRACTOR_FIELDS } from "./fragments";

const CONTRACTOR_BY_ID_QUERY = gql`
  ${CORE_CONTRACTOR_FIELDS}
  query ContractorByID($id: ID!) {
    contractor(id: $id) {
      ...CoreContractorFields
    }
  }
`

const CONTRACTOR_QUERY = gql`
  ${CORE_CONTRACTOR_FIELDS}
  query Contractor($cursor: String, $filter: String, $agencyRepEmails: [String], $entities: [String]) {
    contractors(first:25, after: $cursor, filter: $filter, agencyRepEmails: $agencyRepEmails, entities: $entities, sort:CREATED_DESC) {
      totalCount
      unredacted
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...CoreContractorFields
        }
      }
    }
  }
`;

const AGENCY_PAY_NO_PO_QUERY = gql`
  query AgencyPayNoPurchaseOrder {
    agencyPayNoPurchaseOrder{
      invoiceId
      fullName
      campaign
      coveragePeriod
      totalPremium
      grossPay
      platformAccessFee
      invoicePaidInFull
      platformAccessFeePaidInFull
    }
  }
`

const JOB_CATEGORY_QUERY = gql`
  query JobCategory {
    jobCategory{
      className
      code
    }
  }
`

const ASSIGNMENT_INVOICE_HISTORY = gql`
  query AssignmentInvoiceHistory($jobId: String!) {
    assignmentInvoiceHistory(jobId: $jobId) {
      id
      publicId
      grossPay
      premiumDue
      created
      voided
      paycycleStartdate
      paycycleEnddate
      refundStatus
      paidInFull
      agencyPayInvoiceId
    }
  }
`;

const ENTITY_QUERY = gql`
  query EntityName {
    entityNames {
      name
    }
  }
`

const AGENCY_REP_EMAIL_QUERY = gql`
  query AgencyRepEmail {
    agencyRepEmails {
      email
    }
  }
`


export {
    CONTRACTOR_BY_ID_QUERY,
    CONTRACTOR_QUERY,
    AGENCY_PAY_NO_PO_QUERY,
    JOB_CATEGORY_QUERY,
    ASSIGNMENT_INVOICE_HISTORY,
    ENTITY_QUERY,
    AGENCY_REP_EMAIL_QUERY
}