import React, { useContext, createContext, useState, useMemo, useEffect, useCallback } from 'react';
import ReactLoading from 'react-loading';
import MainWrapper from '../components/MainWrapper';
import CSVUpload from '../components/CSVUpload';
import AdminDisclaimer from '../components/AdminDisclaimer';
import PageHeader from '../components/PageHeader';
import Modal from 'react-modal';
let importContentStyle = {
  width                 : '640px',
  maxHeight             : '90vh',
}

let contentStyle = {
  top                   : '50%',
  left                  : '50%',
  right                 : 'auto',
  bottom                : 'auto',
  marginRight           : '-50%',
  transform             : 'translate(-50%, -50%)',
  boxShadow             : '2px 2px 8px 0px rgba( 0, 0, 0, 0.2 )',
  overflow              : 'hidden',
}
let importModalStyles = {
  content: {
    ...contentStyle,
    ...importContentStyle
  },
  overlay : {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  } 
}
function ImportResults(props){
  const {importResults} = props
  if (importResults?.processed === 0){
    return (
      <div>
      <h2 className="f5 fw4 lh-copy mb4"> Import error  </h2>
      </div>
    )
  }

  return(
    <div>
      <h2 className="f5 fw4 lh-copy mb4">
        Import complete - results
      </h2>
      <div className="f6 fw4 lh-copy">
        <span className="f4 fw6 b black-90">{importResults?.processed}</span> row(s) uploaded
      </div>
      <div className="f6 fw4 lh-copy">
        <span className="f4 fw6 b black-90">{importResults?.success}</span> rows successfully processed
      </div>
      <div className="f6 fw4 lh-copy gray">
        <span className="f4 fw6 b">{importResults?.failure?.length}</span> errors
      </div>
      <div className="f6 fw4 lh-copy gray">
        Total premium <span className="f4 fw6 b">{centsToDollars(importResults?.total_premium)}</span>
      </div>
      <div className="f6 fw4 lh-copy gray">
        Platform access fee <span className="f4 fw6 b">{centsToDollars(importResults?.platform_access_fee)}</span>
      </div>
      <div className="f6 fw4 lh-copy black">
        Grand total <span className="f4 fw6 b">{centsToDollars(importResults?.grand_total)}</span>
      </div>

      {importResults?.failure.length > 0 &&
        <div className="f6 fw4 lh-copy black-90 mt4">
          <span className="f5 fw6 b">Fail reason:</span>
          {importResults?.failure.map((label, idx) => 
            <span className="f6 fw4 db">{label}</span>
          )}
        </div>
      }
    </div>
  )
}

const Importing = () => (
  <div>
    <div className="tc db mb2">
      Importing...
    </div>
    <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
  </div>
)
function BulkImportModal(props){
  const [importStarted, setImportStarted] = useState(false)
  const [importComplete, setImportComplete] = useState(false)
  const [importResults, setImportResults] = useState();

  const renderModalBody = () => {
    if(importStarted && !importComplete){
      return(
        <Importing />
      )
    } else if(importResults){
      return(
      <div>
      DONE
      </div>
      )
    } else {
      return (
        <CSVUpload resource_type={'filing'} 
          client={props.client}
          setImportResults={setImportResults}
          toggleImportStarted={() => setImportStarted(!importStarted)}
          toggleImportComplete={() => setImportComplete(!importComplete)}
          closeModal={props.closeModal}
          token={props.token}
          columnMapping={ false }
        />
      )
    }
  }

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={() => {
        props.closeModal()
        setImportResults(null)
      }}
      style={importModalStyles}
      contentLabel="Import Filings"
      preventScroll={true}
    >
      <div className="roboto">
        <PageHeader title={'Import Filings'} />
        {renderModalBody()}
      </div>
    </Modal>
  )
}

function GetStarted(props) {
  return (
    <div>
      <p className="lh-copy f6 fw3 black-70">
      Persist the filed job activity reports on this page.
      </p>
      <p className="lh-copy f6 fw3 black-70">
      </p>
      <p className="mt4">
      <button 
        type="button" 
        onClick={props.openModal}
        className="f6 link dim br2 ph3 pv2 mb2 dib white bg-blue bn pointer">
        Import Filings
      </button>
      </p>
    </div>
  )
}
function FilingAdmin(props){
  const [successMessage, setSuccessMessage] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const renderSuccess = (message) => (
    <div className="br2 f6 flex items-center justify-center pa2 bg-washed-green green mb4">
      <span className="lh-title ml3">{message}</span>
    </div>
  )

  return (
    <MainWrapper isTestView={props.isTestView}>
      {successMessage ? renderSuccess(successMessage) : <AdminDisclaimer />}
      <GetStarted openModal={() => setModalIsOpen(true)} />
      <BulkImportModal 
        openModal={() => setModalIsOpen(true)}
        closeModal={() => setModalIsOpen(false)}
        modalIsOpen={modalIsOpen}
        token={props.token}
        client={props.client}
      />
      
      </MainWrapper>
  )
}

export default FilingAdmin;