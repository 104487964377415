export const columnKeys = [
  "entity_name",
  "first_name",
  "last_name",
  "contractor_company_name",
  "email",
  "job_name",
  "job_description",
  "primary_work_state",
  "home_address_line1",
  "home_address_line2",
  "home_address_locality",
  "home_address_region",
  "home_address_postalcode",
  "legal_entity_type",
  "tax_id",
  "phone",
  "num_of_employees",
  "wage",
  "effective_start_date",
  "effective_end_date",
  "coverage_tier",
  "purchase_order_number",
  "coverage_type",
  "withhold_premium",
  "purchase_order_contact_name",
  "purchase_order_contact_email",
];
