import * as Yup from 'yup';

import { getIn } from 'formik';

const getNestedValue = (path, initialValues) => {
  // Correctly handle paths with array indices, e.g., "jobs[0].job.wage"
  const segments = path.replace(/\[['"]?|['"]?\]/g, '.').split('.').filter(Boolean);
  return segments.reduce((acc, key) => {
    return acc && acc[key] !== undefined ? acc[key] : undefined;
  }, initialValues);
};

function getStyles(errors, fieldName, touched) {
  if (getIn(errors, fieldName) && getIn(touched, fieldName)) {
    return {
      border: '1px solid red',
      backgroundColor: '#ffdfdf'
    }
  }
}

// Function to create a reusable test for not-null validation
const createNotNullTest = (initialValues, errorMessage) => {
  return Yup.mixed().test(
    'not-null',
    errorMessage,
    function (value) {
      const { path, createError } = this;
      const initialValue = initialValues[path];
      if (initialValue !== null && initialValue !== undefined && (value === null || value === undefined)) {
        return createError({ path, message: errorMessage });
      }
      return true;
    }
  );
};

const nestedCreateNotNullTest = (initialValues, errorMessage) => {
  return Yup.mixed().test(
    'not-null',
    errorMessage,
    function(value) {
      const { path, createError } = this;
      const initialValue = getNestedValue(path, initialValues);
      
      if (initialValue !== null && initialValue !== undefined && (value === null || value === undefined || value === '')) {
        return createError({ path, message: errorMessage });
      }
      return true;
    }
  );
};

// Assuming you have a way to access or pass initial values for the validation schema
// This function now accepts `initialValues` to apply the custom validation logic
const createValidationSchema = (initialValues) => {
  const entitySchema = Yup.object().shape({
    // Apply the custom test to the 'name' field within the entity schema
    name: nestedCreateNotNullTest(initialValues, 'Client name cannot be cleared')
    // Add other fields of the entity here
  });

  const jobSchema = Yup.object().shape({
    entity: entitySchema,
    wage: nestedCreateNotNullTest(initialValues, 'Wage cannot be cleared')
      .test(
        'greater-than-zero',
        'Wage must be greater than 0',
        value => value === undefined || value === null || value > 0
      ),
  });

  const nodeSchema = Yup.object().shape({
    node: Yup.object().shape({
      job: jobSchema,
    }),
    // Add other fields of the node here
  });

  // The overall schema for the array of jobs, now dependent on initialValues
  const jobsSchema = Yup.array().of(nodeSchema);

  // Return the complete validation schema including jobs and potentially other fields
  return Yup.object().shape({
    jobs: jobsSchema,
    firstName: createNotNullTest(initialValues, 'First name cannot be cleared'),
    lastName: createNotNullTest(initialValues, 'Last name cannot be cleared'),
    email: createNotNullTest(initialValues, 'Email cannot be cleared'),
    locality: createNotNullTest(initialValues, 'Locality cannot be cleared'),
    // Add other top-level fields and validations as needed
  });
};

export {
  createValidationSchema,
  getStyles
};